import React from "react";
import "./TemplateKeywords.scss";

const TemplateKeywords = ({
  TEMPLATE_KEYWORDS,
  handleTemplate,
  open_up = false,
}: any) => {
  return (
    <aside>
      <div className={`template-keyword ${open_up ? "open-up" : ""}`}>
        {Object.keys(TEMPLATE_KEYWORDS).map((key: any) => {
          return (
            <>
              <h4>{key}</h4>
              {TEMPLATE_KEYWORDS[key].map((keywords: any) => {
                return (
                  <p>
                    {keywords.map((keyword: any) => (
                      <mark onClick={() => handleTemplate(`${keyword}`)}>
                        {keyword}
                      </mark>
                    ))}
                  </p>
                );
              })}
            </>
          );
        })}
      </div>
    </aside>
  );
};

export default TemplateKeywords;
