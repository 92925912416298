import { Dispatch } from "redux";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "./index";
import { UPDATE_CONTACT, IConversation } from "./conversations";
import { USERS_LOGOUT_USER_REQUEST } from "./user";
import { conversationActions } from "./conversations";
import { MODAL_SHOW } from "./modal";

export interface IExternalContactForm {
  first_name: string;
  last_name: string;
  notes?: string;
  phone_number: string;
  in_contacts?: boolean;
  is_deleted?: boolean;
  company?: string;
  job_title?: string;
  email?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zipcode: string;
  custom_field_1?: string;
  custom_field_2?: string;
  custom_field_3?: string;
}
export interface IExternalContact extends IExternalContactForm {
  id: string;
}
export interface IInternalContactPhone {
  created_at: string;
  id: number;
  is_deleted: boolean;
  phone_number: string;
  timezone: string;
  title: string;
}
export interface IInternalContact {
  auto_status: number;
  created: string;
  custom_status: number;
  default_phone_id: number;
  display_as: string;
  email: string;
  first_name: string;
  id: string;
  // isPasswordExpired: boolean;
  last_name: string;
  // must_change_password: boolean;
  notes: string;
  // password: string;
  // password_changed_at: string;
  // password_reset_expiries: string;
  // password_reset_token: string | null;
  // passwords: string[];
  phones: IInternalContactPhone[];
  // registration_status: number;
  // reports_to: string;
  // send_email_invite: boolean;
  status: number;
  // tempPassword: string;
  timezone: string;
  title: string;
  username: string;
}

// ------------------------------------
// Constants
// ------------------------------------
export const CONTACTS_CLEAR_ERRORS = "CONTACTS_CLEAR_ERRORS";

export const CONTACTS_CREATE_REQUEST = "CONTACTS_CREATE_REQUEST";
export const CONTACTS_CREATE_SUCCESS = "CONTACTS_CREATE_SUCCESS";
export const CONTACTS_CREATE_FAILURE = "CONTACTS_CREATE_FAILURE";

export const CONTACTS_DELETE_REQUEST = "CONTACTS_DELETE_REQUEST";
export const CONTACTS_DELETE_SUCCESS = "CONTACTS_DELETE_SUCCESS";
export const CONTACTS_DELETE_FAILURE = "CONTACTS_DELETE_FAILURE";

export const CONTACTS_GET_REQUEST = "CONTACTS_GET_REQUEST";
export const CONTACTS_GET_SUCCESS = "CONTACTS_GET_SUCCESS";
export const CONTACTS_GET_FAILURE = "CONTACTS_GET_FAILURE";

export const CONTACTS_UPDATE_REQUEST = "CONTACTS_UPDATE_REQUEST";
export const CONTACTS_UPDATE_SUCCESS = "CONTACTS_UPDATE_SUCCESS";
export const CONTACTS_UPDATE_FAILURE = "CONTACTS_UPDATE_FAILURE";

export const CONTACTS_IMPORT_REQUEST = "CONTACTS_IMPORT_REQUEST";
export const CONTACTS_IMPORT_SUCCESS = "CONTACTS_IMPORT_SUCCESS";
export const CONTACTS_IMPORT_FAILURE = "CONTACTS_IMPORT_FAILURE";

const SET_ACTIVE_CONTACT = "SET_ACTIVE_CONTACT";
const SET_NEW_CONTACT = "SET_NEW_CONTACT";
const CLEAR_ACTIVE_CONTACT = "CLEAR_ACTIVE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";

// ------------------------------------
// Action Creators
// ------------------------------------
const clearContactErrors = () => ({ type: CONTACTS_CLEAR_ERRORS });

const createContactRequest = () => ({ type: CONTACTS_CREATE_REQUEST });
const createContactSuccess = (res: AxiosResponse) => ({
  type: CONTACTS_CREATE_SUCCESS,
  payload: res.data,
});
const createContactFailure = (err: AxiosError) => ({
  type: CONTACTS_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteContactRequest = () => ({ type: CONTACTS_DELETE_REQUEST });
const deleteContactSuccess = (contactId: number) => ({
  type: CONTACTS_DELETE_SUCCESS,
  payload: contactId,
});
const deleteContactFailure = (err: AxiosError) => ({
  type: CONTACTS_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const getContactsRequest = () => ({ type: CONTACTS_GET_REQUEST });
const getContactsSuccess = (res: AxiosResponse) => ({
  type: CONTACTS_GET_SUCCESS,
  payload: res.data.sort((a: any, b: any) =>
    a.is_deleted !== true && b.is_deleted === true
      ? -1
      : b.is_deleted !== true && a.is_deleted === true
      ? 1
      : 0
  ),
});
const getContactsFailure = (err: AxiosError) => ({
  type: CONTACTS_GET_FAILURE,
  payload: err && err.response && err.response.data,
});

const setActiveContact = (id: string) => ({
  type: SET_ACTIVE_CONTACT,
  payload: id,
});

const updateContactRequest = () => ({ type: CONTACTS_UPDATE_REQUEST });
const updateContactSuccess = (res: AxiosResponse) => ({
  type: CONTACTS_UPDATE_SUCCESS,
  payload: res.data,
});
const updateContactFailure = (err: AxiosError) => ({
  type: CONTACTS_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const importContactRequest = () => ({ type: CONTACTS_IMPORT_REQUEST });
const importContactSuccess = (res: AxiosResponse) => ({
  type: CONTACTS_IMPORT_SUCCESS,
  payload: res.data,
});
const importContactFailure = (err: AxiosError) => ({
  type: CONTACTS_IMPORT_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteContactStore = (contact: IExternalContact) => ({
  type: DELETE_CONTACT,
  payload: contact,
});

// ------------------------------------
// Actions
// ------------------------------------
const clearActiveContact = () => (dispatch: Dispatch) => {
  dispatch({ type: CLEAR_ACTIVE_CONTACT });
};

const clearErrors = () => (dispatch: Dispatch) => {
  dispatch(clearContactErrors());
};

const createContact = (form: IExternalContact) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  return new Promise(async (resolve, reject) => {
    dispatch(createContactRequest());
    try {
      form.phone_number = "1" + form.phone_number;
      const { id, token } = getState().users.authenticatedUser;
      const res = await Axios.post(
        `${API_URL}/users/${id}/external_contacts`,
        form,
        withAuthorizationHeader(token)
      );
      dispatch(createContactSuccess(res));
      dispatch(conversationActions.updateConversationName(res));
      resolve(res.data);
    } catch (err) {
      dispatch(createContactFailure(err));
      reject(err && err.response && err.response.data);
    }
  });
};

const deleteContact = (contactId: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token } = getState().users.authenticatedUser;
  if (id && token && contactId) {
    try {
      dispatch(deleteContactRequest());
      const response = await Axios.delete(
        `${API_URL}/users/${id}/external_contacts/${contactId}`,
        withAuthorizationHeader(token)
      );
      dispatch(deleteContactSuccess(contactId));
      dispatch(deleteContactStore(response.data.data));
    } catch (err) {
      dispatch(deleteContactFailure(err));
    }
  }
};

const getContactsList = () => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const authenticatedUser = getState().users.authenticatedUser;
  if (authenticatedUser) {
    const { id, token } = authenticatedUser;
    if (id && token) {
      try {
        dispatch(getContactsRequest());
        // const res = await Axios.get(
        //   `${API_URL}/users/${id}/contacts`,
        //   withAuthorizationHeader(token)
        // );
        const res = await Axios.get(
          `${API_URL}/users/${id}/contacts-sumarry`,
          withAuthorizationHeader(token)
        );
        dispatch(getContactsSuccess(res));
      } catch (err) {
        dispatch(getContactsFailure(err));
      }
    }
  }
};

const setContact = (id: string) => (dispatch: Dispatch) => {
  dispatch(setActiveContact(id));
};

const setNewContact = (phone_number: string) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { data } = getState().conversations;
  const newConversation = data.find((conversation: IConversation) => {
    return conversation.external.find(
      external => external.phone_number === phone_number
    );
  });
  if (newConversation && newConversation.external) {
    const newContact = newConversation.external.find(
      ex => ex.phone_number === phone_number
    );
    dispatch({ type: SET_NEW_CONTACT, payload: newContact });
  }
};

const updateContact = (contactId: number, form: IExternalContact) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token } = getState().users.authenticatedUser;
  return new Promise(async (resolve, reject) => {
    if (id && token && contactId && form) {
      try {
        dispatch(updateContactRequest());
        if (
          form.phone_number.length === 10 &&
          form.phone_number.indexOf("1") !== 0
        ) {
          form.phone_number = "1" + form.phone_number;
        }
        const res = await Axios.put(
          `${API_URL}/users/${id}/external_contacts/${contactId}`,
          form,
          withAuthorizationHeader(token)
        );
        dispatch(updateContactSuccess(res));
        dispatch(conversationActions.updateConversationName(res));
        resolve(res);
      } catch (err) {
        dispatch(updateContactFailure(err));
        reject(err && err.response && err.response.data);
      }
    }
  });
};

const importContacts = (contacts: IExternalContact[]) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  return new Promise(async (resolve, reject) => {
    dispatch(importContactRequest());
    try {
      const { id, token } = getState().users.authenticatedUser;
      const res = await Axios.post(
        `${API_URL}/users/${id}/external_contacts/import`,
        { contacts },
        withAuthorizationHeader(token)
      );
      dispatch(importContactSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(importContactFailure(err));
      reject(err);
    }
  });
};

export const contactsActions = {
  clearActiveContact,
  clearErrors,
  createContact,
  deleteContact,
  getContactsList,
  setContact,
  setNewContact,
  updateContact,
  importContacts,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeContact: null,
  isFetching: false,
  data: [],
  errors: [],
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  let data: any;
  switch (type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case CONTACTS_CREATE_REQUEST:
    case CONTACTS_DELETE_REQUEST:
    case CONTACTS_GET_REQUEST:
    case CONTACTS_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case MODAL_SHOW:
    case CONTACTS_CLEAR_ERRORS:
      return { ...state, errors: [] };

    case CONTACTS_CREATE_SUCCESS:
      data = { ...payload, phone_number: payload.phone_number };
      return { ...state, isFetching: false, data: [...state.data, data] };

    case CONTACTS_DELETE_SUCCESS:
      return {
        ...state,
        activeContact: null,
        isFetching: false,
        data: state.data.filter(
          (item: any) =>
            !(item.hasOwnProperty("is_deleted") && item.id === payload)
        ),
      };

    case CONTACTS_GET_SUCCESS:
      data = payload.map((contact: IExternalContact) =>
        contact.phone_number
          ? { ...contact, phone_number: contact.phone_number }
          : contact
      );
      return { ...state, isFetching: false, data };

    case UPDATE_CONTACT:
    case CONTACTS_UPDATE_SUCCESS:
      data = { ...payload, phone_number: payload.phone_number };
      return {
        ...state,
        activeContact: data,
        isFetching: false,
        data: state.data.map((item: any) =>
          item.hasOwnProperty("is_deleted") && item.id === payload.id
            ? data
            : item
        ),
      };

    case SET_ACTIVE_CONTACT:
      return {
        ...state,
        activeContact:
          state.data.find((item: any) => item.id === payload) || null,
      };

    case SET_NEW_CONTACT:
      return {
        ...state,
        activeContact: {
          ...payload,
          first_name: "",
          last_name: "",
          notes: "",
          phone_number: payload.phone_number,
          in_contacts: false,
          is_deleted: false,
        },
      };

    case CLEAR_ACTIVE_CONTACT:
      return { ...state, activeContact: null };

    case CONTACTS_CREATE_FAILURE:
    case CONTACTS_IMPORT_FAILURE:
    case CONTACTS_DELETE_FAILURE:
    case CONTACTS_GET_FAILURE:
    case CONTACTS_UPDATE_FAILURE:
      const errors = payload && payload.messages ? payload.messages : [];
      return { ...state, isFetching: false, errors };

    default:
      return state;
  }
};
