import { Dispatch, AnyAction } from "redux";
import { CONVERSATIONS_REMOVE_MESSAGE_FAILURE } from "./conversations";

// ------------------------------------
// Constants
// ------------------------------------
export const MODAL_SHOW = "MODAL_SHOW";
export const MODAL_HIDE = "MODAL_HIDE";
export const MODAL_SET_STATE = "MODAL_SET_STATE";

// ------------------------------------
// Actions
// ------------------------------------
const openModal = (modalType: string, modalProps = {}) => (
  dispatch: Dispatch
) => {
  dispatch({ type: MODAL_SHOW, payload: { modalType, modalProps } });
};

const closeModal = () => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_HIDE });
};

const setModalState = (payload: any) => (dispatch: Dispatch) => {
  dispatch({ type: MODAL_SET_STATE, payload });
};

export const modalActions = {
  closeModal,
  openModal,
  setModalState,
};

// ------------------------------------
// State
// ------------------------------------
const initialState = {
  modalType: "",
  modalProps: {},
  initProps: {
    contactsModal: {},
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case MODAL_SHOW:
      localStorage.removeItem("modalForm");
      return {
        modalType: action.payload.modalType,
        modalProps: action.payload.modalProps,
      };
    case MODAL_HIDE:
      return initialState;
    case MODAL_SET_STATE:
      return { ...state, initProps: action.payload };
    case CONVERSATIONS_REMOVE_MESSAGE_FAILURE:
      return {
        ...state,
        modalProps: { ...state.modalProps, errors: action.payload },
      };
    default:
      return state;
  }
};
