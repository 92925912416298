import React, { ChangeEvent, PureComponent } from "react";
import "./ThemedCheckbox.scss";
import { connect } from "react-redux";
import { LightenDarkenColor, ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}

interface OwnProps {
  id: string;
  text: string;
  checked: boolean;
  onChange: (e: ChangeEvent) => void;
  className?: string;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedCheckbox extends PureComponent<Props> {
  render() {
    let brandColor;

    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor = LightenDarkenColor(
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme,
        30
      );
    }

    return (
      <React.Fragment>
        <input
          checked={!!this.props.checked}
          id={this.props.id}
          onChange={this.props.onChange}
          type="checkbox"
        />
        <label htmlFor={this.props.id} className={this.props.className || ""}>
          <span
            style={
              brandColor && !!this.props.checked
                ? { backgroundColor: brandColor, borderColor: brandColor }
                : {}
            }
          ></span>
          {this.props.text}
        </label>
      </React.Fragment>
    );
  }
}

ThemedCheckbox.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedCheckbox);
