import React from "react";

namespace AsyncComponent {
  export interface State {
    Component: any;
  }
}

export default function asyncComponent(getComponent: any): any {
  class AsyncComponent extends React.Component<{}, AsyncComponent.State> {
    constructor(props: any) {
      super(props);
      this.state = { Component: null };
    }

    async componentDidMount() {
      const { default: Component } = await getComponent();
      this.setState({ Component });
    }

    render() {
      const C = this.state.Component;
      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}
