import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { usersActions, IResetPasswordForm } from "~/store/user";
import Loader from "~/components/common/Loader/Loader";
import { EMAIL_REGEXP } from "~/config";
import { AxiosResponse } from "axios";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

interface DispatchProps {
  resetPassword: (form: any) => Promise<{}>;
}

interface OwnProps extends RouteComponentProps {}

interface StateProps {
  authenticatedUser: any;
  authenticationError: any[];
  isFetching: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  form: IResetPasswordForm;
  result: string;
};

const mapDispatchToProps = {
  resetPassword: usersActions.resetPassword,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  authenticationError: state.users.errors,
  isFetching: state.users.isFetching,
});

class ResetPasswordForm extends PureComponent<Props, State> {
  state = {
    form: {
      email: "",
    },
    result: "",
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Validate form fields here

    // Send form
    if (EMAIL_REGEXP.test(this.state.form.email)) {
      this.props
        .resetPassword(this.state.form)
        .then((res: AxiosResponse) => this.setState({ result: res.data }));
    }
  };

  handleFieldChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { name, value } = e.target as HTMLInputElement;
    this.setState({ form: { ...this.state.form, [name]: value } });
  };

  render() {
    return this.state.result ? (
      <p className="infoResult">{this.state.result}</p>
    ) : (
      <form className="form" onSubmit={this.handleSubmit}>
        <h1>Reset Password</h1>
        <p className="info">
          Password reset link will be sent to the email address below
        </p>
        <ThemedInput
          autoFocus
          className="input"
          name="email"
          onChange={this.handleFieldChange}
          placeholder="Enter your email"
          required
          type="email"
          value={this.state.form.email}
        />
        <ThemedButton
          className="submit"
          disabled={this.props.isFetching}
          type="submit"
        >
          {this.props.isFetching ? <Loader /> : "Send"}
        </ThemedButton>
        <Link to="/login" className="prt-login-form-pass">
          Back to Sign In
        </Link>
      </form>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResetPasswordForm)
);
