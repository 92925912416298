import Axios, { AxiosResponse, AxiosError } from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

// ------------------------------------
// Constants
// ------------------------------------
const STATUS_CREATE_REQUEST = "STATUS_CREATE_REQUEST";
const STATUS_CREATE_SUCCESS = "STATUS_CREATE_SUCCESS";
const STATUS_CREATE_FAILURE = "STATUS_CREATE_FAILURE";

const STATUS_DELETE_REQUEST = "STATUS_DELETE_REQUEST";
const STATUS_DELETE_SUCCESS = "STATUS_DELETE_SUCCESS";
const STATUS_DELETE_FAILURE = "STATUS_DELETE_FAILURE";

const STATUS_LIST_REQUEST = "STATUS_LIST_REQUEST";
const STATUS_LIST_SUCCESS = "STATUS_LIST_SUCCESS";
const STATUS_LIST_FAILURE = "STATUS_LIST_FAILURE";

const STATUS_SET_REQUEST = "STATUS_SET_REQUEST";
export const STATUS_SET_SUCCESS = "STATUS_SET_SUCCESS";
const STATUS_SET_FAILURE = "STATUS_SET_FAILURE";

const STATUS_UPDATE_REQUEST = "STATUS_UPDATE_REQUEST";
const STATUS_UPDATE_SUCCESS = "STATUS_UPDATE_SUCCESS";
const STATUS_UPDATE_FAILURE = "STATUS_UPDATE_FAILURE";

const STATUS_UPDATE = "STATUS_UPDATE";

// ------------------------------------
// Interfaces
// ------------------------------------
export interface IStatusForm {
  color: string;
  id?: number;
  title: string;
  uid?: string;
}

export interface IStatus {
  user_id: string;
  status: {
    class: string;
    title: string;
  };
}

// ------------------------------------
// Action Creators
// ------------------------------------
const createStatusRequest = () => ({ type: STATUS_CREATE_REQUEST });
const createStatusSuccess = (res: AxiosResponse) => ({
  type: STATUS_CREATE_SUCCESS,
  payload: res.data,
});
const createStatusFailure = (err: AxiosError) => ({
  type: STATUS_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteStatusRequest = () => ({ type: STATUS_DELETE_REQUEST });
const deleteStatusSuccess = (res: number) => ({
  type: STATUS_DELETE_SUCCESS,
  payload: res,
});
const deleteStatusFailure = (err: AxiosError) => ({
  type: STATUS_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listStatusesRequest = () => ({ type: STATUS_LIST_REQUEST });
const listStatusesSuccess = (res: AxiosResponse) => ({
  type: STATUS_LIST_SUCCESS,
  payload: res.data,
});
const listStatusesFailure = (err: AxiosError) => ({
  type: STATUS_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const setStatusRequest = () => ({ type: STATUS_SET_REQUEST });
const setStatusSuccess = (res: AxiosResponse) => ({
  type: STATUS_SET_SUCCESS,
  payload: res.data,
});
const setStatusFailure = (err: AxiosError) => ({
  type: STATUS_SET_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateStatusRequest = () => ({ type: STATUS_UPDATE_REQUEST });
const updateStatusSuccess = (res: AxiosResponse) => ({
  type: STATUS_UPDATE_SUCCESS,
  payload: res.data.data,
});
const updateStatusFailure = (err: AxiosError) => ({
  type: STATUS_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const statusUpdate = (payload: IStatus[]) => ({ type: STATUS_UPDATE, payload });

// ------------------------------------
// Actions
// ------------------------------------
const createStatus = (form: IStatusForm) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      dispatch(createStatusRequest());
      const res = await Axios.post(
        `${API_URL}/statuses`,
        form,
        withAuthorizationHeader(token)
      );
      dispatch(createStatusSuccess(res));
    } catch (err) {
      dispatch(createStatusFailure(err));
    }
  }
};

const deleteStatus = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      dispatch(deleteStatusRequest());
      const res = await Axios.delete(
        `${API_URL}/statuses/${id}`,
        withAuthorizationHeader(token)
      );
      dispatch(deleteStatusSuccess(id));
    } catch (err) {
      dispatch(deleteStatusFailure(err));
    }
  }
};

const listStatuses = () => async (dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      dispatch(listStatusesRequest());
      const res = await Axios.get(
        `${API_URL}/statuses`,
        withAuthorizationHeader(token)
      );
      dispatch(listStatusesSuccess(res));
    } catch (err) {
      dispatch(listStatusesFailure(err));
    }
  }
};

const setStatus = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      dispatch(setStatusRequest());
      const res = await Axios.put(
        `${API_URL}/statuses`,
        { id },
        withAuthorizationHeader(token)
      );
      dispatch(setStatusSuccess(res));
    } catch (err) {
      dispatch(setStatusFailure(err));
    }
  }
};

const updateStatus = (form: IStatusForm) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      dispatch(updateStatusRequest());
      const res = await Axios.put(
        `${API_URL}/statuses/${form.id}`,
        form,
        withAuthorizationHeader(token)
      );
      dispatch(updateStatusSuccess(res));
    } catch (err) {
      dispatch(updateStatusFailure(err));
    }
  }
};

const logoutStatus = () => async (_dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  if (token) {
    try {
      Axios.post(`${API_URL}/statuses/logout`, withAuthorizationHeader(token));
    } catch (err) {}
  }
};

const setUserStatus = (statuses: IStatus[]) => (dispatch: Dispatch) => {
  dispatch(statusUpdate(statuses));
};

export const statusActions = {
  createStatus,
  deleteStatus,
  listStatuses,
  setStatus,
  updateStatus,
  logoutStatus,
  setUserStatus,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  data: [],
  errors: [],
  currentStatusId: -1,
  isFetching: false,
  statuses: {},
};

export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case STATUS_CREATE_REQUEST:
    case STATUS_DELETE_REQUEST:
    case STATUS_LIST_REQUEST:
    case STATUS_SET_REQUEST:
    case STATUS_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case STATUS_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [...state.data, payload.data],
      };

    case STATUS_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [
          ...state.data.filter((status: IStatusForm) => status.id !== payload),
        ],
      };

    case STATUS_LIST_SUCCESS:
      return { ...state, isFetching: false, data: payload.data };

    case STATUS_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: [
          ...state.data.map((status: IStatusForm) =>
            status.id === payload.id ? payload : status
          ),
        ],
      };

    case STATUS_CREATE_FAILURE:
    case STATUS_DELETE_FAILURE:
    case STATUS_LIST_FAILURE:
    case STATUS_SET_FAILURE:
    case STATUS_UPDATE_FAILURE:
      return { ...state, isFetching: false, errors: payload };
    case STATUS_UPDATE:
      return { ...state, statuses: { ...state.statuses, ...payload } };

    default:
      return state;
  }
};
