import { History } from "history";
import * as _ from "lodash";
import React, { Component } from "react";
import { connect } from "react-redux";
import { match, withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import Chat from "~/components/Chat/Chat";
import Loader from "~/components/common/Loader/Loader";
import ConversationsPanel from "~/components/ConversationsPanel/ConversationsPanel";
import Header from "~/components/Header/Header";
import Sidebar from "~/components/Sidebar/Sidebar";
import SidePanel from "~/components/SidePanel/SidePanel";
import { conversationActions } from "~/store/conversations";
import { listsActions, IList } from "~/store/lists";
import {
  IPrebuiltReply,
  prebuiltRepliesActions,
} from "~/store/replies_prebuilt";
import "./ChatView.scss";
import classNames from "classnames";
import SchedulerPanel from "~/components/ScheulerPanel/SchedulerPanel";

namespace ChatView {
  interface StateProps {
    activeConversation: string;
    authenticatedUser: any;
    isFetchingLists: boolean;
    isFetchingPrebuiltReplies: boolean;
    lists: IList[] | null;
    location: Location;
    prebuiltReplies: IPrebuiltReply[] | null;
    isSchedulePanelOpen: boolean;
  }
  interface DispatchProps {
    listLists: () => void;
    listReplies: () => void;
    openConversation: (conversationId: number) => void;
  }
  interface OwnProps {
    history: History;
    match: match;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
  export type State = {
    isSidebarOpen: boolean;
    panelShown: string;
    isBlastOpen: boolean;
    showClipBoard: boolean;
    menuOpenCount: number;
    brandColor: string;
  };
}

const mapStateToProps = (state: any) => ({
  activeConversation: state.conversations.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  isFetchingLists: state.lists.isFetching,
  isFetchingPrebuiltReplies: state.prebuiltReplies.isFetching,
  lists: state.lists.data,
  location: state.location,
  prebuiltReplies: state.prebuiltReplies.data,
  isSchedulePanelOpen: state.schedule_panel.isSchedulePanelOpen,
});

const mapDispatchToProps = {
  listLists: listsActions.getList,
  listReplies: prebuiltRepliesActions.listPrebuiltReplies,
  openConversation: conversationActions.openConversation,
};

@(withRouter as any)
@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
export default class ChatView extends Component<
  ChatView.Props,
  ChatView.State
> {
  state = {
    isSidebarOpen: false,
    panelShown: "conversations",
    isBlastOpen: false,
    showClipBoard: false,
    menuOpenCount: 0,
    brandColor: "",
    // isScheduleMessageOpen: false,
  };

  componentDidUpdate() {
    /* Prefetch a list of the suggested replies.
     * We need it to be able to count how much suggested replies
     * matched with last message of active conversation.
     */
    // if (
    //   this.props.prebuiltReplies === null &&
    //   this.props.authenticatedUser &&
    //   this.props.authenticatedUser.activePhone &&
    //   !this.props.isFetchingPrebuiltReplies
    // ) {
    //   this.props.listReplies();
    // }

    /* Prefetch a list of the blast lists.
     * We might need it as a data source to search through it
     * while forming search results.
     */
    if (
      this.props.lists === null &&
      this.props.authenticatedUser !== null &&
      !this.props.isFetchingLists
    ) {
      this.props.listLists();
    }
  }

  onBack = () => this.setState({ panelShown: "conversations" });

  showClipboard = () => {
    this.setState({ ...this.state, showClipBoard: true }, () => {
      setTimeout(() => {
        this.setState({ ...this.state, showClipBoard: false });
      }, 2000);
    });
  };

  menuOpen = (isOpen: boolean) => {
    const sign = isOpen ? 1 : -1;
    this.setState(prevState => {
      return {
        ...prevState,
        menuOpenCount: prevState.menuOpenCount + sign * 1,
      };
    });
  };

  brandColor = (brandColor: string) => {
    this.setState({ ...this.state, brandColor: brandColor });
  };

  toggleSidebar = () =>
    this.setState((prevState: ChatView.State) => ({
      isSidebarOpen: !prevState.isSidebarOpen,
    }));

  toggelBlast = () => {
    this.setState((prevState: ChatView.State) => ({
      isBlastOpen: !prevState.isBlastOpen,
    }));
  };

  render() {
    const invitedUser = _.get(
      this.props,
      "authenticatedUser.must_change_password",
      false
    );
    if (invitedUser === true && this.props.match.path !== "/signup") {
      return <Redirect to="/signup" />;
    }
    const { authenticatedUser } = this.props;
    if (!authenticatedUser) {
      return (
        <div className="centered">
          <Loader />
        </div>
      );
    }
    return (
      <Sidebar
        className="ChatView"
        isOpen={this.state.isSidebarOpen}
        close={this.toggleSidebar}
      >
        <Header onSidebarButtonClick={this.toggleSidebar} />
        <div
          className={classNames("content", {
            menu_open: this.state.menuOpenCount > 0,
          })}
        >
          <SidePanel toggelBlast={this.toggelBlast} />
          <ConversationsPanel
            isBlastOpen={this.state.isBlastOpen}
            activeConversation={this.props.activeConversation}
            shown={this.state.panelShown === "conversations"}
            openConversation={id =>
              this.setState({ panelShown: "chat" }, () =>
                this.props.openConversation(id)
              )
            }
          />
          <div
            className={classNames("scheduler-wrapper", {
              open: this.props.isSchedulePanelOpen,
            })}
          >
            <Chat
              shown={this.state.panelShown === "chat"}
              onBack={this.onBack}
              showClipboard={this.showClipboard}
              menuOpen={this.menuOpen}
              brandColor={this.brandColor}
            />
            <div className="scheduler-window">
              <SchedulerPanel></SchedulerPanel>
            </div>
          </div>
          <div
            className={classNames("copied_to_clipboard", {
              show: this.state.showClipBoard,
            })}
            style={{ backgroundColor: this.state.brandColor }}
          >
            <span>Copied to clipboard</span>
          </div>
        </div>
      </Sidebar>
    );
  }
}
