import classnames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import styled from "styled-components";
import { IConversation } from "~/store/conversations";
import { HIGHLIGHTS, ITemplate, templatesActions } from "~/store/templates";
import { LightenDarkenColor, ThemeContext } from "~/utils";
import AttachmentView from "../common/AttachmentView/AttachmentView";
import "./TemplatePanel.scss";

namespace TemplatePanel {
  interface StateProps {
    activeConversation: IConversation;
    authenticatedUser: any;
    templates: ITemplate[];
  }
  interface DispatchProps {
    listTemplate: () => void;
  }
  interface OwnProps {
    closePanel: () => void;
    selectTemplate: (template: any) => void;
    shown: boolean;
    openDown?: boolean;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapStateToProps = (state: any) => ({
  activeConversation: state.conversations.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  templates: state.templates.data,
});

const mapDispatchToProps = {
  listTemplate: templatesActions.listTemplates,
};

class TemplatePanel extends PureComponent<TemplatePanel.Props> {
  static propTypes = {
    closePanel: PropTypes.func.isRequired,
    selectReply: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
  };

  public componentDidMount() {
    this.props.listTemplate();
  }

  public render() {
    const {
      activeConversation,
      authenticatedUser,
      templates,
      shown,
    } = this.props;

    let brandColor: string, lightColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightColor = LightenDarkenColor(brandColor, 30);
    }

    let ThemedKeyword = styled.li`
      &:hover > div > div > b {
        background: ${lightColor || "#00bbe5"} !important;
      }
    `;

    return (
      <aside
        className={classnames(
          "TemplatePanel",
          { openDown: this.props.openDown },
          { openTop: !this.props.openDown },
          { shown }
        )}
      >
        <ul>
          {(templates || []).length ? (
            templates
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((template, key) => (
                <ThemedKeyword
                  key={key}
                  onClick={() => this.props.selectTemplate(template)}
                >
                  <div className="flex-container">
                    <div>
                      <b>{template.title}</b>
                    </div>
                    <div
                      style={{ borderRight: `10px solid ${brandColor}` }}
                      className="arrow-left"
                    ></div>
                    <div
                      style={{ border: `1px solid ${brandColor}` }}
                      className="border-temp"
                    >
                      <Highlighter
                        highlightClassName="high-light-theme"
                        searchWords={HIGHLIGHTS}
                        autoEscape={true}
                        textToHighlight={template.template_content}
                        caseSensitive={true}
                      />
                      {template.media_data && (
                        <AttachmentView media_data={template.media_data} />
                      )}
                    </div>
                  </div>
                  {/* <span>{template.template_content}</span> */}
                </ThemedKeyword>
              ))
          ) : (
            <li className="disabled">No template.</li>
          )}
        </ul>
        <i className="icon-close" onClick={this.props.closePanel} />
      </aside>
    );
  }
}

TemplatePanel.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplatePanel as any);
