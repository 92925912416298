import React from "react";
import { connect } from "react-redux";
import ContactsModal from "./ContactsModal/ContactsModal";
import ProfileEditModal from "./ProfileEditModal/ProfileEditModal";
import RepliesModal from "./RepliesModal/RepliesModal";
import ReportsModal from "./ReportsModal/ReportsModal";
import SearchModal from "./SearchModal/SearchModal";
import HelpInfo from "./HelpInfo/HelpInfo";
import SettingsModal from "./SettingsModal/SettingsModal";
import ConfirmModal from "./ConfirmModal/ConfirmModal";
import TemplateModal from "./TemplateModal/TemplateModal";
import ScheduleModal from "./ScheduleModal/ScheduleModal";

const MODAL_COMPONENTS: any = {
  CONTACTS_MODAL: ContactsModal,
  PROFILE_EDIT_MODAL: ProfileEditModal,
  REPLIES_MODAL: RepliesModal,
  REPORTS_MODAL: ReportsModal,
  SEARCH_MODAL: SearchModal,
  HELP_INFO: HelpInfo,
  SETTINGS_MODAL: SettingsModal,
  CONFIRM_MODAL: ConfirmModal,
  TEMPLATE_MODAL: TemplateModal,
  SCHEDULE_MODAL: ScheduleModal,
};

const ModalRoot = ({
  modalType,
  modalProps,
  ...rest
}: {
  modalType?: string;
  modalProps?: any;
}) => {
  if (!modalType) return null;
  const SpecificModal = MODAL_COMPONENTS[modalType];
  return <SpecificModal {...modalProps} {...rest} />;
};

export default connect((state: any) => state.modal)(ModalRoot);
