import React, { useState } from "react";
import { connect } from "react-redux";
import withSizes from "react-sizes";
import ReactTooltip from "react-tooltip";
import IconHelp from "~/assets/help.svg";
import ThemedTab from "~/components/common/ThemedTab/ThemedTab";
import ContactContent from "~/components/ContactContent/ContactContent";
import ListContent from "~/components/ListContent/ListContent";
import { contactsActions } from "~/store/contacts";
import Modal from "../AbstractModal/AbstractModal";
import "./ContactsModal.scss";

interface DispatchProps {
  clearErrors: () => void;
}
interface OwnProps {
  activeContact: string;
  phoneNumber: string;
  activeEdit: boolean;
  openList: boolean;
}
interface StateProps {}

type Props = DispatchProps & StateProps & OwnProps;

const mapDispatchToProps = {
  clearErrors: contactsActions.clearErrors,
};

const mapSizesToProps = ({ width }: { width: number }) => ({
  isMobile: width < 600,
});

const ContactsModal = (props: Props) => {
  const [activeTab, setTab] = useState(!props.openList ? 1 : 2);

  function changeActiveTab(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.stopPropagation();
    const dataset = e.currentTarget.dataset;
    if (typeof dataset !== undefined) {
      const activeTab = "" + dataset.tabId;
      if (Number.isInteger(+activeTab)) {
        setTab(+activeTab);
      }
    }
  }

  function renderTabs() {
    const tabs = [
      {
        id: 1,
        title: "Contacts",
        tooltip: {
          id: "contacts",
          offset: -45,
          content: [
            "Contacts saved under the current profile.",
            <br />,
            "Icons next to the contact:",
            <ul>
              <li>
                <i className="icon-circle"></i> is system created internal
                contact
              </li>
              <li>
                <i className="icon-phone"></i> is a number not defaulted to any
                contact
              </li>
              <li>
                <i className="icon-profile"></i> is an external contact
              </li>
            </ul>,
          ],
        },
      },
      {
        id: 2,
        title: "Lists",
        tooltip: {
          id: "lists",
          styles: "tooltipRight",
          offset: 50,
          content: [
            "A list of one or more contacts, commonly used for message blasts.",
            <br />,
            "When a blast is sent, recipients do not see other recipients.",
          ],
        },
      },
    ];
    return (
      <ul className="ContactsModal__Tabs">
        {tabs.map(tab => (
          <ThemedTab
            key={tab.id}
            onClick={changeActiveTab}
            className={activeTab === tab.id ? "active" : ""}
            data-tab-id={tab.id}
          >
            <span>
              {tab.title}
              <img
                className="help-icon"
                src={IconHelp}
                alt="Help"
                data-tip
                data-for={tab.tooltip.id}
              />
              <ReactTooltip
                className={`tooltipContainer ${tab.tooltip.styles || ""}`}
                id={tab.tooltip.id}
                effect="solid"
                place="bottom"
                offset={{ left: props.isMobile ? tab.tooltip.offset : 0 }}
              >
                <span className="tooltipContent">
                  {tab.tooltip.content.map((content: any, j: number) => (
                    <React.Fragment key={j}>{content}</React.Fragment>
                  ))}
                </span>
              </ReactTooltip>
            </span>
          </ThemedTab>
        ))}
      </ul>
    );
  }

  return (
    <Modal title="Contacts" className="ContactsModal">
      {renderTabs()}
      {activeTab === 1 ? <ContactContent /> : <ListContent />}
    </Modal>
  );
};

export default connect(
  null,
  mapDispatchToProps
)(withSizes(mapSizesToProps)(ContactsModal as any));
