import { AxiosResponse } from "axios";
import { History } from "history";
import React, { Component, BaseSyntheticEvent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { usersActions } from "~/store/user";
import classnames from "classnames";
import IntroSlider from "~/components/HelpScreens/IntroSlider/IntroSlider";
import "./SignUpForm.scss";
import _ from "lodash";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

namespace SignUpForm {
  export interface State {
    error: string;
    form: {
      current_password: string;
      confirm_password: string;
      password: string;
    };
    showConfirmPassword: boolean;
    showPassword: boolean;
  }

  interface OwnProps {
    history: History;
    reset: boolean;
  }

  interface StateProps {
    isResetPassword: boolean;
    authenticatedUser: any;
  }

  interface DispatchProps {
    updateUser: (form: {}) => Promise<AxiosResponse>;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  updateUser: usersActions.updateUser,
};

const mapStateToProps = (state: any) => ({
  isResetPassword: state.users.isResetPassword,
  authenticatedUser: state.users.authenticatedUser,
});

@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
@(withRouter as any)
export default class SignUpForm extends Component<
  SignUpForm.Props,
  SignUpForm.State
> {
  state = {
    error: "",
    form: {
      confirm_password: "",
      password: "",
    },
    showConfirmPassword: false,
    showPassword: false,
    showIntroHelp: false,
  };

  togglePassword = (field: string) =>
    this.setState(prevState => ({ showPassword: !prevState[field] }));
  showPassword = (field: string) => {
    let state: any = {};
    state[field] = true;
    this.setState(state);
  };
  hidePassword = (field: string) => {
    let state: any = {};
    state[field] = false;
    this.setState(state);
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Validate form fields here

    // Send form
    const { password } = this.state.form;
    this.props
      .updateUser({ password })
      .then(() => this.setState({ showIntroHelp: true }))
      .catch(err =>
        this.setState({
          error: _.get(
            err,
            "response.data[0].constraints.PasswordWasUsedConstraint",
            ""
          ),
        })
      );
  };

  enterApp = () => {
    this.props.history.push("/");
  };

  handleFieldChange = (e: ChangeEvent) => {
    e.stopPropagation();
    let newFormState = Object.assign({}, this.state.form);
    newFormState[e.target["name"]] = e.target["value"];
    this.setState({ form: newFormState });
  };

  checkLength = () => this.state.form.password.length >= 8;
  checkFirst = () => /^[a-zA-Z].*/.test(this.state.form.password);
  checkCapital = () => /[A-Z]+/.test(this.state.form.password);
  checkNumber = () => /[0-9]+/.test(this.state.form.password);
  checkSpecial = () => /[\!\@\#\$\%\^\&\*]+/.test(this.state.form.password);
  checkConfirm = () =>
    this.state.form.password &&
    this.state.form.password === this.state.form.confirm_password;

  validPassword = () =>
    this.checkLength() &&
    this.checkFirst() &&
    this.checkCapital() &&
    this.checkNumber() &&
    this.checkSpecial &&
    this.checkConfirm();

  render() {
    const { showIntroHelp } = this.state;
    return showIntroHelp ? (
      <IntroSlider enterApp={this.enterApp} />
    ) : (
      <form className="SignUpForm form" onSubmit={this.handleSubmit}>
        <h1>{this.props.isResetPassword ? "Reset Password" : "Sign Up"}</h1>
        <p>
          {this.props.isResetPassword
            ? "Please set a new password."
            : "To complete your registration please type your password."}
        </p>
        <div className="ProfileAccount__PasswordRequirements">
          <h4>Password requirements:</h4>
          <ul>
            <li className={this.checkLength() ? "active" : ""}>
              8 characters minimum
            </li>
            <li className={this.checkFirst() ? "active" : ""}>
              First character has to be alpha
            </li>
            <li className={this.checkCapital() ? "active" : ""}>
              At least one capital letter
            </li>
            <li className={this.checkNumber() ? "active" : ""}>
              At least one number
            </li>
            <li className={this.checkSpecial() ? "active" : ""}>
              At least one special character
              <br />
              (!, @, #, $, %, ^, &, *)
            </li>
          </ul>
        </div>
        <div className="field">
          <ThemedInput
            className="input"
            name="password"
            onChange={this.handleFieldChange}
            placeholder="Password"
            type={this.state.showPassword ? "text" : "password"}
            value={this.state.form.password}
          />
          <button
            type="button"
            onBlur={this.hidePassword.bind(this, "showPassword")}
            onMouseDown={this.showPassword.bind(this, "showPassword")}
            onMouseUp={this.togglePassword.bind(this, "showPassword")}
          >
            <i className="far fa-eye"></i>
          </button>
        </div>
        <div className="field">
          <ThemedInput
            className="input"
            name="confirm_password"
            onChange={this.handleFieldChange}
            placeholder="Confirm Password"
            type={this.state.showPassword ? "text" : "password"}
            value={this.state.form.confirm_password}
          />
          <button
            type="button"
            onBlur={this.hidePassword.bind(this, "showConfirmPassword")}
            onMouseDown={this.showPassword.bind(this, "showConfirmPassword")}
            onMouseUp={this.togglePassword.bind(this, "showConfirmPassword")}
          >
            <i className="far fa-eye"></i>
          </button>
        </div>
        {this.state.error && (
          <span className="error-msg">{this.state.error}</span>
        )}
        {this.props.authenticatedUser &&
          this.props.authenticatedUser.display_note && (
            <span className="error-msg">
              {this.props.authenticatedUser.display_note}
            </span>
          )}
        <ThemedButton
          className={classnames("submit", { disabled: !this.validPassword() })}
          type="submit"
        >
          {this.props.isResetPassword ? "Reset" : "Sign Up"}
        </ThemedButton>
      </form>
    );
  }
}
