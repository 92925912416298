import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import useStateWithCallback from "use-state-with-callback";
import useDebounce from "~/hooks/useDebounce";
import { conversationActions, IConversation } from "~/store/conversations";
import { schedulesPanelActions } from "~/store/schedule_panel";
import { IUserPhone, usersActions } from "~/store/user";
import { fpn } from "~/utils";
import ChatSettings from "./components/ChatSettings";
import classNames from "classnames";
import { Branding } from "~/branding";
// ------------------------------------
// Interfaces
// ------------------------------------
interface DispatchProps {
  saveBubble: (form: object) => any;
  getCSVDataForDownloadMessage: (conversationId: string, type: string) => any;
  toggleSchedulePanel: () => void;
}

interface OwnProps {
  onBack: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface StateProps {
  authenticatedUser: any;
  chat: IConversation;
  isSchedulePanelOpen: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

// ------------------------------------
// Mappings
// ------------------------------------
const mapsDispatchToProps: DispatchProps = {
  saveBubble: usersActions.updateUser,
  getCSVDataForDownloadMessage:
    conversationActions.getCSVDataForDownloadMessage,
  toggleSchedulePanel: schedulesPanelActions.toggleSchedulePanel,
};

const mapStateToProps = (state: any): StateProps => ({
  authenticatedUser: state.users.authenticatedUser,
  chat: state.conversations.activeConversation,
  isSchedulePanelOpen: state.schedule_panel.isSchedulePanelOpen,
});

// ------------------------------------
// Component
// ------------------------------------
export default connect(
  mapStateToProps,
  mapsDispatchToProps
)((props: Props) => {
  const INITIAL_SETTINGS = {
    color: "#00BBE5",
    font: { value: "Helvetica Neue", label: "Helvetica Neue" },
    fontSize: 16,
  };
  const localStyles = localStorage && localStorage.getItem("bubbleStyles");
  const [bubbleStyle, setBubbleStyle] = useState({
    ...INITIAL_SETTINGS,
    ...JSON.parse(localStyles || "{}"),
  });

  const csvLink: any = useRef(null);
  const [csvData, setcsvData] = useStateWithCallback(
    { data: [], isSet: false },
    (csvData: any) => {
      if (csvData.isSet) {
        csvLink.current.link.click();
        setcsvData({ ...csvData, isSet: false });
      }
    }
  );

  const debouncedBubbleStyles = useDebounce(bubbleStyle, 500);
  useEffect(() => {
    if (debouncedBubbleStyles) {
      props.saveBubble({
        settings: {
          bubble_color: bubbleStyle.color,
          font_family: JSON.stringify(bubbleStyle.font),
          font_size: bubbleStyle.fontSize,
        },
      });
    }
  }, [debouncedBubbleStyles]);

  function handleChangeBubbleStyle({
    payload = null,
    shouldSetInitial = false,
  }: {
    payload?: object | null;
    shouldSetInitial?: boolean;
  }) {
    const updatedStyle = !shouldSetInitial ? payload : INITIAL_SETTINGS;
    const newStyle = { ...bubbleStyle, ...updatedStyle };
    localStorage.setItem("bubbleStyles", JSON.stringify(newStyle));
    setBubbleStyle(newStyle);
  }

  function renderNotes(chat: IConversation) {
    let interlocutor;
    if (chat) {
      if (chat.type === "blast") {
        return <h2>{chat.name}</h2>;
      }
      const { phones, activePhone } = props.authenticatedUser;
      const myPhone = phones.find(
        (phone: IUserPhone) => phone.id === activePhone
      );
      if (chat && chat.internal && chat.external) {
        const interlocutors = [...chat.internal, ...chat.external];
        if (myPhone && interlocutors.length === 2)
          // one-to-one chat
          interlocutor = interlocutors.find(
            contact => contact.phone_number !== myPhone.phone_number
          );
      }
      return (
        <>
          <h2>{prepareLabel(chat)}</h2>
          {interlocutor && interlocutor.notes && (
            <div>{interlocutor.notes}</div>
          )}
        </>
      );
    }
    return null;
  }

  const prepareLabel = (conversation: IConversation) => {
    if (!conversation.name) {
      return conversation.name;
    }
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      conversation.name
        .trim()
        .split(" ")
        .join("")
    );

    let name = conversation.name;

    if (isPhoneName) {
      name = fpn(
        conversation.name,
        props.authenticatedUser.show_censor_contact
      );
    }

    return conversation.external.length === 1 &&
      conversation.external[0].in_contacts === false
      ? fpn(
          conversation.external[0].phone_number,
          props.authenticatedUser.show_censor_contact
        )
      : name;
  };

  const renderMessageType = (messageType: string, defaultTitle: string) => {
    let messageTitle;
    switch (messageType) {
      case "autoreply":
        messageTitle = "Auto-Reply";
        break;
      case "subscribed_message":
        messageTitle = "Opted In Message";
        break;
      case "unsubscribed_message":
        messageTitle = "Opted Out Message";
        break;
      default:
        messageTitle = defaultTitle;
        break;
    }
    return messageTitle;
  };

  const downloadMessageThread = () => {
    props
      .getCSVDataForDownloadMessage(props.chat.id, props.chat.type)
      .then((resolve: any) => {
        const myId = props.authenticatedUser.id;
        const csvData = [["Time Stamp", "From", "To", "User Name", "Message"]];
        resolve.messages.forEach((msg: any) => {
          const {
            media_link,
            message_type,
            sender: {
              internal,
              phone_number,
              first_name,
              last_name,
              display_as,
            },
            sent_at,
            text_content,
          } = msg;

          const me =
            (internal && msg.sender_id === myId) ||
            phone_number !== resolve.active_conversation_phone_number;
          const data = text_content + (media_link ? "\n" + media_link : "");
          const defaultMessageTitle =
            internal && display_as && display_as.length
              ? display_as
              : `${first_name} ${last_name}`;
          if (me) {
            csvData.push([
              moment(sent_at).format("MM/DD/YYYY hh:mm:ss A"),
              resolve.user_phone_number,
              resolve.active_conversation_phone_number,
              renderMessageType(message_type || "", defaultMessageTitle),
              data,
            ]);
          } else {
            csvData.push([
              moment(sent_at).format("MM/DD/YYYY hh:mm:ss A"),
              resolve.active_conversation_phone_number,
              resolve.user_phone_number,
              renderMessageType(message_type || "", defaultMessageTitle),
              data,
            ]);
          }
        });
        setcsvData({ data: csvData, isSet: true });
      })
      .catch((reject: any) => {});
  };

  return (
    <header className="Chat__HeaderContainer">
      <CSVLink
        data={csvData.data}
        filename={`${props.chat.name}_${Branding.APP_NAME}.csv`}
        className="hidden"
        ref={csvLink}
      ></CSVLink>
      <button className="Chat__HeaderBack" type="button" onClick={props.onBack}>
        <i className="icon-inbound"></i>
      </button>
      <div className="Chat__HeaderTitle">{renderNotes(props.chat)}</div>
      {props.chat.type !== "blast" && (
        <div
          style={{ padding: 4, cursor: "pointer" }}
          onClick={downloadMessageThread}
        >
          <i className="icon-download"></i>
        </div>
      )}
      <ChatSettings
        data={bubbleStyle}
        onChangeStyles={handleChangeBubbleStyle}
        containerClassName={
          props.isSchedulePanelOpen ? "schedule-window-open" : ""
        }
      />
      {
        <div
          className={classNames("chat-header-schedule-panel", {
            open: props.isSchedulePanelOpen,
          })}
          onClick={props.toggleSchedulePanel}
        >
          <i className="icon-clock"></i>
        </div>
      }
    </header>
  );
});
