import _ from "lodash";
import classNames from "classnames";
import React, { Component, BaseSyntheticEvent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { usersActions } from "~/store/user";
import { statusActions } from "~/store/statuses";
import { AxiosPromise } from "axios";
import { withRouter } from "react-router";
import { History } from "history";
import { modalActions } from "~/store/modal";
import "./ProfileAccount.scss";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import { LightenDarkenColor, ThemeContext } from "~/utils";

namespace ProfileAccount {
  export interface IForm {
    current_password: string;
    password: string;
  }

  interface StateProps {
    authenticatedUser: any;
    history: History;
    profile: any;
    isAuthenticated: boolean;
    changePasswordScreen: boolean;
  }
  interface DispatchProps {
    closeModal: () => void;
    logoutUser: (callback: Function) => void;
    updateUser: (form: any) => AxiosPromise;
    logoutStatus: () => void;
  }
  interface OwnProps {}
  export type Props = StateProps & DispatchProps & OwnProps;

  export type State = {
    changePasswordScreen: boolean;
    errors: any[];
    form: { confirm_password?: string } & IForm;
  };
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  logoutUser: usersActions.logoutUser,
  updateUser: usersActions.updateUser,
  logoutStatus: statusActions.logoutStatus,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  isAuthenticated: state.users.isAuthenticated,
  profile: state.users.authenticatedUser,
});

@(withRouter as any)
@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
export default class ProfileAccount extends Component<
  ProfileAccount.Props,
  ProfileAccount.State
> {
  static contextType = ThemeContext;

  state = {
    changePasswordScreen: false,
    errors: [],
    form: {
      current_password: "",
      confirm_password: "",
      password: "",
    },
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (this.state.form.password === this.state.form.confirm_password) {
      this.props
        .updateUser({
          password: this.state.form.password,
          current_password: this.state.form.current_password,
        })
        .then(this.toggleScreen)
        .catch(err =>
          this.setState({ errors: _.get(err, "response.data", []) })
        );
    }
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    this.setState({ form: { ...this.state.form, [id]: value } });
  };

  toggleScreen = () => {
    this.setState(prevState => ({
      changePasswordScreen: !prevState.changePasswordScreen,
    }));
  };

  afterLogout = () => {
    this.props.closeModal();
    this.props.history.push("/login");
  };

  checkLength = () => this.state.form.password.length >= 8;
  checkFirst = () => /^[a-zA-Z].*/.test(this.state.form.password);
  checkCapital = () => /[A-Z]+/.test(this.state.form.password);
  checkNumber = () => /[0-9]+/.test(this.state.form.password);
  checkSpecial = () => /[\!\@\#\$\%\^\&\*]+/.test(this.state.form.password);
  checkConfirm = () =>
    this.state.form.password &&
    this.state.form.password === this.state.form.confirm_password;

  render() {
    let brandColor, lightColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightColor = LightenDarkenColor(brandColor, 30);
    }

    const errorCurrentPassword = this.state.errors.find((error: any) =>
      error.constraints.hasOwnProperty("CurrentPasswordIsValidConstraint")
    );
    const errorAlreadyPassword = this.state.errors.find((error: any) =>
      error.constraints.hasOwnProperty("PasswordWasUsedConstraint")
    );

    const AccountInfoScreen = (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            <label htmlFor="email">Email</label>
            <ThemedInput
              className="input"
              disabled
              id="email"
              type="email"
              value={this.props.profile.email}
            />
          </div>
          <div className="column">
            <label htmlFor="display_as">Password</label>
            <ThemedInput
              className="input"
              disabled
              id="password"
              type="text"
              value="**********"
            />
            <a
              style={lightColor ? { color: lightColor } : {}}
              className="ProfileAccount__ChangePassword lightblue"
              href="#"
              onClick={this.toggleScreen}
            >
              Change Password
            </a>
          </div>
        </div>
        <div className="columns">
          <div className="column">
            <label htmlFor="account_level">Account Level</label>
            <input
              className="input"
              disabled
              id="account_level"
              type="text"
              value="User"
            />
          </div>
          <div className="column"></div>
        </div>
      </React.Fragment>
    );

    const ChangePasswordScreen = (
      <React.Fragment>
        <div className="columns">
          <div className="column">
            <label className="mandatory" htmlFor="current_password">
              Current Password
            </label>
            <ThemedInput
              autoComplete="current-password"
              className="input"
              id="current_password"
              onChange={this.handleChange}
              type="password"
              value={this.state.form.current_password}
            />
            {errorCurrentPassword && (
              <span className="error-msg">
                {
                  errorCurrentPassword.constraints
                    .CurrentPasswordIsValidConstraint
                }
              </span>
            )}
            <label className="mandatory" htmlFor="password">
              New Password
            </label>
            <ThemedInput
              autoComplete="new-password"
              className="input"
              id="password"
              onChange={this.handleChange}
              type="password"
              value={this.state.form.password}
            />
            {errorAlreadyPassword && (
              <span className="error-msg">
                {errorAlreadyPassword.constraints.PasswordWasUsedConstraint}
              </span>
            )}
            <label className="mandatory" htmlFor="confirm_password">
              Confirm New Password
            </label>
            <ThemedInput
              className="input"
              id="confirm_password"
              onChange={this.handleChange}
              type="password"
              value={this.state.form.confirm_password}
            />
          </div>
          <div className="column center">
            <div className="ProfileAccount__PasswordRequirements">
              <h4>Password requirements:</h4>
              <ul>
                <li className={classNames({ active: this.checkLength() })}>
                  8 characters minimum
                </li>
                <li className={classNames({ active: this.checkFirst() })}>
                  First character has to be alpha
                </li>
                <li className={classNames({ active: this.checkCapital() })}>
                  At least one capital letter
                </li>
                <li className={classNames({ active: this.checkNumber() })}>
                  At least one number
                </li>
                <li className={classNames({ active: this.checkSpecial() })}>
                  At least one special character
                  <br />
                  (!, @, #, $, %, ^, &, *)
                </li>
              </ul>
            </div>
          </div>
        </div>
      </React.Fragment>
    );

    return (
      <React.Fragment>
        <form className="ProfileAccount" onSubmit={this.handleSubmit}>
          {this.props.isAuthenticated &&
            (this.state.changePasswordScreen
              ? ChangePasswordScreen
              : AccountInfoScreen)}
        </form>
        <footer>
          {this.props.isAuthenticated &&
            (this.state.changePasswordScreen ? (
              <React.Fragment>
                <ThemedButton
                  disabled={!this.checkConfirm()}
                  onClick={this.handleSubmit}
                >
                  Save
                </ThemedButton>
                <button
                  className="button"
                  type="button"
                  onClick={this.toggleScreen}
                >
                  Cancel
                </button>
              </React.Fragment>
            ) : (
              <ThemedButton
                onClick={() => {
                  this.props.logoutStatus();
                  this.props.logoutUser(this.afterLogout);
                }}
              >
                Logout
              </ThemedButton>
            ))}
        </footer>
      </React.Fragment>
    );
  }
}
