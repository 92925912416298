import classnames from "classnames";
import React, { PureComponent, BaseSyntheticEvent } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import withSizes from "react-sizes";

import Modal from "../AbstractModal/AbstractModal";
import ReplyEdit from "~/components/forms/ReplyEdit/ReplyEdit";
import PrebuiltReplyEdit from "~/components/forms/PrebuiltReplyEdit/PrebuiltReplyEdit";
import RepliesList from "~/components/RepliesList/RepliesList";
import { modalActions } from "~/store/modal";
import { autoRepliesActions, IAutoReply } from "~/store/replies_auto";
import {
  prebuiltRepliesActions,
  IPrebuiltReply,
} from "~/store/replies_prebuilt";
import IconHelp from "~/assets/help.svg";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedTab from "~/components/common/ThemedTab/ThemedTab";
import "./RepliesModal.scss";
import { Branding } from "~/branding";

namespace RepliesModal {
  interface DispatchProps {
    closeModal: () => void;
    createConversation: (
      members: Array<string>,
      listeners: Array<string>
    ) => void;
    listAutoReplies: () => void;
    listPrebuiltReplies: () => void;
    updateReply: (reply: IAutoReply) => void;
  }
  interface OwnProps {
    isMobile: boolean;
  }
  interface StateProps {
    autoRepliesList: Array<IAutoReply>;
    prebuiltRepliesList: Array<IPrebuiltReply>;
  }
  export interface State {
    activeReply: number;
    activeEdit: boolean;
    activeTab: number;
    shown: string;
  }
  export type Props = DispatchProps & StateProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createReply: autoRepliesActions.createAutoReply,
  listAutoReplies: autoRepliesActions.listAutoReplies,
  listPrebuiltReplies: prebuiltRepliesActions.listPrebuiltReplies,
  updateReply: autoRepliesActions.updateAutoReply,
};

const mapStateToProps = (state: any) => ({
  autoRepliesList: state.autoReplies.data,
  prebuiltRepliesList: state.prebuiltReplies.data,
});

@withSizes(({ width }) => ({ isMobile: width < 600 }))
class RepliesModal extends PureComponent<
  RepliesModal.Props,
  RepliesModal.State
> {
  state = {
    activeReply: -1,
    activeEdit: false,
    activeTab: 1,
    shown: "list",
  };

  componentDidMount() {
    // Fetch replies lists here
    this.props.listAutoReplies();
    this.props.listPrebuiltReplies();
  }

  openEditForm = () => this.setState({ activeEdit: true, shown: "edit" });
  closeEditForm = () => this.setState({ activeEdit: false, shown: "list" });

  changeActiveTab = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const activeTab = +e.currentTarget.dataset.tabId;
    if (Number.isInteger(activeTab)) {
      this.setState({ activeTab, activeEdit: false, shown: "list" });
    }
  };

  render() {
    const AutoRepliesTab = () => (
      <React.Fragment>
        <RepliesList
          isAutoReplies={true}
          items={this.props.autoRepliesList}
          openReply={(id: number) =>
            this.setState({ activeEdit: true, activeReply: id, shown: "edit" })
          }
          shown={this.state.shown === "list"}
          toggleReply={(checked, e, id) => {
            e.stopPropagation();
            const reply = this.props.autoRepliesList.find(
              reply => `reply_${reply.id}` === id
            );
            if (reply) {
              reply.is_active = checked;
              this.props.updateReply(reply);
            }
          }}
        />
        {this.state.activeEdit && (
          <ReplyEdit
            shown={this.state.shown === "edit"}
            reply={this.state.activeReply}
            onBack={this.closeEditForm}
          />
        )}
      </React.Fragment>
    );

    const PrebuiltRepliesTab = () => (
      <React.Fragment>
        <RepliesList
          isAutoReplies={false}
          items={this.props.prebuiltRepliesList || []}
          openReply={(id: number) =>
            this.setState({ activeEdit: true, activeReply: id, shown: "edit" })
          }
          shown={this.state.shown === "list"}
        />
        {this.state.activeEdit && (
          <PrebuiltReplyEdit
            shown={this.state.shown === "edit"}
            reply={this.state.activeReply}
            onBack={this.closeEditForm}
          />
        )}
      </React.Fragment>
    );

    const tabs = [
      {
        id: 1,
        title: (
          <span>
            Auto Replies
            <img
              className="help-icon"
              src={IconHelp}
              alt="Help"
              data-tip
              data-for="auto"
            />
            <ReactTooltip
              className="tooltipContainer"
              id="auto"
              effect="solid"
              place="bottom"
              offset={{ left: this.props.isMobile ? -45 : 0 }}
            >
              <span className="tooltipContent">
                Replies designed to be sent automatically without human
                intervention.
                <br />
                Replies could be sent based on keyword triggers or within a
                specified date/time range.
                <br />
                Auto reply examples:
                <ol>
                  <li>
                    Out-of-office reply which can be set up to reply to an
                    incoming message while you’re on vacation.
                  </li>
                  <li>
                    Keyword trigger: a reply will be sent if an incoming message
                    contains the keywords ‘business hours’
                  </li>
                </ol>
              </span>
            </ReactTooltip>
          </span>
        ),
        component: <AutoRepliesTab />,
      },
      {
        id: 2,
        title: (
          <span>
            Suggested Replies
            <img
              className="help-icon"
              src={IconHelp}
              alt="Help"
              data-tip
              data-for="suggested"
            />
            <ReactTooltip
              className="tooltipContainer tooltipRight"
              id="suggested"
              effect="solid"
              place="bottom"
              offset={{ left: this.props.isMobile ? 124 : 0 }}
            >
              <span className="tooltipContent">
                Suggested replies are commonly used responses that could be
                prebuilt, so the response doesn’t need to be retyped each time.
                <br />
                Suggested reply examples:
                <ol>
                  <li>
                    {`“Thank you for reaching us today. Please visit our website:
                    ${Branding.WEBSITE_URL} to get more information.”`}
                  </li>
                  <li>
                    “To pay your monthly bill online, please go to
                    www.monthlyutility.com/billpay”
                  </li>
                </ol>
              </span>
            </ReactTooltip>
          </span>
        ),
        component: <PrebuiltRepliesTab />,
      },
    ];

    const activeTab = tabs.filter(tab => tab.id === this.state.activeTab)[0];

    return (
      <Modal className="RepliesModal" title="Reply Options">
        <ul className="RepliesModal__Tabs">
          {tabs.map(tab => (
            <ThemedTab
              key={tab.id}
              onClick={this.changeActiveTab}
              className={classnames({
                active: this.state.activeTab === tab.id,
              })}
              data-tab-id={tab.id}
            >
              {tab.title}
            </ThemedTab>
          ))}
        </ul>
        <section>{activeTab && activeTab.component}</section>
        {!this.state.activeEdit && (
          <footer>
            <ThemedButton
              onClick={() =>
                this.setState({
                  activeReply: -1,
                  activeEdit: true,
                  shown: "edit",
                })
              }
            >
              <i className="icon-add"></i>
            </ThemedButton>
          </footer>
        )}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RepliesModal as any);
