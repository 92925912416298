import Datetime from "react-datetime";
import "./ThemedDatetime.scss";
import React, { ChangeEvent, PureComponent } from "react";
import { connect } from "react-redux";
import { LightenDarkenColor, ThemeContext } from "~/utils";
import styled from "styled-components";

interface StateProps {
  authenticatedUser: any;
}

interface OwnProps {
  id: string;
  text: string;
  checked: boolean;
  onChange: (e: ChangeEvent) => void;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedDatetime extends PureComponent<Props> {
  render() {
    let brandColor, lightenColor, Wrapper;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightenColor = LightenDarkenColor(brandColor, 30);
    }

    Wrapper = styled.div`
      .rdt.rdtOpen > input {
        border-color: ${brandColor};
      }
      .rdt > input {
        cursor: pointer;
      }
      .rdtPicker {
        right: ${this.props.offsetRight || 0}px;
      }
      .rdtPicker thead tr:first-child th,
      .rdtPicker .rdtTimeToggle {
        color: ${lightenColor};
      }
      .rdtPicker td.rdtDay:hover,
      .rdtPicker td.rdtHour:hover,
      .rdtPicker td.rdtMinute:hover,
      .rdtPicker td.rdtSecond:hover,
      .rdtPicker .rdtTimeToggle:hover {
        border-color: ${lightenColor};
      }
      .rdtPicker td.rdtToday:before {
        border-bottom-color: ${lightenColor};
      }

      .rdtPicker td.rdtActive,
      .rdtPicker td.rdtActive:hover {
        background-color: ${lightenColor} !important;
      }
    `;

    return (
      <Wrapper className="datetimeWrapper">
        <Datetime {...this.props} />
        {this.props.children}
      </Wrapper>
    );
  }
}

ThemedDatetime.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedDatetime);
