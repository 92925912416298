import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SearchInput from "~/components/SearchInput/SearchInput";
import { IAutoReply } from "~/store/replies_auto";
import { IPrebuiltReply } from "~/store/replies_prebuilt";
import ThemedSwitch from "../common/ThemedSwitch/ThemedSwitch";
import "./RepliesList.scss";

interface OwnProps {
  activeReply: number;
  isAutoReplies: boolean;
  items: IAutoReply[] | IPrebuiltReply[];
  openReply: (replyId: number) => void;
  shown: boolean;
  toggleReply?: (
    checked: boolean,
    e: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => void;
}

type Props = OwnProps;

interface State {
  searchTerm: string;
}

export default class RepliesList extends PureComponent<Props, State> {
  static propTypes = {
    activeReply: PropTypes.number.isRequired,
    isAutoReplies: PropTypes.bool.isRequired,
    items: PropTypes.array.isRequired,
    openReply: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
    toggleReply: PropTypes.func,
  };

  state = {
    searchTerm: "",
  };

  handleSearch = (value: string) => {
    this.setState({ searchTerm: value });
  };

  filterReplies = (replies: IAutoReply[] | IPrebuiltReply[]) => {
    const search = this.state.searchTerm;
    const exp = new RegExp(search, "i");
    return replies.filter(reply => (search ? exp.test(reply.title) : true));
  };

  public render() {
    const { activeReply, openReply } = this.props;
    const replies = this.filterReplies(this.props.items);

    return (
      <>
        {this.props.shown && (
          <SearchInput
            className="underline left-icon"
            placeholder={`Search ${
              this.props.isAutoReplies ? "auto" : "suggested"
            } reply`}
            onChange={this.handleSearch}
          />
        )}
        <ul className={classNames("RepliesList", { shown: this.props.shown })}>
          {replies.length ? (
            replies.map((reply: IAutoReply | IPrebuiltReply, key: number) => (
              <li
                className={classNames({ active: activeReply === reply.id })}
                key={key}
                data-index={reply.id}
                onClick={() => openReply(reply.id)}
              >
                <span>{reply.title}</span>
                {this.props.isAutoReplies && (
                  <ThemedSwitch
                    id={`reply_${reply.id}`}
                    checkedIcon={<i>ON</i>}
                    uncheckedIcon={<i>OFF</i>}
                    onChange={this.props.toggleReply}
                    checked={!!reply.is_active}
                  />
                )}
              </li>
            ))
          ) : (
            <li className="no">No replies created yet.</li>
          )}
        </ul>
      </>
    );
  }
}
