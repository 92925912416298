import classnames from "classnames";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IconHelp from "~/assets/help.svg";
import Loader from "~/components/common/Loader/Loader";
import { IConversation } from "~/store/conversations";
import { modalActions } from "~/store/modal";
import {
  ISchedule,
  schedulesActions,
  tzScheduleShift,
} from "~/store/schedules";
import { schedulesPanelActions } from "~/store/schedule_panel";
import { HIGHLIGHTS } from "~/store/templates";
import { ThemeContext } from "~/utils";
import AttachmentView from "../common/AttachmentView/AttachmentView";
import ThemedButton from "../common/ThemedButton/ThemedButton";
import ThemedTab from "../common/ThemedTab/ThemedTab";
import "./SchedulerPanel.scss";

// ------------------------------------
// Interfaces
// ------------------------------------
interface DispatchProps {
  openModal: (modalId: string, modalProps: object) => void;
  listSchedules: () => void;
  toggleSchedulePanel: () => void;
}

interface OwnProps {
  // isScheduleMessageOpen: boolean;
}

interface StateProps {
  activeChat: IConversation;
  authenticatedUser: any;
  schedulesList: ISchedule[];
  isFetching: boolean;
  modalType: string;
  activeConversationId: string;
  isSchedulePanelOpen: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

// ------------------------------------
// Mappings
// ------------------------------------
const mapsDispatchToProps: DispatchProps = {
  openModal: modalActions.openModal,
  listSchedules: schedulesActions.listSchedules,
  toggleSchedulePanel: schedulesPanelActions.toggleSchedulePanel,
};

const mapStateToProps = (state: any): StateProps => ({
  activeChat: state.conversations.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  schedulesList: state.schedules.data,
  isFetching: state.schedules.isFetching,
  modalType: state.modal.modalType,
  activeConversationId: state.schedules.activeConversationId,
  isSchedulePanelOpen: state.schedule_panel.isSchedulePanelOpen,
});

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)((props: Props) => {
  const { authenticatedUser: user } = props;
  const [activeTabId, setActiveTabId] = useState(0);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    if (props.isSchedulePanelOpen) {
      props.listSchedules();
    }
  }, [props.activeChat && props.activeChat.id]);

  useEffect(() => {
    if (
      props.isSchedulePanelOpen &&
      props.activeChat.id != props.activeConversationId
    ) {
      props.listSchedules();
    }
  }, [props.isSchedulePanelOpen]);

  let brandColor: any;
  if (theme.theme || (user && user.company && user.company.whitelabeling)) {
    brandColor =
      theme.theme === "default"
        ? user.company.whitelabeling.primary_css_color
        : theme.theme;
  }

  const changeActiveTab = (e: any) => {
    e.stopPropagation();
    const activeTab = +e.currentTarget.dataset.tabId;
    if (Number.isInteger(activeTab)) {
      setActiveTabId(activeTab);
    }
  };

  const sortSchedleMessages = (a: any, b: any): number => {
    const aDate = a.schedule_at ? new Date(a.schedule_at).getTime() : 0;
    const bDate = b.schedule_at ? new Date(b.schedule_at).getTime() : 0;
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  };

  const sortRecurringMessages = (a: any, b: any): number => {
    const aDate = a.id;
    const bDate = b.id;
    return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
  };

  const ScheduleContent = (type: any) => {
    const schedulesList =
      props.schedulesList.filter((schedule: any) => schedule.type == type) ||
      [];
    return (
      <>
        {props.isFetching && <Loader />}
        {!props.isFetching && (
          <ul className="scheduler-list">
            {schedulesList.length ? (
              schedulesList
                .filter((schedule: any) => schedule.type == type)
                .sort(type == 0 ? sortSchedleMessages : sortRecurringMessages)
                .map((schedule: any, key: number) => (
                  <li key={key}>
                    <div
                      className={`edit-schedule ${
                        type == 0
                          ? ""
                          : schedule.recurring_schedule_message.filter(
                              (rsm: any) => rsm.isActive
                            ).length <= 1
                          ? "schedule-recurring-single"
                          : "schedule-recurring"
                      }`}
                    >
                      <i className="time-icon-clock"></i>
                      <span style={{ textTransform: "uppercase" }}>
                        {type === 0
                          ? moment(schedule.schedule_at).format(
                              "MM/DD/YYYY hh:mm:ss A"
                            )
                          : schedule.recurring_schedule_message
                              .map(tzScheduleShift.bind(null, "load"))
                              .filter((rsm: any) => rsm.isActive)
                              .map(
                                (rsm: any) =>
                                  `${rsm.dayName.slice(0, 3)} (${moment
                                    .utc(rsm.timeFrom, "HH:mm:ss")
                                    .local()
                                    .format("hh:mm:ss A")})`
                              )
                              .join("  ")}
                      </span>
                      <a
                        className="link"
                        onClick={() =>
                          props.openModal("SCHEDULE_MODAL", {
                            scheduleId: schedule.id,
                          })
                        }
                      >
                        Edit
                      </a>
                    </div>
                    <div
                      className="info"
                      style={brandColor ? { borderColor: brandColor } : {}}
                    >
                      <p>
                        {" "}
                        <span className="bold">
                          {schedule.user.first_name} {schedule.user.last_name}:
                        </span>
                        <Highlighter
                          highlightClassName="high-light-theme"
                          searchWords={HIGHLIGHTS}
                          autoEscape={true}
                          textToHighlight={schedule.message_content}
                          caseSensitive={true}
                        />
                      </p>
                      {schedule.media_data && (
                        <AttachmentView media_data={schedule.media_data} />
                      )}
                    </div>
                  </li>
                ))
            ) : (
              <li className="no">
                No {type === 0 ? "Message" : "Recurring"} Schedules created yet.
              </li>
            )}
          </ul>
        )}
      </>
    );
  };

  const TabContent = (title: string, offset: number, content: string) => (
    <span>
      {title}
      <img
        className="help-icon"
        src={IconHelp}
        alt="Help"
        data-tip
        data-for={title}
      />
      <ReactTooltip
        className="tooltipContainer"
        id={title}
        effect="solid"
        place="bottom"
        offset={{ left: offset }}
      >
        <span className="tooltipContent">{content}</span>
      </ReactTooltip>
    </span>
  );

  const tabs = [
    {
      id: 0,
      title: TabContent("Scheduled Message", -22, "Schedule Message"),
      component: ScheduleContent(0),
    },
    {
      id: 1,
      title: TabContent("Recurring Message", 155, "Recurring Message"),
      component: ScheduleContent(1),
    },
  ];

  const activeTab = tabs.filter(tab => tab.id === activeTabId)[0];

  return (
    <div className="SchedulerPanel">
      <div className="inner">
        <header>
          <h1>Schedule Options</h1>
        </header>
        <ul className="SchedulerPanel__Tabs">
          {tabs.map(tab => (
            <ThemedTab
              key={tab.id}
              onClick={changeActiveTab}
              className={classnames({
                active: activeTabId === tab.id,
              })}
              data-tab-id={tab.id}
            >
              {tab.title}
            </ThemedTab>
          ))}
        </ul>
        {activeTab && activeTab.component}
        <div style={{ padding: 15, display: "grid" }}>
          <ThemedButton
            onClick={() =>
              props.openModal("SCHEDULE_MODAL", {
                scheduleId: -1,
                activeTabId: activeTab.id,
              })
            }
          >
            Add {activeTab.id === 0 ? "Scheduled" : "Recurring"} Message
          </ThemedButton>
        </div>
      </div>
      <i
        className="icon-close icon-close-position"
        onClick={props.toggleSchedulePanel}
      />
    </div>
  );
});
