import Axios, { AxiosError, AxiosResponse } from "axios";
import moment from "moment";
import { AnyAction, Dispatch } from "redux";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

export interface IRecurringAutoReply {
  isActive: boolean;
  dayName: string;
  timeFrom?: string;
  timeTo?: string;
  isValid?: boolean;
  [key: string]: any;
}

export interface IAutoReply {
  id?: number;
  title: string;
  keyword: string;
  keywords: Array<string>;
  use_keywords: boolean;
  exact_match: boolean;
  start_at?: moment.Moment | string | undefined;
  end_at?: moment.Moment | string | undefined;
  text_content: string;
  is_active: boolean;
  send_to_all: boolean;
  send_to_contacts: boolean;
  recurring_replies: IRecurringAutoReply[];
  media_link: string | null;
  file_Name: string | null;
  file_Size: string | null;
  media_file_mime: string;
}

// ------------------------------------
// Constants
// ------------------------------------
const AUTO_REPLIES_CREATE_REQUEST = "AUTO_REPLIES_CREATE_REQUEST";
const AUTO_REPLIES_CREATE_SUCCESS = "AUTO_REPLIES_CREATE_SUCCESS";
const AUTO_REPLIES_CREATE_FAILURE = "AUTO_REPLIES_CREATE_FAILURE";

const AUTO_REPLIES_DELETE_REQUEST = "AUTO_REPLIES_DELETE_REQUEST";
const AUTO_REPLIES_DELETE_SUCCESS = "AUTO_REPLIES_DELETE_SUCCESS";
const AUTO_REPLIES_DELETE_FAILURE = "AUTO_REPLIES_DELETE_FAILURE";

const AUTO_REPLIES_LIST_REQUEST = "AUTO_REPLIES_LIST_REQUEST";
const AUTO_REPLIES_LIST_SUCCESS = "AUTO_REPLIES_LIST_SUCCESS";
const AUTO_REPLIES_LIST_FAILURE = "AUTO_REPLIES_LIST_FAILURE";

const AUTO_REPLIES_UPDATE_REQUEST = "AUTO_REPLIES_UPDATE_REQUEST";
const AUTO_REPLIES_UPDATE_SUCCESS = "AUTO_REPLIES_UPDATE_SUCCESS";
const AUTO_REPLIES_UPDATE_FAILURE = "AUTO_REPLIES_UPDATE_FAILURE";

// ------------------------------------
// Action Creators
// ------------------------------------
const createAutoReplyRequest = () => ({ type: AUTO_REPLIES_CREATE_REQUEST });
const createAutoReplySuccess = (res: IAutoReply) => ({
  type: AUTO_REPLIES_CREATE_SUCCESS,
  payload: res,
});
const createAutoReplyFailure = (err: AxiosError) => ({
  type: AUTO_REPLIES_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteAutoReplyRequest = () => ({ type: AUTO_REPLIES_DELETE_REQUEST });
const deleteAutoReplySuccess = (res: number) => ({
  type: AUTO_REPLIES_DELETE_SUCCESS,
  payload: res,
});
const deleteAutoReplyFailure = (err: AxiosError) => ({
  type: AUTO_REPLIES_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listAutoRepliesRequest = () => ({ type: AUTO_REPLIES_LIST_REQUEST });
const listAutoRepliesSuccess = (res: AxiosResponse) => ({
  type: AUTO_REPLIES_LIST_SUCCESS,
  payload: res.data,
});
const listAutoRepliesFailure = (err: AxiosError) => ({
  type: AUTO_REPLIES_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateAutoReplyRequest = () => ({ type: AUTO_REPLIES_UPDATE_REQUEST });
const updateAutoReplySuccess = (res: IAutoReply) => ({
  type: AUTO_REPLIES_UPDATE_SUCCESS,
  payload: res,
});
const updateAutoReplyFailure = (err: AxiosError) => ({
  type: AUTO_REPLIES_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------
const createAutoReply = (form: IAutoReply) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  if (form.use_keywords && form.keywords) {
    form.keywords = form.keywords.map((keyword: any) => keyword.value);
    if (!form.keyword) {
      form.keyword = form.keywords[0];
    }
  } else {
    form.keywords = [];
    form.keyword = "";
  }
  if (form.type === 1) {
    delete form.start_at;
    delete form.end_at;
    form.recurring_replies = form.recurring_replies
      .filter(i => i.isActive)
      .map(i => ({
        isActive: i.isActive,
        dayName: i.dayName,
        timeFrom: i.timeFrom,
        timeTo: i.timeTo,
      }));
  } else {
    delete form.recurring_replies;
  }
  if (!form.use_keywords) {
    delete form.keyword;
    // delete form.keywords;
    delete form.exact_match;
  }
  console.log(form);

  try {
    dispatch(createAutoReplyRequest());
    const res = await Axios.post(
      `${API_URL}/phone_numbers/${activePhone}/autoreplies`,
      form,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(createAutoReplySuccess({ ...form, id: res.data.data.id }));
    }
  } catch (err) {
    dispatch(createAutoReplyFailure(err));
  }
};

const deleteAutoReply = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(deleteAutoReplyRequest());
    const res = await Axios.delete(
      `${API_URL}/phone_numbers/${activePhone}/autoreplies/${id}`,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(deleteAutoReplySuccess(id));
    }
  } catch (err) {
    dispatch(deleteAutoReplyFailure(err));
  }
};

const listAutoReplies = () => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(listAutoRepliesRequest());
    const res = await Axios.get(
      `${API_URL}/phone_numbers/${activePhone}/autoreplies`,
      withAuthorizationHeader(token)
    );
    dispatch(listAutoRepliesSuccess(res));
  } catch (err) {
    dispatch(listAutoRepliesFailure(err));
  }
};

const updateAutoReply = (form: IAutoReply) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token, activePhone } = getState().users.authenticatedUser;
  if (id && token && form) {
    try {
      const restForm = Object.assign({}, form);
      if (restForm.use_keywords && restForm.keywords) {
        const newKeywords = restForm.keywords.map(
          (keyword: any) => keyword.value
        );
        restForm.keywords = [...newKeywords];
        restForm.keyword = restForm.keywords[0];
      } else {
        restForm.keywords = [];
        restForm.keyword = "";
      }

      dispatch(updateAutoReplyRequest());
      await Axios.put(
        `${API_URL}/phone_numbers/${activePhone}/autoreplies/${form.id}`,
        restForm,
        withAuthorizationHeader(token)
      );
      dispatch(updateAutoReplySuccess(restForm));
    } catch (err) {
      dispatch(updateAutoReplyFailure(err));
    }
  }
};

export const autoRepliesActions = {
  createAutoReply,
  deleteAutoReply,
  listAutoReplies,
  updateAutoReply,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeReply: -1,
  isFetching: false,
  data: [],
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case AUTO_REPLIES_CREATE_REQUEST:
    case AUTO_REPLIES_DELETE_REQUEST:
    case AUTO_REPLIES_LIST_REQUEST:
    case AUTO_REPLIES_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case AUTO_REPLIES_CREATE_SUCCESS:
      const newReplies = [...state.data];
      !newReplies.find((item: IAutoReply) => item.id === action.payload.id) &&
        newReplies.push(action.payload as never);
      return {
        ...state,
        isFetching: false,
        activeReply: action.payload.id,
        data: newReplies,
      };

    case AUTO_REPLIES_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeReply: -1,
        data: state.data.filter(
          (reply: IAutoReply) => reply.id !== action.payload
        ),
      };

    case AUTO_REPLIES_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.map((item: IAutoReply) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case AUTO_REPLIES_LIST_SUCCESS:
      return { ...state, isFetching: false, data: action.payload };

    case AUTO_REPLIES_CREATE_FAILURE:
    case AUTO_REPLIES_DELETE_FAILURE:
    case AUTO_REPLIES_LIST_FAILURE:
    case AUTO_REPLIES_UPDATE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
