import classNames from "classnames";
import React, { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { connect } from "react-redux";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedTextarea from "~/components/common/ThemedTextarea/ThemedTextarea";
import {
  contactsActions,
  IExternalContact,
  IExternalContactForm,
} from "~/store/contacts";
import { conversationActions } from "~/store/conversations";
import USFlag from "~/assets/us.svg";
import "./ContactEdit.scss";
import { fpn1, fpnNew } from "~/utils";

interface DispatchProps {
  createContact: (form: IExternalContact) => Promise<unknown>;
  updateContact: (
    contactId: number | string,
    form: IExternalContact
  ) => Promise<unknown>;
  updateContactStore: (contact: IExternalContact) => void;
}

interface OwnProps {
  onBack: () => void;
  shown: boolean;
}

interface StateProps {
  activeContact: IExternalContact;
  errors: [];
  addNewFromNumber: boolean;
  authenticatedUser: any;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  createContact: contactsActions.createContact,
  updateContact: contactsActions.updateContact,
  updateContactStore: conversationActions.updateContactStore,
};

const mapStateToProps = (state: any) => ({
  activeContact: state.contacts.activeContact,
  errors: state.contacts.errors,
  addNewFromNumber: state.modal.modalProps && state.modal.modalProps.activeEdit,
  authenticatedUser: state.users.authenticatedUser,
});

const formInitial: IExternalContactForm = {
  first_name: "",
  last_name: "",
  notes: "",
  phone_number: "",
  in_contacts: true,
  is_deleted: false,
  company: "",
  job_title: "",
  email: "",
  address_line_1: "",
  address_line_2: "",
  city: "",
  state: "",
  zipcode: "",
  custom_field_1: "",
  custom_field_2: "",
  custom_field_3: "",
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const [form, setForm] = useState(formInitial);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (props.activeContact) {
      setForm(props.activeContact);
    }
  }, []);

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    if (props.activeContact) {
      const updateForm = { ...form };
      // Adding to contacts from Conversation List
      if (props.addNewFromNumber) {
        updateForm.in_contacts = true;
      }
      // Update contact
      props
        .updateContact(props.activeContact.id, updateForm)
        .then(() => {
          props.updateContactStore({ ...props.activeContact, ...updateForm });
          props.onBack();
        })
        .catch(errors => setErrors(errors && errors.messages));
    } else {
      // Create contact
      props
        .createContact({ ...form, in_contacts: true })
        .then(props.onBack)
        .catch(errors => setErrors(errors && errors.messages));
    }
  }

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  }

  function handleChangeNumber(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { value } = e.target;
    if (/^\d*$/.test(value)) {
      setForm({ ...form, phone_number: value });
    }
  }

  function contactName(name: string) {
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      name
        .trim()
        .split(" ")
        .join("")
    );
    if (isPhoneName) {
      name = fpn1(name, props.authenticatedUser.show_censor_contact);
    }
    return name;
  }

  return (
    <form
      className={classNames("ContactEdit", { shown: props.shown })}
      onSubmit={handleSubmit}
    >
      <div className="ContactEdit__Scroll">
        <button type="button" onClick={props.onBack}>
          <i className="icon-back"></i>
        </button>
        <h1>{props.activeContact ? "Edit" : "Add"} Contact</h1>
        <div>
          <div style={{ position: "relative" }}>
            <label
              className={classNames("mandatory", {
                error: isValidPhoneNumber(form.phone_number),
              })}
              htmlFor="phone_number"
            >
              Phone
            </label>
            <img id="phone_number_flag" src={USFlag} alt="USA Flag" />
            <span>+1</span>
            <ThemedInput
              id="phone_number"
              type="text"
              onChange={handleChangeNumber}
              maxLength={10}
              placeholder="Phone Number"
              value={
                form.phone_number.length == 11 &&
                form.phone_number.indexOf("1") === 0
                  ? fpnNew(
                      form.phone_number.slice(1),
                      props.authenticatedUser.show_censor_contact
                    )
                  : fpnNew(
                      form.phone_number,
                      props.authenticatedUser.show_censor_contact
                    )
              }
            />
          </div>
          <div className="columns">
            <label className="mandatory" htmlFor="first_name">
              Name
            </label>
            <ThemedInput
              id="first_name"
              type="text"
              onChange={handleChange}
              placeholder="First Name"
              value={contactName(form.first_name)}
            />
            <ThemedInput
              id="last_name"
              type="text"
              onChange={handleChange}
              placeholder="Last Name"
              value={form.last_name}
            />
          </div>
          <div className="columns_2">
            <div>
              <label htmlFor="company_name">Company</label>
              <ThemedInput
                id="company"
                type="text"
                onChange={handleChange}
                placeholder="Company"
                value={form.company}
              />
            </div>
            <div>
              <label htmlFor="job_title">Job Title</label>
              <ThemedInput
                id="job_title"
                type="text"
                onChange={handleChange}
                placeholder="Job Title"
                value={form.job_title}
              />
            </div>
          </div>
          <div className="columns_2">
            <div>
              <label htmlFor="email">Email</label>
              <ThemedInput
                id="email"
                type="text"
                onChange={handleChange}
                placeholder="Email"
                value={form.email}
              />
            </div>
            <div>
              <label htmlFor="address_line_1">Address Line 1</label>
              <ThemedInput
                id="address_line_1"
                type="text"
                onChange={handleChange}
                placeholder="Address Line 1"
                value={form.address_line_1}
              />
            </div>
          </div>
          <div className="columns_2">
            <div>
              <label htmlFor="address_line_2">Address Line 2</label>
              <ThemedInput
                id="address_line_2"
                type="text"
                onChange={handleChange}
                placeholder="Address Line 2"
                value={form.address_line_2}
              />
            </div>
            <div>
              <label htmlFor="city">City</label>
              <ThemedInput
                id="city"
                type="text"
                onChange={handleChange}
                placeholder="City"
                value={form.city}
              />
            </div>
          </div>
          <div className="columns_2">
            <div>
              <label htmlFor="state">State</label>
              <ThemedInput
                id="state"
                type="text"
                onChange={handleChange}
                placeholder="State"
                value={form.state}
              />
            </div>
            <div>
              <label htmlFor="zipcode">Zipcode</label>
              <ThemedInput
                id="zipcode"
                type="text"
                onChange={handleChange}
                placeholder="Zipcode"
                value={form.zipcode}
              />
            </div>
          </div>
          <div className="columns_2">
            <div>
              <label htmlFor="custom_field_1">Custom Field 1</label>
              <ThemedInput
                id="custom_field_1"
                type="text"
                onChange={handleChange}
                placeholder="Custom Field 1"
                value={form.custom_field_1}
              />
            </div>
            <div>
              <label htmlFor="custom_field_2">Custom Field 2</label>
              <ThemedInput
                id="custom_field_2"
                type="text"
                onChange={handleChange}
                placeholder="Custom Field 2"
                value={form.custom_field_2}
              />
            </div>
          </div>
          <div>
            <label htmlFor="custom_field_3">Custom Field 3</label>
            <ThemedInput
              id="custom_field_3"
              type="text"
              onChange={handleChange}
              placeholder="Custom Field 3"
              value={form.custom_field_3}
            />
          </div>
          <div>
            <label htmlFor="notes">Notes</label>
            <ThemedTextarea
              id="notes"
              placeholder="Notes"
              maxLength={160}
              onChange={handleChange}
              value={form.notes}
            ></ThemedTextarea>
          </div>
          {errors && errors.length > 0 && (
            <div className="error">
              <ul>
                {errors.map((error: string, key: number) => (
                  <li key={key}>
                    <label>{error}</label>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>
      <footer>
        <ThemedButton
          type="submit"
          disabled={
            !form.first_name || !form.last_name || form.phone_number.length < 10
          }
        >
          {props.activeContact &&
          props.activeContact.first_name &&
          !props.addNewFromNumber
            ? "Update"
            : "Add"}
        </ThemedButton>
        <button onClick={props.onBack} type="button">
          Cancel
        </button>
      </footer>
    </form>
  );
});
