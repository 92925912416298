import React, { Component } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import _ from "lodash";
import Modal from "~/components/modals/AbstractModal/AbstractModal";
import "./HelpInfo.scss";
import styled from "styled-components";
import { ThemeContext } from "~/utils";
import LogoutScreen from "~/assets/help/logout.png";
import ChangePasswordScreen from "~/assets/help/change_password.png";

import PhoneChange from "~/assets/help/image1.gif";
import ImportingContacts from "~/assets/help/image3.gif";
import BlastMessages from "~/assets/help/image4.gif";
import LoginScreen from "~/assets/help/image6.gif";
import StatusChange from "~/assets/help/image7.gif";
import AddingContact from "~/assets/help/image9.gif";
import AutoReplies from "~/assets/help/image10.gif";
import SuggestedReplies from "~/assets/help/image11.gif";
import WhitelableTextConverter from "~/components/WhitelableTextConverter/WhitelableTextConverter";
import Placeholders from "~/assets/help/image12.gif";
import Template1 from "~/assets/help/image13.gif";
import Template2 from "~/assets/help/image14.gif";
import Schedule1 from "~/assets/help/image15.gif";
import Schedule2 from "~/assets/help/image16.gif";
import { Branding } from "~/branding";

const helpItems = [
  {
    key: "about",
    title: <WhitelableTextConverter text={`About ${Branding.APP_NAME}`} />,
    html: () => (
      <>
        <h2>
          <WhitelableTextConverter text={`About ${Branding.APP_NAME}`} />
        </h2>
        <p>
          <WhitelableTextConverter
            text={`${Branding.APP_NAME} is an application designed to facilitate internal and
          external communications through texts on any message-enabled business
          number. To foster enhanced team collaboration, each user can have
          access to multiple numbers in ${Branding.APP_NAME}.`}
          />
        </p>
      </>
    ),
  },
  {
    key: "faq",
    title: "Frequently Asked Questions",
    html: (onToggle: any, active = 0, that) => {
      const qa = [
        {
          q:
            "I have my office number, but saw I have access to some additional numbers. Why do I need another number?",
          a: [
            <WhitelableTextConverter
              text={`${Branding.APP_NAME} provides you the flexibility of having numbers that can be accessed by multiple users so texts do not go unanswered. These can be useful for customer service queues, marketing promotions, or even main reception numbers staffed by multiple people.`}
            />,
          ],
        },
        {
          q: "How can I change the number I'm using for messaging?",
          a: [
            "If you have access to multiple numbers, your default number is selected automatically after logging in. To switch to another number, simply click the dropdown arrow next to the selected number under your name and select from the dropdown list provided.",
          ],
        },
        {
          q: "How can I order more numbers?",
          a: ["To order more numbers, please contact your Administrator."],
        },
        {
          q: "Why can't I find my conversation?",
          a: [
            <WhitelableTextConverter
              text={`Your conversation might be hidden by one of the users that has access to the phone number. However, once a new message is sent in the conversation thread, this conversation will be shown in the list again. You can start a new message to this contact by selecting their name from the Contacts section of ${Branding.APP_NAME}.`}
            />,
          ],
        },
        {
          q: "Can I hide a conversation?",
          a: [
            "To hide a conversation from the list, simply mouse over (or swipe left on your smartphone) the contact and click the X button.",
          ],
        },
        {
          q: "How can I change my password?",
          a: [
            'Select your name in the upper right hand corner of the screen and select the "Account" tab. Once there, click "Change Password" to change your password. Fill out the old password field as well as the new password and confirm password fields. The new password and confirm new password fields must match.​ Click "Save" to save the new password or click "Cancel" to cancel the password change.',
          ],
        },
        {
          q: "How long are my messages saved?",
          a: [
            <WhitelableTextConverter
              text={`Messages you send and receive within ${Branding.APP_NAME} are saved indefinitely unless storage becomes a limitation.`}
            />,
          ],
        },
        {
          q: "Why are my messages archived?",
          a: [
            "Messages are archived in compliance with your organization’s policies.",
          ],
        },
        {
          q: "How do I find a specific message?",
          a: [
            'Select the "Advanced Search" icon and enter what word/phrase you\'re looking for in the "Search Query" field. Make sure to select a period you want to search (Last Month, Last Year, Custom, etc.).',
            <WhitelableTextConverter
              text={`Please note you can also include the name of a specific contact in the "Conversations with" field. This will allow you to search all conversations with that specific person. If you leave the "Conversations with" field blank, ${Branding.APP_NAME} will search through all conversations.`}
            />,
            'Select "Search" and the appropriate results will be displayed.',
          ],
        },
        {
          q: "How do I set my status?",
          a: [
            'Your status (the circle on the upper righthand corner of the screen) can be changed easily. Simply click on the dropdown menu to open a list of options. Available should be the first and only one provided as standard option on the list. To add a status, simply type the status in the field, select a color, and hit "return". To change the color of a status, click on the circle until it becomes the color you want.',
            <img src={StatusChange} alt="Changing status" />,
            'To delete a status from your list, hover over the status and click the "Trash" icon.',
            'You cannot delete the "Available" status.',
          ],
        },
        {
          q: "How do I bulk upload contacts?",
          a: [
            'Bulk uploading contacts is simple! Select "Contacts" from the main menu then click the Upload arrow. You will be asked to choose a CSV file from your computer or to drag and drop one in.',
            "PLEASE NOTE: Your CSV file must follow a specific organization: ​Phone number (10 digit, no dashes), Last Name, First Name, Notes. Do not include headers.",
            "Once the list is dropped in, you will be allowed to preview the information before importing.",
          ],
        },
        {
          q: "Why aren't my lists importing?",
          a: [
            "Your CSV file has to follow a specific organization. If your numbers have dashes between them or your columns are mixed up, you will not be allowed to import the list. Please double-check your formatting before submitting.",
          ],
        },
        {
          q: "Can I upload multiple lists at once?",
          a: [
            <WhitelableTextConverter
              text={`You are able to upload one list at a time in the current version of ${Branding.APP_NAME}.`}
            />,
          ],
        },
      ];
      return (
        <>
          <h2>Frequently Asked Questions</h2>
          <ul className="faq">
            {qa.map((item, i: number) => (
              <li className={classnames({ active: active === i })} key={i}>
                <strong onClick={onToggle.bind(that, i)}>{item.q}</strong>
                {active === i &&
                  item.a.map((answer: string, j: number) => (
                    <p key={"" + i + j}>{answer}</p>
                  ))}
              </li>
            ))}
          </ul>
        </>
      );
    },
  },
  {
    key: "tutorial",
    title: "Tutorials",
    html: () => (
      <p>
        <WhitelableTextConverter
          text={`${Branding.APP_NAME} is designed to let you manage all your communication with
        customers.`}
        />
      </p>
    ),
    children: [
      {
        key: "tutorial/gs",
        title: "Getting started",
        html: () => (
          <>
            <h2>Getting started</h2>
            <p>
              <WhitelableTextConverter
                text={`${Branding.APP_NAME} was designed with the user in mind and we've made it a
              priority to ensure your experience as intuitive as possible!
              Getting started is quick, easy, and painless. All you need to do
              is log in with the credentials your administrator provided via
              email to access the powerful features of ${Branding.APP_NAME}.`}
              />
            </p>
            <img src={LoginScreen} alt={`${Branding.APP_NAME} login screen`} />
            <p>
              <b>Email</b>
              <br />
              Enter your email address
            </p>
            <p>
              <b>Password</b>
              <br />1<sup>st</sup>​ login: Enter the temporary password provided
              to you in the welcome email. You will be prompted to set up a new
              password. Please follow the "Password Requirements" listed below.
              After 1<sup>s​t</sup>​ login: Enter your personal password.
            </p>
            <p>
              <b>Keep me logged in</b>
              <br />
              Unchecked: You will be automatically logged out after 10 hours.
              <br />
              Checked: You will be automatically logged out after 30 days.
            </p>
            <p>
              <b>Sign In Button</b>
              <br />
              Once you've entered your email and password, click this button to
              log in.
            </p>
            <p>
              <b>Forgot Password</b>
              <br />
              Click here to reset your password. You will receive a link in the
              email you associated with your account.
            </p>
            <p>
              <b>Password Requirements</b>
              <br />
              To ensure the highest levels of security, when creating a new
              password, please follow these requirements:
              <ul>
                <li>8 character minimum</li>
                <li>Password must begin with a letter, not a number</li>
                <li>At least one capital letter</li>
                <li>At least one number</li>
                <li>At least one special character (!, @, #, $, %, ^, &, *)</li>
              </ul>
            </p>
            <p>
              <b>Logout</b>
              <br />
              Go to your profile by clicking on your name in the upper
              right-hand corner of the screen. Then click on the "Account" tab
              and select "Logout" to exit{" "}
              <WhitelableTextConverter text={`${Branding.APP_NAME}.`} />
            </p>
            <img
              src={LogoutScreen}
              alt={`${Branding.APP_NAME} logout screen`}
            />
            <p>
              <b>Change Password</b>
              <br />
              To change your password, select the "Change Password" link. Enter
              your current password, the new password, and confirm that new
              password. ​The new password and confirm new password fields must
              match.​ Click "Save" to save the new password or click "Cancel" to
              cancel the password change.
            </p>
            <img
              src={ChangePasswordScreen}
              alt={`${Branding.APP_NAME} change password screen`}
            />
          </>
        ),
      },
      {
        key: "tutorial/contacts",
        title: "Contacts",
        html: () => (
          <>
            <h2>Contacts</h2>
            <p>
              You should begin by building/reviewing your contact list. This
              will be on the left side of the main message window on the main
              page. All contacts that are internal to your corporate network are
              marked by a colored circle (green, orange, or red), while all
              contacts outside of your enterprise are marked by a black user
              icon.
            </p>
            <p>
              To add a new contact, simply select the "Contact" icon from the
              blue menu on the far left of the screen. This will open a window
              to display all your contacts and message lists. Simply select the
              plus icon to add a new contact.
            </p>
            <img src={AddingContact} alt="Adding a contact" />
            <p>
              To add new contacts via lists, select "Contacts" from the main
              menu then click the Upload arrow. You will be asked to choose a
              CSV file from your computer or to drag and drop one in.
            </p>
            <p>
              PLEASE NOTE: Your CSV file must follow a specific organization:
              Phone number (10 digit, no dashes), Last Name, First Name, Notes.
              Do not include headers.
            </p>
            <p>
              Once the list is dropped in, you will be allowed to preview the
              information before importing.
            </p>
            <img src={ImportingContacts} alt="Importing contacts" />
          </>
        ),
      },
      {
        key: "tutorial/mnumbers",
        title: "Multiple Numbers",
        html: () => (
          <>
            <h2>Multiple Numbers</h2>
            <p>
              If you have access to multiple numbers, you'll be able to view
              them by clicking the dropdown option beside the number that you're
              currently using.
            </p>
            <img src={PhoneChange} alt="Changing phone number" />
          </>
        ),
      },
      {
        key: "tutorial/sreplies",
        title: "Suggested Replies",
        html: () => (
          <>
            <h2>Suggested Replies</h2>
            <p>
              Save yourself some time by setting up suggested replies based on
              certain keywords. For instance if you have a retail business and
              you want to have replies to common questions, this can be a useful
              feature (e.g.: Customer texts: "How much is your current discount
              on sweaters?" The word “discount” will trigger a suggested
              response: "We're offering 40% off all winter sweaters until
              November 6th." that you can select when responding).
            </p>
            <p>
              To set up a suggested response, select the Reply Options icon from
              the main menu. Select the suggested replies tab and the plus icon
              on the bottom of the page. Once that's done, simply enter the
              title of the reply (seen by you only), the keywords to trigger it,
              and the actual suggested reply. When finished click add to save
              the suggested reply to the number.
            </p>
            <img src={SuggestedReplies} alt="Suggested Replies" />
          </>
        ),
      },
      {
        key: "tutorial/areplies",
        title: "Automatic Responses",
        html: () => (
          <>
            <h2>Automatic Responses</h2>
            <p>
              You can also set up automatic responses for different situations
              to facilitate a more immediate response, e.g. for when the office
              is closed. Simply select the Reply Options icon from the main
              menu, click on the Auto Replies tab and the plus icon on the
              bottom of the page. Like you did with suggested replies, begin by
              adding a title (seen only by you).
            </p>
            <ul>
              <li>
                <b>Keyword Trigger</b>
                <br />
                You can set the reply to trigger based on a specific keyword
                (e.g. Someone texts "What's your address?" and if “address” is
                the designated key word,{" "}
                <WhitelableTextConverter text={`${Branding.APP_NAME}`} /> will
                automatically send a response with the office address you’ve
                entered).
              </li>
              <li>
                <b>All Incoming Messages Trigger</b>
                <br />
                You can also trigger the auto reply when anyone texts your
                number (ex: A customer texts a toll free conference number
                anything and automatically receives the guest PIN for entering a
                conference call
              </li>
            </ul>
            <p>
              Once you've decided how the automatic reply will be triggered, and
              entered the reply you want, you must schedule it. There are two
              scheduling options: recurring and date range.
            </p>
            <ul>
              <li>
                <b>Recurring</b>
                <br />
                This will open a menu containing the days of the week, as well
                as "Active From" and "Active To" times. Select which days and
                times you want the auto reply to be sent by selecting the
                checkboxes next to the days, entering numbers in the “hour” and
                “minute” dropdowns, and selecting the time of day (am/pm). This
                can be helpful for use cases such as for someone who has a
                flexible schedule and isn't always in the office on certain
                days.
              </li>
              <li>
                <b>Date Range</b>
                <br />
                This will allow you to select the start and end date of your
                auto-reply. Date Range auto replies are particularly helpful for
                vacation and office-wide closures.
              </li>
            </ul>
            <p>
              After the criteria have been selected, you can choose whether this
              auto reply will be sent to everyone in your contacts (meaning
              everyone listed in your current address book), everyone not in
              your contacts (anyone who texts your number who isn't in your
              address book) or both. It's safest to select both when you're out
              on vacation.
            </p>
            <p>
              Finally, you can either enable or disable this auto reply based
              using the blue slider at the bottom of the window. Once all this
              is completed, click "Add" to add it to your list of auto replies.
              Please Note: You can turn auto replies off and on at any time from
              the "Auto Replies" list under "Reply Options."
            </p>
            <img src={AutoReplies} alt="Automatic Responses" />
          </>
        ),
      },
      {
        key: "tutorial/blast",
        title: "Blast Messages",
        html: () => (
          <>
            <h2>Blast Messages</h2>
            <p>
              These are particularly helpful for promotional outreach! To set up
              a blast message, click the "Contacts" icon and select the "Lists"
              tab in the window. Once in, click the plus button to add a new
              list.
            </p>
            <p>
              Name the list in a way that's informative to you, then add members
              from your contact list using the "Members" dropdown. You can
              either search through your contacts or scroll through the
              pre-populated list. Be sure everyone you want on the blast list is
              listed in your contacts.
            </p>
            <p>
              Once you've added the appropriate members, customize your
              compliance message. It is required by federal law that each
              initial message in a blast be accompanied with a compliance
              message allowing the recipient to opt out of future
              correspondence.
            </p>
            <p>
              You may also add any notes that you deem helpful (these notes will
              not be displayed to the recipients, only you). Simply select "Add"
              to add the list to your system.
            </p>
            <img src={BlastMessages} alt="Blast Messages" />
          </>
        ),
      },
      {
        key: "tutorial/template",
        title: "Templates",
        html: () => (
          <>
            <h2>Templates</h2>
            <p>
              Save time and energy by making templates for common messages you
              often send, like appointment reminders. To setup a new template,
              select the “T” icon from the blue menu on the far left of the
              screen. Select the add “+” button in the bottom right-hand corner
              of the popup screen. Give your template a title. Type your content
              into the template section using text, placeholders, emojis, and
              images. Then click save.
            </p>
            <img src={Template2} alt="Template2" />
            <p>
              To utilize your template, select the “T” icon at the bottom of
              your screen near your message box. Your templates will be
              displayed in alphabetical order by title. Select and send your
              template of choice.
            </p>
            <img src={Template1} alt="Template1" />
          </>
        ),
      },
      {
        key: "tutorial/placeholder",
        title: "Placeholders",
        html: () => (
          <>
            <h2>Placeholders</h2>
            <p>
              Placeholders are great for applying dynamic contact information to
              any message. Placeholders pull information from the recipient’s
              contact card, or your own personal information. To insert a
              placeholder you can use the [] icon next to the message box, or
              you can simply type your placeholder between two brackets like:
              [First Name].
            </p>
            <p>
              If the placeholder is recognized, the system will highlight the
              phrase. If the contact card does not contain a value for that
              placeholder, the system will send it as “blank” so your contact
              does not know you were trying to use a dynamic placeholder.
            </p>
            <img src={Placeholders} alt="PlaceHolder" />
          </>
        ),
      },
      {
        key: "tutorial/schedule",
        title: "Scheduled Messages",
        html: () => (
          <>
            <h2>Scheduled Messages</h2>
            <p>
              Be prepared and never forget to send your message again. Send
              appointment reminders, important updates, or a timely thought. To
              setup a new scheduled message, select the clock icon at the
              top-right of the conversation window. This will open a third panel
              on the right-side of the screen. At the bottom of the new panel
              screen select “Add Scheduled Message” or “Add Recurring Message”.
              This will open a popup window for your new message.
            </p>
            <p>
              Place your text in the text box. Utilize the power of templates,
              placeholders, emoji’s and attachments to enhance your message.
              Choose to send your message one time with the “Date & Time”
              selection or send your message on a repeated schedule using the
              “Recurring” selection. Set the time at which you would like to
              send your message, down to the second, and click “Add”.
            </p>
            <img src={Schedule2} alt="Schedule2" />
            <p>
              To edit your message, or to adjust the sending time, click the
              “Edit” button just above the individual message. When it is time
              for your message to send, your message will move from the
              scheduled messages queue and it will post in the conversation
              thread. Never again miss sending a message.
            </p>
            <img src={Schedule1} alt="Schedule1" />
          </>
        ),
      },
    ],
  },
];

type HelpItem = {
  title: string;
  html: Function;
  children: HelpItem[];
  onSelectSection: Function;
};

type HelpItems = {
  items: HelpItem[];
  level: number;
  onSelectSection: Function;
  activeKey: string;
  dotColor: string;
};

interface StateProps {
  authenticatedUser: any;
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

const GlossaryItems = connect(mapStateToProps)(
  (props: HelpItems & StateProps, context) => {
    const {
      authenticatedUser: user,
      items,
      level,
      onSelectSection,
      activeKey,
    } = props;
    let brandColor;
    if (context.theme || (user && user.company && user.company.whitelabeling)) {
      const theme = context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
    let Item = styled.div`
      :before {
        background: ${brandColor} !important;
      }
    `;
    return (
      <div className="HelpInfoModalContent__Sidebar__Items">
        {_.map(items, (item, index) => (
          <React.Fragment key={index}>
            <Item
              className={classnames(
                `HelpInfoModalContent__Items_Item level-${level}`,
                { active: activeKey === item.key }
              )}
              onClick={() => onSelectSection(item.html, item.key)}
            >
              {item.title}
            </Item>
            {item.children && (
              <GlossaryItems
                items={item.children}
                level={level + 1}
                onSelectSection={onSelectSection}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    );
  }
);

GlossaryItems.contextType = ThemeContext;

class HelpInfoModal extends Component {
  state = {
    activeSection: helpItems[0].html,
    activeQuestion: 0,
    selected: false,
  };

  onSelectSection = (activeSection: string, activeKey: string) => {
    this.setState({ activeSection, activeKey, selected: true });
  };

  handleBack = () => {
    this.setState({ selected: false });
  };

  toggleQuestion = (index: number, e: MouseEvent) => {
    e.stopPropagation();
    this.setState({ activeQuestion: index });
  };

  render() {
    const { authenticatedUser: user } = this.props;
    const { activeSection, activeKey } = this.state;
    let support_email = Branding.SUPPORT_EMAIL;
    let support_phone = Branding.SUPPORT_PHONE_NUMBER;
    if (user && user.company && user.company.whitelabeling) {
      support_email = user.company.whitelabeling.support_email;
      support_phone = user.company.whitelabeling.support_phone;
    }

    return (
      <Modal title="Help" className="HelpInfoModal">
        <div className="HelpInfoModalContent">
          <div className="HelpInfoModalContent__Sidebar">
            <GlossaryItems
              level={1}
              items={helpItems}
              onSelectSection={this.onSelectSection}
              activeKey={activeKey}
            />
          </div>
          <div
            className={`HelpInfoModalContent__Content ${
              this.state.selected ? "selected" : ""
            }`}
          >
            <button
              className="backButton"
              type="button"
              onClick={this.handleBack}
            >
              <i className="icon-back"></i>
            </button>
            {activeSection(
              this.toggleQuestion,
              this.state.activeQuestion,
              this
            )}
          </div>
        </div>
        <footer className="HelpInfoModalContent__Footer">
          <b>Support:</b>
          <a href={`mailto:${support_email}`}>{support_email}</a>
          <span>or</span>
          {support_phone}
        </footer>
      </Modal>
    );
  }
}

export default connect(mapStateToProps)(HelpInfoModal);
