import classNames from "classnames";
import React, { useEffect, useState, useLayoutEffect } from "react";
import { connect } from "react-redux";
import { IList, listsActions } from "~/store/lists";
import Loader from "../common/Loader/Loader";
import "./ListList.scss";

interface DispatchProps {
  getBlastList: () => Promise<unknown>;
  setActiveList: (id: string) => void;
}

interface StateProps {
  activeList: IList | null;
  isFetching: boolean;
  items: IList[];
  activeEdit: boolean;
}

interface OwnProps {
  changeShown: (shown: string) => void;
  message?: string;
  isEdit: any;
  search: string;
  shown: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  getBlastList: listsActions.getList,
  setActiveList: listsActions.setList,
};
const mapStateToProps = (state: any) => ({
  activeList: state.lists.activeList,
  isFetching: state.lists.isFetching,
  items: state.lists.data,
  activeEdit: state.modal.modalProps.activeEdit || false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  let filteredItems;
  const [first, setFirst] = useState(!props.activeEdit);
  const defaultPageSize = 20;
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    props.getBlastList();
  }, []);

  useLayoutEffect(() => {
    const updateSize = () => {
      const PAGE_SIZE = window.innerHeight / 625;
      if (PAGE_SIZE > 1) {
        setPageSize(PAGE_SIZE * defaultPageSize);
      }
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    if (first) {
      filteredItems = filterLists();
      if (filteredItems[0]) {
        props.setActiveList(filteredItems[0].id);
        setFirst(false);
      }
    }
  }, [props.items]);

  function handleListClick(item: any) {
    if (!props.isEdit) {
      props.setActiveList(item.id);
      props.changeShown("profile");
    }
  }

  function sortABC(a: IList, b: IList): number {
    return a.name === b.name ? 0 : a.name < b.name ? -1 : 1;
  }

  function filterLists() {
    let list = props.items;
    if (props.search) {
      list = list.filter(item =>
        item.name.toLowerCase().includes(props.search.toLowerCase())
      );
    }
    return list.sort(sortABC).slice(0, pageSize);
  }

  filteredItems = filterLists();

  const handleScroll = (event: any) => {
    if (event) {
      const target = event.target;
      const diff = Math.trunc(target.scrollHeight - target.scrollTop);
      const bottom = diff <= Math.trunc(target.clientHeight);
      if (bottom) {
        if (props.items.length >= pageSize + defaultPageSize) {
          setPageSize(pageSize + defaultPageSize);
        } else if (props.items.length != pageSize) {
          setPageSize(props.items.length);
        }
      }
    }
  };

  return (
    <ul
      className={classNames("ContactList searchable", { shown: props.shown })}
      onScroll={handleScroll}
    >
      {props.isFetching && props.items.length === 0 ? (
        <Loader />
      ) : filteredItems.length ? (
        filteredItems.map((item: IList) => (
          <li
            className={classNames({
              active: props.activeList && props.activeList.id === item.id,
              disabled: props.isEdit,
            })}
            key={item.id}
            onClick={handleListClick.bind(null, item)}
          >
            <i className={classNames("icon-broadcast")}></i>
            <span>{item.name}</span>
          </li>
        ))
      ) : (
        <li>{props.message || "No data"}</li>
      )}
    </ul>
  );
});
