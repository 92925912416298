import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Switch from "react-switch";
import styled from "styled-components";
import { LightenDarkenColor, ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}
interface DispatchProps {}
interface OwnProps {
  className: string;
  checked: boolean;
  key: any;
  onClick?: any;
  "data-tab-id": any;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedSwitch extends PureComponent<Props> {
  toggleClick = (e: MouseEvent) => {
    e.stopPropagation();
  };

  render() {
    let brandColor, lightenColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightenColor = LightenDarkenColor(brandColor, 30);
    }

    let SwitchWrapper = styled.div`
      .checked .react-switch-bg {
        background: ${lightenColor} !important;
      }
    `;

    return (
      <SwitchWrapper onClick={this.toggleClick}>
        <Switch
          handleDiameter={30}
          height={40}
          width={100}
          onColor={lightenColor || "#00bbe5"}
          offColor={"#a1a1a1"}
          className={this.props.checked ? "checked" : ""}
          {...this.props}
        />
      </SwitchWrapper>
    );
  }
}

ThemedSwitch.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedSwitch);
