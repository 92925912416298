import React from "react";
import { connect } from "react-redux";
import _ from "lodash";
import { Branding } from "~/branding";

interface StateProps {
  authenticatedUser: any;
}

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class Logo extends React.PureComponent<StateProps> {
  render() {
    const wl = _.get(
      this.props.authenticatedUser,
      "company.whitelabeling",
      false
    );
    return (
      <div style={{ textAlign: "center" }}>
        {wl && wl.logo_url ? (
          <img className="top-logo" src={wl.logo_url} />
        ) : (
          <img
            className="top-logo"
            src={Branding.COMPANY_LOGO}
            alt={Branding.COMPANY_NAME}
            style={{ width: "50%" }}
          />
        )}
        <strong
          style={{
            display: "block",
            margin: "0 auto",
            font: '600 15px/18px "Helvetica Neue", Arial, sans-serif',
          }}
        >
          {wl && wl.application_name
            ? wl.application_name
            : Branding.COMPANY_NAME}
        </strong>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  null
)(Logo);
