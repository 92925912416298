import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { contactsActions, IExternalContact } from "~/store/contacts";
import { conversationActions } from "~/store/conversations";
import "./SearchInput.scss";
import { normalizePhoneNumber, isValidPhoneNumber } from "~/utils";
import { ThemeContext } from "~/utils";

interface DispatchProps {
  createContact: (form: IExternalContact) => Promise<any>;
  createConversation: (internal: string[], external: string[]) => void;
  getContactsList: () => void;
}
interface OwnProps {
  placeholder: string;
  className?: string;
  search: string;
  onChange: (search: string) => void;
  onClicked: () => void;
  clicked: boolean;
  open_search: boolean;
}
interface StateProps {
  contactList: any[];
  authenticatedUser: any;
  conversations: any[];
  searchConversations: any[];
  isFetchingSearchConversations: boolean;
}
export interface State {
  caret: {
    dir: number;
    end: number;
    start: number;
  };
  showStartConversation: boolean;
}

export type Props = DispatchProps & StateProps & OwnProps;

const mapDispatchToProps = {
  createContact: contactsActions.createContact,
  createConversation: conversationActions.createConversation,
  getContactsList: contactsActions.getContactsList,
};

const mapStateToProps = (state: any) => ({
  contactList: state.contacts.data,
  conversations: state.conversations.data,
  authenticatedUser: state.users.authenticatedUser,
  searchConversations: state.conversations.searchConversations,
  isFetchingSearchConversations:
    state.conversations.isFetchingSearchConversations,
});

class SearchInput extends PureComponent<Props, State> {
  private searchInput: HTMLInputElement | null = null;

  static propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string.isRequired,
  };

  state: State = {
    caret: {
      dir: 0,
      end: 0,
      start: 0,
    },
    showStartConversation: false,
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (
      prevProps.authenticatedUser === null &&
      this.props.authenticatedUser !== null &&
      this.props.contactList.length === 0
    ) {
      this.props.getContactsList();
    }

    if (prevState.showStartConversation === this.state.showStartConversation) {
      const { dir, end, start } = this.state.caret;
      if (this.searchInput && start && end) {
        const pos = end - (end - start) + dir;
        this.searchInput.setSelectionRange(pos, pos);
      }
    }

    if (prevProps.search !== this.props.search && this.props.search === "") {
      this.setState({ showStartConversation: false });
    } else {
      const value = this.props.search;
      console.log("New Chat Button Should Show If All Are True", {
        notIsFetching: !this.props.isFetchingSearchConversations,
        clicked: this.props.clicked,
        open_search: this.props.open_search,
        search: value,
        isValid: value ? isValidPhoneNumber(value) : null,
        notIsSaved: value ? !this.isSavedPhoneNumber(value) : null,
        notHasActive: value ? !this.hasActiveConversation(value) : null,
        notHasActiveSearch: value ? !this.hasActiveSearchConversation(value) : null,
      });
      this.setState({
        showStartConversation:
          !this.props.isFetchingSearchConversations &&
          this.props.clicked &&
          this.props.open_search &&
          !!value &&
          isValidPhoneNumber(value) &&
          !this.isSavedPhoneNumber(value) &&
          !this.hasActiveConversation(value) &&
          !this.hasActiveSearchConversation(value),
      });
    }
  };

  handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;

    if (this.searchInput) {
      const start = this.searchInput.selectionStart || 0;
      const end = this.searchInput.selectionEnd || 0;
      const dir = value.length - this.searchInput.value.length;

      this.setState(
        {
          caret: { dir, end, start },
          showStartConversation: false,
        },
        () => {
          this.props.onChange(value);
          if (this.searchInput && start && end) {
            const pos = end - (end - start) + dir;
            this.searchInput.setSelectionRange(pos, pos);
          }
        }
      );
    }
  };

  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.props.onClicked();
    }
  };

  isSavedPhoneNumber = (phone_number: string) => {
    if (phone_number.length === 10) {
      phone_number = "1" + phone_number;
    }
    const phones = this.props.contactList
      .filter(contact => contact.in_contacts)
      .reduce((result: string[], item: any) => {
        const phone =
          item.hasOwnProperty("is_phone") || item.hasOwnProperty("in_contacts")
            ? item.phone_number
            : item.phones.map((p: any) => p.phone_number).join(".");
        return [...result, phone];
      }, []);
    return phones.join(".").indexOf(phone_number) > -1;
  };

  hasActiveSearchConversation = (phone_number: string) => {
    // Bring to 11 digits if not full phone number
    phone_number = normalizePhoneNumber(phone_number);
    if (phone_number.length === 10) {
      phone_number = `1${phone_number}`;
    }
    const phonesInConversations = this.props.searchConversations
      // To show the button based only on conversations
      // and ignoring existing blast list which is also a conversation
      .filter(conversation => conversation.type === "conversation")
      .map(conversation => [
        ...conversation.external.map(external => external.phone_number),
        ...conversation.internal.map(internal => internal.phone_number),
      ])
      .reduce((acc, cur) => {
        cur.forEach(i => !acc.includes(i) && acc.push(i));
        return acc;
      }, []);
    return phonesInConversations.includes(phone_number);
  };

  hasActiveConversation = (phone_number: string) => {
    // Bring to 11 digits if not full phone number
    phone_number = normalizePhoneNumber(phone_number);
    if (phone_number.length === 10) {
      phone_number = `1${phone_number}`;
    }
    const phonesInConversations = this.props.conversations
      // To show the button based only on conversations
      // and ignoring existing blast list which is also a conversation
      .filter(conversation => conversation.type === "conversation")
      .map(conversation => [
        ...conversation.external.map(external => external.phone_number),
        ...conversation.internal.map(internal => internal.phone_number),
      ])
      .reduce((acc, cur) => {
        cur.forEach(i => !acc.includes(i) && acc.push(i));
        return acc;
      }, []);
    return phonesInConversations.includes(phone_number);
  };

  startConversation = () => {
    const phone_number = normalizePhoneNumber(this.props.search);
    const form: IExternalContact = {
      first_name:
        phone_number.length === 10 ? `1${phone_number}` : phone_number,
      last_name: "",
      // slicing because "1" will be added in createContact action
      // @TODO: enhance normalizePhoneNumber() to add "1" if needed and use it everywhere
      phone_number:
        phone_number.length === 10 ? phone_number : phone_number.slice(1),
      in_contacts: false,
      is_deleted: false,
    };
    this.props
      .createContact(form)
      .then((item: IExternalContact) => {
        this.props.createConversation([], [item.id]);
      })
      .catch(err => {
        // @TODO: To get rid of this magic string BE should specify error code
        if (
          err &&
          err.messages &&
          err.messages[0].slice(0, -11) ===
            "This number already exists in the list of contacts for "
        ) {
          const alreadyExistUnsavedContact = this.props.contactList.find(
            contact =>
              contact.in_contacts === false &&
              contact.phone_number === phone_number
          );
          if (alreadyExistUnsavedContact) {
            this.props.createConversation([], [alreadyExistUnsavedContact.id]);
          }
        }
      })
      .finally(() =>
        this.setState({ showStartConversation: false }, () =>
          this.props.onChange("")
        )
      );
  };

  renderSearch = () => {
    if (typeof this.props.onClicked === "function") {
      let brandColor;
      const { authenticatedUser: user } = this.props;
      if (
        this.context.theme ||
        (user && user.company && user.company.whitelabeling)
      ) {
        const theme = this.context.theme;
        brandColor =
          theme === "default"
            ? user.company.whitelabeling.primary_css_color
            : theme;
      }
      return (
        <i
          className="icon-search"
          style={{ background: brandColor }}
          onClick={() => this.props.onClicked()}
        ></i>
      );
    }
    return <i className="icon-search"></i>;
  };

  render() {
    return (
      <div
        className={classNames(
          "SearchInput",
          typeof this.props.onClicked === "function" ? "search-button" : "",
          this.props.className
        )}
      >
        {this.renderSearch()}
        <input
          onChange={this.handleChange}
          placeholder={this.props.placeholder}
          ref={ref => (this.searchInput = ref)}
          value={this.props.search}
          type="text"
          onKeyDown={
            typeof this.props.onClicked === "function"
              ? this.handleKeyDown
              : undefined
          }
        />
        {this.state.showStartConversation && (
          <button
            className="StartConversation"
            type="button"
            onClick={this.startConversation}
          >
            <i className="icon-chat"></i>
          </button>
        )}
      </div>
    );
  }
}
SearchInput.contextType = ThemeContext;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchInput);
