import React, { PureComponent } from "react";
import { connect } from "react-redux";

import { ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}
interface DispatchProps {}
interface OwnProps {
  children?: JSX.Element | string;
  className?: string;
  disabled?: boolean;
  onClick?: any;
  type?: string;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedButton extends PureComponent<Props> {
  render() {
    let brandColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
    return (
      <button
        className={this.props.className || "button is-primary"}
        disabled={this.props.disabled || false}
        style={brandColor ? { backgroundColor: brandColor } : {}}
        type={this.props.type || "button"}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}

ThemedButton.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedButton);
