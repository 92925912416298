import React, { Component } from "react";
import classnames from "classnames";
import "./DragAndDrop.scss";

const previewHeadersMap: any = {
  phone_number: "Phone number",
  last_name: "Last name",
  first_name: "First name",
  company: "Company",
  job_title: "Job title",
  email: "Email",
  address_line_1: "Address Line 1",
  address_line_2: " Address Line 2",
  city: "City",
  state: "State",
  zipcode: "Zipcode",
  custom_field_1: "Custom Field 1",
  custom_field_2: "Custom Field 2",
  custom_field_3: "Custom Field 3",
  notes: "Notes",
};

namespace DragAndDrop {
  export type State = {
    drag: boolean;
    filename: string;
  };
  export type Props = {
    handleDrop: (files: FileList) => void;
    csvData: any;
    error: string;
    dataType?: string;
    hint?: string;
  };
}

class DragAndDrop extends Component<DragAndDrop.Props, DragAndDrop.State> {
  state = {
    drag: false,
    filename: "",
  };
  dragCounter: number = 0;
  file: any;
  dropRef = React.createRef<HTMLDivElement>();
  handleDrag = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
  };
  handleDragIn = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter++;
    if (
      e.dataTransfer &&
      e.dataTransfer.items &&
      e.dataTransfer.items.length > 0
    ) {
      this.setState({ drag: true });
    }
  };
  handleDragOut = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.dragCounter--;
    if (this.dragCounter === 0) {
      this.setState({ drag: false });
    }
  };
  handleDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ drag: false });
    if (
      e.dataTransfer &&
      e.dataTransfer.files &&
      e.dataTransfer.files.length > 0
    ) {
      this.props.handleDrop(e.dataTransfer.files);
      e.dataTransfer.clearData();
      this.dragCounter = 0;
      this.setState({ filename: e.dataTransfer.files[0].name });
    }
  };
  handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length === 1) {
      this.props.handleDrop(e.target.files);
      this.dragCounter = 0;
      this.setState({ filename: e.target.files[0].name });
    }
  };

  clearFile = () => {
    this.file.value = null;
    this.setState({ filename: "" });
  };

  componentDidMount() {
    let div = this.dropRef.current;
    if (div) {
      div.addEventListener("dragenter", this.handleDragIn);
      div.addEventListener("dragleave", this.handleDragOut);
      div.addEventListener("dragover", this.handleDrag);
      div.addEventListener("drop", this.handleDrop);
    }
  }
  componentWillUnmount() {
    const div = this.dropRef.current;
    if (div) {
      div.removeEventListener("dragenter", this.handleDragIn);
      div.removeEventListener("dragleave", this.handleDragOut);
      div.removeEventListener("dragover", this.handleDrag);
      div.removeEventListener("drop", this.handleDrop);
    }
  }
  render() {
    const upload_hint = (
      <div className="upload-hint">
        <span onClick={() => this.file.click()}>Choose file</span> or just drag
        & drop file here
      </div>
    );
    const csvKeys: string[] =
      this.props.csvData && this.props.csvData.length
        ? Object.keys(this.props.csvData[0])
        : [];
    return (
      <div className="dragndrop-container" ref={this.dropRef}>
        {(this.state.drag || this.props.csvData.length === 0) &&
          !this.props.children && (
            <div className={classnames("inner", { hover: this.state.drag })}>
              <div className="placeholder">
                {upload_hint}
                {this.props.hint && (
                  <span
                    dangerouslySetInnerHTML={{ __html: this.props.hint }}
                  ></span>
                )}
              </div>
            </div>
          )}
        {!this.state.drag && this.props.csvData.length > 0 && (
          <React.Fragment>
            <div className="inner table">
              {upload_hint}
              {this.state.filename.length > 0 && (
                <div className="upload-hint text-left">
                  File {this.state.filename}:
                </div>
              )}
              <div className="row">
                {csvKeys.map((key: string) => (
                  <div className="cell th">
                    {previewHeadersMap[key] ? previewHeadersMap[key] : key}
                  </div>
                ))}
              </div>
              {this.props.csvData.slice(0, 8).map((row: any, rid: number) => (
                <div className="row" key={rid}>
                  {csvKeys.map((key: string, cid: number) => (
                    <div className="cell" key={rid * 4 + cid}>
                      {key === "notes"
                        ? `${row[key].substring(0, 32)}${
                            row[key].length > 32 ? "…" : ""
                          }`
                        : row[key]}
                    </div>
                  ))}
                </div>
              ))}
              {this.props.csvData.length > 8 && (
                <div className="upload-hint">
                  … and {this.props.csvData.length - 8} more lines{" "}
                </div>
              )}
            </div>
          </React.Fragment>
        )}
        <input
          type="file"
          accept={this.props.dataType}
          ref={ref => {
            this.file = ref;
          }}
          onChange={this.handleChangeFile}
        />
        {!!this.props.error && (
          <span className="error-msg">{this.props.error}</span>
        )}
        {this.props.children}
      </div>
    );
  }
}
export default DragAndDrop;
