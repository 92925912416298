import React, { PureComponent } from "react";
import "./AuthView.scss";
import Logo from "~/components/common/WhiteLabeling/Logo";
import LoginForm from "~/components/forms/LoginForm/LoginForm";
import SignUpForm from "~/components/forms/SignUpForm/SignUpForm";
import ResetPasswordForm from "~/components/forms/ResetPasswordForm/ResetPasswordForm";
import { RouteComponentProps, withRouter } from "react-router";
import { connect } from "react-redux";
import { usersActions, ICheckTokenForm } from "~/store/user";
import _ from "lodash";
import Loader from "~/components/common/Loader/Loader";
import { Branding } from "~/branding";

interface StateProps {
  authenticatedUser: any;
  isDomainCustomizationFetched: boolean;
}

interface DispatchProps extends RouteComponentProps {
  checkResetPasswordToken: (form: ICheckTokenForm) => any;
  loadDomainCustomization: () => Promise<any>;
}

const mapStateToProps = (state: any): StateProps => ({
  authenticatedUser: state.users.authenticatedUser,
  isDomainCustomizationFetched: state.users.isDomainCustomizationFetched,
});

const mapDispatchToProps = {
  checkResetPasswordToken: usersActions.checkResetPasswordToken,
  loadDomainCustomization: usersActions.loadDomainCustomization,
};

export type Props = StateProps & DispatchProps;

class AuthView extends PureComponent<Props> {
  componentDidMount() {
    // Load customization if not loaded yet
    if (!this.props.isDomainCustomizationFetched) {
      this.props.loadDomainCustomization();
    }

    const token =
      this.props.match &&
      this.props.match.params &&
      (this.props.match.params as { token: string }).token;
    if (token) {
      // Check reset token
      this.props
        .checkResetPasswordToken({ token })
        .then(() => {
          this.props.history.push("/");
        })
        .catch();
    }
  }

  render() {
    const { authenticatedUser, match } = this.props;
    return authenticatedUser ? (
      <div className="AuthView">
        <div className="panel">
          <Logo />
          {match.path === "/login" && <LoginForm />}
          {match.path === "/signup" && <SignUpForm />}
          {match.path === "/restore" && <SignUpForm />}
          {match.path === "/reset" && <ResetPasswordForm />}
          <div>
            {authenticatedUser &&
              authenticatedUser.company &&
              authenticatedUser.company.whitelabeling &&
              authenticatedUser.company.whitelabeling.show_login_logo_mv && (
                <img
                  className="powered"
                  src={Branding.POWERED_BY_LOGO}
                  alt={`Powered by ${Branding.COMPANY_NAME}`}
                />
              )}
          </div>
        </div>
        <div className="support"></div>
      </div>
    ) : (
      <div className="centered">
        <Loader />
      </div>
    );
  }
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthView)
);
