import classNames from "classnames";
import { sortBy } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IconUnsubscribe from "~/assets/unsubscribe.svg";
import { IExternalContact } from "~/store/contacts";
import { conversationActions } from "~/store/conversations";
import { IList, listsActions } from "~/store/lists";
import { modalActions } from "~/store/modal";
import { fpn } from "~/utils";
import Loader from "../common/Loader/Loader";
import "./ListProfile.scss";

interface DispatchProps {
  closeModal: () => void;
  createBlast: (blastId: string) => void;
  deleteList: (listId: number) => void;
}

interface OwnProps {
  list: number | string;
  onBack: () => void;
  onEdit: () => void;
  setShown: (shown: string) => void;
  shown: boolean;
}

interface StateProps {
  activeList: IList | null;
  authenticatedUser: any;
  isDeleting: boolean;
  items: [];
  isFetchingBlastDetails: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createBlast: conversationActions.createBlast,
  deleteList: listsActions.deleteList,
};

const mapStateToProps = (state: any) => ({
  activeList: state.lists.activeList,
  authenticatedUser: state.users.authenticatedUser,
  isDeleting: state.lists.isDeleting,
  items: state.lists.data,
  isFetchingBlastDetails: state.lists.isFetchingBlastDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const [sortingMethod, setSortingMethod] = useState("Name");
  const [isSortingOpen, setSortingOpen] = useState(false);

  let blacklist: string[] = [];
  useEffect(() => {
    if (props.authenticatedUser) {
      const { activePhone, phones } = props.authenticatedUser;
      if (phones) {
        const active = phones.find((phone: any) => phone.id === activePhone);
        if (active) {
          blacklist = active.blacklisted;
        }
      }
    }
  }, [props.authenticatedUser]);

  function sortMemberList(members: IExternalContact[]) {
    return sortBy(
      members.filter(
        member =>
          sortingMethod !== "Unsubscribed" ||
          blacklist.includes(member.phone_number)
      ),
      ["title"]
    );
  }

  function prepareLabel(label: string) {
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      label
        .trim()
        .split(" ")
        .join("")
    );
    return isPhoneName
      ? fpn(label, props.authenticatedUser.show_censor_contact)
      : label;
  }

  function handleStart() {
    if (props.activeList) {
      props.createBlast(props.activeList.id);
      props.closeModal();
    }
  }

  async function handleDelete(listId: number) {
    if (listId) {
      await props.deleteList(listId);
      props.setShown("contacts");
    }
  }

  function toggleSortingOpen() {
    setSortingOpen(!isSortingOpen);
  }

  function handleSetSorting(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setSortingMethod(e.target.dataset.sort);
    setSortingOpen(false);
  }

  return (
    <div className={classNames("ListProfile", { shown: props.shown })}>
      {props.isFetchingBlastDetails ? (
        <Loader />
      ) : (
        props.activeList && (
          <>
            <button type="button" onClick={props.onBack}>
              <i className="icon-back"></i>
            </button>
            <header>
              <h1>{props.activeList.name}</h1>
              <h2>{props.activeList.notes}</h2>
            </header>
            <div className="ListProfile__Members">
              {props.activeList.id && (
                <>
                  <div className="ListProfile__Header">
                    <h3>
                      {props.activeList.csv_filename
                        ? "MEMBERS IMPORTED FROM"
                        : "MEMBERS"}
                    </h3>
                    {props.activeList.csv_filename === "" && (
                      <div
                        className="ListProfile__SortLabel"
                        onClick={toggleSortingOpen}
                      >
                        <span>Sort by:</span>
                        <b>{sortingMethod}</b>
                        <i className="icon-edit" />
                        {isSortingOpen && (
                          <div
                            className="ListProfile__Select"
                            onClick={handleSetSorting}
                          >
                            <span data-sort="Name">Name</span>
                            <span data-sort="Unsubscribed">Unsubscribed</span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {props.activeList.csv_filename === "" && (
                    <div>
                      {sortMemberList(props.activeList.external).map(
                        (ex, key) => (
                          <div key={key}>
                            <i className="icon-profile"></i>
                            {prepareLabel(ex.title)}
                            {blacklist.includes(ex.phone_number) && (
                              <img
                                src={IconUnsubscribe}
                                alt="Unsubscribed"
                                data-tip
                                data-for="unsubscribe"
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  )}
                  {props.activeList.csv_filename !== "" && (
                    <div className="csv-container">
                      <CSVLink
                        data={props.activeList.external.map((item: any) => [
                          item.phone_number,
                          item.title,
                        ])}
                        filename={props.activeList.csv_filename}
                        target="_blank"
                      >
                        {props.activeList.csv_filename}
                      </CSVLink>
                      <h3>{props.activeList.external.length} MEMBERS</h3>
                      <h4>
                        IMPORTED:{" "}
                        {moment(props.activeList.created).format("MM/DD/YYYY")}
                      </h4>
                    </div>
                  )}
                </>
              )}
              <ReactTooltip
                className="listTooltip"
                id="unsubscribe"
                effect="solid"
              >
                <span>Unsubscribed</span>
              </ReactTooltip>
            </div>
            {props.isDeleting ? (
              <Loader />
            ) : (
              <footer>
                <button
                  className="text-btn"
                  type="button"
                  onClick={handleStart}
                >
                  <i className="icon-chat"></i>
                </button>
                <button
                  className="edit-btn"
                  type="button"
                  onClick={props.onEdit}
                >
                  <i className="icon-edit"></i>
                </button>
                <button
                  className="delete-btn"
                  type="button"
                  onClick={handleDelete.bind(null, props.activeList.id)}
                >
                  <i className="icon-delete"></i>
                </button>
              </footer>
            )}
          </>
        )
      )}
    </div>
  );
});
