import React, { Component, BaseSyntheticEvent } from "react";
import Modal from "../AbstractModal/AbstractModal";
import ProfileGeneral from "../../forms/ProfileGeneral/ProfileGeneral";
import ProfileAccount from "../../forms/ProfileAccount/ProfileAccount";
// import ProfilePhones from '../../forms/ProfilePhones/ProfilePhones';
import "./ProfileEditModal.scss";
import ThemedTab from "~/components/common/ThemedTab/ThemedTab";

export default class ProfileEditModal extends Component {
  state = {
    activeTab: 1,
  };
  changeActiveTab = (e: BaseSyntheticEvent) => {
    const activeTab = +e.target.dataset.tabId;
    if (Number.isInteger(activeTab)) {
      this.setState({ activeTab });
    }
  };
  render() {
    const tabs = [
      { id: 1, title: "General", component: <ProfileGeneral /> },
      { id: 2, title: "Account", component: <ProfileAccount /> },
      // { id: 3, title: 'Phones', component: <ProfilePhones /> },
    ];
    const activeTab = tabs.filter(tab => tab.id === this.state.activeTab)[0];
    return (
      <Modal title="Edit Profile" className="ProfileEditModal">
        <ul className="ProfileEditModal__Tabs" onClick={this.changeActiveTab}>
          {tabs.map(tab => (
            <ThemedTab
              key={tab.id}
              className={this.state.activeTab === tab.id ? "active" : ""}
              data-tab-id={tab.id}
            >
              {tab.title}
            </ThemedTab>
          ))}
        </ul>
        <div>{activeTab && activeTab.component}</div>
      </Modal>
    );
  }
}
