import moment from "moment";
import { Dispatch, AnyAction } from "redux";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "./index";

// ------------------------------------
// Constants
// ------------------------------------
const REPORTS_LIST_REQUEST = "REPORTS_LIST_REQUEST";
const REPORTS_LIST_SUCCESS = "REPORTS_LIST_SUCCESS";
const REPORTS_LIST_FAILURE = "REPORTS_LIST_FAILURE";

const CAMPAIGN_REQUEST = "CAMPAIGN_REQUEST";
const CAMPAIGN_SUCCESS = "CAMPAIGN_SUCCESS";

// ------------------------------------
// Action Creators
// ------------------------------------
const reportListRequest = () => ({ type: REPORTS_LIST_REQUEST });
const reportListSuccess = (res: AxiosResponse) => ({
  type: REPORTS_LIST_SUCCESS,
  payload: res.data,
});
const reportListFailure = (err: AxiosError) => ({
  type: REPORTS_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const campaignRequest = (payload: any) => ({ type: CAMPAIGN_REQUEST, payload });
const campaignSuccess = (res: any) => ({
  type: CAMPAIGN_SUCCESS,
  payload: res.data,
});
// ------------------------------------что
// Actions
// ------------------------------------
const list = (dateFrom: Date, dateTo: Date, phone: string) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const authenticatedUser = getState().users.authenticatedUser;

  if (authenticatedUser) {
    const { id, token } = authenticatedUser;
    if (id && token) {
      try {
        dispatch(reportListRequest());
        const res = await Axios.get(
          `${API_URL}/reports/tr/${moment(dateFrom)
            .utc()
            .format("YYYY-MM-DD")}/${moment(dateTo)
            .utc()
            .format("YYYY-MM-DD")}/${phone}`,
          withAuthorizationHeader(token)
        );
        dispatch(reportListSuccess(res));
      } catch (err) {
        dispatch(reportListFailure(err));
      }
    }
  }
};

const getCSVDataForReportDownload = (
  dateFrom: Date,
  dateTo: Date,
  phone: string,
  reportType: string
) => async (dispatch: Dispatch, getState: () => any) => {
  const authenticatedUser = getState().users.authenticatedUser;
  if (authenticatedUser) {
    const { id, token } = authenticatedUser;
    if (id && token) {
      try {
        const res = await Axios.get(
          `${API_URL}/reports/csv_data/${moment(dateFrom)
            .utc()
            .format("YYYY-MM-DD")}/${moment(dateTo)
            .utc()
            .format("YYYY-MM-DD")}/${phone}/${reportType}`,
          withAuthorizationHeader(token)
        );
        return res;
      } catch (err) {
        return err.response;
      }
    }
  }
};

const fetchCampaignDetail = (phone: string) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const authenticatedUser = getState().users.authenticatedUser;

  if (authenticatedUser) {
    const { id, token } = authenticatedUser;
    if (id && token) {
      try {
        dispatch(campaignRequest(true));
        const res = await Axios.get(
          `${API_URL}/phone/campaign/${phone}`,
          withAuthorizationHeader(token)
        );
        dispatch(campaignSuccess(res));
      } catch (err) {
        dispatch(campaignRequest(false));
      }
    }
  }
};

export const reportsActions = {
  list,
  getCSVDataForReportDownload,
  fetchCampaignDetail,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  dataDiagram: null,
  dataStats: null,
  dataTopInbound: null,
  dataTopOutbound: null,
  isFetchingCampaignDetail: false,
  campaignId: "",
};

const sortByDate = (a: any, b: any): number => {
  const a_date = new Date(a.sent_at_truncated_to_day).getTime();
  const b_date = new Date(b.sent_at_truncated_to_day).getTime();
  return a_date === b_date ? 0 : a_date < b_date ? 1 : -1;
};

export default (state = initialState, { type, payload }: AnyAction) => {
  switch (type) {
    case REPORTS_LIST_REQUEST:
      return { ...state, isFetching: true, form: payload };

    case REPORTS_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        blastReportData: payload.data.blastReportData.sort(sortByDate),
        creditReportData: payload.data.creditReportData,
        segmentReportData: payload.data.segmentReportData,
        dataDiagram: payload.data.diagramData.sort(sortByDate),
        stopReportData: payload.data.stopMessageInboundData.sort(sortByDate),
        dataStats: payload.data.deliveredNotDeliveredData,
        dataTopInbound: [
          ...payload.data.top5ExternalUsersInboundData,
          ...payload.data.top5InternalUsersInboundData,
        ],
        dataTopOutbound: [
          ...payload.data.top5ExternalUsersOutboundData,
          ...payload.data.top5InternalUsersOutboundData,
        ],
      };

    case REPORTS_LIST_FAILURE:
      return { ...state, isFetching: false };

    case CAMPAIGN_REQUEST:
      return { ...state, isFetchingCampaignDetail: payload, campaignId: "" };

    case CAMPAIGN_SUCCESS:
      return {
        ...state,
        isFetchingCampaignDetail: false,
        campaignId: payload.campaignId,
      };
    default:
      return state;
  }
};
