import _ from "lodash";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Phone from "~/assets/phone.svg";
import { contactsActions } from "~/store/contacts";
import { conversationActions, IConversation } from "~/store/conversations";
import { prebuiltRepliesActions } from "~/store/replies_prebuilt";
import { usersActions } from "~/store/user";
import { fpn } from "~/utils";
import "./PhonesDropdown.scss";

interface StateProps {
  conversations: IConversation[];
  user: any;
}
interface DispatchProps {
  setPhone: (phone_id: number) => any;
  getConversationsList: () => any;
  openConversation: (id: string) => void;
  listReplies: () => void;
  getContactsList: () => void;
}
interface OwnProps {}
type Props = StateProps & DispatchProps & OwnProps;
interface State {
  isOpen: boolean;
}

const mapStateToProps = (state: any): StateProps => ({
  conversations: state.conversations.data,
  user: state.users.authenticatedUser,
});

const mapDispatchToProps: DispatchProps = {
  getConversationsList: conversationActions.getConversationsList,
  openConversation: conversationActions.openConversation,
  setPhone: usersActions.setPhone,
  listReplies: prebuiltRepliesActions.listPrebuiltReplies,
  getContactsList: contactsActions.getContactsList,
};

class PhonesDropdown extends PureComponent<Props, State> {
  state = {
    isOpen: false,
  };

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  setPhone = (phone_id: number) => {
    this.props.setPhone(phone_id).then(() => {
      // this.props.listReplies();
      this.props.getConversationsList();
      this.props.getContactsList();
    });
  };

  renderBadge = (count: number) =>
    count ? (
      <div className="PhonesDropdown__PhoneItem__Badge">
        <span>{count}</span>
      </div>
    ) : null;

  showName = (name: string) => {
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      name
        .trim()
        .split(" ")
        .join("")
    );
    return isPhoneName ? fpn(name, this.props.user.show_censor_contact) : name;
  };

  renderPhoneItem = phone => (
    <div
      key={phone.id}
      className={`PhonesDropdown__PhoneItem ${
        !phone.isTitle ? "PhonesDropdown__PhoneItem--list" : ""
      }`}
      onClick={() => {
        if (!phone.isTitle) {
          this.setPhone(phone.id);
        }
      }}
    >
      <div className="PhonesDropdown__PhoneItem__Label">
        <div>
          <img src={Phone} />
        </div>
        <div>
          <strong>{this.showName(phone.title)}</strong>
          <span>
            {fpn(phone.phone_number, this.props.user.show_censor_contact)}
          </span>
        </div>
      </div>

      {phone.isTitle ? phone.icon : this.renderBadge(phone.unreadCount)}
    </div>
  );

  public render() {
    const phones = _.get(this, "props.user.phones", []);
    const currentPhoneId = _.get(this, "props.user.activePhone", 0);
    const currentPhone = _.find(phones, (p: any) => p.id === currentPhoneId);

    return phones.length ? (
      <div
        className={this.state.isOpen ? "PhonesDropdown open" : "PhonesDropdown"}
        onClick={() => {
          if (phones.length > 1) {
            this.toggle();
          }
        }}
      >
        {this.props.toggleButton ||
          this.renderPhoneItem({
            ...currentPhone,
            isTitle: true,
            icon: phones.length > 1 && <i className="icon-back"></i>,
          })}

        {phones.length > 1 && (
          <ul
            className={`PhonesDropdown__List ${this.props.topHeader &&
              "PhonesDropdown__List--header"}`}
          >
            {phones
              .filter(
                phone => this.props.topHeader || phone.id !== currentPhone.id
              )
              .map(this.renderPhoneItem)}
          </ul>
        )}
      </div>
    ) : null;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PhonesDropdown);
