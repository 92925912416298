import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

export interface IPrebuiltReply {
  id?: number;
  title: string;
  keywords: Array<string>;
  text_content: string;
}

// ------------------------------------
// Constants
// ------------------------------------
const PREBUILT_REPLIES_CREATE_REQUEST = "PREBUILT_REPLIES_CREATE_REQUEST";
const PREBUILT_REPLIES_CREATE_SUCCESS = "PREBUILT_REPLIES_CREATE_SUCCESS";
const PREBUILT_REPLIES_CREATE_FAILURE = "PREBUILT_REPLIES_CREATE_FAILURE";

const PREBUILT_REPLIES_DELETE_REQUEST = "PREBUILT_REPLIES_DELETE_REQUEST";
const PREBUILT_REPLIES_DELETE_SUCCESS = "PREBUILT_REPLIES_DELETE_SUCCESS";
const PREBUILT_REPLIES_DELETE_FAILURE = "PREBUILT_REPLIES_DELETE_FAILURE";

const PREBUILT_REPLIES_LIST_REQUEST = "PREBUILT_REPLIES_LIST_REQUEST";
const PREBUILT_REPLIES_LIST_SUCCESS = "PREBUILT_REPLIES_LIST_SUCCESS";
const PREBUILT_REPLIES_LIST_FAILURE = "PREBUILT_REPLIES_LIST_FAILURE";

const PREBUILT_REPLIES_UPDATE_REQUEST = "PREBUILT_REPLIES_UPDATE_REQUEST";
const PREBUILT_REPLIES_UPDATE_SUCCESS = "PREBUILT_REPLIES_UPDATE_SUCCESS";
const PREBUILT_REPLIES_UPDATE_FAILURE = "PREBUILT_REPLIES_UPDATE_FAILURE";

// ------------------------------------
// Action Creators
// ------------------------------------
const createPrebuiltReplyRequest = () => ({
  type: PREBUILT_REPLIES_CREATE_REQUEST,
});
const createPrebuiltReplySuccess = (res: IPrebuiltReply) => ({
  type: PREBUILT_REPLIES_CREATE_SUCCESS,
  payload: res,
});
const createPrebuiltReplyFailure = (err: AxiosError) => ({
  type: PREBUILT_REPLIES_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deletePrebuiltReplyRequest = () => ({
  type: PREBUILT_REPLIES_DELETE_REQUEST,
});
const deletePrebuiltReplySuccess = (res: number) => ({
  type: PREBUILT_REPLIES_DELETE_SUCCESS,
  payload: res,
});
const deletePrebuiltReplyFailure = (err: AxiosError) => ({
  type: PREBUILT_REPLIES_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listPrebuiltRepliesRequest = () => ({
  type: PREBUILT_REPLIES_LIST_REQUEST,
});
const listPrebuiltRepliesSuccess = (res: AxiosResponse) => ({
  type: PREBUILT_REPLIES_LIST_SUCCESS,
  payload: res.data,
});
const listPrebuiltRepliesFailure = (err: AxiosError) => ({
  type: PREBUILT_REPLIES_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const updatePrebuiltReplyRequest = () => ({
  type: PREBUILT_REPLIES_UPDATE_REQUEST,
});
const updatePrebuiltReplySuccess = (res: IPrebuiltReply) => ({
  type: PREBUILT_REPLIES_UPDATE_SUCCESS,
  payload: res,
});
const updatePrebuiltReplyFailure = (err: AxiosError) => ({
  type: PREBUILT_REPLIES_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------
const createPrebuiltReply = (form: IPrebuiltReply) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  form.keywords = form.keywords.map((keyword: any) => keyword.value);
  try {
    dispatch(createPrebuiltReplyRequest());
    const res = await Axios.post(
      `${API_URL}/phone_numbers/${activePhone}/prebuilt_replies`,
      form,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(createPrebuiltReplySuccess({ ...form, id: res.data.data.id }));
    }
  } catch (err) {
    dispatch(createPrebuiltReplyFailure(err));
  }
};

const deletePrebuiltReply = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(deletePrebuiltReplyRequest());
    const res = await Axios.delete(
      `${API_URL}/phone_numbers/${activePhone}/prebuilt_replies/${id}`,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(deletePrebuiltReplySuccess(id));
    }
  } catch (err) {
    dispatch(deletePrebuiltReplyFailure(err));
  }
};

const listPrebuiltReplies = () => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(listPrebuiltRepliesRequest());
    const res = await Axios.get(
      `${API_URL}/phone_numbers/${activePhone}/prebuilt_replies`,
      withAuthorizationHeader(token)
    );
    dispatch(listPrebuiltRepliesSuccess(res));
  } catch (err) {
    dispatch(listPrebuiltRepliesFailure(err));
  }
};

const updatePrebuiltReply = (form: IPrebuiltReply) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token, activePhone } = getState().users.authenticatedUser;

  if (id && token && form) {
    const restForm = Object.assign({}, form);
    const newKeywords = restForm.keywords.map((keyword: any) => keyword.value);
    restForm.keywords = [...newKeywords];
    try {
      dispatch(updatePrebuiltReplyRequest());
      await Axios.put(
        `${API_URL}/phone_numbers/${activePhone}/prebuilt_replies/${restForm.id}`,
        restForm,
        withAuthorizationHeader(token)
      );
      dispatch(updatePrebuiltReplySuccess(restForm));
    } catch (err) {
      dispatch(updatePrebuiltReplyFailure(err));
    }
  }
};

export const prebuiltRepliesActions = {
  createPrebuiltReply,
  deletePrebuiltReply,
  listPrebuiltReplies,
  updatePrebuiltReply,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeReply: -1,
  isFetching: false,
  data: null,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case PREBUILT_REPLIES_CREATE_REQUEST:
    case PREBUILT_REPLIES_DELETE_REQUEST:
    case PREBUILT_REPLIES_LIST_REQUEST:
    case PREBUILT_REPLIES_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case PREBUILT_REPLIES_CREATE_SUCCESS:
      const newReplies = [...state.data];
      !newReplies.find(
        (item: IPrebuiltReply) => item.id === action.payload.id
      ) && newReplies.push(action.payload as never);
      return {
        ...state,
        isFetching: false,
        activeReply: action.payload.id,
        data: newReplies,
      };

    case PREBUILT_REPLIES_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeReply: -1,
        data: state.data.filter(
          (reply: IPrebuiltReply) => reply.id !== action.payload
        ),
      };

    case PREBUILT_REPLIES_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.map((item: IPrebuiltReply) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case PREBUILT_REPLIES_LIST_SUCCESS:
      return { ...state, isFetching: false, data: action.payload.data };

    case PREBUILT_REPLIES_CREATE_FAILURE:
    case PREBUILT_REPLIES_DELETE_FAILURE:
    case PREBUILT_REPLIES_LIST_FAILURE:
    case PREBUILT_REPLIES_UPDATE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
