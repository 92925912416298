import classNames from "classnames";
import moment from "moment";
import React, { Component } from "react";
import Linkify from "react-linkify";
import { connect } from "react-redux";
import styled from "styled-components";
import {
  conversationActions,
  IConversation,
  IMessage,
} from "~/store/conversations";
import { fetchAndOutputAttach, ThemeContext } from "~/utils";
import "./Chat.scss";
import ChatHeader from "./ChatHeader";
import ChatPanel from "./ChatPanel";
import WhitelableTextConverter from "../WhitelableTextConverter/WhitelableTextConverter";
import copy from "copy-to-clipboard";
import { contextMenu, Item, Menu, MenuProvider } from "react-contexify";
import "react-contexify/dist/ReactContexify.min.css";
import { modalActions } from "~/store/modal";
import Loader from "../common/Loader/Loader";
import { Branding } from "~/branding";

interface DispatchProps {
  getConversation: (conversationId: string, type: string, cb: Function) => void;
  resetScroll: () => void;
  sendMessage: (conversationId: string, message: string, type: string) => void;
  clearUnreadMessages: (conversationId: string) => void;
  deleteMessage: (
    conversationId: string,
    messageId: string,
    type: string
  ) => void;
  openModal: (modalId: string, modalProps: object) => void;
  closeModal: () => void;
  loadMessage: (conversationId: string, type: string, cb: Function) => void;
}

interface OwnProps {
  shown: boolean;
  onBack: () => void;
  showClipboard: () => void;
  menuOpen: (isOpen: boolean) => void;
  brandColor: (brandColor: string) => void;
}

interface Refs {
  messageField: Element | null;
  messagesEnd: Element | null;
  messagesRefs: [HTMLLIElement | null];
  messagesRefsCountOld: number;
  lastMessageId: number;
}

interface State {}

interface StateProps {
  activeConversation: IConversation;
  authenticatedUser: any;
  isConversationsFetching: boolean;
  scrollToMessage: number;
  domainWL: any;
  modalType: string;
  hasMoreMessage: boolean;
}

type Props = DispatchProps & StateProps & OwnProps;

const mapsDispatchToProps: DispatchProps = {
  getConversation: conversationActions.getConversation,
  resetScroll: conversationActions.resetScroll,
  sendMessage: conversationActions.sendMessage,
  clearUnreadMessages: conversationActions.clearUnreadMessages,
  deleteMessage: conversationActions.deleteMessage,
  openModal: modalActions.openModal,
  closeModal: modalActions.closeModal,
  loadMessage: conversationActions.loadMessage,
};

const mapStateToProps = (state: any): StateProps => ({
  authenticatedUser: state.users.authenticatedUser,
  activeConversation: state.conversations.activeConversation,
  isConversationsFetching: state.conversations.isFetchingConversation,
  scrollToMessage: state.conversations.scrollToMessage,
  domainWL:
    (state.users.authenticatedUser &&
      state.users.authenticatedUser.company &&
      state.users.authenticatedUser.company.whitelabeling) ||
    {},
  modalType: state.modal.modalType,
  hasMoreMessage: state.conversations.hasMoreMessage,
});

const INITIAL_SETTINGS = {
  color: "#00BBE5",
  font: { value: "Helvetica Neue", label: "Helvetica Neue" },
  fontSize: 16,
};

const allowedImages = ["image/jpeg", "image/pjpeg", "image/png"];
const allowedPdf = ["application/pdf", "application/x-pdf"];
const allowedMIMETypes = [
  ...allowedImages,
  ...allowedPdf,
  "audio/wav",
  "audio/wave",
  "audio/x-wav",
  "video/3gpp",
  "video/mp4",
];

const linkProps = { target: "_blank" };

class Chat extends Component<Props, State, Refs> {
  public messageField: HTMLInputElement | null = null;
  public messagesEnd: Element | null = null;
  private messagesRefs: Array<HTMLLIElement | null> = [];
  private messagesRefsCountOld: number = 0;
  private observer: MutationObserver | null = null;
  private lastMessageId: number = 0;

  componentDidMount() {
    this.updateScroll(null, undefined);
    window.addEventListener("scroll", this.handleScroll, true);
  }

  componentWillUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    if (this.messagesEnd && this.props.hasMoreMessage) {
      if (
        this.messagesEnd.scrollTop == 0 &&
        !this.props.isConversationsFetching
      ) {
        this.props.loadMessage(
          this.props.activeConversation.id,
          this.props.activeConversation.type,
          () => {}
        );
      }
    }
  };

  // shouldComponentUpdate(nextProps: Props) {
  //   if (this.props.modalType !== "" || nextProps.modalType !== "") {
  //     return false;
  //   }
  //   return true;
  // }

  componentDidUpdate(prevProps: Props) {
    // Active chat has been changed
    if (
      this.props.activeConversation &&
      this.props.activeConversation.id &&
      this.props.scrollToMessage === -1 &&
      (!prevProps.activeConversation ||
        (prevProps.activeConversation &&
          this.props.activeConversation.id !== prevProps.activeConversation.id))
    ) {
      this.props.getConversation(
        this.props.activeConversation.id,
        this.props.activeConversation.type,
        () => {
          this.props.clearUnreadMessages(this.props.activeConversation.id);
          if (this.props.scrollToMessage === -1) {
            this.updateScroll(null, true);
          }
        }
      );
    }

    // Scroll the chat
    if (
      (this.props.scrollToMessage !== -1 && prevProps.scrollToMessage === -1) ||
      this.messagesRefsCountOld !== this.messagesRefs.length
    )
      this.updateScroll();

    // Save refs length to be able to track when a new ref was added
    this.messagesRefsCountOld = this.messagesRefs.length;

    if (
      this.observer &&
      prevProps.activeConversation !== this.props.activeConversation &&
      this.props.activeConversation === null
    ) {
      this.observer.disconnect();
      this.observer = null;
    }

    // if (prevProps.chat && this.props.chat && this.props.activeConversation.messages.length !== prevProps.activeConversation.messages.length) {
    //   this.renderRepliesCount(this.props.chat);
    // }

    if (this.messagesEnd && this.observer === null) {
      // Create an observer and pass it a callback
      this.observer = new MutationObserver(this.updateScroll);
      // Observe for new children that will change the height
      this.observer.observe(this.messagesEnd, { childList: true });
    }
  }

  updateScroll = (mutations?: object, forceDown = false) => {
    if (this.messagesEnd) {
      if (mutations === null && forceDown) {
        console.log("Force down!");
        this.messagesEnd.scrollTop = this.messagesEnd.scrollHeight;
      } else {
        if (
          this.messagesRefs.length &&
          this.props.scrollToMessage !== -1 &&
          this.messagesRefs[this.props.scrollToMessage]
        ) {
          // Scroll to particular message
          // Old method

          // this.messagesEnd.scrollTo(
          //   0,
          //   this.messagesRefs[this.props.scrollToMessage].offsetTop -
          //   this.messagesRefs[this.props.scrollToMessage].clientHeight -
          //   60
          // );

          // New method
          this.messagesEnd.scrollTo(
            0,
            this.messagesRefs[this.props.scrollToMessage].offsetTop - 140
          );
        } else {
          if (
            this.props.activeConversation &&
            this.props.activeConversation.messages &&
            this.props.activeConversation.messages.length
          ) {
            const lastMessage = this.props.activeConversation.messages[
              this.props.activeConversation.messages.length - 1
            ];
            const myId = this.props.authenticatedUser.id;

            const lastMessageMine =
              lastMessage &&
              (lastMessage.message_type === "autoreply" ||
                (lastMessage.sender.internal &&
                  lastMessage.sender_id === myId)) &&
              this.lastMessageId !== lastMessage.id;
            // If the last message in active conversation that was changed was from me
            if (
              lastMessageMine ||
              (!lastMessageMine &&
                !(
                  this.messagesEnd.scrollTop <
                  this.messagesEnd.scrollHeight -
                    this.messagesEnd.clientHeight * 1.5
                ))
            ) {
              // console.log('Scroll down! ' + (lastMessageMine ? 'M' : 'Not m') + 'ine!');
              this.messagesEnd.scrollTo(0, this.messagesEnd.scrollHeight);
            }
            this.lastMessageId = lastMessage && lastMessage.id;
            // ) {
            // Then scroll chat to the bottom when last message was written by me or
            // when viewport isn't scrolled more than 1.5 screen up
            // (for example if a user is looking for some previous message,
            // we won't to scroll down automatically to not disturb him).

            // }
          }
        }
      }
    }
  };

  setMessageRef = (id, ref) => (this.messagesRefs[+id] = ref);

  renderMessageType = (messageType: string, defaultTitle: string) => {
    let messageTitle;
    switch (messageType) {
      case "autoreply":
        messageTitle = "Auto-Reply";
        break;
      case "subscribed_message":
        messageTitle = "Opted In Message";
        break;
      case "unsubscribed_message":
        messageTitle = "Opted Out Message";
        break;
      default:
        messageTitle = defaultTitle;
        break;
    }
    return messageTitle;
  };

  removeMessage = (message_id: any) => {
    this.props.deleteMessage(
      this.props.activeConversation.id,
      message_id,
      this.props.activeConversation.type
    );
  };

  shrowConfirmRemoveMessage = ({ props }: any) => {
    this.props.openModal("CONFIRM_MODAL", {
      title: "Remove Message",
      body: `Are you sure you want to remove this message?`,
      onYes: () => {
        this.removeMessage(props.id);
      },
      onNo: this.props.closeModal,
    });
  };

  handleOpenMenuEvent = (
    e: any,
    menu_id: string,
    id: any,
    text_content: any
  ) => {
    e.preventDefault();
    contextMenu.show({
      id: menu_id,
      event: e,
      props: {
        id: id,
        text_content: text_content,
      },
    });
  };

  handleCopy = ({ props }: any) => {
    copy(`${props.text_content}`);
    this.props.showClipboard();
  };

  handleMenuState = (isMenuOpen: boolean) => {
    this.props.menuOpen(isMenuOpen);
  };

  outboundMenu = () => {
    return (
      <Menu
        id="outbound_menu"
        animation={"zoom"}
        className="custom-menu"
        onShown={() => this.handleMenuState(true)}
        onHidden={() => this.handleMenuState(false)}
      >
        <Item className="menu-theme" onClick={this.handleCopy}>
          <span style={{ paddingLeft: 1 }}>Copy</span>
        </Item>
        <Item className="menu-theme" onClick={this.shrowConfirmRemoveMessage}>
          <span style={{ paddingLeft: 1 }}>Remove</span>
        </Item>
      </Menu>
    );
  };

  getExternalId = (): any => {
    if (
      this.props.activeConversation &&
      this.props.activeConversation.external
    ) {
      if (this.props.activeConversation.external.length === 1) {
        return this.props.activeConversation.external[0].id;
      }
    }
    return undefined;
  };

  renderMessages = (chat: IConversation, color: string) => {
    let messages = null,
      ThemedMessage: string;
    const myId = this.props.authenticatedUser.id;
    const bubbleStyles =
      this.props.authenticatedUser.settings || INITIAL_SETTINGS;
    const settingsColor = bubbleStyles && bubbleStyles.bubble_color;
    const themeColor =
      settingsColor && settingsColor !== INITIAL_SETTINGS.color
        ? settingsColor
        : color || "#00bbe5";

    let font_family;
    try {
      font_family = JSON.parse(bubbleStyles.font_family).value;
    } catch (err) {
      console.log(err);
      font_family = INITIAL_SETTINGS.font.value;
    }

    ThemedMessage = styled.li`
      &:not(.me) > div {
        background: ${themeColor} !important;
      }
      & > div {
        border-color: ${themeColor} !important;
        font-size: ${bubbleStyles.font_size}px !important;
        & > * {
          font-family: ${font_family};
        }
      }
    `;

    if (chat && chat.messages) {
      const external_id = this.getExternalId();
      messages = chat.messages.map((msg: IMessage, key: number) => {
        const {
          id,
          media_file_mime,
          media_file_name,
          media_link,
          message_type,
          sender: { display_as, first_name, internal, last_name, is_Not_Same },
          sent_at,
          text_content,
          scheduler_id,
        } = msg;
        const defaultMessageTitle =
          internal && display_as && display_as.length
            ? display_as
            : `${first_name} ${last_name}`;
        const autoReplyTypes = [
          "autoreply",
          "subscribed_message",
          "unsubscribed_message",
        ];

        const fileExt = media_link && media_link.slice(-4);
        return (
          <ThemedMessage
            className={classNames({
              me:
                (internal && msg.sender_id === myId) ||
                is_Not_Same ||
                (external_id && msg.sender_id !== external_id),
              autoreply: autoReplyTypes.includes(message_type || ""),
            })}
            key={key}
            ref={this.setMessageRef.bind(this, id)}
          >
            <button
              onClick={e =>
                this.handleOpenMenuEvent(e, "outbound_menu", id, text_content)
              }
            >
              <i className="icon-vertical-dotted"></i>
            </button>
            <small>
              {scheduler_id && (
                <i className="time-icon-clock schedule-icon"></i>
              )}
              {moment(sent_at).format("MM/DD/YYYY hh:mm:ss A")}
            </small>
            <MenuProvider
              id="outbound_menu"
              data={{ id: id, text_content: text_content }}
            >
              <div className="bubble">
                <b>
                  {this.renderMessageType(
                    message_type || "",
                    defaultMessageTitle
                  )}
                  :
                </b>
                <span>
                  <Linkify properties={linkProps}> {text_content}</Linkify>
                </span>
                {media_link && media_file_mime && fileExt && (
                  <div className="media">
                    <a
                      href={media_link}
                      target="_blank"
                      download={media_file_name}
                      onClick={e => {
                        fetchAndOutputAttach(e, media_file_name, media_link);
                      }}
                    >
                      {allowedImages.includes(media_file_mime) ? (
                        <img src={media_link} />
                      ) : (
                        <div className="flex">
                          <div
                            className={classNames("filePreview", {
                              generic: !allowedMIMETypes.includes(
                                media_file_mime
                              ),
                            })}
                          >
                            {allowedMIMETypes.includes(media_file_mime) ? (
                              fileExt[0] === "." ? (
                                fileExt.slice(1)
                              ) : (
                                fileExt
                              )
                            ) : (
                              <i className="icon-attach"></i>
                            )}
                          </div>
                          <div className="imageDetails">
                            <div>{media_file_name}</div>
                          </div>
                        </div>
                      )}
                    </a>
                  </div>
                )}
              </div>
            </MenuProvider>
          </ThemedMessage>
        );
      });
    }
    return messages;
  };

  render() {
    if (!this.props.activeConversation) {
      return (
        <div className="text-message ConversationList">
          <p>
            <WhitelableTextConverter
              text={`Welcome to ${Branding.COMPANY_NAME} ${Branding.APP_NAME}.`}
            />
          </p>
          <p>
            Communicate via messaging with your existing business numbers.
            Automate your customer engagement and improve responsiveness with
            our Auto Response tools.
          </p>
          <p>Refer to the Quick Start Guide to begin.</p>
        </div>
      );
    }

    let brandColor, lightColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightColor =
        theme === "default" || theme === "#004B87" ? "#004B87" : brandColor;
      this.props.brandColor(brandColor);
      document.documentElement.style.setProperty(
        "--menu-hover-color",
        brandColor
      );
    }

    return (
      <section className={classNames("Chat", { shown: this.props.shown })}>
        <ChatHeader onBack={this.props.onBack} />
        <ul className="messages" ref={el => (this.messagesEnd = el)}>
          {this.props.isConversationsFetching && <Loader />}
          {this.renderMessages(this.props.activeConversation, lightColor)}
        </ul>
        <ChatPanel />
        <this.outboundMenu />
      </section>
    );
  }
}

Chat.contextType = ThemeContext;

export default connect<StateProps, DispatchProps, {}>(
  mapStateToProps,
  mapsDispatchToProps
)(Chat);
