import { AxiosPromise } from "axios";
import moment from "moment";
import React, {
  BaseSyntheticEvent,
  ChangeEvent,
  PureComponent,
  KeyboardEvent,
} from "react";
import { connect } from "react-redux";
import Select, { Creatable } from "react-select";
import ThemedDatetime from "~/components/common/ThemedDatetime/ThemedDatetime";
import {
  IExternalContact,
  IInternalContact,
  contactsActions,
} from "~/store/contacts";
import { searchActions } from "~/store/search";
import { modalActions } from "~/store/modal";
import "./SearchForm.scss";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

namespace SearchForm {
  export interface IForm {
    conversations: never[];
    date_from: Date;
    date_to: Date;
    period: number;
    query: string;
  }

  interface StateProps {
    contacts: [];
    conversations: [];
    form: IForm;
  }
  interface DispatchProps {
    closeModal: () => void;
    getContactsList: () => void;
    search: (form: IForm) => AxiosPromise;
  }
  interface OwnProps {
    onSearch: () => void;
  }
  export type Props = StateProps & DispatchProps & OwnProps;

  export type State = {};
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  getContactsList: contactsActions.getContactsList,
  search: searchActions.search,
};

const mapStateToProps = (state: any) => ({
  contacts: state.contacts.data,
  conversations: state.conversations.data,
  form: state.search.form,
});

class SearchForm extends PureComponent<SearchForm.Props> {
  state = {
    form: {
      conversations: [],
      date_from: new Date(),
      date_to: new Date(),
      period: 3,
      query: "",
    },
  };

  componentDidMount() {
    this.props.getContactsList();
    this.setState(
      { form: this.props.form },
      this.updateDate.bind(this, this.state.form.period)
    );
  }

  handleSearch = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    this.props.search(this.state.form).then(this.props.onSearch);
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: SearchForm.IForm = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handlePressKey = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === "Enter") {
      const { id, value } = e.target as HTMLInputElement;
      const form: SearchForm.IForm = { ...this.state.form, [id]: value };
      this.setState({ form }, () => {
        this.props.search(this.state.form).then(this.props.onSearch);
      });
    }
  };

  handleDate = (option: any) => {
    this.updateDate(+option.value);
  };

  updateDate = (value: number) => {
    let date_from,
      date_to = new Date();
    switch (value) {
      case 1:
        date_from = moment(date_to)
          .subtract(1, "year")
          .toDate();
        break;
      case 2:
        date_from = moment(date_to)
          .subtract(3, "months")
          .toDate();
        break;
      case 3:
        date_from = moment(date_to)
          .subtract(1, "month")
          .toDate();
        break;
      default:
        date_from = date_to;
        break;
    }
    const form: SearchForm.IForm = {
      ...this.state.form,
      date_from,
      date_to,
      period: +value,
    };
    this.setState({ form });
  };

  handleCustomChange = (value: any, id: string) => {
    const form: SearchForm.IForm = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  render() {
    const contacts = this.props.contacts
      .filter(
        (contact: IExternalContact | IInternalContact) =>
          (contact.hasOwnProperty("display_as") && contact.is_phone !== true) ||
          (!contact.hasOwnProperty("display_as") &&
            contact.is_deleted === false)
      )
      .map((contact: IExternalContact | IInternalContact) => ({
        value: `${contact.id}_${
          contact.hasOwnProperty("display_as") ? "internal" : "external"
        }`,
        label:
          contact.display_as || `${contact.first_name} ${contact.last_name}`,
      }));

    const options = [
      { value: 1, label: "Last Year" },
      { value: 2, label: "Last Quarter" },
      { value: 3, label: "Last Month" },
      { value: 4, label: "Custom" },
    ];
    return (
      <React.Fragment>
        <form className="SearchForm">
          <div>
            <label className="mandatory" htmlFor="query">
              Search Query
            </label>
            <ThemedInput
              className="input"
              id="query"
              type="text"
              onChange={this.handleChange}
              onKeyPress={this.handlePressKey}
              value={this.state.form.query}
            />
          </div>

          <div className="columns">
            <div className="column">
              <label className="mandatory" htmlFor="period">
                Period
              </label>
              <Select
                className="select"
                defaultValue={options[this.state.form.period - 1]}
                id="period"
                onChange={this.handleDate}
                options={options}
                value={options[this.state.form.period - 1]}
              />
            </div>
            {+this.state.form.period === 4 && (
              <React.Fragment>
                <div className="column">
                  <label className="mandatory" htmlFor="date_from">
                    From
                  </label>
                  <ThemedDatetime
                    className="icon-date"
                    closeOnSelect
                    inputProps={{
                      id: "date_from",
                      readOnly: true,
                      placeholder: "Select start date...",
                    }}
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={(value: any) =>
                      this.handleCustomChange(value, "date_from")
                    }
                    value={moment(this.state.form.date_from).format(
                      "MM/DD/YYYY"
                    )}
                  />
                </div>
                <div className="column">
                  <label className="mandatory" htmlFor="date_to">
                    To
                  </label>
                  <ThemedDatetime
                    className="icon-date"
                    offsetRight={4}
                    closeOnSelect
                    inputProps={{
                      id: "date_to",
                      readOnly: true,
                      placeholder: "Select end date...",
                    }}
                    dateFormat="MM/DD/YYYY"
                    timeFormat={false}
                    onChange={(value: any) =>
                      this.handleCustomChange(value, "date_to")
                    }
                    value={moment(this.state.form.date_to).format("MM/DD/YYYY")}
                  />
                </div>
              </React.Fragment>
            )}
          </div>
          <div>
            <label htmlFor="conversations">Conversations with</label>
            <Creatable
              isMulti
              className="select"
              id="conversations"
              name="conversations"
              placeholder="Choose conversations or type phone number to search the messages in or leave it empty to search input all conversations."
              value={this.state.form.conversations}
              onChange={value =>
                this.handleCustomChange(value, "conversations")
              }
              options={contacts}
            />
          </div>
        </form>
        <footer>
          <ThemedButton
            disabled={!this.state.form.query}
            onClick={this.handleSearch}
          >
            Search
          </ThemedButton>
          <button
            className="button"
            type="button"
            onClick={this.props.closeModal}
          >
            Cancel
          </button>
        </footer>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchForm as any);
