import classNames from "classnames";
import moment from "moment";
import React, { Component, ChangeEvent } from "react";
import { connect } from "react-redux";
import Modal from "../AbstractModal/AbstractModal";
import "./ReportsModal.scss";
import ThemedDatetime from "~/components/common/ThemedDatetime/ThemedDatetime";
import { IUserPhone } from "~/store/user";
import { reportsActions } from "~/store/reports";
import { AxiosPromise } from "axios";
import Loader from "~/components/common/Loader/Loader";
import { IExternalContact, IInternalContact } from "~/store/contacts";
import { fpn } from "~/utils";
import { CSVLink } from "react-csv";
import ReactTooltip from "react-tooltip";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import IconHelp from "~/assets/help.svg";
import IconRefresh from "~/assets/refresh.png";

interface StateProps {
  isFetching: boolean;
  authenticatedUser: any;
  dataDiagram: any;
  dataStats: any;
  dataTop: {
    inbound: any;
    outbound: any;
  };
  query: string;
  contacts: [];
  segmentReport: any;
  blastReportData: any;
  stopReportData: any;
  isFetchingCampaignDetail: boolean;
  campaignId: string;
  creditReport: any;
}
interface DispatchProps {
  listReports: (dateFrom: Date, dateTo: Date, phone: string) => AxiosPromise;
  getCSVDataForReportDownload: (
    dateFrom: Date,
    dateTo: Date,
    phone: string,
    reportType: string
  ) => AxiosPromise;
  fetchCampaignDetail: (phone: string) => AxiosPromise;
}
interface OwnProps {}
type Props = StateProps & DispatchProps & OwnProps;
type State = {
  activeReport: number;
  formScreen: boolean;
  form: {
    date_from: moment.Moment;
    date_to: moment.Moment;
    number: number;
    flow: string;
    download_type: string;
  };
  csvData: any;
  file_name: string;
};

const mapDispatchToProps = {
  listReports: reportsActions.list,
  getCSVDataForReportDownload: reportsActions.getCSVDataForReportDownload,
  fetchCampaignDetail: reportsActions.fetchCampaignDetail,
};

const mapStateToProps = (state: any) => ({
  isFetching: state.reports.isFetching,
  authenticatedUser: state.users.authenticatedUser,
  dataDiagram: state.reports.dataDiagram,
  dataStats: state.reports.dataStats,
  dataTop: {
    inbound: state.reports.dataTopInbound,
    outbound: state.reports.dataTopOutbound,
  },
  query: state.search.form.query,
  contacts: state.contacts.data,
  segmentReport: state.reports.segmentReportData,
  blastReportData: state.reports.blastReportData,
  stopReportData: state.reports.stopReportData,
  campaignId: state.reports.campaignId,
  isFetchingCampaignDetail: state.reports.isFetchingCampaignDetail,
  creditReport: state.reports.creditReportData,
});

class ReportsModal extends Component<Props, State> {
  csvLinkRef = React.createRef();

  state = {
    activeReport: -1,
    formScreen: false,
    form: {
      date_from: moment().subtract(1, "months"),
      date_to: moment(),
      number:
        this.props.authenticatedUser &&
        this.props.authenticatedUser.phones.find(
          (phone: IUserPhone) =>
            phone.id === this.props.authenticatedUser.activePhone
        ).id,
      flow: "all",
      report_flow: "delivery",
      download_type: "MESSAGE_REPORT",
    },
    csvData: [],
    file_name: "",
  };

  componentDidMount() {
    if (this.state.form.number) {
      this.props.listReports(
        this.state.form.date_from.toDate(),
        this.state.form.date_to.toDate(),
        this.state.form.number
      );
      this.props.fetchCampaignDetail(this.state.form.number);
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.form.date_from !== this.state.form.date_from ||
      prevState.form.date_to !== this.state.form.date_to ||
      prevState.form.number !== this.state.form.number
    ) {
      this.props.listReports(
        this.state.form.date_from.toDate(),
        this.state.form.date_to.toDate(),
        this.state.form.number
      );
    }
  }

  onBack = () => this.setState({ activeReport: -1, formScreen: true });

  handleCustomChange = (value: any, id: string) => {
    this.setState({ form: { ...this.state.form, [id]: value } });
  };

  handleNumberChange = (e: ChangeEvent) => {
    const { id, value } = e.target as HTMLSelectElement;
    this.setState({ form: { ...this.state.form, [id]: value } });
  };

  isInternalActuallyExternal = (user: any) => {
    return !!this.props.contacts.find(
      u => !u.email && u.phone_number === user.phone_number
    );
  };

  composeUserName = (user: any) => {
    const externals: IExternalContact[] = this.props.contacts.filter(
      (u: IExternalContact | IInternalContact) =>
        !u.email && u.phone_number === user.phone_number
    );
    let userName = "";

    if (externals.length === 1) {
      userName =
        externals[0].first_name && externals[0].last_name
          ? `${externals[0].first_name} ${externals[0].last_name}`
          : externals[0].phone_number;
    } else {
      userName =
        user.phone_title ||
        user.title ||
        `${user.first_name} ${user.last_name}`;
    }

    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      userName
        .trim()
        .split(" ")
        .join("")
    );
    if (isPhoneName) {
      userName = fpn(
        userName,
        this.props.authenticatedUser.show_censor_contact
      );
    }

    return userName;
  };

  sortMostTexted = (
    { messages_count: a }: any,
    { messages_count: b }: any
  ): number => (+a === +b ? 0 : +a < +b ? 1 : -1);

  accumulate = (inbound: any[], outbound: any[], field: string) => {
    return [
      ...inbound.filter(i => i.hasOwnProperty(field)),
      ...outbound.filter(i => i.hasOwnProperty(field)),
    ].reduce((acc, cur) => {
      const found = acc.findIndex((i: any) => i[field] === cur[field]);
      if (found !== -1) {
        acc[found] = {
          ...acc[found],
          ...cur,
          messages_count:
            "" + (+acc[found].messages_count + +cur.messages_count),
        };
        return acc;
      } else return [...acc, cur];
    }, []);
  };

  renderMostTexted = () => {
    const most_texted =
      this.state.form.flow === "all" &&
      this.props.dataTop["inbound"] &&
      this.props.dataTop["outbound"]
        ? [
            ...this.accumulate(
              this.props.dataTop["inbound"],
              this.props.dataTop["outbound"],
              "phone_number"
            ),
          ]
        : this.state.form.flow === "inbound" && this.props.dataTop["inbound"]
        ? this.props.dataTop[this.state.form.flow as "inbound"]
        : this.state.form.flow === "outbound" && this.props.dataTop["outbound"]
        ? this.props.dataTop[this.state.form.flow as "outbound"]
        : [];

    return most_texted.length ? (
      <ol>
        {most_texted
          .sort(this.sortMostTexted)
          .slice(0, 5)
          .map((user: any) => (
            <li>
              <i
                className={
                  user.hasOwnProperty("phone_title") &&
                  !this.isInternalActuallyExternal(user)
                    ? "icon-circle"
                    : "icon-profile"
                }
              ></i>
              <span title={this.composeUserName(user)}>
                {this.composeUserName(user)}
              </span>
              <b>{user.messages_count}</b>
            </li>
          ))}
      </ol>
    ) : (
      <span className="no-data">
        No data for selected period or phone number
      </span>
    );
  };

  /*
   * 1. Start date should not be after today
   * 2. Start date should not be after end date
   */
  validFromDate = (current: moment.Moment) => {
    const end = moment(this.state.form.date_to);
    return current.isBefore(end);
  };

  // End date should be after start date
  validToDate = (current: moment.Moment) => {
    const today = moment();
    const start = moment(this.state.form.date_from);
    return current.isBefore(today) && current.isAfter(start);
  };

  renderReport = () => {
    return this.state.form.report_flow === "delivery"
      ? this.renderBlastReport()
      : this.renderStopReport();
  };

  downloadReport = async (reportType: string) => {
    try {
      const res = await this.props.getCSVDataForReportDownload(
        this.state.form.date_from.toDate(),
        this.state.form.date_to.toDate(),
        this.state.form.number,
        reportType
      );
      const { data: reportDatas, status } = res;
      if (status === 200) {
        switch (reportType) {
          case "BLAST_REPORT": {
            const csvData = [
              [
                "Time Stamp",
                "Blast Name",
                "Sender Name",
                "From Phone Number",
                "To Phone Number",
                "Message",
                "Status",
              ],
            ];
            reportDatas.data.forEach((reportData: any) => {
              const {
                sent_at_truncated_to_day,
                blast_name,
                sender_name,
                text_content,
                from_number,
                blast_contact_reject,
                blast_contact_success,
              } = reportData;
              if (blast_contact_reject) {
                blast_contact_reject.split(",").forEach((reject: any) => {
                  csvData.push([
                    moment(sent_at_truncated_to_day).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    ),
                    blast_name,
                    sender_name,
                    from_number,
                    reject,
                    text_content,
                    "Rejected",
                  ]);
                });
              }
              if (blast_contact_success && blast_contact_success.length > 0) {
                blast_contact_success.split(",").forEach((success: any) => {
                  csvData.push([
                    moment(sent_at_truncated_to_day).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    ),
                    blast_name,
                    sender_name,
                    from_number,
                    success,
                    text_content,
                    "Delivered",
                  ]);
                });
              }
            });
            this.setState(
              {
                csvData,
                file_name: "Blast_Report",
              },
              () => {
                if (this.csvLinkRef) {
                  this.csvLinkRef.current.link.click();
                }
              }
            );
            break;
          }
          case "STOP_REPORT": {
            const csvData = [
              [
                "Time Stamp",
                "Sender Name",
                "From Phone Number",
                "To Phone Number",
                "Message",
              ],
            ];
            reportDatas.data.forEach((reportData: any) => {
              const {
                from,
                to,
                sent_at_truncated_to_day,
                name,
                text_content,
              } = reportData;
              csvData.push([
                moment(sent_at_truncated_to_day).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                name,
                from,
                to,
                text_content,
              ]);
            });
            this.setState(
              {
                csvData,
                file_name: "Stop_Report",
              },
              () => {
                if (this.csvLinkRef) {
                  this.csvLinkRef.current.link.click();
                }
              }
            );
            break;
          }
          case "MESSAGE_REPORT": {
            const csvData = [
              [
                "Time Stamp",
                "Sender Name",
                "From Phone Number",
                "To Phone Number",
                "Message",
                "Type",
                "Status",
              ],
            ];
            reportDatas.data.forEach((reportData: any) => {
              const {
                from,
                to,
                sent_at_truncated_to_day,
                name,
                text_content,
                type,
                status,
              } = reportData;
              csvData.push([
                moment(sent_at_truncated_to_day).format(
                  "MM/DD/YYYY hh:mm:ss A"
                ),
                name,
                from,
                to,
                text_content,
                type,
                status,
              ]);
            });
            this.setState(
              {
                csvData,
                file_name: "Message_Report",
              },
              () => {
                if (this.csvLinkRef) {
                  this.csvLinkRef.current.link.click();
                }
              }
            );
            break;
          }
        }
      } else {
        console.log("Error : ", reportDatas.error_message);
      }
    } catch (err) {
      console.log("err.response.data : ", err);
    }
  };

  tooltip = (id: any, count: any) => {
    return (
      <ReactTooltip
        className="tooltipContainer wd15"
        id={id}
        effect="solid"
        place="top"
        offset={{ left: 0 }}
      >
        <span className="tooltipContent">{count}</span>
      </ReactTooltip>
    );
  };

  renderBlastReport = () => {
    return this.props.blastReportData.map((day: any, i: number) => (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "75px 1fr",
          gridGap: 15,
        }}
      >
        <div className="blast-text">
          <span>{day.name}</span>
          {moment(day.sent_at_truncated_to_day)
            .utc()
            .format("MM/DD")}
        </div>
        <div
          className="ReportsModal__Progress"
          key={i}
          style={{
            borderLeft: "1px solid #dfdfdf",
            width: `${(day.total_count * 100) /
              Math.max(
                ...this.props.blastReportData.map((day: any) => day.total_count)
              )}%`,
          }}
        >
          <div
            className="ReportsModal__ProgressInbound"
            style={{
              width: `${(day.delivered_count * 100) / day.total_count}%`,
              cursor: "pointer",
            }}
            data-tip
            data-for={`delivery_${i}`}
          >
            {this.tooltip(`delivery_${i}`, day.delivered_count)}
          </div>
          <div
            className="ReportsModal__ProgressOutbound"
            style={{
              width: `${(day.rejected_count * 100) / day.total_count}%`,
              cursor: "pointer",
            }}
            data-tip
            data-for={`rejected_${i}`}
          >
            {this.tooltip(`rejected_${i}`, day.rejected_count)}
            <span>{day.total_count}</span>
          </div>
        </div>
      </div>
    ));
  };

  renderStopReport = () => {
    return this.props.stopReportData.map((day: any, i: number) => (
      <div
        className="ReportsModal__Progress"
        key={i}
        style={{
          width: `${(day.total_count * 100) /
            Math.max(
              ...this.props.stopReportData.map((day: any) => day.total_count)
            )}%`,
        }}
      >
        <div
          className="ReportsModal__ProgressInbound"
          style={{
            width: `${(day.total_count * 100) / day.total_count}%`,
            cursor: "pointer",
          }}
          data-tip
          data-for={`stop_inbound_${i}`}
        >
          {this.tooltip(`stop_inbound_${i}`, day.total_count)}
          <span>
            {moment(day.sent_at_truncated_to_day)
              .utc()
              .format("MM/DD")}
          </span>
        </div>
        <div
          className="ReportsModal__ProgressOutbound"
          style={{
            width: `0%`,
          }}
        >
          <span>{day.total_count}</span>
        </div>
      </div>
    ));
  };

  reportHeader = () => {
    const isBlastReport = this.state.form.report_flow === "delivery";
    return (
      <span className="header-item">
        {isBlastReport ? "Blast Campaigns" : "Stop Report"}
      </span>
    );
  };

  render() {
    return (
      <Modal
        className="ReportsModal"
        title={
          this.state.formScreen ? (
            "Reports"
          ) : (
            <div>
              <CSVLink
                data={this.state.csvData}
                filename={`${this.state.file_name}.csv`}
                className="hidden"
                ref={this.csvLinkRef}
              ></CSVLink>
              <span>Messaging Activity Report</span>
              <div className="ReportsModal__Subheader">
                <div>
                  <span>Period:</span>
                  <ThemedDatetime
                    className="icon-date"
                    closeOnSelect
                    inputProps={{
                      id: "date_from",
                      readOnly: true,
                      placeholder: "Select start date...",
                    }}
                    isValidDate={this.validFromDate}
                    dateFormat="MM/DD/YYYY"
                    onChange={(value: any) =>
                      this.handleCustomChange(value, "date_from")
                    }
                    value={moment(this.state.form.date_from).format(
                      "MM/DD/YYYY"
                    )}
                  >
                    <div className="right-arrow" />
                  </ThemedDatetime>
                  <span className="dash">—</span>
                  <ThemedDatetime
                    className="icon-date"
                    closeOnSelect
                    inputProps={{
                      id: "date_to",
                      readOnly: true,
                      placeholder: "Select end date...",
                    }}
                    isValidDate={this.validToDate}
                    dateFormat="MM/DD/YYYY"
                    onChange={(value: any) =>
                      this.handleCustomChange(value, "date_to")
                    }
                    value={moment(this.state.form.date_to).format("MM/DD/YYYY")}
                  >
                    <div className="right-arrow" />
                  </ThemedDatetime>
                </div>
                <div className="number__wrapper">
                  <span className="number">Number:</span>
                  <select id="number" onChange={this.handleNumberChange}>
                    {this.props.authenticatedUser.phones.map(
                      (phone: IUserPhone, i: number) => (
                        <option
                          key={i}
                          selected={
                            this.props.authenticatedUser.activePhone ===
                            phone.id
                          }
                          value={phone.id}
                        >
                          {fpn(
                            phone.phone_number,
                            this.props.authenticatedUser.show_censor_contact
                          )}
                        </option>
                      )
                    )}
                  </select>
                  <div className="right-arrow right-0" />
                </div>
                <div className="refresh">
                  <ThemedButton
                    onClick={() => {
                      if (this.state.form.number) {
                        this.props.listReports(
                          this.state.form.date_from.toDate(),
                          this.state.form.date_to.toDate(),
                          this.state.form.number
                        );
                      }
                    }}
                  >
                    Refresh <img src={IconRefresh}></img>
                  </ThemedButton>
                </div>
              </div>
            </div>
          )
        }
        showBack={this.state.formScreen}
        onBack={this.onBack}
      >
        <section>
          {this.state.activeReport === 1 ? (
            <div
              className="ReportsModal__Report"
              onClick={() =>
                this.setState({ activeReport: 1, formScreen: false })
              }
            >
              <i className="icon-reports"></i>
              <span>Default</span>
            </div>
          ) : (
            <>
              <div className="ReportsModal__ReportDetails">
                <div className="ReportsModal__Panel ReportsModal__MessagesBreakdown">
                  <header>
                    <div className="legend">
                      <span className="inbound">Inbound</span>
                      <span className="outbound">Outbound</span>
                    </div>
                    <span className="header-item">Messaging Flow</span>
                  </header>
                  <section>
                    <div
                      className={classNames({
                        "no-data":
                          !this.props.dataDiagram ||
                          !this.props.dataDiagram.length,
                      })}
                    >
                      {this.props.isFetching ? (
                        <Loader />
                      ) : this.props.dataDiagram &&
                        this.props.dataDiagram.length ? (
                        <>
                          {this.props.dataDiagram.map((day: any, i: number) => (
                            <div
                              className="ReportsModal__Progress"
                              key={i}
                              style={{
                                width: `${(day.total_count * 100) /
                                  Math.max(
                                    ...this.props.dataDiagram.map(
                                      day => day.total_count
                                    )
                                  )}%`,
                              }}
                            >
                              <div
                                className="ReportsModal__ProgressInbound"
                                style={{
                                  width: `${(day.inbound_count * 100) /
                                    day.total_count}%`,
                                  cursor: "pointer",
                                }}
                                data-tip
                                data-for={`message_inbound_${i}`}
                              >
                                {this.tooltip(
                                  `message_inbound_${i}`,
                                  day.inbound_count
                                )}
                                <span>
                                  {moment(day.sent_at_truncated_to_day)
                                    .utc()
                                    .format("MM/DD")}
                                </span>
                              </div>
                              <div
                                className="ReportsModal__ProgressOutbound"
                                style={{
                                  width: `${(day.outbound_count * 100) /
                                    day.total_count}%`,
                                  cursor: "pointer",
                                }}
                                data-tip
                                data-for={`message_outbound_${i}`}
                              >
                                {this.tooltip(
                                  `message_outbound_${i}`,
                                  day.outbound_count
                                )}
                                <span>{day.total_count}</span>
                              </div>
                            </div>
                          ))}
                          <div className="legend">
                            <span className="outbound">Outbound</span>
                            <span className="inbound">Inbound</span>
                          </div>
                        </>
                      ) : (
                        <span className="no-data">
                          No data for selected period or phone number
                        </span>
                      )}
                    </div>
                  </section>
                </div>
                <div>
                  <div className="ReportsModal__Panel ReportsModal__MessagesActivity">
                    <header>Messaging Statistics</header>
                    <section>
                      {this.props.isFetching ? (
                        <Loader />
                      ) : this.props.dataStats ? (
                        <>
                          <div className="column">
                            <h5>Successful</h5>
                            <span className="success">
                              {this.props.dataStats.delivered_count}
                            </span>
                          </div>
                          <div className="column">
                            <h5 className="failed-help">
                              Failed
                              <img
                                className="help-icon help-icon-abs"
                                src={IconHelp}
                                alt="Help"
                                data-tip
                                data-for="auto"
                              />
                              <ReactTooltip
                                className="tooltipContainer lt-align"
                                id="auto"
                                effect="solid"
                                place="bottom"
                                offset={{ left: 0 }}
                              >
                                <span className="tooltipContent">
                                  Failed messages are messages where the
                                  receiving carrier has sent an “Undelivered” or
                                  “Rejected” response.
                                  <br />
                                  This may happen because a message was sent to
                                  a number which was not SMS enabled,
                                  <br />
                                  or a receiving carrier may be blocking your
                                  messages because they suspect likely spam
                                  activity.
                                  <br />
                                  To increase your delivery rate on high volume
                                  message blasts,
                                  <br />
                                  please register your number for a Campaign ID
                                  at
                                  <br />
                                  <a className="ml0">
                                    www.campaignregistry.com
                                  </a>
                                </span>
                              </ReactTooltip>
                            </h5>
                            <span className="failed">
                              {this.props.dataStats.not_delivered_count}
                            </span>
                          </div>
                          <div className="column">
                            <h5>Inbound</h5>
                            <span>{this.props.dataStats.total_inbound}</span>
                          </div>
                          <div className="column">
                            <h5>Outbound</h5>
                            <span>{this.props.dataStats.total_outbound}</span>
                          </div>
                          {this.props.segmentReport && (
                            <>
                              <div className="column">
                                <h5>SMS Segments</h5>
                                <span>
                                  {this.props.segmentReport.sms_segment}
                                </span>
                              </div>
                              <div className="column">
                                <h5>MMS Segments</h5>
                                <span>
                                  {this.props.segmentReport.mms_segment}
                                </span>
                              </div>
                            </>
                          )}
                          {this.props.creditReport && (
                            <>
                              <div className="column">
                                <h5>SMS Credit</h5>
                                <span>
                                  {this.props.creditReport.sms_credit}
                                </span>
                              </div>
                              <div className="column">
                                <h5>MMS Credit</h5>
                                <span>
                                  {this.props.creditReport.mms_credit}
                                </span>
                              </div>
                            </>
                          )}
                        </>
                      ) : (
                        <span className="no-data">
                          No data for selected period or phone number
                        </span>
                      )}
                    </section>
                  </div>
                </div>
              </div>

              <div className="ReportsModal__ReportDetails">
                <div className="ReportsModal__Panel ReportsModal__MessagesBreakdown">
                  <header>
                    <div className="legend report">
                      {this.state.form.report_flow === "delivery" && (
                        <>
                          <span className="inbound">Delivered</span>
                          <span className="outbound">Rejected</span>
                        </>
                      )}
                      <select
                        dir="rtl"
                        id="report_flow"
                        onChange={this.handleNumberChange}
                        className="blast-dropdown"
                      >
                        <option
                          selected={this.state.form.report_flow === "delivery"}
                          value="delivery"
                        >
                          Delivery Report
                        </option>
                        <option
                          selected={this.state.form.report_flow === "stop"}
                          value="stop"
                        >
                          Stop Report
                        </option>
                      </select>
                      <div className="right-arrow" />
                    </div>
                    {this.reportHeader()}
                  </header>
                  <section>
                    <div
                      className={classNames({
                        "no-data":
                          this.state.form.report_flow === "delivery"
                            ? !this.props.blastReportData ||
                              !this.props.blastReportData.length
                            : !this.props.stopReportData ||
                              !this.props.stopReportData.length,
                        test: this.state.form.report_flow === "delivery",
                      })}
                    >
                      {this.props.isFetching ? (
                        <Loader />
                      ) : (this.state.form.report_flow === "delivery" ? (
                          this.props.blastReportData &&
                          this.props.blastReportData.length
                        ) : (
                          this.props.stopReportData &&
                          this.props.stopReportData.length
                        )) ? (
                        <>{this.renderReport()}</>
                      ) : (
                        <span className="no-data">
                          No data for selected period or phone number
                        </span>
                      )}
                    </div>
                  </section>
                </div>
                <div>
                  <div className="ReportsModal__Panel ReportsModal__MostTexted">
                    <header>
                      <div className="flow_wrapper">
                        <select
                          dir="rtl"
                          id="flow"
                          onChange={this.handleNumberChange}
                        >
                          <option
                            selected={this.state.form.flow === "all"}
                            value="all"
                          >
                            All
                          </option>
                          <option
                            selected={this.state.form.flow === "inbound"}
                            value="inbound"
                          >
                            Inbound
                          </option>
                          <option
                            selected={this.state.form.flow === "outbound"}
                            value="outbound"
                          >
                            Outbound
                          </option>
                        </select>
                        <div className="right-arrow" />
                      </div>
                      <span>Most Active</span>
                    </header>
                    <section className="rt-section">
                      {this.props.isFetching ? (
                        <Loader />
                      ) : (
                        this.renderMostTexted()
                      )}
                    </section>
                  </div>
                </div>
              </div>

              <div className="ReportsModal__ReportDetails">
                <div className="ReportsModal__Panel Campaign ReportsModal__MessagesBreakdown">
                  {this.props.isFetchingCampaignDetail ? (
                    <Loader />
                  ) : (
                    <header>
                      <span className="header-item">
                        <span className="campaign-help">
                          Campaign ID
                          <img
                            className="help-icon help-icon-abs"
                            src={IconHelp}
                            alt="Help"
                            data-tip
                            data-for="campaign"
                          />
                          <ReactTooltip
                            className="campaign-tp"
                            id="campaign"
                            effect="solid"
                            place="top"
                            offset={{ left: 0 }}
                          >
                            <span>
                              To increase your delivery rate on high volume
                              message blasts it is best practice to register for
                              a Campaign ID.
                              <br />
                              Your Campaign ID can be applied to your phone
                              number by contacting your system administrator.
                              <br />
                              <a className="ml0">www.campaignregistry.com</a>
                            </span>
                          </ReactTooltip>
                        </span>
                        {
                          <span className="header-text">
                            {this.props.campaignId || "Not Found"}
                          </span>
                        }
                      </span>
                    </header>
                  )}
                </div>
                <div>
                  <div className="ReportsModal__Panel campaign ReportsModal__MessagesActivity">
                    {this.props.isFetching ? (
                      <Loader />
                    ) : (
                      <header className="download">
                        <i
                          className="icon-download"
                          onClick={() => {
                            console.log("Download Message Report");
                            this.downloadReport(this.state.form.download_type);
                          }}
                        ></i>
                        <select
                          dir="rtl"
                          id="download_type"
                          onChange={this.handleNumberChange}
                          className="message-dropdown"
                        >
                          <option
                            selected={
                              this.state.form.download_type === "MESSAGE_REPORT"
                            }
                            value="MESSAGE_REPORT"
                          >
                            Message Flow Report
                          </option>

                          <option
                            selected={
                              this.state.form.download_type === "BLAST_REPORT"
                            }
                            value="BLAST_REPORT"
                          >
                            Delivery Report
                          </option>
                          <option
                            selected={
                              this.state.form.download_type === "STOP_REPORT"
                            }
                            value="STOP_REPORT"
                          >
                            Stop Report
                          </option>
                        </select>
                        <div className="right-arrow1" />
                      </header>
                    )}
                  </div>
                </div>
              </div>
            </>
          )}
        </section>
        {/* { !this.state.activeEdit && <footer>
          <ThemedButton onClick={() => this.setState({activeReport: -1})}><i className='icon-add'></i></ThemedButton>
        </footer> } */}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsModal);
