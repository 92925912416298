import classnames from "classnames";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./EmojiPanel.scss";
import "emoji-mart/css/emoji-mart.css";
import { Picker } from "emoji-mart";

interface Props {
  selectEmoji: (emoji: string) => void;
  shown: boolean;
  openDown?: boolean;
}

export default class EmojiPanel extends PureComponent<Props> {
  static propTypes = {
    selectEmoji: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
  };

  render() {
    return (
      <aside
        className={classnames(
          "EmojiPanel",
          { openDown: this.props.openDown },
          { openTop: !this.props.openDown },
          { shown: this.props.shown }
        )}
      >
        <Picker
          darkMode={false}
          // emojisToShowFilter={(emoji) => emoji.unified.split('-').length <= 2}
          exclude={["flags"]}
          onSelect={emoji => this.props.selectEmoji(emoji)}
          showPreview={false}
          showSkinTones={false}
          skin={1}
        />
      </aside>
    );
  }
}
