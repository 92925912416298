import classnames from "classnames";
import React, { BaseSyntheticEvent, PureComponent } from "react";
import { connect } from "react-redux";
import withSizes from "react-sizes";
import ReactTooltip from "react-tooltip";
import IconHelp from "~/assets/help.svg";
import { Branding } from "~/branding";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedTab from "~/components/common/ThemedTab/ThemedTab";
import TemplateEdit from "~/components/forms/TemplateEdit/TemplateEdit";
import TemplateList from "~/components/TemplateList/TemplateList";
import { modalActions } from "~/store/modal";
import { ITemplate, templatesActions } from "~/store/templates";

import Modal from "../AbstractModal/AbstractModal";
import "./TemplateModal.scss";

namespace TemplateModal {
  interface DispatchProps {
    closeModal: () => void;
    createConversation: (
      members: Array<string>,
      listeners: Array<string>
    ) => void;
    listTemplates: () => void;
    updateTemplate: (template: ITemplate) => void;
  }
  interface OwnProps {
    isMobile: boolean;
  }
  interface StateProps {
    templatesList: Array<ITemplate>;
    isFetching: boolean;
  }
  export interface State {
    activeTemplate: number;
    activeEdit: boolean;
    activeTab: number;
    shown: string;
  }
  export type Props = DispatchProps & StateProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createTemplate: templatesActions.createTemplate,
  listTemplates: templatesActions.listTemplates,
  updateTemplate: templatesActions.updateTemplate,
};

const mapStateToProps = (state: any) => ({
  templatesList: state.templates.data,
  isFetching: state.templates.isFetching,
});

@withSizes(({ width }) => ({ isMobile: width < 600 }))
class TemplateModal extends PureComponent<
  TemplateModal.Props,
  TemplateModal.State
> {
  state = {
    activeTemplate: -1,
    activeEdit: false,
    activeTab: 1,
    shown: "list",
  };

  componentDidMount() {
    // Fetch replies lists here
    this.props.listTemplates();
  }

  openEditForm = () => this.setState({ activeEdit: true, shown: "edit" });
  closeEditForm = () => this.setState({ activeEdit: false, shown: "list" });

  changeActiveTab = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const activeTab = +e.currentTarget.dataset.tabId;
    if (Number.isInteger(activeTab)) {
      this.setState({ activeTab, activeEdit: false, shown: "list" });
    }
  };

  render() {
    const TemplateTab = () => (
      <React.Fragment>
        <TemplateList
          items={this.props.templatesList || []}
          openTemplate={(id: number) =>
            this.setState({
              activeEdit: true,
              activeTemplate: id,
              shown: "edit",
            })
          }
          shown={this.state.shown === "list"}
          isFetching={this.props.isFetching}
        />
        {this.state.activeEdit && (
          <TemplateEdit
            shown={this.state.shown === "edit"}
            template={this.state.activeTemplate}
            onBack={this.closeEditForm}
          />
        )}
      </React.Fragment>
    );

    const tabs = [
      {
        id: 1,
        title: (
          <span>
            Templates
            <img
              className="help-icon"
              src={IconHelp}
              alt="Help"
              data-tip
              data-for="template"
            />
            <ReactTooltip
              className="tooltipContainer tooltipRight"
              id="template"
              effect="solid"
              place="bottom"
              offset={{ left: this.props.isMobile ? 124 : 0 }}
            >
              <span className="tooltipContent">
                Template are commonly used responses that could be prebuilt, so
                the response doesn’t need to be retyped each time.
                <br />
                Template examples:
                <ol>
                  <li>
                    {`“Thank you for reaching us today. Please visit our website:
                    ${Branding.WEBSITE_URL} to get more information.”`}
                  </li>
                  <li>
                    “To pay your monthly bill online, please go to
                    www.monthlyutility.com/billpay”
                  </li>
                </ol>
              </span>
            </ReactTooltip>
          </span>
        ),
        component: <TemplateTab />,
      },
    ];

    const activeTab = tabs.filter(tab => tab.id === this.state.activeTab)[0];

    return (
      <Modal className="TemplateModal" title="Template Options">
        <ul className="TemplateModal__Tabs">
          {tabs.map(tab => (
            <ThemedTab
              key={tab.id}
              onClick={this.changeActiveTab}
              className={classnames({
                active: this.state.activeTab === tab.id,
              })}
              data-tab-id={tab.id}
            >
              {tab.title}
            </ThemedTab>
          ))}
        </ul>
        <section>{activeTab && activeTab.component}</section>
        {!this.state.activeEdit && (
          <footer>
            <ThemedButton
              onClick={() =>
                this.setState({
                  activeTemplate: -1,
                  activeEdit: true,
                  shown: "edit",
                })
              }
            >
              <i className="icon-add"></i>
            </ThemedButton>
          </footer>
        )}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateModal as any);
