import { AxiosPromise } from "axios";
import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from "react";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedCheckbox from "~/components/common/ThemedCheckbox/ThemedCheckbox";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedRadio from "~/components/common/ThemedRadio/ThemedRadio";
import ThemedSwitch from "~/components/common/ThemedSwitch/ThemedSwitch";
import ThemedTextarea from "~/components/common/ThemedTextarea/ThemedTextarea";
import TimePicker from "~/components/common/TimePicker/TimePicker";
import {
  autoRepliesActions,
  IAutoReply,
  IRecurringAutoReply,
} from "~/store/replies_auto";
const momenttimezone = require("moment-timezone");
import { ThemeContext, formatFileSize, LightenDarkenColor } from "~/utils";
import "./ReplyEdit.scss";
import OutsideClickHandler from "react-outside-click-handler";
import AttachmentPanel from "../../AttachmentPanel/AttachmentPanel";
import EmojiPanel from "../../EmojiPanel/EmojiPanel";
import Loader from "~/components/common/Loader/Loader";
import CreatableSelect from "react-select/lib/Creatable";

namespace ReplyEdit {
  export type State = {
    isPanelOpen: boolean;
    form: IAutoReply;
    replyId: number | null;
    // validFromTime: any,
    // validToTime: any
    isMMSPanelOpen: any;
    media: object | null;
    inputValue: string;
  };

  interface StateProps {
    authenticatedUser: any;
    replies: [];
  }

  interface DispatchProps {
    createReply: (form: IAutoReply) => AxiosPromise;
    deleteReply: (id: number) => AxiosPromise;
    updateReply: (form: IAutoReply) => AxiosPromise;
  }

  interface OwnProps {
    reply?: number;
    onBack: () => void;
    shown: boolean;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

interface Refs {
  messageField: Element | null;
}

const mapDispatchToProps = {
  createReply: autoRepliesActions.createAutoReply,
  deleteReply: autoRepliesActions.deleteAutoReply,
  updateReply: autoRepliesActions.updateAutoReply,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  replies: state.autoReplies.data,
});

const SCHEDULING_DATE_RANGE = 0;
const SCHEDULING_RECURRENT = 1;
const SCHEDULING_ALWAYS = 2;

const initialMMSPanelsState: {
  [x: string]: boolean;
} = {
  Attachment: false,
  Emoji: false,
};

const initialMediaState = {
  error: "",
  file_Name: null,
  file_Size: null,
  media_link: "",
  media: null,
  media_file_mime: "",
};

class ReplyEdit extends PureComponent<ReplyEdit.Props, ReplyEdit.State, Refs> {
  public messageField: HTMLInputElement | null = null;
  static propTypes = {
    reply: PropTypes.number,
    onBack: PropTypes.func,
    shown: PropTypes.bool.isRequired,
  };

  state = {
    isPanelOpen: false,
    replyId: -1,
    inputValue: "",
    isMMSPanelOpen: initialMMSPanelsState,
    media: initialMediaState,
    form: {
      title: "",
      keywords: [],
      keyword: "",
      use_keywords: false,
      exact_match: false,
      start_at: undefined,
      end_at: undefined,
      type: 0,
      text_content: "",
      is_active: true,
      send_to_contacts: true,
      send_to_all: true,
      media_link: "",
      file_Name: null,
      file_Size: null,
      media_file_mime: "",
      recurring_replies: [
        { isActive: false, dayName: "Monday" },
        { isActive: false, dayName: "Tuesday" },
        { isActive: false, dayName: "Wednesday" },
        { isActive: false, dayName: "Thursday" },
        { isActive: false, dayName: "Friday" },
        { isActive: false, dayName: "Saturday" },
        { isActive: false, dayName: "Sunday" },
      ],
    },
    // validFromTime: {},
    // validToTime: {}
  };

  componentDidMount() {
    if (this.props.reply !== -1) this.loadReply(this.props.reply);
  }

  componentDidUpdate(prevProps: ReplyEdit.Props) {
    if (this.props.reply !== -1 && this.props.reply !== prevProps.reply)
      this.loadReply(this.props.reply);
  }

  loadReply = (replyId?: number) => {
    const recurring_replies = [
      { isActive: false, dayName: "Monday" },
      { isActive: false, dayName: "Tuesday" },
      { isActive: false, dayName: "Wednesday" },
      { isActive: false, dayName: "Thursday" },
      { isActive: false, dayName: "Friday" },
      { isActive: false, dayName: "Saturday" },
      { isActive: false, dayName: "Sunday" },
    ];
    const reply: IAutoReply | undefined = this.props.replies.find(
      (item: IAutoReply) => item.id === replyId
    );
    if (reply) {
      const form = { ...reply };
      if (!form.recurring_replies) {
        form.recurring_replies = recurring_replies;
      } else {
        form.recurring_replies = recurring_replies.map(
          (i: IRecurringAutoReply) => {
            const existing = form.recurring_replies.find(
              (item: IRecurringAutoReply) => item.dayName === i.dayName
            );
            const res = existing || i;
            res.isValid = this.checkValidTimeRange(res);
            return res;
          }
        );
      }
      if (form.keywords) {
        form.keywords = form.keywords.map((keyword: string) =>
          this.createOption(keyword)
        );
      } else {
        form.keywords = [];
      }
      let media = initialMediaState;
      let isMMSPanelOpen = initialMMSPanelsState;
      if (form.media_link) {
        media = {
          media_file_mime: form.media_file_mime,
          media_link: form.media_link,
          file_Name: form.file_Name,
          file_Size: form.file_Size,
          media: { media_link: form.media_link },
        };
        isMMSPanelOpen = {
          Attachment: true,
          Emoji: false,
        };
      }
      this.setState({ media, form, replyId: reply.id, isMMSPanelOpen });
    }
  };

  handleInputChange = (inputValue: string) => this.setState({ inputValue });

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { keywords } = this.state.form;
    const inputValue = this.state.inputValue;
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        if (keywords.filter(keyword => keyword.value === inputValue).length > 0)
          return;
        this.setState({
          inputValue: "",
          form: {
            ...this.state.form,
            keywords: [...keywords, this.createOption(inputValue)],
          },
        });
        event.preventDefault();
    }
  };

  createOption = (label: string) => ({ key: label, label, value: label });

  handleKeywordsChange = newValue => {
    const form: IAutoReply = { ...this.state.form, keywords: newValue };
    this.setState({ form });
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: IAutoReply = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleChangeDate = (value: moment.Moment | string, id: string) => {
    const form: IAutoReply = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleCheckbox = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, checked } = e.target as HTMLInputElement;
    const form: IAutoReply = { ...this.state.form, [id]: checked };
    this.setState({ form });
  };

  handleDayCheckbox = (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { checked, id } = e.target;
    if (id) {
      const dayName = id.split("_")[1];
      let recurring_replies: IRecurringAutoReply[] = [
        ...this.state.form.recurring_replies,
      ];
      recurring_replies = recurring_replies.map(day => {
        if (day.dayName === dayName) {
          day.isActive = checked;
        }
        return day;
      });
      const form: IAutoReply = { ...this.state.form, recurring_replies };
      this.setState({ form });
    }
  };

  checkValidTimeRange = (day: any) => {
    if (day.timeFrom && day.timeTo) {
      const [hoursFrom, minutesFrom] = day.timeFrom.split(":");
      const [hoursTo, minutesTo] = day.timeTo.split(":");
      const dateFrom = moment(new Date(0)).set({
        hour: +hoursFrom,
        minute: +minutesFrom,
      });
      const dateTo = moment(new Date(0)).set({
        hour: +hoursTo,
        minute: +minutesTo,
      });
      return dateFrom.isBefore(dateTo);
    } else {
      return false;
    }
  };

  updateTime = (
    time: Date,
    err: boolean,
    timeType: string,
    dayName: string
  ) => {
    let recurring_replies: IRecurringAutoReply[] = [
      ...this.state.form.recurring_replies,
    ];
    recurring_replies = recurring_replies.map(day => {
      if (day.dayName === dayName) {
        day[timeType] = moment(time).format("HH:mm");
        // this.setState({
        //   validFromTime: this.validFromTime(),
        //   validToTime: this.validToTime()
        // });
        day.isValid = this.checkValidTimeRange(day);
      }
      return day;
    });
    const form: IAutoReply = { ...this.state.form, recurring_replies };

    this.setState({ form });
  };

  handleRadio = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    const form = { ...this.state.form, type: +value };
    this.setState({ form });
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const {
      file_Name = null,
      file_Size = null,
      media_link = "",
      media_file_mime = "",
    } = this.state.media;
    this.setState(
      {
        form: {
          ...this.state.form,
          media_link: media_link,
          file_Name: file_Name,
          file_Size: file_Size,
          media_file_mime: media_file_mime,
          end_at:
            typeof this.state.form.end_at !== "string"
              ? moment(this.state.form.end_at)
                  .utcOffset(0, true)
                  .format()
              : this.state.form.end_at,
          start_at:
            typeof this.state.form.start_at !== "string"
              ? moment(this.state.form.start_at)
                  .utcOffset(0, true)
                  .format()
              : this.state.form.start_at,
        },
      },
      () => {
        if (this.props.reply !== -1) {
          // Update reply
          this.props.updateReply(this.state.form).then(this.props.onBack);
        } else {
          // Create reply
          this.props.createReply(this.state.form).then(this.props.onBack);
        }
      }
    );
  };

  togglePanel = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({ isPanelOpen: !prevState.isPanelOpen }));
  };

  toggleMMSPanel = (e: any) => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      isMMSPanelOpen: {
        [e.target.id]: !this.state.isMMSPanelOpen[e.target.id],
      },
    });
  };

  closePanels = () => {
    this.setState({ ...this.state, isMMSPanelOpen: { initialMMSPanelsState } });
  };

  getMaxTimeFrom = (timeTo: string | undefined) => {
    if (timeTo) {
      const [hours, minutes] = timeTo.split(":");
      const date = new Date(0);
      date.setHours(+hours, +minutes, 0, 0);
      return date;
    } else return this.getMaxTimeTo();
  };

  getMinTimeFrom = () => {
    const date = new Date(0);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  getMaxTimeTo = () => {
    const date = new Date(0);
    date.setHours(23, 59, 0, 0);
    return date;
  };

  getMinTimeTo = (timeFrom: string | undefined) => {
    if (timeFrom) {
      const [hours, minutes] = timeFrom.split(":");
      const date = new Date(0);
      date.setHours(+hours, +minutes, 0, 0);
      return date;
    } else return this.getMinTimeFrom();
  };

  validateForm = () => {
    let isValid = true;
    if (
      !this.state.form.title ||
      (this.state.form.use_keywords &&
        (!this.state.form.keywords || this.state.form.keywords.length <= 0)) ||
      !this.state.form.text_content ||
      (this.state.form.type === SCHEDULING_DATE_RANGE &&
        !(this.state.form.start_at || this.state.form.end_at))
    )
      isValid = false;
    if (this.state.form.type === SCHEDULING_RECURRENT) {
      const reccurent = this.state.form.recurring_replies.filter(
        i => i.isActive
      );
      if (
        reccurent.length === 0 ||
        reccurent.filter(i => !i.timeFrom || !i.timeTo || !i.isValid).length
      )
        isValid = false;
    }
    return !isValid;
  };

  onCopy = () => {
    let form: IAutoReply | undefined = (this.props.replies.find(
      (reply: IAutoReply) => reply.id === this.props.reply
    ) as never) as IAutoReply;
    if (form) {
      form = {
        ...form,
        title: `${form.title} Copy`,
      };
      delete form.id;
      this.props.createReply(form);
      this.props.onBack();
    }
  };

  /*
   * 1. Start date should be today or after
   * 2. Start date should not be after end date
   */
  validFromDate = (current: moment.Moment) =>
    current.isSameOrAfter(moment().subtract(1, "day")) &&
    (this.state.form.end_at ? current.isBefore(this.state.form.end_at) : true);

  // End date should be after start date
  validToDate = (current: moment.Moment) =>
    current.isAfter(this.state.form.start_at || moment());

  // NOT WORKING PROPERLY
  // validFromTime = () => {
  //   const end = moment(this.state.form.end_at);
  //   return end && {
  //     hours: { min: 0, max: end.hours() },
  //     minutes: { min: 0, max: end.minutes() }
  //   }
  // }

  // validToTime = () => {
  //   const start = moment(this.state.form.start_at);
  //   return start && {
  //     hours: { min: start.hours(), max: 23 },
  //     minutes: { min: start.minutes(), max: 59 }
  //   };
  // }

  handleDeleteAttachment = () => {
    this.setState({ ...this.state, media: { initialMediaState } });
  };

  handleCloseAttachment = () => {
    this.setState({
      ...this.state,
      isMMSPanelOpen: { initialMMSPanelsState },
    });
  };

  handleSetMedia = ({ file, media }: any) => {
    this.setState({
      ...this.state,
      media: {
        media_file_mime: media.media_file_mime,
        file_Name: file.name,
        file_Size: formatFileSize(file.size),
        media_link: media.media_link,
        media: media,
      },
    });
  };

  handleEmoji = (emoji: any) => {
    if (this.messageField) {
      const start = this.messageField.selectionStart || 0;
      const end = this.messageField.selectionEnd || 0;
      this.setState(
        prevState => ({
          ...this.state,
          form: {
            ...this.state.form,
            text_content: [
              prevState.form.text_content.slice(0, start as number),
              emoji.native,
              prevState.form.text_content.slice(end as number),
            ].join(""),
          },
        }),
        () => {
          if (this.messageField !== null) {
            const pos = end - (end - start) + emoji.native.length;
            this.messageField.focus();
            this.messageField.setSelectionRange(pos, pos);
          }
        }
      );
    }
  };

  render() {
    let brandColor: string;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }

    return (
      <div className={this.props.shown ? "ReplyEdit shown" : "ReplyEdit"}>
        <header>
          <button type="button" onClick={this.props.onBack}>
            <i className="icon-back"></i>
          </button>
          <h1>{this.props.reply !== -1 ? "Edit" : "Add"} Auto Reply</h1>
        </header>
        <form>
          <div>
            <label className="mandatory" htmlFor="title">
              Title
            </label>
            <ThemedInput
              id="title"
              type="text"
              onChange={this.handleChange}
              placeholder="Title"
              value={this.state.form.title}
            />
            <ThemedCheckbox
              id="use_keywords"
              text="Use Keyword"
              onChange={this.handleCheckbox}
              checked={this.state.form.use_keywords}
            />
          </div>
          {this.state.form.use_keywords && (
            <div>
              <label className="mandatory" htmlFor="text">
                Keyword
              </label>
              <CreatableSelect
                id="keywords"
                components={{ DropdownIndicator: null }}
                menuIsOpen={false}
                inputValue={this.state.inputValue}
                isMulti
                onChange={this.handleKeywordsChange}
                onInputChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder="Enter a keyword or keyphrase, then hit return to add it"
                theme={theme => ({
                  ...theme,
                  borderShadow: "none",
                  borderWidth: "1px",
                  colors: {
                    ...theme.colors,
                    primary:
                      (brandColor || theme.colors.primary) + " !important",
                    primary25:
                      (brandColor
                        ? LightenDarkenColor(brandColor, 30)
                        : theme.colors.primary25) + " !important",
                  },
                })}
                value={this.state.form.keywords}
              />
              <ThemedCheckbox
                id="exact_match"
                text="Exact Match"
                onChange={this.handleCheckbox}
                checked={this.state.form.exact_match}
              />
            </div>
          )}
          <div>
            <label className="mandatory" htmlFor="notes">
              Auto reply
            </label>
            <ThemedTextarea
              handleRef={(el: any) => (this.messageField = el)}
              id="text_content"
              placeholder="Type Auto reply..."
              onChange={this.handleChange}
              value={this.state.form.text_content}
              className="remove-margin-bottom"
            ></ThemedTextarea>
          </div>
          <section className="MMSPanel">
            <>
              <OutsideClickHandler onOutsideClick={this.closePanels}>
                <div className="options">
                  <button
                    id="Attachment"
                    onClick={this.toggleMMSPanel}
                    style={brandColor ? { color: brandColor } : {}}
                    type="button"
                  >
                    <i className="icon-attach" id="Attachment"></i>
                    {this.state.media && this.state.media.media_link && (
                      <div className="badge" id="Attachment">
                        1
                      </div>
                    )}
                  </button>
                  <button
                    id="Emoji"
                    onClick={this.toggleMMSPanel}
                    style={brandColor ? { color: brandColor } : {}}
                    type="button"
                  >
                    <i className="icon-emoji" id="Emoji"></i>
                  </button>
                </div>
                <EmojiPanel
                  selectEmoji={this.handleEmoji}
                  shown={
                    this.state &&
                    this.state.isMMSPanelOpen &&
                    this.state.isMMSPanelOpen.Emoji
                      ? this.state.isMMSPanelOpen.Emoji
                      : false
                  }
                  openDown={true}
                />
                <AttachmentPanel
                  attachment={this.state.media}
                  closePanel={this.handleCloseAttachment}
                  setAttachment={this.handleSetMedia}
                  deleteAttachment={this.handleDeleteAttachment}
                  shown={this.state.isMMSPanelOpen.Attachment}
                  openDown={true}
                />
              </OutsideClickHandler>
            </>
          </section>
          <div className="ReplyEdit__Scheduling">
            <label htmlFor="send_to_contacts">Scheduling</label>
            <ThemedRadio
              checked={this.state.form.type === SCHEDULING_RECURRENT}
              id="scheduling_recurrent"
              name="scheduling"
              onChange={this.handleRadio}
              text="Recurring"
              value={"" + SCHEDULING_RECURRENT}
            />
            <ThemedRadio
              checked={this.state.form.type === SCHEDULING_DATE_RANGE}
              id="scheduling_date_range"
              name="scheduling"
              onChange={this.handleRadio}
              text="Date Range"
              value={"" + SCHEDULING_DATE_RANGE}
            />
            <ThemedRadio
              checked={this.state.form.type === SCHEDULING_ALWAYS}
              id="scheduling_always"
              name="scheduling"
              onChange={this.handleRadio}
              text="Always"
              value={"" + SCHEDULING_ALWAYS}
            />
          </div>
          {this.state.form.type === SCHEDULING_RECURRENT && (
            <div>
              <table className="ReplyEdit__SchedulingTable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Active From</th>
                    <th>Active To</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.form.recurring_replies.map(
                    (day: IRecurringAutoReply, key) => (
                      <tr key={key}>
                        <td>
                          <ThemedCheckbox
                            id={`Recurring_${day.dayName}`}
                            text={day.dayName}
                            onChange={this.handleDayCheckbox}
                            checked={day.isActive}
                          />
                        </td>
                        <td>
                          <TimePicker
                            disabled={!day.isActive}
                            fieldType={"from"}
                            id={`Recurring_${day.dayName}_ActiveFrom`}
                            maxTime={this.getMaxTimeFrom(day.timeTo)}
                            minTime={this.getMinTimeFrom()}
                            onTimeChange={(time, err) =>
                              this.updateTime(
                                time,
                                err,
                                "timeFrom",
                                day.dayName
                              )
                            }
                            value={day.timeFrom}
                          />
                        </td>
                        <td>
                          <TimePicker
                            disabled={!day.isActive}
                            fieldType={"to"}
                            id={`Recurring_${day.dayName}_ActiveTo`}
                            maxTime={this.getMaxTimeTo()}
                            minTime={this.getMinTimeTo(day.timeFrom)}
                            onTimeChange={(time, err) =>
                              this.updateTime(time, err, "timeTo", day.dayName)
                            }
                            value={day.timeTo}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
          {this.state.form.type === SCHEDULING_DATE_RANGE && (
            <div className="columns">
              <div>
                <label htmlFor="start_at">Active From</label>
                <Datetime
                  className="icon-date"
                  dateFormat="MM/DD/YYYY"
                  displayTimeZone="utc"
                  inputProps={{
                    id: "start_at",
                    readOnly: true,
                    placeholder: "Select start date...",
                  }}
                  onChange={moment => this.handleChangeDate(moment, "start_at")}
                  isValidDate={this.validFromDate}
                  timeFormat="hh:mm A"
                  value={
                    this.state.form.start_at &&
                    moment(this.state.form.start_at)
                      .utc(false)
                      .format("MM/DD/YYYY hh:mm A")
                  }
                />
              </div>
              <div>
                <label htmlFor="end_at">Active To</label>
                <Datetime
                  className="icon-date"
                  dateFormat="MM/DD/YYYY"
                  displayTimeZone="utc"
                  inputProps={{
                    id: "end_at",
                    readOnly: true,
                    placeholder: "Select end date...",
                  }}
                  onChange={moment => this.handleChangeDate(moment, "end_at")}
                  isValidDate={this.validToDate}
                  timeFormat="hh:mm A"
                  value={
                    this.state.form.end_at &&
                    moment(this.state.form.end_at)
                      .utc(false)
                      .format("MM/DD/YYYY hh:mm A")
                  }
                />
              </div>
            </div>
          )}
          <div>
            <label htmlFor="send_to_contacts">Send to everyone:</label>
            <div>
              <ThemedCheckbox
                id="send_to_contacts"
                text="In Contacts"
                onChange={this.handleCheckbox}
                checked={this.state.form.send_to_contacts}
              />
            </div>
            <div>
              <ThemedCheckbox
                id="send_to_all"
                text="Not in Contacts"
                onChange={this.handleCheckbox}
                checked={this.state.form.send_to_all}
              />
            </div>
          </div>
          <div className="ReplyEdit__toggle">
            <ThemedSwitch
              checkedIcon={<i>ON</i>}
              uncheckedIcon={<i>OFF</i>}
              onColor={"#00bbe5"}
              offColor={"#a1a1a1"}
              onChange={() =>
                this.setState(prevState => ({
                  form: {
                    ...this.state.form,
                    is_active: !prevState.form.is_active,
                  },
                }))
              }
              checked={!!this.state.form.is_active}
            />
            <span>
              Autoreply is {this.state.form.is_active ? "enabled" : "disabled"}
            </span>
          </div>
        </form>
        <div
          onClick={this.togglePanel}
          className={classNames("panel", { open: this.state.isPanelOpen })}
        >
          <div>
            <ThemedButton type="button" onClick={this.onCopy}>
              Copy Auto Reply
            </ThemedButton>
            <button
              type="button"
              onClick={() => {
                this.props
                  .deleteReply(this.state.replyId)
                  .then(this.props.onBack);
              }}
            >
              Delete Auto Reply
            </button>
            <button className="close" type="button" onClick={this.togglePanel}>
              Close
            </button>
          </div>
        </div>
        <footer>
          <ThemedButton
            type="submit"
            onClick={this.handleSubmit}
            disabled={this.validateForm()}
          >
            {this.props.reply !== -1 ? "Update" : "Add"}
          </ThemedButton>
          <button type="button" onClick={this.props.onBack}>
            Cancel
          </button>
          {this.props.reply !== -1 && (
            <button
              className="more"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              ···
            </button>
          )}
        </footer>
      </div>
    );
  }
}

ReplyEdit.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReplyEdit);
