import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}

interface DispatchProps {}

interface OwnProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  id?: string;
  maxLength?: number;
  name?: string;
  onBlur?: any;
  onChange?: any;
  onClick?: any;
  onFocus?: any;
  placeholder?: string;
  value?: string;
  handleRef?: any;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  focus: boolean;
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedTextarea extends PureComponent<Props, State> {
  state = {
    focus: false,
  };

  handleFocus = () => {
    this.setState({ focus: true }, () => {
      if (this.props.onFocus) this.props.onFocus();
    });
  };

  handleBlur = () => {
    this.setState({ focus: false }, () => {
      if (this.props.onBlur) this.props.onBlur();
    });
  };

  render() {
    let brandColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
    return (
      <textarea
        ref={this.props.handleRef}
        autoComplete={this.props.autoComplete}
        className={this.props.className || "input"}
        disabled={this.props.disabled}
        id={this.props.id}
        maxLength={this.props.maxLength}
        name={this.props.name}
        onClick={this.props.onClick}
        onChange={this.props.onChange}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        placeholder={this.props.placeholder}
        style={
          brandColor && this.state.focus ? { borderColor: brandColor } : {}
        }
        value={this.props.value}
      ></textarea>
    );
  }
}

ThemedTextarea.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedTextarea);
