import { AnyAction, Dispatch } from "redux";

// ------------------------------------
// Constants
// ------------------------------------
const TOGGLE_SCHEDULE_PANEL = "TOGGLE_SCHEDULE_PANEL";

// ------------------------------------
// Action Creators
// ------------------------------------
const toggleSchedulePanelRequest = () => ({
  type: TOGGLE_SCHEDULE_PANEL,
});

const toggleSchedulePanel = () => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(toggleSchedulePanelRequest());
};

export const schedulesPanelActions = {
  toggleSchedulePanel,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isSchedulePanelOpen: false,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_SCHEDULE_PANEL:
      return { ...state, isSchedulePanelOpen: !state.isSchedulePanelOpen };

    default:
      return state;
  }
};
