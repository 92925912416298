import { AxiosPromise } from "axios";
import classNames from "classnames";
import moment from "moment";
import React, { BaseSyntheticEvent, ChangeEvent, Component } from "react";
import Datetime from "react-datetime";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import ErrorMessage from "~/components/common/EroorMessage/ErrorMessage";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedCheckbox from "~/components/common/ThemedCheckbox/ThemedCheckbox";
import ThemedHighlitedTextarea from "~/components/common/ThemedHighlitedTextarea/ThemedHighlitedTextarea";
import ThemedRadio from "~/components/common/ThemedRadio/ThemedRadio";
import TimePickerHHMMSS from "~/components/common/TimePicker/TimePickerHHMMSS";
import TemplateKeywords from "~/components/TemplatePanel/TemplateKeywords";
import {
  IRecurringScheduleMessage,
  ISchedule,
  schedulesActions,
  tzScheduleShift,
} from "~/store/schedules";
import { HIGHLIGHTS, TEMPLATE_KEYWORDS } from "~/store/templates";
import { ThemeContext } from "~/utils";
import AttachmentPanel from "../../AttachmentPanel/AttachmentPanel";
import EmojiPanel from "../../EmojiPanel/EmojiPanel";
import TemplatePanel from "../../TemplatePanel/TemplatePanel";
import "./ScheduleEdit.scss";

type State = {
  isPastDate: boolean;
  isPanelOpen: boolean;
  form: ISchedule;
  scheduleId: number | undefined;
  isMMSPanelOpen: any;
};

interface StateProps {
  authenticatedUser: any;
  schedule_messages: [];
  template_count: number;
}

interface DispatchProps {
  createSchedule: (form: ISchedule) => AxiosPromise;
  deleteSchedule: (id: number) => AxiosPromise;
  updateSchedule: (form: ISchedule) => AxiosPromise;
}

interface OwnProps {
  scheduleId?: number;
  onBack: () => void;
  shown: boolean;
  activeTabId?: number;
}
type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  createSchedule: schedulesActions.createSchedule,
  deleteSchedule: schedulesActions.deleteSchedule,
  updateSchedule: schedulesActions.updateSchedule,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  schedule_messages: state.schedules.data,
  template_count: (state.templates.data && state.templates.data.length) || 0,
});

const SCHEDULING_DATE_RANGE = 0;
const SCHEDULING_RECURRENT = 1;

const initialMMSPanelsState: {
  [x: string]: boolean;
} = {
  Attachment: false,
  Emoji: false,
  TemplatePanel: false,
  TemplateKeywords: false,
};

const initialMediaState: any = {
  error: "",
  file: null,
  media: null,
};

class ScheduleEdit extends Component<Props, State> {
  state = {
    isPastDate: false,
    isPanelOpen: false,
    scheduleId: -1,
    isMMSPanelOpen: initialMMSPanelsState,
    media: { ...initialMediaState },
    form: {
      schedule_at: moment(),
      message_content: "",
      type: 0,
      recurring_schedule_timeFrom: moment("1970-01-01 00:00:00").format(
        "HH:mm:ss"
      ),
      recurring_schedule_message: [
        {
          id: 0,
          isActive: false,
          dayName: "Monday",
          isUpdate: false,
        },
        {
          id: 1,
          isActive: false,
          dayName: "Tuesday",
          isUpdate: false,
        },
        {
          id: 2,
          isActive: false,
          dayName: "Wednesday",
          isUpdate: false,
        },
        {
          id: 3,
          isActive: false,
          dayName: "Thursday",
          isUpdate: false,
        },
        {
          id: 4,
          isActive: false,
          dayName: "Friday",
          isUpdate: false,
        },
        {
          id: 5,
          isActive: false,
          dayName: "Saturday",
          isUpdate: false,
        },
        {
          id: 6,
          isActive: false,
          dayName: "Sunday",
          isUpdate: false,
        },
      ],
      media_data: initialMediaState,
      saving: false,
    },
  };
  componentDidMount() {
    console.log("this.props.schedule ", this.props.scheduleId);
    if (this.props.scheduleId !== -1) {
      this.loadSchedule(this.props.scheduleId);
    } else if (this.props.activeTabId) {
      const form = { ...this.state.form, type: this.props.activeTabId };
      this.setState({ form });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (
      this.props.scheduleId !== -1 &&
      this.props.scheduleId !== prevProps.scheduleId
    )
      this.loadSchedule(this.props.scheduleId);
  }

  shouldComponentUpdate(
    _nextProps: Readonly<Props>,
    nextState: Readonly<State>
  ): boolean {
    return !nextState.form.saving;
  }

  loadSchedule = (scheduled?: number) => {
    const recurring_schedule_message = [
      {
        id: 0,
        isActive: false,
        dayName: "Monday",
        isUpdate: false,
      },
      {
        id: 1,
        isActive: false,
        dayName: "Tuesday",
        isUpdate: false,
      },
      {
        id: 2,
        isActive: false,
        dayName: "Wednesday",
        isUpdate: false,
      },
      {
        id: 3,
        isActive: false,
        dayName: "Thursday",
        isUpdate: false,
      },
      {
        id: 4,
        isActive: false,
        dayName: "Friday",
        isUpdate: false,
      },
      {
        id: 5,
        isActive: false,
        dayName: "Saturday",
        isUpdate: false,
      },
      {
        id: 6,
        isActive: false,
        dayName: "Sunday",
        isUpdate: false,
      },
    ];
    const schedule_msg: ISchedule =
      this.props.schedule_messages.find(
        (item: ISchedule) => item.id === scheduled
      ) || this.state.form;
    if (schedule_msg) {
      const form = Object.assign({}, schedule_msg);
      if (!form.recurring_schedule_message) {
        form.recurring_schedule_message = [...recurring_schedule_message];
      } else {
        form.recurring_schedule_message = form.recurring_schedule_message.map(
          tzScheduleShift.bind(null, "load")
        );
        form.recurring_schedule_message = [
          ...recurring_schedule_message.map((i: IRecurringScheduleMessage) => {
            const existing = form.recurring_schedule_message.find(
              (item: IRecurringScheduleMessage) => item.dayName === i.dayName
            );
            const res = Object.assign({}, existing || i);
            res["id"] = i.id;
            res["isUpdate"] = i.isUpdate;
            if (res && res.isActive && res["timeFrom"]) {
              // const [hours, minutes, seconds] = res['timeFrom'].split(":");
              // const date = Date.UTC(1998, 2, 2, +hours, +minutes, +seconds);
              // const date = new Date(0);
              // date.setHours(+hours, +minutes, 0, 0);
              // console.log('UTC date ', date);
              // const local = moment.utc(date).local().format('HH:mm:ss');
              // console.log('local ', local);
              // res['timeFrom'] = local;

              form.recurring_schedule_timeFrom = moment
                .utc(res["timeFrom"], "HH:mm:ss")
                .local()
                .format("HH:mm:ss");
              delete res["timeFrom"];
              console.log("res", res);
            }
            return res;
          }),
        ];
      }
      form.media_data = schedule_msg["media_data"] || initialMediaState;
      const isMMSPanelOpen = { ...initialMMSPanelsState };
      if (schedule_msg["media_data"]) {
        isMMSPanelOpen.Attachment = true;
      }
      form.schedule_at = form.schedule_at || moment();
      this.setState(
        { form, scheduleId: schedule_msg["id"], isMMSPanelOpen },
        () => {
          console.log("State ", this.state.form);
        }
      );
      form.saving = false;
    }
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: ISchedule = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleChangeDate = (value: moment.Moment | string, id: string) => {
    // console.log('value ', value);
    // console.log('Is Before ', moment(value).isBefore(moment()));

    const form: ISchedule = { ...this.state.form, [id]: value };
    this.setState({ form, isPastDate: moment(value).isBefore(moment()) });
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const current_date = moment();
    if (this.state.form.type === SCHEDULING_DATE_RANGE) {
      if (moment(this.state.form.schedule_at).isBefore(current_date)) {
        this.setState({ isPastDate: true });
        return;
      }
    }
    let recurring_message: IRecurringScheduleMessage[] = [
      { isActive: false, dayName: "Monday" },
      { isActive: false, dayName: "Tuesday" },
      { isActive: false, dayName: "Wednesday" },
      { isActive: false, dayName: "Thursday" },
      { isActive: false, dayName: "Friday" },
      { isActive: false, dayName: "Saturday" },
      { isActive: false, dayName: "Sunday" },
    ];
    if (this.state.form.type === SCHEDULING_RECURRENT) {
      if (this.state.form.recurring_schedule_message) {
        recurring_message = Object.assign(
          [],
          this.state.form.recurring_schedule_message.map((message: any) => {
            const r_s_message = Object.assign({}, message);
            if (
              r_s_message.isActive &&
              this.state.form.recurring_schedule_timeFrom
            ) {
              // console.log('Message ', r_s_message.dayName);
              // console.log('Time From ', r_s_message['timeFrom'])
              // console.log('B4 tIME ', moment(r_s_message['timeFrom'], 'HH:mm:ss').utc().format("HH:mm:ss"))
              // const [hours, minutes, seconds] = r_s_message['timeFrom'].split(":");
              // const date = new Date();
              // date.setHours(+hours, +minutes, +seconds, 0);
              // console.log('date ', date);
              // console.log('UTC ', moment.utc(date).format('HH:mm:ss'));
              r_s_message["timeFrom"] = moment(
                this.state.form.recurring_schedule_timeFrom,
                "HH:mm:ss"
              )
                .utc()
                .format("HH:mm:ss");
              // console.log("from ", r_s_message["timeFrom"]);
            } else {
              delete r_s_message["timeFrom"];
            }
            delete r_s_message["isUpdate"];
            delete r_s_message["id"];
            return r_s_message;
          })
        );
        // console.log(
        //   "A4 this.state.form.recurring_schedule_message",
        //   recurring_message
        // );
        this.state.form.schedule_at = undefined;
      }
      recurring_message = recurring_message.map(
        tzScheduleShift.bind(null, "save")
      );
      // console.log(
      //   "A44 this.state.form.recurring_schedule_message",
      //   recurring_message
      // );
    }

    this.setState(
      {
        form: {
          ...this.state.form,
          schedule_at:
            this.state.form.type === SCHEDULING_RECURRENT
              ? undefined
              : typeof this.state.form.schedule_at !== "string"
              ? moment(this.state.form.schedule_at)
                  // .utcOffset(0, true)
                  .format()
              : this.state.form.schedule_at,
          recurring_schedule_message: recurring_message,
          saving: true,
        },
      },
      () => {
        this.state.form.saving = false;
        // this.props.createSchedule(this.state.form).then(this.props.onBack);
        if (this.props.scheduleId !== -1) {
          // Update reply
          this.props.updateSchedule(this.state.form).then(this.props.onBack);
        } else {
          // Create reply
          this.props.createSchedule(this.state.form).then(this.props.onBack);
        }
      }
    );
  };

  handleDayCheckbox = (e: any) => {
    e.stopPropagation();
    const { checked, id } = e.target;
    if (id) {
      const dayName = id.split("_")[1];
      let recurring_schedule_message: IRecurringScheduleMessage[] = [
        ...this.state.form.recurring_schedule_message,
      ];
      recurring_schedule_message = recurring_schedule_message.map(day => {
        if (day.dayName === dayName) {
          day.isActive = checked;
        }
        return day;
      });
      const form: ISchedule = {
        ...this.state.form,
        recurring_schedule_message,
      };
      this.setState({ form });
    }
  };

  getMaxTimeFrom = (timeTo: string | undefined) => {
    if (timeTo) {
      const [hours, minutes] = timeTo.split(":");
      const date = new Date(0);
      date.setHours(+hours, +minutes, 0, 0);
      return date;
    } else return this.getMaxTimeTo();
  };

  getMinTimeFrom = () => {
    const date = new Date(0);
    date.setHours(0, 0, 0, 0);
    return date;
  };

  getMaxTimeTo = () => {
    const date = new Date(0);
    date.setHours(23, 59, 0, 0);
    return date;
  };

  updateTime = (time: Date) => {
    let recurring_schedule_message: IRecurringScheduleMessage[] = [
      ...this.state.form.recurring_schedule_message,
    ];
    this.state.form.recurring_schedule_timeFrom = moment(time).format(
      "HH:mm:ss"
    );
    const form: ISchedule = { ...this.state.form, recurring_schedule_message };
    this.setState({ form });
  };

  handleRadio = (e: ChangeEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    const form = { ...this.state.form, type: +value };
    this.setState({ form });
  };

  closePanels = () => {
    if (
      this.state.isMMSPanelOpen &&
      (this.state.isMMSPanelOpen.Emoji ||
        this.state.isMMSPanelOpen.Attachment ||
        this.state.isMMSPanelOpen.TemplateKeywords ||
        this.state.isMMSPanelOpen.TemplatePanel)
    ) {
      this.setState({ isMMSPanelOpen: { initialMMSPanelsState } });
    }
  };

  toggleMMSPanel = (e: any) => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      isMMSPanelOpen: {
        [e.target.id]: !this.state.isMMSPanelOpen[e.target.id],
      },
    });
  };

  handleDeleteAttachment = () => {
    this.setState({
      form: { ...this.state.form, media_data: { initialMediaState } },
    });
  };

  handleCloseAttachment = () => {
    this.setState({
      ...this.state,
      isMMSPanelOpen: { initialMMSPanelsState },
    });
  };

  handleSetMedia = ({ file, media }: any) => {
    const media_data = {
      file: {
        name: file.name,
        size: file.size,
      },
      media: {
        media_file_mime: media.media_file_mime,
        media_link: media.media_link,
        media_minio_bucket_name: "lol",
      },
    };
    this.setState({ form: { ...this.state.form, media_data } });
  };

  validToDate = (current: moment.Moment) =>
    current.isSameOrAfter(moment().subtract(1, "day"));

  getRanges(input: string) {
    const ranges = HIGHLIGHTS.map(high_light => {
      const ranges = [];
      const inputLower = input;
      const strLower = high_light;
      let index = 0;
      while (((index = inputLower.indexOf(strLower, index)), index !== -1)) {
        ranges.push([index, index + strLower.length]);
        index += strLower.length;
      }
      return ranges;
    });
    return Array.prototype.concat.apply([], ranges);
  }

  handleScheduleMessage = (message: any) => {
    const isString = typeof message === "string";
    const messageField: any = document.getElementById("message_content");
    if (messageField) {
      const start = messageField.selectionStart || 0;
      const end = messageField.selectionEnd || 0;
      this.setState(
        prevState => ({
          ...this.state,
          form: {
            ...this.state.form,
            message_content: [
              prevState.form.message_content.slice(0, start as number),
              isString ? message : message.native,
              prevState.form.message_content.slice(end as number),
            ].join(""),
          },
        }),
        () => {
          if (messageField !== null) {
            const pos =
              end -
              (end - start) +
              (isString ? message.length : message.native.length);
            messageField.focus();
            messageField.setSelectionRange(pos, pos);
          }
        }
      );
    }
  };

  validateForm = () => {
    let isValid = true;
    if (
      !this.state.form.message_content ||
      (this.state.form.type === SCHEDULING_DATE_RANGE &&
        (!this.state.form.schedule_at || this.state.isPastDate))
    )
      isValid = false;
    if (this.state.form.type === SCHEDULING_RECURRENT) {
      const reccurent: any = this.state.form.recurring_schedule_message.filter(
        i => i.isActive
      );
      if (
        reccurent.length === 0 ||
        !this.state.form.recurring_schedule_timeFrom
      )
        isValid = false;
    }
    return !isValid;
  };

  togglePanel = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({ isPanelOpen: !prevState.isPanelOpen }));
  };

  render() {
    const template_options = {
      TEMPLATE_KEYWORDS,
      handleTemplate: this.handleScheduleMessage,
    };

    let brandColor: string = "";
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      document.documentElement.style.setProperty(
        "--calender-hover-color",
        brandColor
      );
    } else {
      document.documentElement.style.setProperty(
        "--calender-hover-color",
        "#004B87"
      );
    }
    return (
      <div className={this.props.shown ? "ScheduleEdit shown" : "ScheduleEdit"}>
        <form>
          <div>
            <label className="mandatory" htmlFor="message_content">
              Message
            </label>
            <ThemedHighlitedTextarea
              id="message_content"
              placeholder="Type Message..."
              className={"input highlight-input"}
              onChange={this.handleChange}
              value={this.state.form.message_content}
              getRanges={this.getRanges}
              rows={8}
            ></ThemedHighlitedTextarea>
            <section className="MMSPanel">
              <>
                <OutsideClickHandler onOutsideClick={this.closePanels}>
                  <div className="options">
                    <button
                      id="Attachment"
                      onClick={this.toggleMMSPanel}
                      style={brandColor ? { color: brandColor } : {}}
                      type="button"
                    >
                      <i className="icon-attach" id="Attachment"></i>
                      {this.state.form.media_data &&
                        this.state.form.media_data.media && (
                          <div className="badge" id="Attachment">
                            1
                          </div>
                        )}
                    </button>
                    <button
                      id="Emoji"
                      onClick={this.toggleMMSPanel}
                      style={
                        brandColor !== undefined ? { color: brandColor } : {}
                      }
                      type="button"
                    >
                      <i className="icon-emoji" id="Emoji"></i>
                    </button>
                    <button
                      id="TemplateKeywords"
                      onClick={this.toggleMMSPanel}
                      style={brandColor ? { color: brandColor } : {}}
                      type="button"
                    >
                      [ ]
                    </button>
                    <button
                      id="TemplatePanel"
                      style={
                        brandColor ? { color: brandColor } : { color: "#fff" }
                      }
                      type="button"
                      onClick={e => {
                        e.stopPropagation();
                        this.setState({
                          ...this.state,
                          isMMSPanelOpen: {
                            TemplatePanel: !this.state.isMMSPanelOpen[
                              "TemplatePanel"
                            ],
                          },
                        });
                      }}
                    >
                      <h1 style={{ fontSize: 25, margin: 0, height: 27 }}>T</h1>
                      {this.props.template_count > 0 && (
                        <span className="badget-span">
                          {this.props.template_count}
                        </span>
                      )}
                    </button>
                  </div>
                  <EmojiPanel
                    selectEmoji={this.handleScheduleMessage}
                    shown={
                      this.state &&
                      this.state.isMMSPanelOpen &&
                      this.state.isMMSPanelOpen.Emoji
                        ? this.state.isMMSPanelOpen.Emoji
                        : false
                    }
                    openDown={true}
                  />
                  <AttachmentPanel
                    attachment={this.state.form.media_data}
                    closePanel={this.handleCloseAttachment}
                    setAttachment={this.handleSetMedia}
                    deleteAttachment={this.handleDeleteAttachment}
                    shown={this.state.isMMSPanelOpen.Attachment}
                    openDown={true}
                  />
                  {this.state.isMMSPanelOpen.TemplateKeywords && (
                    <TemplateKeywords {...template_options} />
                  )}
                  <TemplatePanel
                    closePanel={this.handleCloseAttachment}
                    selectTemplate={(template: any) => {
                      this.setState(
                        {
                          form: {
                            ...this.state.form,
                            media_data:
                              template.media_data || initialMediaState,
                          },
                        },
                        () => {
                          this.handleScheduleMessage(template.template_content);
                        }
                      );
                    }}
                    shown={this.state.isMMSPanelOpen.TemplatePanel}
                    openDown={true}
                  />
                </OutsideClickHandler>
              </>
            </section>
          </div>
          <div className="ScheduleEdit__Scheduling">
            <label htmlFor="send_to_contacts">Scheduling</label>
            <ThemedRadio
              checked={this.state.form.type === SCHEDULING_RECURRENT}
              id="scheduling_recurrent"
              name="scheduling"
              onChange={this.handleRadio}
              text="Recurring"
              value={"" + SCHEDULING_RECURRENT}
            />
            <ThemedRadio
              checked={this.state.form.type === SCHEDULING_DATE_RANGE}
              id="scheduling_date_range"
              name="scheduling"
              onChange={this.handleRadio}
              text="Date & Time"
              value={"" + SCHEDULING_DATE_RANGE}
            />
          </div>
          {this.state.form.type === SCHEDULING_RECURRENT && (
            <div>
              <table className="ScheduleEdit__SchedulingTable">
                <thead>
                  <tr>
                    <th className="th_33">Day(s) of Week</th>
                    <th className="th_60">Time of Day</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.form.recurring_schedule_message.map(
                    (day: IRecurringScheduleMessage, key) => (
                      <tr key={key}>
                        <td className="td_33">
                          <ThemedCheckbox
                            id={`Recurring_${day.dayName}`}
                            text={`${day.dayName}`}
                            onChange={this.handleDayCheckbox}
                            checked={day.isActive}
                          />
                        </td>
                        <td className="td_60">
                          {day.id === 0 && (
                            <TimePickerHHMMSS
                              disabled={
                                !this.state.form.recurring_schedule_message.filter(
                                  day => day.isActive
                                ).length
                              }
                              fieldType={"from"}
                              id={`Recurring_ActiveFrom`}
                              maxTime={this.getMaxTimeFrom(
                                this.state.form.recurring_schedule_timeFrom
                              )}
                              minTime={this.getMinTimeFrom()}
                              onTimeChange={(time, err) =>
                                this.updateTime(time)
                              }
                              value={
                                this.state.form.recurring_schedule_timeFrom
                              }
                              isUpdate={day.isUpdate}
                            />
                          )}
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
          {this.state.form.type === SCHEDULING_DATE_RANGE && (
            <div className="columns">
              <div>
                <label htmlFor="schedule_at">Active From</label>
                <ErrorMessage
                  errorMessage="Past Date & Time is not Allowed."
                  showMessage={this.state.isPastDate}
                />
                <Datetime
                  className="icon-date"
                  dateFormat="MM/DD/YYYY"
                  inputProps={{
                    id: "schedule_at",
                    readOnly: true,
                    placeholder: "Select date & time",
                  }}
                  onChange={moment =>
                    this.handleChangeDate(moment, "schedule_at")
                  }
                  isValidDate={this.validToDate}
                  timeFormat="hh:mm:ss A"
                  value={
                    this.state.form.schedule_at &&
                    moment(this.state.form.schedule_at).format(
                      "MM/DD/YYYY hh:mm:ss A"
                    )
                  }
                />
              </div>
            </div>
          )}
        </form>
        <div
          onClick={this.togglePanel}
          className={classNames("panel", { open: this.state.isPanelOpen })}
        >
          <div>
            <button
              type="button"
              onClick={() => {
                this.props
                  .deleteSchedule(this.state.scheduleId)
                  .then(this.props.onBack);
              }}
            >
              Delete Schedule
            </button>
            <ThemedButton type="button" onClick={this.togglePanel}>
              Close
            </ThemedButton>
          </div>
        </div>
        <footer>
          <ThemedButton
            type="submit"
            onClick={this.handleSubmit}
            disabled={this.validateForm()}
          >
            {this.props.scheduleId !== -1 ? "Update" : "Add"}
          </ThemedButton>
          {this.props.scheduleId !== -1 && (
            <button
              className="more"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              ···
            </button>
          )}
        </footer>
      </div>
    );
  }
}

ScheduleEdit.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleEdit as any);
