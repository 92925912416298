import React, { Component, KeyboardEvent } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import propTypes from "prop-types";
import PrivateRoute from "../common/PrivateRoute";
import routes from "~/routes";
import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";
import { Location } from "history";
import { isLocalStorageAvailable, ThemeContext } from "~/utils";
import "./App.scss";
import ModalRoot from "../modals";
import Websocket from "../Websocket/Websocket";
import { AxiosPromise } from "axios";
import { withRouter } from "react-router";
import { History } from "history";
import Sound from "react-sound";
import DingSound from "../../assets/beep.mp3";
import { FaviconAndTitle } from "../FaviconAndTitle/FaviconAndTitle";
import { Branding } from "~/branding";

namespace App {
  interface StateProps {
    history: History;
    domainWL: any;
    location: Location;
    isAuthenticated: boolean;
  }
  interface DispatchProps {
    closeModal: () => void;
    verifyToken: (token: string) => AxiosPromise;
  }
  interface OwnProps {
    history: History;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  verifyToken: usersActions.verifyToken,
};

const mapStateToProps = (state: any) => ({
  location: state.location,
  isAuthenticated: state.users.isAuthenticated,
  userSettings: (state.users.authenticatedUser || {}).settings || {},
  domainWL:
    (state.users.authenticatedUser &&
      state.users.authenticatedUser.company &&
      state.users.authenticatedUser.company.whitelabeling) ||
    {},
  wlColor:
    (state.users.authenticatedUser &&
      (
        (state.users.authenticatedUser.company &&
          state.users.authenticatedUser.company.whitelabeling) ||
        {}
      ).primary_css_color) ||
    "#004B87",
});

@(withRouter as any)
@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
export default class App extends Component<App.Props> {
  static propTypes = {
    location: propTypes.object,
  };

  constructor(props) {
    super(props);

    this.state = {
      notification_visual: true,
      notification_audible: true,
      theme: props.wlColor,
      playing: Sound.status.STOPPED,
    };
  }

  componentDidMount() {
    const isDebug = Object.fromEntries(new URLSearchParams(location.search));
    if (isDebug.debug !== "true") {
      window.console.log = () => {};
      window.console.group = () => {};
      window.console.groupEnd = () => {};
      window.console.groupCollapsed = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};
    }

    // Take token from storage if exist
    if (isLocalStorageAvailable()) {
      const token = localStorage.getItem("user");
      if (token) {
        // Send token to server to verify it
        this.props
          .verifyToken(token)
          .then(res => {
            const theme =
              (res.data && res.data.settings) ||
              (res.data.company && res.data.company.whitelabeling) ||
              {};
            this.setState({
              ...theme,
              theme:
                theme.theme_color ||
                (theme.theme && theme.theme.color) ||
                (
                  res.data.whitelabeling ||
                  (res.data.company && res.data.company.whitelabeling) ||
                  {}
                ).primary_css_color ||
                "#004B87",
            });
          })
          .catch(err => {
            if (
              err.response &&
              err.response.status &&
              (err.response.status === 401 || err.response.status === 403)
            ) {
              localStorage.removeItem("user");
              this.props.history.push("/login");
            }
          });
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      !this.props.isAuthenticated &&
      Object.values(prevProps.domainWL).length === 0 &&
      Object.values(this.props.domainWL).length > 0
    ) {
      this.setState({
        theme: this.props.domainWL.primary_css_color || "#004B87",
      });
    }
    if (!prevProps.isAuthenticated && this.props.isAuthenticated) {
      this.setState({
        ...this.props.userSettings,
        theme: this.props.userSettings.theme_color || this.props.wlColor,
      });
    }

    if (prevProps.isAuthenticated && !this.props.isAuthenticated) {
      this.setState({
        notification_visual: true,
        notification_audible: true,
        theme: this.props.wlColor,
      });
    }
  }

  handleCloseEsc = (e: KeyboardEvent) => {
    if (e.keyCode === 27) {
      e.stopPropagation();
      this.props.closeModal();
    }
  };

  handleChangeTheme = theme => {
    const theme_color =
      theme.theme.value === "default" ? this.props.wlColor : theme.theme_color;

    this.setState({ ...theme, theme: theme_color });
  };

  handleStartPlaying = () => {
    if (this.state.notification_audible) {
      this.setState({ playing: Sound.status.STOPPED }, () =>
        this.setState({ playing: Sound.status.PLAYING })
      );
    }
  };

  handleFinishPlaying = () => this.setState({ playing: Sound.status.STOPPED });

  render() {
    return (
      <main className="App" tabIndex={0} onKeyDown={this.handleCloseEsc}>
        <FaviconAndTitle
          wl={this.props.domainWL}
          defaultTitle={`${Branding.APP_NAME}`}
        />
        <ThemeContext.Provider value={this.state}>
          <Websocket onMessageArrived={this.handleStartPlaying}>
            <Switch location={this.props.location}>
              {routes.map((route, i) =>
                route.canAccess ? (
                  <Route key={i} {...route} />
                ) : (
                  <PrivateRoute
                    key={i}
                    {...route}
                    auth={this.props.isAuthenticated}
                  />
                )
              )}
            </Switch>
            <ModalRoot onChangeTheme={this.handleChangeTheme} />
          </Websocket>
        </ThemeContext.Provider>
        <Sound
          autoLoad={true}
          url={DingSound}
          playStatus={this.state.playing}
          onFinishedPlaying={this.handleFinishPlaying}
          volume={75}
        />
      </main>
    );
  }
}
