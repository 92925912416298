import moment from "moment";
import React, { PureComponent, BaseSyntheticEvent } from "react";
import { connect } from "react-redux";
import {
  contactsActions,
  IExternalContact,
  IInternalContact,
} from "~/store/contacts";
import { conversationActions, IConversation } from "~/store/conversations";
import { IList } from "~/store/lists";
import {
  fpn,
  formatNumberandContacts,
  isValidPhoneNumber,
  normalizePhoneNumber,
} from "~/utils";
import "./ConversationsList.scss";
import classNames from "classnames";
import { modalActions } from "~/store/modal";
import Loader from "../common/Loader/Loader";
import Moment from "react-moment";

type SRContact = IExternalContact | IInternalContact;

interface SearchItem {
  id: string;
  title: string;
  type: string;
  status?: string;
  hint?: string;
}
interface StateProps {
  contactList: SRContact[];
  conversations: IConversation[];
  lists: IList[];
  user: any;

  isFetchingSearchConversations: boolean;
  searchConversations: [];
  authenticatedUser: any;
  statuses: any;
  companyId: number;
  hasSearchMoreConversations: boolean;
}
interface DispatchProps {
  createContact: (form: IExternalContact) => Promise<any>;
  createConversation: (internal: string[], external: string[]) => void;
  openModal: (modalId: string, modalProps: object) => void;
  handleSetSearch: (search: string) => void;
  openConversation: (conversationId: string) => void;

  getSearchConversationsListLazy: (name: string, scroll: boolean) => void;
}
interface OwnProps {
  activeConversation: string;
  search: string;
  isBlastOpen: boolean;
  clicked: boolean;
}

interface State {
  open: boolean;
}

type Props = StateProps & DispatchProps & OwnProps & State;

const mapStateToProps = (state: any, _ownProps: OwnProps): StateProps => ({
  contactList: state.contacts.data.filter(
    (contact: IExternalContact) =>
      (contact.hasOwnProperty("phones") && contact.registration_status === 1) ||
      (contact.in_contacts && !contact.is_deleted)
  ),
  conversations: state.conversations.data,
  lists: state.lists.data || [],
  user: state.users.authenticatedUser,

  searchConversations: state.conversations.searchConversations,
  isFetchingSearchConversations:
    state.conversations.isFetchingSearchConversations,
  authenticatedUser: state.users.authenticatedUser,
  statuses: state.statuses.statuses,
  companyId: state.users.authenticatedUser.company.id,
  hasSearchMoreConversations: state.conversations.hasSearchMoreConversations,
});

const mapDispatchToProps: DispatchProps = {
  createContact: contactsActions.createContact,
  createConversation: conversationActions.createConversation,
  openConversation: conversationActions.openConversation,
  openModal: modalActions.openModal,

  getSearchConversationsListLazy:
    conversationActions.getSearchConversationsListLazy,
};

class SearchResultItems extends PureComponent<Props> {
  inital_search_string: string = "";

  componentWillMount() {
    this.inital_search_string = this.props.search;
    this.props.getSearchConversationsListLazy(
      this.props.search.toLowerCase(),
      false
    );
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    console.log("this.props.clicked ", this.props.clicked);
    console.log("Initial ", this.inital_search_string.trim().toLowerCase());
    console.log("final ", this.props.search.trim().toLowerCase());
    if (
      this.props.clicked &&
      this.inital_search_string.trim().toLowerCase() !==
        this.props.search.trim().toLowerCase()
    ) {
      this.props.getSearchConversationsListLazy(
        this.props.search.toLowerCase(),
        false
      );
      this.inital_search_string = this.props.search;
    }
  }

  filterContacts = (contacts: SRContact[]): SRContact[] => {
    let str = this.props.search.toLowerCase();
    if (isValidPhoneNumber(str)) {
      str = normalizePhoneNumber(str);
    }
    return contacts.filter((contact: SRContact) => {
      return (
        [contact.first_name, contact.last_name]
          .join(" ")
          .toLocaleLowerCase()
          .indexOf(str) >= 0 ||
        (contact.hasOwnProperty("phones")
          ? contact.phones.find(
              (phone: any) => phone.id === contact.default_phone_id
            ).phone_number
          : contact.phone_number
        ).indexOf(str) !== -1
      );
    });
  };

  filterConversations = (conversations: IConversation[]): IConversation[] => {
    let str = this.props.search.toLowerCase();
    if (isValidPhoneNumber(str)) {
      str = normalizePhoneNumber(str);
    }
    return conversations.filter(
      (conversation: IConversation) =>
        ((this.props.isBlastOpen && conversation.type === "blast") ||
          conversation.type === "conversation") &&
        (conversation.name.toLocaleLowerCase().indexOf(str) !== -1 ||
          conversation.external
            .map(ec => ec && ec.phone_number)
            .join(".")
            .indexOf(str) !== -1 ||
          conversation.internal
            .filter(ic => ic.id !== this.props.user.activePhone)
            .map(ic => ic && ic.phone_number)
            .join(".")
            .indexOf(str) !== -1)
    );
  };

  filterLists = (lists: IList[]): IList[] => {
    let str = this.props.search.toLowerCase();
    if (isValidPhoneNumber(str)) {
      str = normalizePhoneNumber(str);
    }
    return lists.filter(
      (list: IList) =>
        list.name.toLocaleLowerCase().indexOf(str) !== -1 ||
        list.external
          .map(ec => ec && ec.phone_number)
          .join(".")
          .indexOf(str) !== -1
    );
  };

  formatSearchItems = (items: any): SearchItem[] =>
    items.map((item: any) => ({
      id: item.id,
      type: ["conversation", "blast"].includes(item.type)
        ? item.type
        : item.hasOwnProperty("compliance")
        ? "blastlist"
        : item.is_phone
        ? "phone"
        : item.hasOwnProperty("email")
        ? "internal"
        : "external",
      title:
        item.hasOwnProperty("display_as") &&
        item.hasOwnProperty("display_as").length > 0
          ? item.display_as
          : item.hasOwnProperty("first_name")
          ? [item.first_name, item.last_name].join(" ")
          : item.is_phone
          ? item.phone_number
          : item.name,
      hint:
        item.is_phone || item.hasOwnProperty("in_contacts")
          ? fpn(item.phone_number, this.props.user.show_censor_contact)
          : item.last_message
          ? moment(item.last_message).fromNow()
          : "",
    }));

  handleStartConversation = (id: string) => {
    const contact = this.props.contactList.find(contact => contact.id === id);
    const members: string[] = [];
    if (contact) {
      if (contact.is_phone === true) {
        members.push(contact.id);
      } else if (contact.default_phone_id) {
        members.push(contact.default_phone_id);
      }
      const external = contact.hasOwnProperty("display_as") ? [] : [contact.id];
      this.props.createConversation(members, external);
    }
  };

  handleClick = item => {
    this.props.handleSetSearch("");
    if (["conversation", "blast"].includes(item.type)) {
      this.props.openConversation(item.id);
    } else {
      this.handleStartConversation(item.id);
    }
  };

  removeSimilarElements = (
    contacts: SRContact[],
    conversations: IConversation[],
    lists: IList[]
  ): any => {
    const contactsInConversations = conversations
      .map(conversation => [
        ...conversation.external.map(external => external.id),
        ...conversation.internal.map(internal => internal.id),
      ])
      .reduce((acc, cur) => {
        cur.forEach(i => !acc.includes(i) && acc.push(i));
        return acc;
      }, []);

    return [
      ...contacts.filter(contact => {
        const id = contact.hasOwnProperty("default_phone_id")
          ? (contact as IInternalContact).phones.find(
              (phone: any) => phone.id === contact.default_phone_id
            ).id
          : contact.id;
        return !contactsInConversations.includes(id);
      }),
      ...conversations,
      ...lists,
    ];
  };

  renderConversationIcon = (id: string) => {
    const conversation = this.props.conversations.find(c => c.id === id);
    if (conversation) {
      if (conversation.type === "blast") return "icon-broadcast";
      if (
        conversation.external.length === 0 &&
        conversation.internal.length === 2
      )
        return "icon-circle";
      if (
        conversation.external.length === 1 &&
        conversation.internal.length === 1
      )
        return "icon-profile";
      if (
        conversation.external.length === 0 &&
        conversation.internal.length > 2
      )
        return "icon-group";
      if (
        conversation.external.length > 1 &&
        conversation.internal.length === 1
      )
        return "icon-group";
      return "icon-profile";
    }
    return;
  };

  renderItemIcon = (conversation: any) => {
    if (conversation.type === "blast") return "icon-broadcast";
    // 1-to-1 chat with external
    if (
      conversation.external.length === 1 &&
      conversation.internal.length === 1
    )
      return "icon-profile";
    // group chat with internals
    if (conversation.external.length === 0 && conversation.internal.length > 2)
      return "icon-group";
    // group chat with externals
    if (conversation.external.length > 1 && conversation.internal.length === 1)
      return "icon-group";
    // 1-to-1 chat with internal
    if (
      conversation.external.length === 0 &&
      conversation.internal.length === 2
    ) {
      const { activePhone } = this.props.authenticatedUser;
      const companyId = this.props.companyId;
      if (activePhone) {
        const interlocutor = conversation.internal.find(
          (phone: any) => phone.id !== activePhone
        );
        if (interlocutor && interlocutor.id) {
          if (interlocutor.company && interlocutor.company.id !== companyId) {
            return "icon-profile";
          }
          if (interlocutor.shared) {
            return "icon-phone";
          } else {
            return this.props.statuses[interlocutor.id]
              ? `icon-circle icon-${this.props.statuses[interlocutor.id].class}`
              : "icon-circle";
          }
        }
      }
      return "icon-circle";
    }
    return "icon-profile";
  };

  handleAddBlast = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.props.openModal("CONTACTS_MODAL", {
      activeEdit: true,
      openList: true,
    });
  };

  prepareLabel = (conversation: IConversation) => {
    if (conversation.type === "blast") {
      return conversation.name;
    }
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      conversation.name
        .trim()
        .split(" ")
        .join("")
    );

    let name = conversation.name;

    if (isPhoneName) {
      name = fpn(
        conversation.name,
        this.props.authenticatedUser.show_censor_contact
      );
    }

    return conversation.external.length === 1 &&
      conversation.external[0].in_contacts === false
      ? fpn(
          conversation.external[0].phone_number,
          this.props.authenticatedUser.show_censor_contact
        )
      : name;
  };

  handleScroll = (event: any) => {
    if (event && this.props.hasSearchMoreConversations) {
      const target = event.target;
      // console.log(
      //   "scrollHeight => ",
      //   target.scrollHeight,
      //   " scrollTop =>",
      //   target.scrollTop,
      //   " clientHeight =>",
      //   target.clientHeight
      // );
      const diff = Math.trunc(target.scrollHeight - target.scrollTop);
      // console.log("diiff => ", diff);
      const bottom = diff <= Math.trunc(target.clientHeight);
      // console.log("bottom => ", bottom);
      if (bottom && !this.props.isFetchingSearchConversations) {
        this.props.getSearchConversationsListLazy(
          this.props.search.toLowerCase(),
          true
        );
      }
    }
  };

  renderTime = (conversation: any) => {
    return conversation.last_message ? (
      <Moment fromNow>{conversation.last_message}</Moment>
    ) : null;
  };

  render() {
    // const items = this.formatSearchItems(
    //   this.removeSimilarElements(
    //     this.filterContacts(this.props.contactList),
    //     this.filterConversations(this.props.conversations),
    //     this.filterLists(this.props.lists)
    //   )
    // );
    // const list_item = this.filterLists(this.props.lists);
    const items = this.props.searchConversations;
    return items.length ? (
      <div className="con-panel">
        <ul
          className={classNames("ConversationsList", {
            open: this.props.isBlastOpen,
          })}
          onScroll={this.handleScroll}
        >
          {items
            .filter(
              (item: SearchItem) =>
                item.type !== "blast" && item.type !== "blastlist"
            )
            .map((item: SearchItem, key: number) => (
              <li key={key} onClick={() => this.handleClick(item)}>
                <span> {this.renderTime(item)}</span>
                <i className={this.renderItemIcon(item)}></i>
                {this.prepareLabel(item)}
              </li>
            ))}
          {this.props.isFetchingSearchConversations && <Loader />}
        </ul>

        <div
          className={classNames("cus-collapse", {
            open: this.props.isBlastOpen,
          })}
        >
          <span>List</span>
          <button
            type="button"
            className="is-primary"
            onClick={this.handleAddBlast}
          >
            <i className="icon-plus"></i>
          </button>
        </div>

        <ul className="ConversationsList">
          {items
            .filter((item: any) => item.type === "blast")
            .map((item: any, key: number) => (
              <li key={key} onClick={() => this.handleClick(item)}>
                <span> {this.renderTime(item)}</span>
                <i className={this.renderItemIcon(item)}></i>
                {this.prepareLabel(item)}
              </li>
            ))}
        </ul>
      </div>
    ) : this.props.isFetchingSearchConversations ? (
      <Loader />
    ) : (
      <div className="ConversationList search">
        {isValidPhoneNumber(this.props.search) ? (
          <span>There are no matches with that search filter.</span>
        ) : (
          <span>
            No such phone or name in the contacts.
            <br />
            Use valid phone number format to start
            <br />a new conversation, e.g. 2001234567
          </span>
        )}
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultItems as any);
