import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { conversationActions, IConversation } from "~/store/conversations";
import { IPrebuiltReply } from "~/store/replies_prebuilt";
import {
  HIGHLIGHTS,
  ITemplate,
  HIGHLIGHT_PROFILE_DATA,
  HIGHLIGHT_CONTACT_DATA,
  TEMPLATE_KEYWORDS,
} from "~/store/templates";
import { escapeRegExp, ThemeContext } from "~/utils";
import AttachmentPanel from "../AttachmentPanel/AttachmentPanel";
import EmojiPanel from "../EmojiPanel/EmojiPanel";
import PrebuiltRepliesPanel from "../PrebuiltRepliesPanel/PrebuiltRepliesPanel";
import TemplatePanel from "../TemplatePanel/TemplatePanel";
import TextareaAutosize from "react-textarea-autosize";
import TemplateKeywords from "../TemplatePanel/TemplateKeywords";

interface DispatchProps {
  sendMessage: (
    conversationId: string,
    message: string,
    type: string,
    media: any
  ) => void;
}

interface StateProps {
  activeChat: IConversation;
  authenticatedUser: any;
  isConversationsFetching: boolean;
  prebuiltReplies: IPrebuiltReply[];
  templates: ITemplate[];
}

interface OwnProps {}

type Props = DispatchProps & StateProps & OwnProps;

const mapsDispatchToProps: DispatchProps = {
  sendMessage: conversationActions.sendMessage,
};

const mapStateToProps = (state: any): StateProps => ({
  activeChat: state.conversations.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  isConversationsFetching: state.conversations.isFetchingConversation,
  prebuiltReplies: state.prebuiltReplies.data,
  templates: state.templates.data,
});

const initialMediaState: any = {
  error: "",
  file: null,
  media: null,
};

const initialPanelsState: {
  [x: string]: boolean;
} = {
  Attachment: false,
  Emoji: false,
  Options: false,
  PrebuiltReplies: false,
  TemplatePanel: false,
  TemplateKeywords: false,
};
const highlight_contact_data: any = {
  data: {},
};
const highlight_profile_data: any = {
  data: {},
};

export default connect(
  mapStateToProps,
  mapsDispatchToProps
)((props: Props) => {
  const { authenticatedUser: user, templates } = props;
  const theme = useContext(ThemeContext);
  const [caretPosition, setCaretPosition] = useState(0);
  const [media, setMedia] = useState(initialMediaState);
  const [message, setMessage] = useState("");
  const [isPanelOpen, setPanelOpen] = useState(initialPanelsState);
  const messageRef = useRef(null);

  function handleEmoji(emoji: any) {
    if (messageRef && messageRef.current) {
      const start = messageRef.current.selectionStart;
      const end = messageRef.current.selectionEnd;
      setMessage(
        [
          message.slice(0, start as number),
          emoji.native,
          message.slice(end as number),
        ].join("")
      );
      // Set caret position
      const pos = end - (end - start) + emoji.native.length;
      messageRef.current.focus();
      messageRef.current.setSelectionRange(pos, pos);
      setCaretPosition(pos);
      setPanelOpen(initialPanelsState);
    }
  }

  function handleKeywords(keywords: string) {
    if (messageRef && messageRef.current) {
      const start = messageRef.current.selectionStart;
      const end = messageRef.current.selectionEnd;
      setMessage(
        [
          message.slice(0, start as number),
          keywords,
          message.slice(end as number),
        ].join("")
      );
      // Set caret position
      const pos = end - (end - start) + keywords.length;
      messageRef.current.focus();
      messageRef.current.setSelectionRange(pos, pos);
      setCaretPosition(pos);
      // setPanelOpen(initialPanelsState);
    }
  }

  function closePanels(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setPanelOpen(initialPanelsState);
  }

  function closeOptionsPanel(e: React.FocusEvent<HTMLInputElement>) {
    e.stopPropagation();
    setPanelOpen({ ...isPanelOpen, Options: false });
  }

  function togglePanel(
    toggle: string,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    console.log("toggle ", toggle);
    e.stopPropagation();
    setPanelOpen({
      ...initialPanelsState,
      [toggle]: !isPanelOpen[toggle],
    });
  }

  function handleDeleteAttachment() {
    setMedia(initialMediaState);
  }

  function handleCloseAttachment() {
    setPanelOpen(initialPanelsState);
  }

  function handlePrebuiltReplies(reply: string) {
    if (messageRef.current) {
      const start = messageRef.current.selectionStart;
      const end = messageRef.current.selectionEnd;
      setMessage(
        [
          message.slice(0, start as number),
          reply,
          message.slice(end as number),
        ].join("")
      );
      // Set caret position
      const pos = end - (end - start) + reply.length;
      messageRef.current.focus();
      messageRef.current.setSelectionRange(pos, pos);
      setCaretPosition(pos);
      setPanelOpen(initialPanelsState);
    }
  }

  const getHighlights = (input: string) => {
    const first_index = input.indexOf("[");
    const last_index = input.lastIndexOf("]");
    if (first_index > -1 && last_index > -1 && first_index < last_index) {
      const highligths = [];
      const input_substring = input.substring(first_index, last_index + 1);
      for (const high_light of HIGHLIGHTS) {
        if (input_substring.indexOf(high_light) > -1) {
          highligths.push(high_light);
        }
      }
      return highligths;
    }
    return undefined;
  };

  function handleTemplate(template: any) {
    if (messageRef.current) {
      const template_content = updateTemplateContent(template.template_content);
      const start = messageRef.current.selectionStart;
      const end = messageRef.current.selectionEnd;
      // const message_highligths = getHighlights(template_content);
      // if (message_highligths && message_highligths.length > 0) {
      //   const date = moment();
      //   const data = {
      //     ...highlight_contact_data.data,
      //     ...highlight_profile_data.data,
      //     "[Today's Date]": date.format("MMMM, DD YYYY"),
      //     "[Tomorrow's Date]": date.add(1, "days").format("MMMM, DD YYYY"),
      //   };
      //   message_highligths.forEach((message_highligth: any) => {
      //     if (data.hasOwnProperty(message_highligth)) {
      //       template_content = template_content.replaceAll(
      //         message_highligth,
      //         data[message_highligth] ? data[message_highligth] : ""
      //       );
      //     }
      //   });
      // }
      if (template.media_data) {
        setMedia(template.media_data);
      } else {
        setMedia(initialMediaState);
      }
      setMessage(
        [
          message.slice(0, start as number),
          template_content,
          message.slice(end as number),
        ].join("")
      );
      // Set caret position
      const pos = end - (end - start) + template.length;
      messageRef.current.focus();
      messageRef.current.setSelectionRange(pos, pos);
      setCaretPosition(pos);
      setPanelOpen(initialPanelsState);
    }
  }

  const updateTemplateContent = (template_content: any) => {
    const message_highligths = getHighlights(template_content);
    if (message_highligths && message_highligths.length > 0) {
      const date = moment();
      const data = {
        ...highlight_contact_data.data,
        ...highlight_profile_data.data,
        "[Today's Date]": date.format("MMMM DD, YYYY"),
        "[Tomorrow's Date]": date.add(1, "days").format("MMMM DD, YYYY"),
      };
      message_highligths.forEach((message_highligth: any) => {
        if (data.hasOwnProperty(message_highligth)) {
          template_content = template_content.replaceAll(
            message_highligth,
            data[message_highligth] ? data[message_highligth] : ""
          );
        }
      });
    }

    return template_content;
  };

  function handleMessageInput(e: React.ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    setMessage(e.target.value);
  }

  function handleMessageSend() {
    if (props.activeChat && (message.length || media.file)) {
      const message_content = updateTemplateContent(message.trim());
      props.sendMessage(
        props.activeChat.id,
        message_content,
        props.activeChat.type,
        media.media
      );
      setMedia(initialMediaState);
      setMessage("");
      setCaretPosition(0);
      setPanelOpen(initialPanelsState);
    }
  }

  function handleMessageSendWithEnter(e: React.KeyboardEvent) {
    e.stopPropagation();
    if (e.key === "Enter" && !e.shiftKey) {
      if (message.trim().length > 0) {
        handleMessageSend();
      }
      e.preventDefault();
    }
  }

  // Calculate brand color
  let brandColor;
  if (theme.theme || (user && user.company && user.company.whitelabeling)) {
    brandColor =
      theme.theme === "default"
        ? user.company.whitelabeling.primary_css_color
        : theme.theme;
  }

  // Get interlocutor
  let interlocutor;
  if (
    props.activeChat &&
    props.activeChat.internal &&
    props.activeChat.internal.length === 2
  ) {
    const mySharedPhones = props.authenticatedUser.phones.map(
      (phone: any) => phone.phone_number
    );
    interlocutor = props.activeChat.internal.filter(
      (internalNumber: any) =>
        !mySharedPhones.includes(internalNumber.phone_number)
    )[0];
  }

  const filteredReplies = [];
  if (
    props.activeChat &&
    props.activeChat.messages &&
    props.authenticatedUser
  ) {
    const incomingMessages = props.activeChat.messages.filter(
      message =>
        !(
          (message.sender.internal &&
            message.sender_id === props.authenticatedUser.id) ||
          message.message_type === "autoreply"
        )
    );
    if (incomingMessages.length && props.prebuiltReplies !== null) {
      const lastMessage = incomingMessages[incomingMessages.length - 1];
      for (let i = 0, rl = props.prebuiltReplies.length; i < rl; i++) {
        const reply = props.prebuiltReplies[i];
        for (let j = 0, kl = reply.keywords.length; j < kl; j++) {
          if (
            new RegExp(
              "(?:^|[^\\w\\n])" +
                escapeRegExp(reply.keywords[j]) +
                "(?=$|[\\W])",
              "gi"
            ).test(lastMessage.text_content)
          ) {
            filteredReplies.push(props.prebuiltReplies[i]);
            break;
          }
        }
      }
    }
  }

  useEffect(() => {
    console.log("*********** START SET PROFILE DATA **************");
    highlight_profile_data.data = {};
    const currentPhone: any = _.find(
      user.phones,
      (p: any) => p.id === user.activePhone
    );
    try {
      HIGHLIGHT_PROFILE_DATA.forEach(highlight => {
        switch (highlight) {
          case "[My First Name]": {
            highlight_profile_data.data[highlight] = user.first_name;
            break;
          }
          case "[My Last Name]": {
            highlight_profile_data.data[highlight] = user.last_name;
            break;
          }
          case "[My Phone Number]": {
            highlight_profile_data.data[highlight] = currentPhone.phone_number;
            break;
          }
          case "[My Company]": {
            highlight_profile_data.data[highlight] = user.company.name;
            break;
          }
          case "[My Job Title]": {
            highlight_profile_data.data[highlight] = user.title;
            break;
          }
          case "[My Email]": {
            highlight_profile_data.data[highlight] = user.email;
            break;
          }
        }
      });
    } catch (err) {
      console.log("Error ", err);
    }
    console.log("highlight_profile_data.data ", highlight_profile_data.data);
    console.log("*********** END SET PROFILE DATA **************");
  }, [props.authenticatedUser.activePhone]);

  // Auto focus message input on active chat change
  useEffect(() => {
    setMessage("");
    setMedia(initialMediaState);
    messageRef && messageRef.current && messageRef.current.focus();
    const activeChat = props.activeChat;
    highlight_contact_data.data = {};
    if (activeChat.type !== "blast") {
      try {
        console.log("********** START SET CONTACT DATA *************");
        console.log("activeChat ", activeChat);
        let to_contact: any;
        if (activeChat.external.length > 0) {
          to_contact = activeChat.external[0];
        } else if (activeChat.internal.length > 0) {
          to_contact = _.find(
            activeChat.internal,
            (p: any) => p.id !== user.activePhone
          );
        }
        const [first_name, last_name] = activeChat.name
          ? activeChat.name.split(" ")
          : to_contact.hasOwnProperty("title")
          ? to_contact.title.split(" ")
          : [to_contact.first_name, to_contact.last_name];
        HIGHLIGHT_CONTACT_DATA.forEach(highlight => {
          switch (highlight) {
            case "[First Name]": {
              highlight_contact_data.data[highlight] =
                first_name !== to_contact.phone_number ? first_name : "";
              break;
            }
            case "[Last Name]": {
              highlight_contact_data.data[highlight] = last_name;
              break;
            }
            case "[Phone Number]": {
              highlight_contact_data.data[highlight] = to_contact.phone_number;
              break;
            }
            case "[Company]": {
              highlight_contact_data.data[highlight] =
                typeof to_contact.company === "string"
                  ? to_contact.company
                  : to_contact.company.name;
              break;
            }
            case "[Job Title]": {
              highlight_contact_data.data[highlight] = to_contact.job_title;
              break;
            }
            case "[Email]": {
              highlight_contact_data.data[highlight] = to_contact.email;
              break;
            }
            case "[Address Line 1]": {
              highlight_contact_data.data[highlight] =
                to_contact.address_line_1;
              break;
            }
            case "[Address Line 2]": {
              highlight_contact_data.data[highlight] =
                to_contact.address_line_2;
              break;
            }
            case "[City]": {
              highlight_contact_data.data[highlight] = to_contact.city;
              break;
            }
            case "[State]": {
              highlight_contact_data.data[highlight] = to_contact.state;
              break;
            }
            case "[Zip Code]": {
              highlight_contact_data.data[highlight] = to_contact.zipcode;
              break;
            }
            case "[Custom Field 1]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_1;
              break;
            }
            case "[Custom Field 2]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_2;
              break;
            }
            case "[Custom Field 3]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_3;
              break;
            }
          }
        });
      } catch (err) {
        console.log("Error ", err);
      }
      console.log(highlight_contact_data.data);
      console.log("********** END SET CONTACT DATA *************");
    }
  }, [props.activeChat.id]);

  // Update caret position
  useEffect(() => {
    if (messageRef && messageRef.current && caretPosition !== 0) {
      messageRef.current.setSelectionRange(caretPosition, caretPosition);
    }
  }, [caretPosition]);

  useEffect(() => {
    const activeChat = props.activeChat;
    if (activeChat.type !== "blast" && activeChat.updated_external) {
      try {
        console.log("********** START SET CONTACT DATA *************");
        console.log("activeChat ", activeChat);
        const to_contact: any = activeChat.updated_external;
        const [first_name, last_name] = activeChat.name
          ? activeChat.name.split(" ")
          : to_contact.hasOwnProperty("title")
          ? to_contact.title.split(" ")
          : [to_contact.first_name, to_contact.last_name];
        HIGHLIGHT_CONTACT_DATA.forEach(highlight => {
          switch (highlight) {
            case "[First Name]": {
              highlight_contact_data.data[highlight] =
                first_name !== to_contact.phone_number ? first_name : "";
              break;
            }
            case "[Last Name]": {
              highlight_contact_data.data[highlight] = last_name;
              break;
            }
            case "[Phone Number]": {
              highlight_contact_data.data[highlight] = to_contact.phone_number;
              break;
            }
            case "[Company]": {
              highlight_contact_data.data[highlight] =
                typeof to_contact.company === "string"
                  ? to_contact.company
                  : to_contact.company.name;
              break;
            }
            case "[Job Title]": {
              highlight_contact_data.data[highlight] = to_contact.job_title;
              break;
            }
            case "[Email]": {
              highlight_contact_data.data[highlight] = to_contact.email;
              break;
            }
            case "[Address Line 1]": {
              highlight_contact_data.data[highlight] =
                to_contact.address_line_1;
              break;
            }
            case "[Address Line 2]": {
              highlight_contact_data.data[highlight] =
                to_contact.address_line_2;
              break;
            }
            case "[City]": {
              highlight_contact_data.data[highlight] = to_contact.city;
              break;
            }
            case "[State]": {
              highlight_contact_data.data[highlight] = to_contact.state;
              break;
            }
            case "[Zip Code]": {
              highlight_contact_data.data[highlight] = to_contact.zipcode;
              break;
            }
            case "[Custom Field 1]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_1;
              break;
            }
            case "[Custom Field 2]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_2;
              break;
            }
            case "[Custom Field 3]": {
              highlight_contact_data.data[highlight] =
                to_contact.custom_field_3;
              break;
            }
          }
        });
      } catch (err) {
        console.log("Error ", err);
      }
      console.log(highlight_contact_data.data);
      console.log("********** END SET CONTACT DATA *************");
    }
  }, [props.activeChat.isUpdated]);

  const template_options = {
    TEMPLATE_KEYWORDS,
    handleTemplate: handleKeywords,
    open_up: true,
  };

  const isDisable = () => {
    if (
      props &&
      props.activeChat &&
      props.activeChat.messages &&
      props.activeChat.messages.length > 0
    ) {
      const length = props.activeChat.messages.length;
      const messageType = props.activeChat.messages[length - 1].message_type;
      if (messageType === "unsubscribed_message") {
        // if (message) {
        //   setMessage("");
        // }
        return true;
      }
    }
    return false;
  };

  return (
    <footer className={isDisable() ? "disable_footer" : ""}>
      {interlocutor &&
      interlocutor.is_deleted &&
      interlocutor.company &&
      interlocutor.company.id === user.company.id ? (
        "This number has been deleted. Messaging is inactive."
      ) : interlocutor &&
        interlocutor.usersCount === 0 &&
        interlocutor.company &&
        interlocutor.company.id === user.company.id ? (
        "This number is not assigned to a user. Messaging is inactive."
      ) : (
        <>
          <OutsideClickHandler onOutsideClick={closePanels}>
            <AttachmentPanel
              attachment={media}
              closePanel={handleCloseAttachment}
              setAttachment={setMedia}
              deleteAttachment={handleDeleteAttachment}
              shown={isPanelOpen.Attachment}
            />
            <EmojiPanel selectEmoji={handleEmoji} shown={isPanelOpen.Emoji} />
            <TemplatePanel
              closePanel={closePanels}
              selectTemplate={handleTemplate}
              shown={isPanelOpen.TemplatePanel}
            />
            <PrebuiltRepliesPanel
              closePanel={closePanels}
              selectReply={handlePrebuiltReplies}
              shown={isPanelOpen.PrebuiltReplies}
            />
            {isPanelOpen.TemplateKeywords && (
              <TemplateKeywords {...template_options} />
            )}
            <div
              className={classNames("options", {
                shown: isPanelOpen.Options,
              })}
            >
              <button type="button" onClick={togglePanel.bind(null, "Options")}>
                <i className="icon-plus"></i>
              </button>
              <button
                onClick={togglePanel.bind(null, "Attachment")}
                style={brandColor ? { color: brandColor } : {}}
                type="button"
              >
                <i className="icon-attach"></i>
                {media.media && <div className="badge">1</div>}
              </button>
              <button
                onClick={togglePanel.bind(null, "Emoji")}
                style={brandColor ? { color: brandColor } : {}}
                type="button"
              >
                <i className="icon-emoji"></i>
              </button>
              <button
                id="Template"
                onClick={togglePanel.bind(null, "TemplateKeywords")}
                style={brandColor ? { color: brandColor } : {}}
                type="button"
              >
                [ ]
              </button>
              <button
                style={brandColor ? { color: brandColor } : { color: "#fff" }}
                className={classNames("templateBtn")}
                type="button"
                onClick={togglePanel.bind(null, "TemplatePanel")}
              >
                <h1 style={{ fontSize: 25, margin: 0 }}>T</h1>
                {(templates || []).length > 0 && (
                  <span>{templates.length}</span>
                )}
              </button>
              <button
                style={
                  brandColor && filteredReplies.length > 0
                    ? { color: brandColor }
                    : {}
                }
                className={classNames("prebuiltRepliesBtn", {
                  "has-replies": filteredReplies.length > 0,
                })}
                type="button"
                onClick={togglePanel.bind(null, "PrebuiltReplies")}
              >
                <i className="icon-flash"></i>
                {filteredReplies.length > 0 && (
                  <span>{filteredReplies.length}</span>
                )}
              </button>
            </div>
          </OutsideClickHandler>
          <TextareaAutosize
            disabled={isDisable()}
            className="textarea-control"
            ref={messageRef}
            onFocus={closeOptionsPanel}
            onChange={handleMessageInput}
            onKeyPress={handleMessageSendWithEnter}
            placeholder="Type a message..."
            type="text"
            value={message}
            autoFocus
            maxRows={8}
          />
          <button
            className={
              message.trim().length === 0 && !media.file ? "disabled" : ""
            }
            onClick={handleMessageSend}
            style={brandColor ? { background: brandColor } : {}}
            type="submit"
          >
            <i className="icon-send"></i>
          </button>
        </>
      )}
    </footer>
  );
});
