import moment from "moment";
import { Dispatch } from "redux";
import Axios, { AxiosResponse, AxiosError } from "axios";
import { sortBy } from "lodash";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";
import { MODAL_HIDE } from "./modal";

// ------------------------------------
// Constants
// ------------------------------------
const SEARCH_REQUEST = "SEARCH_REQUEST";
const SEARCH_SUCCESS = "SEARCH_SUCCESS";
const SEARCH_FAILURE = "SEARCH_FAILURE";

// ------------------------------------
// Action Creators
// ------------------------------------
const searchRequest = (form: any) => ({ type: SEARCH_REQUEST, payload: form });
const searchSuccess = (res: AxiosResponse) => ({
  type: SEARCH_SUCCESS,
  payload: res.data,
});
const searchFailure = (err: AxiosError) => ({
  type: SEARCH_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------
const search = form => async (dispatch: Dispatch, getState: () => any) => {
  const authenticatedUser = getState().users.authenticatedUser;
  const contacts = getState().contacts.data;
  if (authenticatedUser) {
    const { id, token } = authenticatedUser;
    if (id && token) {
      try {
        const payload = {
          start_date: moment(form.date_from).format("YYYY-MM-DD"),
          end_date: moment(form.date_to).format("YYYY-MM-DD"),
          search_str: form.query,
          contacts: form.conversations.map(k => {
            if (k.__isNew__) {
              return { phone_number: k.value };
            }
            const c = k.value.split("_");
            const contact = contacts.find(
              myContact =>
                myContact.id === c[0] &&
                (c[1] === "internal"
                  ? myContact.hasOwnProperty("display_as")
                  : !myContact.hasOwnProperty("display_as"))
            );
            return (
              contact && {
                id: contact.id,
                type: contact.hasOwnProperty("display_as")
                  ? "internal"
                  : "external",
              }
            );
          }),
        };
        dispatch(searchRequest(form));
        const res = await Axios.post(
          `${API_URL}/messages/search`,
          payload,
          withAuthorizationHeader(token)
        );

        dispatch(searchSuccess({ data: sortBy(res.data, "key").reverse() }));
      } catch (err) {
        dispatch(searchFailure(err));
      }
    }
  }
};

export const searchActions = {
  search,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  isFetching: false,
  data: [],
  form: {
    conversations: [],
    date_from: null,
    date_to: null,
    period: 3,
    query: "",
  },
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case MODAL_HIDE:
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case SEARCH_REQUEST:
      return { ...state, isFetching: true, form: payload };

    case SEARCH_SUCCESS:
      return { ...state, isFetching: false, data: payload };

    case SEARCH_FAILURE:
      return { ...state, isFetching: false, data: [] };

    default:
      return state;
  }
};
