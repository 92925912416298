import classNames from "classnames";
import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import SearchInput from "~/components/SearchInput/SearchInput";
import { ITemplate } from "~/store/templates";
import Loader from "../common/Loader/Loader";
import "./TemplateList.scss";

interface OwnProps {
  activeTemplate: number;
  items: ITemplate[];
  openTemplate: (templateId: number) => void;
  shown: boolean;
  toggletemplate?: (
    checked: boolean,
    e: MouseEvent | React.SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => void;
  isFetching: boolean;
}

type Props = OwnProps;

interface State {
  searchTerm: string;
}

export default class TemplateList extends PureComponent<Props, State> {
  static propTypes = {
    activeTemplate: PropTypes.number.isRequired,
    items: PropTypes.array.isRequired,
    openTemplate: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
    toggletemplate: PropTypes.func,
  };

  state = {
    searchTerm: "",
  };

  handleSearch = (value: string) => {
    this.setState({ searchTerm: value });
  };

  filterTemplates = (templates: ITemplate[]) => {
    const search = this.state.searchTerm;
    const exp = new RegExp(search, "i");
    return templates.filter(template =>
      search ? exp.test(template.title) : true
    );
  };

  public render() {
    const { activeTemplate, openTemplate } = this.props;
    const templates = this.filterTemplates(this.props.items);

    return (
      <>
        {this.props.shown && (
          <SearchInput
            className="underline left-icon"
            placeholder={`Search Template`}
            onChange={this.handleSearch}
          />
        )}
        {this.props.isFetching && <Loader />}
        <ul className={classNames("TemplateList", { shown: this.props.shown })}>
          {templates.length ? (
            templates.map((template: ITemplate, key: number) => (
              <li
                className={classNames({
                  active: activeTemplate === template.id,
                })}
                key={key}
                data-index={template.id}
                onClick={() => openTemplate(template.id)}
              >
                <span>{template.title}</span>
              </li>
            ))
          ) : (
            <li className="no">No templates created yet.</li>
          )}
        </ul>
      </>
    );
  }
}
