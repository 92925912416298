import { combineReducers, Store } from "redux";
import { routerReducer } from "react-router-redux";

import autoReplies from "./replies_auto";
import contacts from "./contacts";
import conversations from "./conversations";
import lists from "./lists";
import location from "./location";
import modal from "./modal";
import prebuiltReplies from "./replies_prebuilt";
import reports from "./reports";
import search from "./search";
import statuses from "./statuses";
import users from "./user";
import websocket from "./websocket";
import templates from "./templates";
import schedules from "./schedules";
import schedule_panel from "./schedule_panel";

export const makeRootReducer = (asyncReducers?: {}) => {
  return combineReducers({
    autoReplies,
    contacts,
    conversations,
    lists,
    location,
    modal,
    prebuiltReplies,
    reports,
    router: routerReducer,
    search,
    statuses,
    users,
    websocket,
    templates,
    schedules,
    schedule_panel,
    ...asyncReducers,
  });
};

export const injectReducer = (
  store: Store & { [key: string]: any },
  { key, reducer }: { key: string; reducer: any }
) => {
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeRootReducer(store.asyncReducers));
};

export default makeRootReducer;
