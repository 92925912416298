import axios from "axios";
import { push } from "react-router-redux";
import { modalActions } from "./modal";
import { UPDATE_USER_TOKEN } from "./user";

export default {
  setup: store => {
    axios.interceptors.response.use(
      response => {
        const replaceToken: string = response.headers.replacetoken;
        if (replaceToken && replaceToken.length) {
          store.dispatch({ type: UPDATE_USER_TOKEN, payload: replaceToken });
          localStorage.setItem("user", replaceToken);
        }
        return response;
      },
      error => {
        if (error && error.response && error.response.status === 401) {
          console.error("[EXPIRED TOKEN]");
          localStorage.clear();
          store.dispatch(modalActions.closeModal());
          store.dispatch(push("/login"));
        }
        return Promise.reject(error);
      }
    );
  },
};
