import classnames from "classnames";
import moment from "moment";
import React, { PureComponent, KeyboardEvent } from "react";
import ThemedInput from "../ThemedInput/ThemedInput";
import "./TimePicker.scss";

namespace TimePickerHHMMSS {
  export interface OwnProps {
    disabled?: boolean;
    id: string;
    onTimeChange: (time: Date, err: boolean) => void;
    text?: string;
    value: string | undefined;
    maxTime: Date;
    minTime: Date;
    fieldType: string;
    isUpdate?: boolean;
  }
}

export default class TimePickerHHMMSS extends PureComponent<
  TimePickerHHMMSS.OwnProps
> {
  state = {
    isAM: true,
    hoursText: "",
    minutesText: "",
    secondsText: "",
  };

  componentDidMount() {
    if (this.props.value) {
      let [hrs, min, sec] = this.props.value.split(":");
      let hours = +hrs;
      let minutes = +min;
      let seconds = +sec;
      let [hoursText, minutesText, secondsText, isAm] = moment()
        .hours(hours)
        .minutes(minutes)
        .seconds(seconds)
        .format("hh:mm:ss:A")
        .split(":");
      this.setState({
        isAM: isAm === "AM",
        hoursText,
        minutesText,
        secondsText,
      });
    }
  }
  componentDidUpdate(prevProps: any) {
    if (
      this.props.isUpdate != prevProps.isUpdate &&
      this.props.value &&
      this.props.value != prevProps.value
    ) {
      let [hrs, min, sec] = this.props.value.split(":");
      let hours = +hrs;
      let minutes = +min;
      let seconds = +sec;
      let [hoursText, minutesText, secondsText, isAm] = moment()
        .hours(hours)
        .minutes(minutes)
        .seconds(seconds)
        .format("hh:mm:ss:A")
        .split(":");
      this.setState({
        isAM: isAm === "AM",
        hoursText,
        minutesText,
        secondsText,
      });
    }
  }
  toggleAmPm = () =>
    !this.props.disabled &&
    this.setState(prevState => ({ isAM: !prevState.isAM }), this.updateValue);

  handleHours = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    let { value } = e.target as HTMLInputElement;
    if (value === "" || !/^\d+$/.test(value)) {
    } else {
      this.setState(
        { hoursText: this.formatText(value, "hoursText") },
        this.updateValue
      );
    }
  };

  handleMinutes = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    let { value } = e.target as HTMLInputElement;
    if (value === "" || !/^\d+$/.test(value)) {
    } else {
      this.setState(
        { minutesText: this.formatText(value, "minutesText") },
        this.updateValue
      );
    }
  };

  handleSeconds = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    let { value } = e.target as HTMLInputElement;
    if (value === "" || !/^\d+$/.test(value)) {
    } else {
      this.setState(
        { secondsText: this.formatText(value, "secondsText") },
        this.updateValue
      );
    }
  };

  formatText = (value: string, type: string) => {
    if (type === "hoursText") {
      if (+value < 10) {
        value = "0" + Number.parseInt(value);
      } else if (+value > 12) {
        value = "12";
      } else {
        value = "" + Number.parseInt(value);
      }
    } else {
      if (+value < 10) {
        value = "0" + Number.parseInt(value);
      } else if (+value > 59) {
        value = "59";
      } else {
        value = "" + Number.parseInt(value);
      }
    }
    return value;
  };

  notZero = () => {
    if (+this.state.hoursText === 0) {
      this.setState({ hoursText: "01" }, this.updateValue);
    }
  };

  updateValue = () => {
    const time = new Date(0);
    let hours = Number.parseInt(this.state.hoursText);
    const minutes = Number.parseInt(this.state.minutesText);
    const seconds = Number.parseInt(this.state.secondsText);
    if (
      !Number.isNaN(hours) &&
      !Number.isNaN(minutes) &&
      !Number.isNaN(seconds)
    ) {
      if (hours === 12) hours = 0;
      if (!this.state.isAM) hours += 12;
      time.setHours(hours, minutes, seconds, 0);
      this.props.onTimeChange(
        time,
        this.isHoursValid() && this.isMinutesValid(this.props.fieldType)
      );
    }
  };

  handleUpDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { keyCode } = e;
    // used to differentiate hours and minutes to make this function reusable
    const id =
      e.currentTarget.placeholder === "hh" ? "hoursText" : "minutesText";
    if (this.state[id] !== "") {
      if (keyCode === 38) {
        // key arrow up
        if (+this.state[id] < (id === "hoursText" ? 12 : 59)) {
          this.setState(
            prevState => ({
              [id]: this.formatText("" + (+prevState[id] + 1), id),
            }),
            this.updateValue
          );
        } else {
          this.setState(
            { [id]: this.formatText(id === "hoursText" ? "1" : "0", id) },
            this.updateValue
          );
        }
      } else if (keyCode === 40) {
        // key arrow down
        if (+this.state[id] > (id === "hoursText" ? 1 : 0)) {
          this.setState(
            prevState => ({
              [id]: this.formatText("" + (+prevState[id] - 1), id),
            }),
            this.updateValue
          );
        } else {
          this.setState({ [id]: this.formatText("59", id) }, this.updateValue);
        }
      }
    }
  };

  isHoursValid = () => {
    // Is hours set?
    if (this.state.hoursText) {
      // Convert hours to full format
      let currentHours = Number.parseInt(this.state.hoursText);
      if (currentHours === 12) currentHours = 0;
      if (!this.state.isAM) currentHours += 12;

      // Compare current date with available range
      return !(
        currentHours < this.props.minTime.getHours() ||
        currentHours > this.props.maxTime.getHours()
      );
    } else {
      return false;
    }
  };

  isMinutesValid = (fieldType: string) => {
    if (this.state.hoursText && this.state.minutesText) {
      // Convert hours to full format
      let currentHours = Number.parseInt(this.state.hoursText);
      if (currentHours === 12) currentHours = 0;
      if (!this.state.isAM) currentHours += 12;

      const currentMinutes = Number.parseInt(this.state.minutesText);

      if (
        currentHours < this.props.minTime.getHours() ||
        currentHours > this.props.maxTime.getHours()
      ) {
        return false;
      } else if (
        (fieldType === "to" &&
          currentHours === this.props.minTime.getHours()) ||
        (fieldType === "from" && currentHours === this.props.maxTime.getHours())
      ) {
        return !(
          (fieldType === "to" &&
            currentMinutes <= this.props.minTime.getMinutes()) ||
          (fieldType === "from" &&
            currentMinutes > this.props.maxTime.getMinutes())
        );
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  render() {
    return (
      <div
        className={classnames("TimePicker", { disabled: this.props.disabled })}
      >
        <ThemedInput
          className={classnames({
            error: !this.props.disabled && !this.isHoursValid(),
          })}
          disabled={this.props.disabled}
          id={this.props.id + "_hours"}
          maxLength={3}
          name={this.props.id + "_hours"}
          onChange={this.handleHours}
          onKeyDown={this.handleUpDown}
          onBlur={this.notZero}
          placeholder="hh"
          type="text"
          value={this.state.hoursText}
        />
        <span>:</span>
        <ThemedInput
          className={classnames({
            error:
              !this.props.disabled &&
              !this.isMinutesValid(this.props.fieldType),
          })}
          disabled={this.props.disabled}
          id={this.props.id + "_minutes"}
          maxLength={3}
          name={this.props.id + "_minutes"}
          onChange={this.handleMinutes}
          onKeyDown={this.handleUpDown}
          placeholder="mm"
          type="text"
          value={this.state.minutesText}
        />
        <span>:</span>
        <ThemedInput
          className={classnames({
            error:
              !this.props.disabled &&
              !this.isMinutesValid(this.props.fieldType),
          })}
          disabled={this.props.disabled}
          id={this.props.id + "_seconds"}
          maxLength={3}
          name={this.props.id + "_seconds"}
          onChange={this.handleSeconds}
          onKeyDown={this.handleUpDown}
          placeholder="ss"
          type="text"
          value={this.state.secondsText}
        />
        <div className="TimePicker__AmPm">
          <button type="button" onClick={this.toggleAmPm}>
            <i className="icon-back"></i>
          </button>
          <span>{this.state.isAM ? "am" : "pm"}</span>
          <button type="button" onClick={this.toggleAmPm}>
            <i className="icon-back"></i>
          </button>
        </div>
      </div>
    );
  }
}
