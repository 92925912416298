import "./ThemedRadio.scss";
import React, { ChangeEvent, PureComponent } from "react";
import { connect } from "react-redux";
import classnames from "classnames";
import { LightenDarkenColor, ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}

interface OwnProps {
  checked: boolean;
  id: string;
  name: string;
  onChange: (e: ChangeEvent) => void;
  text: string;
  value: string;
  readOnly?: boolean;
}

type Props = StateProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedRadio extends PureComponent<Props> {
  render() {
    let brandColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor = LightenDarkenColor(
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme,
        30
      );
    }
    return (
      <label
        className={classnames("ThemedRadio", { readOnly: this.props.readOnly })}
        htmlFor={this.props.id}
      >
        <input
          checked={!!this.props.checked}
          id={this.props.id}
          name={this.props.name}
          onChange={this.props.readOnly ? () => {} : this.props.onChange}
          readOnly={this.props.readOnly || false}
          type="radio"
          value={this.props.value}
        />
        <span
          style={
            brandColor && !!this.props.checked ? { color: brandColor } : {}
          }
        >
          {this.props.text}
        </span>
      </label>
    );
  }
}

ThemedRadio.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedRadio);
