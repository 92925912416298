import React, { Component } from "react";
import { connect } from "react-redux";
import MenuIcon from "~/assets/menu.svg";
import { conversationActions } from "~/store/conversations";
import { modalActions } from "~/store/modal";
import { ThemeContext } from "~/utils";
import Notification from "../Chat/Notifications/Notification";
import PhonesDropdown from "../PhonesDropdown/PhonesDropdown";
import ProfileStatus from "../ProfileStatus/ProfileStatus";
import "./Header.scss";
import { Branding } from "~/branding";

type HeaderProps = {
  authenticatedUser: any;
  onSidebarButtonClick: () => void;
  openModal: (modal: string) => void;
  domainWL: any;
};

const mapDispatchToProps = {
  openModal: modalActions.openModal,
  clearDiffUnread: conversationActions.clearDiffUnread,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  diffUnread: state.conversations.diffUnread,
  domainWL:
    (state.users.authenticatedUser &&
      state.users.authenticatedUser.company &&
      state.users.authenticatedUser.company.whitelabeling) ||
    {},
});

@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
class Header extends Component<HeaderProps> {
  componentDidUpdate(prevProps) {
    if (
      !!this.props.authenticatedUser.settings &&
      !!this.props.authenticatedUser.settings.notification_visual &&
      !!this.props.diffUnread &&
      !prevProps.diffUnread
    ) {
      this.notification.show(this.props.diffUnread);
      this.props.clearDiffUnread();
    }
    // else if (!!this.props.diffUnread && !prevProps.diffUnread) {
    //   this.notification.increaseMessageCount();
    // }
    if (!!this.props.diffUnread) {
      this.props.clearDiffUnread();
    }
  }

  getTotalUnread = (user: any) =>
    user
      ? user.phones.reduce(
          (accumulator, currentValue) =>
            accumulator + (currentValue.unreadCount || 0),
          0
        )
      : 0;

  render() {
    const { authenticatedUser } = this.props;
    let logoUrl, title;
    if (
      authenticatedUser &&
      authenticatedUser.company &&
      authenticatedUser.company.whitelabeling
    ) {
      logoUrl = authenticatedUser.company.whitelabeling.logo_url;
      title = authenticatedUser.company.whitelabeling.application_name;
    }

    const totalUnread = this.getTotalUnread(authenticatedUser);

    return (
      <header className="Header">
        <button
          className="Header__Button"
          onClick={this.props.onSidebarButtonClick}
        >
          <img src={MenuIcon} alt="Menu" />
          {/* <i className='icon-menu'></i> */}
        </button>
        <img
          className="Header__Logo1"
          src={logoUrl || Branding.COMPANY_LOGO}
          alt={Branding.COMPANY_NAME}
        />
        <span className="Header__Logo">{title || Branding.APP_NAME}</span>
        <div style={{ display: "flex", alignItems: "center" }}>
          {!!totalUnread && (
            <PhonesDropdown
              toggleButton={
                <div className="Header__Unread">
                  <i className="icon-chat" />
                  <div>
                    <span>{totalUnread}</span>
                  </div>
                </div>
              }
              topHeader
            />
          )}
          {this.props.authenticatedUser && (
            <div className="Header__Right">
              <button
                className="Header__ProfileButton"
                onClick={() => this.props.openModal("PROFILE_EDIT_MODAL")}
              >
                <i className="icon-profile"></i>
                <span>
                  {authenticatedUser.display_as ||
                    `${authenticatedUser.first_name} ${authenticatedUser.last_name}`}
                </span>
              </button>
              <ProfileStatus />
            </div>
          )}
        </div>
        <Notification
          ref={notification => (this.notification = notification)}
          totalUnread={totalUnread}
          app_name={this.props.domainWL.application_name}
        />
      </header>
    );
  }
}

Header.contextType = ThemeContext;

export default Header;
