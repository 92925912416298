import { AxiosPromise } from "axios";
import csv from "csv";
import PropTypes from "prop-types";
import React, {
  ChangeEvent,
  useEffect,
  FormEvent,
  useState,
  useContext,
} from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { OptionsType } from "react-select/lib/types";
import DragAndDrop from "~/components/common/DragAndDrop/DragAndDrop";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedRadio from "~/components/common/ThemedRadio/ThemedRadio";
import ThemedTextarea from "~/components/common/ThemedTextarea/ThemedTextarea";
import { IExternalContact, IInternalContact } from "~/store/contacts";
import { IList, listsActions } from "~/store/lists";
import { LightenDarkenColor, ThemeContext } from "~/utils";
import "./ListEdit.scss";

export type State = {
  form: IList;
  uploadCSV: boolean;
  csvUploadError: string;
  csvBlasts: any;
  fileName?: string;
};

interface StateProps {
  activeList: IList | null;
  authenticatedUser: any;
  contacts: [];
  isFetching: boolean;
}

interface DispatchProps {
  createList: (form: IList) => AxiosPromise;
  updateList: (listId: string, form: IList) => AxiosPromise;
}

interface OwnProps {
  onBack: () => void;
  shown: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  createList: listsActions.createList,
  updateList: listsActions.updateList,
};

const mapStateToProps = (state: any) => ({
  activeList: state.lists.activeList,
  authenticatedUser: state.users.authenticatedUser,
  contacts: state.contacts.data,
  isFetching: state.lists.isFetching,
});

const formInitial: IList = {
  compliance: `Message and data rates may apply.\nReply STOP to end or HELP for more options.`,
  name: "",
  notes: "",
  external: [],
};

const ListEdit = (props: Props) => {
  const [csvFileName, setCSVFileName] = useState("");
  const [csvLists, setCSVLists] = useState([]);
  const [csvUpload, setCSVUpload] = useState(true);
  const [csvError, setCSVError] = useState("");
  const [form, setForm] = useState(formInitial);
  const [errors, setErrors] = useState([]);
  const theme = useContext(ThemeContext);
  let brandColor: string;
  let contacts: OptionsType<any> = [];

  useEffect(() => {
    if (props.activeList) {
      setCSVUpload(props.activeList.csv_filename !== "");
      setForm({
        ...props.activeList,
        external: createOptions(props.activeList.external),
      });
    }

    const { authenticatedUser: user } = props;
    if (theme || (user && user.company && user.company.whitelabeling)) {
      brandColor =
        theme.theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
  }, []);

  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { id, value } = e.target;
    setForm({ ...form, [id]: value });
  }

  function handleSubmit(e: FormEvent) {
    e.preventDefault();
    const modifiedForm: IList = Object.assign(form, {
      csv_filename: csvFileName,
      external: form.external.map((ex: any) => {
        delete ex.value.id;
        return {
          title: ex.label,
          ...ex.value,
        };
      }),
    });
    console.log("modifiedForm ", modifiedForm);
    if (props.activeList) {
      // Update list
      props.updateList(props.activeList.id, modifiedForm).then(props.onBack);
    } else {
      // Create list
      props.createList(modifiedForm).then(props.onBack);
    }
  }

  function handleExternalChange(newValue: OptionsType<any>) {
    setForm({ ...form, external: [...newValue] });
  }

  function handleRadio(e: ChangeEvent<HTMLInputElement>) {
    e.stopPropagation();
    const { value } = e.target;
    setCSVFileName("");
    setCSVLists([]);
    setCSVUpload(value === "true");
    setForm({ ...form, external: [] });
  }

  function handleDrop(e: FileList) {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(reader.result, (err: Error, csv: any) => {
        const fileName = e[0].name;
        if (err) {
          setCSVError("Please use CSV file to import List contacts");
          setCSVFileName("");
          setCSVLists([]);
          return;
        }
        if (csv && csv.length) {
          const usedPhones: string[] = [];
          csv = csv.reduce((result: any, item: string[]) => {
            if (usedPhones.indexOf(item[0]) === -1) {
              usedPhones.push(item[0]);
              result.push(item);
            }
            return result;
          }, []);
        }
        const external =
          csv && csv.length > 0
            ? csv.filter(validateCSVLine).map(convertCSVLine)
            : [];
        if (external.length) {
          if (external.length > 4000) {
            setCSVError(
              "List contains more than 4000 contacts. Please revise your list to have 4000 contacts or less."
            );
            return;
          }
          setForm({ ...form, external });
          setCSVError("");
          setCSVLists(csv.map(convertCSVPreviewLine));
          setCSVFileName(fileName);
        } else {
          setCSVError(
            "The file contains errors. Please provide valid phone numbers"
          );
        }
      });
    };
    reader.readAsBinaryString(e[0]);
  }

  function createOptions(externals: any[]) {
    return externals.map(item => ({
      label: item.title,
      value: item,
    }));
  }

  function convertCSVLine(line: string[]): object {
    return {
      label: [line[1] ? line[1] : line[0], line[2] ? line[2] : ""].join(" "),
      value: { ...convertCSVPreviewLine(line) },
    };
  }

  function convertCSVPreviewLine(line: string[]): object {
    return {
      phone_number: line[0].length === 10 ? `1${line[0]}` : line[0],
      first_name: line[1] ? line[1] : line[0],
      last_name: line[2] ? line[2] : "",
      company: line[3] ? line[3] : "",
      job_title: line[4] ? line[4] : "",
      email: line[5] ? line[5] : "",
      address_line_1: line[6] ? line[6] : "",
      address_line_2: line[7] ? line[7] : "",
      city: line[8] ? line[8] : "",
      state: line[9] ? line[9] : "",
      zipcode: line[10] ? line[10] : "",
      custom_field_1: line[11] ? line[11] : "",
      custom_field_2: line[12] ? line[12] : "",
      custom_field_3: line[13] ? line[13] : "",
    };
  }

  function validateCSVLine(line: string[]): boolean {
    return /^\d{10,11}$/.test(line[0]);
  }

  contacts = props.contacts
    .filter(
      (contact: IExternalContact | IInternalContact) =>
        !contact.hasOwnProperty("display_as") &&
        (contact as IExternalContact).is_deleted !== true &&
        (contact as IExternalContact).in_contacts === true
    )
    .map((contact: IExternalContact) => ({
      value: contact,
      label: `${contact.first_name} ${contact.last_name}`,
    }));

  return (
    <form
      className={props.shown ? "ListEdit shown" : "ListEdit"}
      onSubmit={handleSubmit}
    >
      <div className="ListEdit__Scroll">
        <button type="button" onClick={props.onBack}>
          <i className="icon-back"></i>
        </button>
        <h1>{props.activeList ? "Edit" : "Add"} List</h1>
        <div>
          <div>
            <label className="mandatory" htmlFor="name">
              Name
            </label>
            <ThemedInput
              id="name"
              type="text"
              onChange={handleChange}
              placeholder="Name"
              value={form.name}
            />
          </div>
          <div>
            <label className="mandatory" htmlFor="members">
              Members
            </label>
            <ThemedRadio
              checked={csvUpload}
              id="upload_csv"
              name="upload_csv"
              onChange={handleRadio}
              text="Import from CSV"
              value="true"
              readOnly={(props.activeList && props.activeList.id) || false}
            />
            <ThemedRadio
              checked={!csvUpload}
              id="manual_members"
              name="manual_members"
              onChange={handleRadio}
              text="Select from Contacts"
              value="false"
              readOnly={(props.activeList && props.activeList.id) || false}
            />
            {csvUpload ? (
              <span>
                <span className="csv-upload-hint">
                  Format of CSV: one recipient per row with number, first name,
                  last name, notes. Download a sample template:
                  <a href="https://messageviewcdn-production.brightlink.com/lol/sample.csv">
                    sample.csv
                  </a>
                </span>
                <DragAndDrop
                  dataType=".csv"
                  handleDrop={handleDrop}
                  csvData={csvLists}
                  error={csvError}
                />
              </span>
            ) : (
              <Select
                className="select"
                isMulti
                id="members"
                name="members"
                value={form.external}
                onChange={handleExternalChange}
                options={contacts}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: (brandColor || "#004B87") + " !important",
                    primary25:
                      ((brandColor && LightenDarkenColor(brandColor, 30)) ||
                        "#00bbe5") + " !important",
                  },
                })}
              />
            )}
          </div>
          <div>
            <label className="mandatory" htmlFor="compliance">
              Compliance Message
            </label>
            <ThemedTextarea
              id="compliance"
              placeholder="Compliance Message"
              onChange={handleChange}
              value={form.compliance}
            ></ThemedTextarea>
          </div>
          <div>
            <label htmlFor="notes">Notes</label>
            <ThemedTextarea
              id="notes"
              placeholder="Notes"
              onChange={handleChange}
              value={form.notes}
            ></ThemedTextarea>
          </div>
        </div>
      </div>
      <footer>
        <ThemedButton
          type="submit"
          disabled={!(form.name && form.compliance && !props.isFetching)}
        >
          {props.activeList ? "Update" : "Add"}
        </ThemedButton>
        <button type="button" onClick={props.onBack}>
          Cancel
        </button>
      </footer>
    </form>
  );
};

ListEdit.propTypes = {
  onBack: PropTypes.func,
  shown: PropTypes.bool.isRequired,
};

ListEdit.defaultProps = {
  shown: false,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListEdit);
