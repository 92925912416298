import React, { Component } from "react";
import { connect } from "react-redux";
import { omit } from "lodash";
import Select from "react-select";
import { ChromePicker } from "react-color";

import { LightenDarkenColor } from "~/utils";

import ThemedSwitch from "~/components/common/ThemedSwitch/ThemedSwitch";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

import { modalActions } from "~/store/modal";
import { usersActions } from "~/store/user";

import Modal from "../AbstractModal/AbstractModal";
import "./SettingsModal.scss";

namespace SettingsModal {
  interface StateProps {
    authenticatedUser: any;
  }
  interface DispatchProps {
    closeModal: Function;
    updateUser: Function;
  }
  interface OwnProps {
    onChangeTheme: Function;
  }
  export type Theme = {
    value: string;
    label: string;
    color?: string;
  };
  export type Props = StateProps & DispatchProps & OwnProps;
  export type State = {
    theme: Theme;
    notification_visual: boolean;
    notification_audible: boolean;
    theme_color: string;
    isCustom: boolean;
    email_send_time: number;
    notification_email: boolean;
  };
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  updateUser: usersActions.updateUser,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  wlColor:
    (state.users.authenticatedUser &&
      state.users.authenticatedUser.company &&
      (state.users.authenticatedUser.company.whitelabeling || {})
        .primary_css_color) ||
    "#004B87",
});

const themes = [
  { value: "default", label: "Default" },
  { value: "light", label: "Light", color: "#0077C8" },
  { value: "dark", label: "Dark", color: "#043a60" },
  { value: "custom", label: "Custom" },
];

const emailSend = [
  { value: 1, label: "1 Minute" },
  { value: 5, label: "5 Minutes" },
  { value: 10, label: "10 Minutes" },
  { value: 30, label: "30 Minutes" },
  { value: 60, label: "1 HR" },
];

class SettingsModal extends Component<
  SettingsModal.Props,
  SettingsModal.State
> {
  constructor(props: any) {
    super(props);

    const theme = props.authenticatedUser.settings || {};
    const selectedTheme =
      (theme.theme_color === props.wlColor && themes[0]) ||
      themes.find(item => item.color === theme.theme_color) ||
      themes[3];

    this.state = {
      theme_color: props.wlColor,
      notification_visual: true,
      notification_audible: true,
      notification_email: true,
      ...theme,
      theme: selectedTheme,
      isCustom: selectedTheme.value === "custom",
    };
  }

  handleUpdateTheme = (theme: SettingsModal.Theme) => {
    this.setState({
      theme,
      isCustom: theme.value === "custom",
      theme_color: theme.color || this.props.wlColor,
    });
  };

  handleSubmit = () => {
    this.props.onChangeTheme(this.state);
    this.props.closeModal();

    this.props.updateUser({
      settings: omit(this.state, ["theme", "isCustom"]),
    });
  };

  handleChangeColor = (color: any) => {
    this.setState({ theme_color: color.hex });
  };

  handleEmailSendTime = (email_time: any) => {
    console.log("email_time ", email_time);
    this.setState({ ...this.state, email_send_time: email_time.value }, () => {
      console.log(this.state);
    });
  };

  render() {
    let brandColor: string;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const themeObj = this.state.theme || {
        value: "default",
        label: "Default",
      };
      const theme = this.state.theme_color || themeObj.color || themeObj.value;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
    const emailSendTime = emailSend.find(
      (emailSend: any) => emailSend.value === this.state.email_send_time
    );

    return (
      <Modal title="Settings" className="SettingsModal">
        <div className="content">
          <div className="themeRow">
            <div className="themeSelector">
              <span>Theme</span>
              <Select
                className="select"
                id="theme"
                name="theme"
                value={this.state.theme || themes[0]}
                onChange={this.handleUpdateTheme}
                options={themes}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: (brandColor || "#004B87") + " !important",
                    primary25:
                      ((brandColor && LightenDarkenColor(brandColor, 50)) ||
                        "#00bbe5") + " !important",
                  },
                })}
              />
            </div>
            {this.state.isCustom && (
              <ChromePicker
                className="picker"
                disableAlpha={true}
                onChange={this.handleChangeColor}
                color={this.state.theme_color}
              />
            )}
          </div>

          <h3>NOTIFICATIONS</h3>
          <div className="themeRow">
            <div className="switcher">
              <span>Visual</span>
              <ThemedSwitch
                checkedIcon={<i>ON</i>}
                uncheckedIcon={<i>OFF</i>}
                onColor={"#00bbe5"}
                offColor={"#a1a1a1"}
                onChange={() =>
                  this.setState(prevState => ({
                    notification_visual: !prevState.notification_visual,
                  }))
                }
                checked={!!this.state.notification_visual}
              />
            </div>
            <div className="switcher">
              <span>Audible</span>
              <ThemedSwitch
                checkedIcon={<i>ON</i>}
                uncheckedIcon={<i>OFF</i>}
                onColor={"#00bbe5"}
                offColor={"#a1a1a1"}
                onChange={() =>
                  this.setState(prevState => ({
                    notification_audible: !prevState.notification_audible,
                  }))
                }
                checked={!!this.state.notification_audible}
              />
            </div>
            <div className="switcher">
              <span>Email</span>
              <ThemedSwitch
                checkedIcon={<i>ON</i>}
                uncheckedIcon={<i>OFF</i>}
                onColor={"#00bbe5"}
                offColor={"#a1a1a1"}
                onChange={() =>
                  this.setState(prevState => ({
                    notification_email: !prevState.notification_email,
                  }))
                }
                checked={!!this.state.notification_email}
              />
            </div>
          </div>
          <h3 style={{ paddingTop: 15 }}>Email Send Time</h3>
          <div className="themeRow">
            <div className="themeSelector">
              <Select
                className="select"
                id="email"
                name="email"
                value={emailSendTime}
                onChange={this.handleEmailSendTime}
                options={emailSend}
                theme={theme => ({
                  ...theme,
                  colors: {
                    ...theme.colors,
                    primary: (brandColor || "#004B87") + " !important",
                    primary25:
                      ((brandColor && LightenDarkenColor(brandColor, 30)) ||
                        "#00bbe5") + " !important",
                  },
                })}
              />
            </div>
          </div>
          <footer>
            <ThemedButton type="submit" onClick={this.handleSubmit}>
              Update
            </ThemedButton>
            <button type="button" onClick={this.props.closeModal}>
              Cancel
            </button>
          </footer>
        </div>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SettingsModal as any);
