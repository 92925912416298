import { Dispatch } from "redux";

export interface IWebsocketMessage {
  type: string;
  payload: any;
}

// ------------------------------------
// Constants
// ------------------------------------
export const WS_SET_CLOSE = "WS_SET_CLOSE";
export const WS_SET_INSTANCE = "WS_SET_INSTANCE";

// ------------------------------------
// Actions
// ------------------------------------
const setWebsocketClose = () => (dispatch: Dispatch) => {
  dispatch({ type: WS_SET_CLOSE });
};

const setWebsocketInstance = (instance: any) => (dispatch: Dispatch) => {
  dispatch({ type: WS_SET_INSTANCE, payload: instance });
};

const userSendStatus = (status: string) => (
  _dispatch: Dispatch,
  getState: () => any
) => {
  const { instance } = getState().websocket;
  instance && instance.emit("user_set_status", { status });
};

export const websocketActions = {
  setWebsocketClose,
  setWebsocketInstance,
  userSendStatus,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  connected: false,
  instance: null,
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case WS_SET_CLOSE:
      return { ...state, connected: false };
    case WS_SET_INSTANCE:
      return { ...state, instance: payload, connected: true };
    default:
      return state;
  }
};
