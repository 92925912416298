import { AxiosPromise } from "axios";
import classNames from "classnames";
import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from "react";
import { connect } from "react-redux";
import CreatableSelect from "react-select/lib/Creatable";
import PropTypes from "prop-types";
import "./PrebuiltReplyEdit.scss";
import {
  prebuiltRepliesActions,
  IPrebuiltReply,
} from "~/store/replies_prebuilt";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedTextarea from "~/components/common/ThemedTextarea/ThemedTextarea";
import { LightenDarkenColor, ThemeContext } from "~/utils";

namespace PrebuiltReplyEdit {
  export type State = {
    inputValue: string;
    isPanelOpen: boolean;
    replyId: number | null;
    form: IPrebuiltReply;
  };

  interface StateProps {
    authenticatedUser: any;
    replies: [];
  }

  interface DispatchProps {
    createReply: (form: IPrebuiltReply) => AxiosPromise;
    deleteReply: (id: number) => AxiosPromise;
    updateReply: (form: IPrebuiltReply) => AxiosPromise;
  }

  interface OwnProps {
    onBack: () => void;
    reply?: number;
    shown: boolean;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  createReply: prebuiltRepliesActions.createPrebuiltReply,
  deleteReply: prebuiltRepliesActions.deletePrebuiltReply,
  updateReply: prebuiltRepliesActions.updatePrebuiltReply,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  replies: state.prebuiltReplies.data,
});

class PrebuiltReplyEdit extends PureComponent<
  PrebuiltReplyEdit.Props,
  PrebuiltReplyEdit.State
> {
  static propTypes = {
    reply: PropTypes.number,
    onBack: PropTypes.func,
    shown: PropTypes.bool.isRequired,
  };

  state = {
    isPanelOpen: false,
    replyId: -1,
    inputValue: "",
    form: {
      title: "",
      keywords: [],
      text_content: "",
    },
  };

  componentDidMount() {
    if (this.props.reply !== -1) this.loadReply(this.props.reply);
  }

  componentDidUpdate(prevProps: PrebuiltReplyEdit.Props) {
    if (this.props.reply !== -1 && this.props.reply !== prevProps.reply)
      this.loadReply(this.props.reply);
  }

  loadReply = (replyId?: number) => {
    if (this.props.replies !== null) {
      const reply: IPrebuiltReply | {} = Object.assign(
        {},
        this.props.replies.find((item: IPrebuiltReply) => item.id === replyId)
      );
      if (reply.keywords) {
        reply.keywords = reply.keywords.map((keyword: string) =>
          this.createOption(keyword)
        );
        this.setState({ replyId: reply.id, form: { ...reply } });
      }
    }
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: IPrebuiltReply = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleKeywordsChange = newValue => {
    const form: IPrebuiltReply = { ...this.state.form, keywords: newValue };
    this.setState({ form });
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (this.props.reply !== -1) {
      // Update reply
      this.props.updateReply(this.state.form).then(this.props.onBack);
    } else {
      // Create reply
      this.props.createReply(this.state.form).then(this.props.onBack);
    }
  };

  createOption = (label: string) => ({ key: label, label, value: label });

  handleInputChange = (inputValue: string) => this.setState({ inputValue });

  handleKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { keywords } = this.state.form;
    const inputValue = this.state.inputValue;
    if (!inputValue) return;

    switch (event.key) {
      case "Enter":
      case "Tab":
        if (keywords.filter(keyword => keyword.value === inputValue).length > 0)
          return;
        this.setState({
          inputValue: "",
          form: {
            ...this.state.form,
            keywords: [...keywords, this.createOption(inputValue)],
          },
        });
        event.preventDefault();
    }
  };

  togglePanel = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({ isPanelOpen: !prevState.isPanelOpen }));
  };

  onCopy = () => {
    if (this.props.replies !== null) {
      let form: IPrebuiltReply | undefined = this.props.replies.find(
        (reply: IPrebuiltReply) => reply.id === this.props.reply
      );
      if (form) {
        form = Object.assign({}, form);
        delete form.id;
        form.title += " Copy";
        form.keywords = form.keywords.map((keyword: string) =>
          this.createOption(keyword)
        );
        this.props.createReply(form).then(this.props.onBack);
      }
    }
  };

  render() {
    let brandColor: string;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }

    return (
      <div
        className={
          this.props.shown ? "PrebuiltReplyEdit shown" : "PrebuiltReplyEdit"
        }
      >
        <header>
          <button type="button" onClick={this.props.onBack}>
            <i className="icon-back"></i>
          </button>
          <h1>{this.props.reply !== -1 ? "Edit" : "Add"} Suggested Reply</h1>
        </header>
        <div className="PrebuiltReplyEdit__Form">
          <div>
            <label className="mandatory" htmlFor="title">
              Title
            </label>
            <ThemedInput
              id="title"
              type="text"
              onChange={this.handleChange}
              placeholder="Title"
              value={this.state.form.title}
            />
          </div>
          <div>
            <label className="mandatory" htmlFor="keywords">
              Keywords
            </label>
            <CreatableSelect
              id="keywords"
              components={{ DropdownIndicator: null }}
              menuIsOpen={false}
              inputValue={this.state.inputValue}
              isMulti
              onChange={this.handleKeywordsChange}
              onInputChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder="Enter a keyword or keyphrase, then hit return to add it"
              theme={theme => ({
                ...theme,
                borderShadow: "none",
                borderWidth: "1px",
                colors: {
                  ...theme.colors,
                  primary: (brandColor || theme.colors.primary) + " !important",
                  primary25:
                    (brandColor
                      ? LightenDarkenColor(brandColor, 30)
                      : theme.colors.primary25) + " !important",
                },
              })}
              value={this.state.form.keywords}
            />
          </div>
          <div>
            <label className="mandatory" htmlFor="notes">
              Suggested Reply
            </label>
            <ThemedTextarea
              id="text_content"
              placeholder="Type suggested reply..."
              onChange={this.handleChange}
              value={this.state.form.text_content}
            ></ThemedTextarea>
          </div>
        </div>
        <div
          onClick={this.togglePanel}
          className={classNames("panel", { open: this.state.isPanelOpen })}
        >
          <div>
            <ThemedButton type="button" onClick={this.onCopy}>
              Copy Suggested Reply
            </ThemedButton>
            <button
              type="button"
              onClick={() => {
                this.props
                  .deleteReply(this.state.replyId)
                  .then(this.props.onBack);
              }}
            >
              Delete Suggested Reply
            </button>
            <button
              className="close"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              Close
            </button>
          </div>
        </div>
        <footer>
          <ThemedButton
            type="submit"
            onClick={this.handleSubmit}
            disabled={
              !(
                this.state.form.title &&
                this.state.form.keywords.length &&
                this.state.form.text_content
              )
            }
          >
            {this.props.reply !== -1 ? "Update" : "Add"}
          </ThemedButton>
          <button type="button" onClick={this.props.onBack}>
            Cancel
          </button>
          {this.props.reply !== -1 && (
            <button
              className="more"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              ···
            </button>
          )}
        </footer>
      </div>
    );
  }
}

PrebuiltReplyEdit.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrebuiltReplyEdit as any);
