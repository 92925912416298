import React, { PureComponent } from "react";
import { connect } from "react-redux";
import "./SidePanel.scss";
import { modalActions } from "~/store/modal";
import { ThemeContext } from "~/utils";

namespace SidePanel {
  interface StateProps {
    authenticatedUser: any;
  }
  interface DispatchProps {
    openModal: (modalId: string) => void;
    setModal: (modalId: string) => void;
  }
  interface OwnProps {
    toggelBlast: () => void;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapStateToProps = (_state: any) => ({
  authenticatedUser: _state.users.authenticatedUser,
});

const mapDispatchToProps = {
  openModal: modalActions.openModal,
  setModalState: modalActions.setModalState,
};

class SidePanel extends PureComponent<SidePanel.Props> {
  render() {
    const { authenticatedUser: user } = this.props;
    let brandColor;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }
    return (
      <aside
        className="SidePanel"
        style={brandColor ? { backgroundColor: brandColor } : {}}
      >
        <button
          type="button"
          onClick={() => this.props.openModal("CONTACTS_MODAL")}
        >
          <i className="icon-contacts"></i>
          <span className="tooltip">Contacts</span>
        </button>
        <button
          type="button"
          onClick={() => this.props.openModal("REPLIES_MODAL")}
        >
          <i className="icon-reply-options"></i>
          <span className="tooltip">Reply&nbsp;Options</span>
        </button>
        <button
          type="button"
          onClick={() => this.props.openModal("TEMPLATE_MODAL")}
        >
          <h1 style={{ fontSize: 30, margin: 0 }}>T</h1>
          <span className="tooltip">Template</span>
        </button>
        <button
          type="button"
          onClick={() => this.props.openModal("REPORTS_MODAL")}
        >
          <i className="icon-reports"></i>
          <span className="tooltip">Reports</span>
        </button>
        <button
          type="button"
          onClick={() => this.props.openModal("SEARCH_MODAL")}
        >
          <i className="icon-advanced-search"></i>
          <span className="tooltip">Advanced&nbsp;Search</span>
        </button>
        <button
          type="button"
          onClick={() => this.props.openModal("SETTINGS_MODAL")}
        >
          <i className="icon-settings"></i>
          <span className="tooltip">Global&nbsp;Settings</span>
        </button>
        <button type="button" onClick={() => this.props.openModal("HELP_INFO")}>
          <i className="icon-help"></i>
          <span className="tooltip">Help</span>
        </button>
        <button type="button" onClick={this.props.toggelBlast}>
          <i className="icon-broadcast"></i>
          <span className="tooltip">Blast</span>
        </button>
      </aside>
    );
  }
}

SidePanel.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SidePanel as any);
