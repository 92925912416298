import { AxiosResponse } from "axios";
import classNames from "classnames";
import React, { BaseSyntheticEvent, Component } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { connect } from "react-redux";
import { IStatusForm, statusActions } from "~/store/statuses";
import { websocketActions } from "~/store/websocket";
import ThemedStatusInput from "../common/ThemedStatusInput/ThemedStatusInput";
import "./ProfileStatus.scss";

interface StateProps {
  authenticatedUser: any;
  statuses: IStatusForm[];
  isAuthenticated: boolean;
  ws: boolean;
  userStatuses: any;
}

interface DispatchProps {
  createStatus: (form: IStatusForm) => Promise<AxiosResponse>;
  deleteStatus: (id: number) => Promise<AxiosResponse>;
  listStatuses: () => Promise<AxiosResponse>;
  userSendStatus: (status: any) => Promise<AxiosResponse>;
  updateStatus: (form: IStatusForm) => Promise<AxiosResponse>;
}

interface OwnProps {}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  isOpen: boolean;
  editId: number;
  activeColor: number;
  activeEditColor: number;
  edit: IStatusForm;
  form: IStatusForm;
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  statuses: state.statuses.data,
  isAuthenticated: state.users.isAuthenticated,
  ws: state.websocket.connected,
  userStatuses: state.statuses.statuses,
});

const mapDispatchToProps = {
  createStatus: statusActions.createStatus,
  deleteStatus: statusActions.deleteStatus,
  listStatuses: statusActions.listStatuses,
  userSendStatus: websocketActions.userSendStatus,
  updateStatus: statusActions.updateStatus,
};

class ProfileStatus extends Component<Props, State> {
  state = {
    isOpen: false,
    editId: -1,
    activeColor: 0,
    activeEditColor: 0,
    colors: ["green", "red", "yellow"],
    edit: {
      color: "",
      title: "",
    },
    form: {
      color: "",
      id: -1,
      title: "",
    },
  };

  componentDidMount() {
    // Get statuses
    this.props.listStatuses();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.isOpen === false && this.state.isOpen === true) {
      this.props.listStatuses();
    }
  }

  toggle = () => this.setState(prevState => ({ isOpen: !prevState.isOpen }));

  close = () => this.setState({ isOpen: false, editId: -1 });

  deleteStatus = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const {
      dataset: { id },
    } = e.currentTarget;
    this.props.deleteStatus(+id);
  };

  apply = (form: IStatusForm, isEdit: boolean = false) => {
    if (isEdit) {
      this.props.updateStatus(form).then(() =>
        this.setState({
          editId: -1,
        })
      );
    } else {
      this.props.createStatus(form);
    }
  };

  openEdit = (e: BaseSyntheticEvent, status: IStatusForm) => {
    e.stopPropagation();
    this.setState({ editId: status.id || -1 });
  };

  setStatus = (status: any) => {
    this.props.userSendStatus(status);
    this.close();
  };

  cancelEdit = (e: BaseSyntheticEvent | KeyboardEvent) => {
    e.stopPropagation();
    this.setState({ editId: -1 });
  };

  render() {
    const currentStatus = (this.props.ws &&
      this.props.userStatuses[this.props.authenticatedUser.id]) || {
      title: "Offline",
      class: "gray",
    };
    const renderStatuses = (isEdit: boolean) => {
      return this.props.statuses.map((status: IStatusForm, i: number) => (
        <li
          className={classNames({
            editing: isEdit && this.state.editId === status.id,
          })}
          key={i}
          onClick={() =>
            this.setStatus({ auto_status: 0, custom_status: status.id })
          }
        >
          {isEdit && this.state.editId === status.id ? (
            <ThemedStatusInput
              autofocus={true}
              isEdit={isEdit}
              edit={status}
              onApply={this.apply}
              onCancel={this.cancelEdit}
            />
          ) : (
            <>
              <div className="buttons">
                <button type="button" onClick={e => this.openEdit(e, status)}>
                  <i className="icon-edit"></i>
                </button>
                <button
                  type="button"
                  onClick={this.deleteStatus}
                  data-id={status.id}
                >
                  <i className="icon-delete"></i>
                </button>
              </div>
              <i className={`icon-circle icon-${status.color}`}></i>
              {status.title}
            </>
          )}
        </li>
      ));
    };

    return (
      <div className="ProfileStatus">
        <OutsideClickHandler onOutsideClick={this.close}>
          <>
            <button onClick={this.toggle}>
              {
                <>
                  <i className={`icon-circle icon-${currentStatus.class}`}></i>{" "}
                  {currentStatus.title}
                </>
              }
            </button>
            {this.state.isOpen && (
              <div>
                <ul>
                  <li
                    onClick={() =>
                      this.setStatus({ auto_status: 1, custom_status: 0 })
                    }
                  >
                    <i className={`icon-circle icon-green`}></i>
                    Available
                  </li>
                  {renderStatuses(this.state.editId !== -1)}
                </ul>
                {this.props.statuses.length < 10 && (
                  <ThemedStatusInput
                    placeholder="Add status and hit return"
                    onApply={this.apply}
                  />
                )}
              </div>
            )}
          </>
        </OutsideClickHandler>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileStatus);
