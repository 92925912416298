import Auth from "./Auth/AuthView";
import asyncComponent from "../components/common/AsyncComponent";

const AsyncChat = asyncComponent(() => import("./Chat/ChatView"));

export default [
  {
    canAccess: false,
    component: AsyncChat,
    exact: true,
    path: "/",
  },
  {
    canAccess: true,
    component: Auth,
    exact: true,
    path: "/reset",
  },
  {
    canAccess: true,
    component: Auth,
    exact: true,
    path: "/restore",
  },
  {
    canAccess: true,
    component: Auth,
    path: "/reset/:token",
  },
  {
    canAccess: true,
    component: Auth,
    exact: true,
    path: "/login",
  },
  {
    canAccess: false,
    component: Auth,
    exact: true,
    path: "/signup",
  },
];
