import moment from "moment";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import "./SearchResults.scss";
import { conversationActions } from "~/store/conversations";
import { modalActions } from "~/store/modal";
import { fpn } from "~/utils";

namespace SearchResults {
  export type State = {};

  interface StateProps {
    authenticatedUser: any;
    contacts: [];
    results: [];
    searchQuery: string;
  }

  interface DispatchProps {
    closeModal: () => void;
    openConversationAndScroll: (
      conversationId: number,
      messageId: number
    ) => void;
  }

  interface OwnProps {
    shown: boolean;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  openConversationAndScroll: conversationActions.openConversationAndScroll,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  contacts: state.contacts.data,
  results: state.search.data,
  searchQuery: state.search.form.query,
});

class SearchResults extends PureComponent<
  SearchResults.Props,
  SearchResults.State
> {
  static propTypes = {
    shown: PropTypes.bool.isRequired,
  };

  formatDate(date: Date) {
    return moment(date)
      .utc()
      .format("MM/DD/YYYY");
  }

  formatLabel = (label: string, value: string) => {
    if (!value) return label;
    return (
      <span>
        {label.split(value).reduce((prev, current, i) => {
          if (!i) return [current];
          return prev.concat(<b key={value + current}>{value}</b>, current);
        }, [])}
      </span>
    );
  };

  formatRecipient = (phone: string) => {
    const { phones } = this.props.authenticatedUser;
    const contacts = this.props.contacts;
    let c_name = undefined;
    const digitsExp = new RegExp("^[0-9]+$", "g");

    if (phones.length) {
      // Check phone in authenticated user phones list
      const userPhones = phones.find(
        (userPhone: any) => userPhone.phone_number === phone
      );
      if (userPhones) {
        // Phone found in authenticated user phones list
        c_name = userPhones.title;
      } else {
        // Check that phone number in the contacts list
        const contactPhones: any = contacts.find((contact: any) =>
          !contact.is_phone && contact.hasOwnProperty("display_as")
            ? !!contact.phones.find(p => p.phone_number === phone)
            : contact.phone_number === phone
        );
        if (contactPhones) {
          c_name =
            contactPhones.hasOwnProperty("display_as") &&
            contactPhones.display_as.length > 0
              ? contactPhones.display_as
              : `${contactPhones.first_name} ${contactPhones.last_name}`;
        } else {
          c_name = phone;
        }
      }
    }

    const isPhoneName = digitsExp.test(
      c_name
        .trim()
        .split(" ")
        .join("")
    );

    if (isPhoneName) {
      c_name = fpn(c_name, this.props.authenticatedUser.show_censor_contact);
    }

    return c_name;
  };

  handleMessage = (msg: any) => {
    this.props.openConversationAndScroll(
      msg._source.conversation_id,
      msg._source.message_id
    );
    this.props.closeModal();
  };

  render() {
    return (
      <div
        className={this.props.shown ? "SearchResults shown" : "SearchResults"}
      >
        <section>
          {this.props.authenticatedUser &&
          this.props.contacts &&
          this.props.results.length
            ? this.props.results.map((result, key) => (
                <div key={key}>
                  <span className="SearchResults__Date">
                    <i className="icon-date"></i>
                    {this.formatDate(result.key_as_string)}
                  </span>
                  <ul className="SearchResults__Messages">
                    {result.top_hits_in_group.hits.hits.map(
                      (msg, k: number) => (
                        <li
                          key={
                            k +
                            "msg" +
                            msg._source.conversation_id +
                            msg._source.message_id
                          }
                          onClick={() => this.handleMessage(msg)}
                        >
                          <div>
                            {this.formatRecipient(msg._source.from_phone_title)}
                            <i className="icon-outbound"></i>
                            {this.formatRecipient(msg._source.to_phone_title)}
                          </div>
                          {this.formatLabel(
                            msg._source.text_content,
                            this.props.searchQuery
                          )}
                        </li>
                      )
                    )}
                  </ul>
                </div>
              ))
            : "No results found."}
        </section>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResults as any);
