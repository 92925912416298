import React from "react";
import { connect } from "react-redux";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import Modal from "../AbstractModal/AbstractModal";
import "./ConfirmModal.scss";

interface OwnProps {
  title: string;
  body: string;
  onYes: Function;
  onNo: Function;
}

interface StateProps {
  isFetching: boolean;
  errors: any[];
}

type Props = OwnProps & StateProps;

const mapStateToProps = (state: any): StateProps => ({
  isFetching: state.modal.isFetching,
  errors: state.modal.modalProps.errors,
});

export default connect(mapStateToProps)((props: Props) => {
  console.log("props=> ", props);
  return (
    <Modal title={props.title} confrimModal className="ConfirmModal">
      <div>
        <p dangerouslySetInnerHTML={{ __html: props.body }}></p>
        {props.errors &&
          props.errors.length !== 0 &&
          props.errors.map((error, index) => (
            <span key={index} className="error">
              {error}
            </span>
          ))}
        <footer>
          {!props.errors || !props.errors.length ? (
            <>
              <ThemedButton className="btn-primary" onClick={props.onYes}>
                Yes
              </ThemedButton>
              <ThemedButton
                className="btn-secondary is-cancel"
                onClick={props.onNo}
              >
                No
              </ThemedButton>
            </>
          ) : (
            <ThemedButton className="btn-primary" onClick={props.onNo}>
              Close
            </ThemedButton>
          )}
        </footer>
      </div>
    </Modal>
  );
});
