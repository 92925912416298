import React, { Component } from "react";
import IdleTimer from "react-idle-timer";
import { connect } from "react-redux";
import socketCluster from "socketcluster-client";
import { wsOptions } from "~/config";
import { conversationActions } from "~/store/conversations";
import { locationChange } from "~/store/location";
import { schedulesActions } from "~/store/schedules";
import { IStatus, statusActions } from "~/store/statuses";
import { usersActions } from "~/store/user";
import { websocketActions } from "~/store/websocket";

const LOGOUT_AFTER = "30";
const STATUS_IDLE = "10";

namespace Websocket {
  interface StateProps {
    isAuthenticated: boolean;
    authenticatedUser: any;
    contacts: any;
    activeConversationId: string;
  }

  interface DispatchProps {
    setWebsocketClose: () => void;
    setWebsocketInstance: (instance: any) => void;
    receiveConversation: (conversation: any) => void;
    markConversationDeleted: (conversation: any) => void;
    receiveMessage: (message: any) => void;
    logoutUser: (cb: any) => void;
    setUnreadMessages: (payload: any) => void;
    setUserStatus: (status: IStatus) => void;
    userSendStatus: (status: any) => void;
    updateToken: (token: string) => void;
    removeMessage: (message: any) => void;
    removeScheduler: (id: number) => void;
  }

  interface OwnProps {
    children: Element[];
    onMessageArrived: () => void;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  setWebsocketClose: websocketActions.setWebsocketClose,
  setWebsocketInstance: websocketActions.setWebsocketInstance,
  // receiveBlast: conversationActions.receiveBlast,
  receiveConversation: conversationActions.receiveConversation,
  markConversationDeleted: conversationActions.markConversationDeleted,
  receiveMessage: conversationActions.receiveMessage,
  logoutUser: usersActions.logoutUser,
  setUnreadMessages: conversationActions.setUnreadMessages,
  setUserStatus: statusActions.setUserStatus,
  userSendStatus: websocketActions.userSendStatus,
  updateToken: usersActions.updateToken,
  removeMessage: conversationActions.removeMessage,
  removeScheduler: schedulesActions.removeSchedule,
};

const mapStateToProps = (state: any) => ({
  isAuthenticated: state.users.isAuthenticated,
  authenticatedUser: state.users.authenticatedUser,
  contacts: state.contacts.data,
  activeConversationId: state.schedules.activeConversationId,
});

class Websocket extends Component<Websocket.Props> {
  idleTimer: IdleTimer | null = null;
  componentDidUpdate(prevProps: Websocket.Props) {
    if (
      prevProps.isAuthenticated === false &&
      this.props.isAuthenticated !== false
    ) {
      const ws = socketCluster.create(wsOptions);
      // Store websocket instance
      ws.on("connect", () => this.props.setWebsocketInstance(ws));
      ws.on("disconnect", this.props.setWebsocketClose);
      // Add message handlers
      ws.on("error", this.handleError);
      ws.on("post_add", this.handlePostAdd);
      ws.on("conversation_add", this.handleConversationAdd);
      ws.on("conversation_delete", this.handleConversationDelete);
      ws.on("blast_add", this.handleConversationAdd);
      // User session expired
      ws.on("removeAuthToken", this.handleLogout);
      ws.on("unread_messages", this.setUnreadMessages);
      // Statuses
      ws.on("user_status", this.user_status);
      //setInterval(this.checkIfNeedLogout, 5000);

      ws.on("update_token", this.updateToken);
      ws.on("remove_message", this.handleRemoveMessage);
    }
  }

  handleRemoveMessage = (message: any, res: any) => {
    this.props.removeMessage(message);
    res(null, true);
  };

  user_status = (status: IStatus, res: Function) => {
    res(null, true);
    this.props.setUserStatus(status);
  };

  handleError = (err: any) => {
    console.error(err);
  };

  updateToken = (obj: any, res: Function) => {
    localStorage.setItem("user", obj.token);
    this.props.updateToken(obj.token);
    res(null, true);
  };

  handleLogout = () => {
    localStorage.clear();
    this.props.logoutUser(() => locationChange("/"));
  };

  handlePostAdd = (message: any, res: any) => {
    const eContact = this.props.contacts.find(
      contact =>
        contact.phone_number &&
        contact.phone_number === message.sender_phone_number &&
        contact.hasOwnProperty("in_contacts")
    );
    // backend sens wrong message.sender data if it's internal from different company, it's easier to fix that here
    if (eContact) {
      message.sender.first_name = eContact.first_name;
      message.sender.last_name = eContact.last_name;
      message.sender.internal = false;
    }
    this.props.receiveMessage(message);
    if (
      message.scheduler_id &&
      this.props.activeConversationId === message.conversation_id
    ) {
      this.props.removeScheduler(message.scheduler_id);
    }

    if (message.sender_id !== this.props.authenticatedUser.id) {
      this.props.onMessageArrived();
    }
    res(null, true);
  };

  handleConversationAdd = (conversation: any, res: any) => {
    this.props.receiveConversation(conversation);
    res(null, true);
  };

  handleConversationDelete = (conversation: any, res: any) => {
    this.props.markConversationDeleted(conversation);
    res(null, true);
  };

  setUnreadMessages = (payload: any, res: any) => {
    res(null, true);
    if (payload && Object.keys(payload).length) {
      this.props.setUnreadMessages(payload);
    }
  };

  onActive = () => {
    this.props.userSendStatus({ auto_status: 1 });
  };

  onIdle = () => {
    this.props.userSendStatus({ auto_status: 2 });
  };

  checkIfNeedLogout = () => {
    if (
      this.idleTimer &&
      this.idleTimer.isIdle() &&
      +new Date() - this.idleTimer.getLastActiveTime() >
        1000 * 60 * +LOGOUT_AFTER
    ) {
      this.handleLogout();
      console.debug("Force logout by inactivity");
    }
  };

  render() {
    return !this.props.isAuthenticated ? (
      this.props.children
    ) : (
      <IdleTimer
        ref={ref => {
          this.idleTimer = ref;
        }}
        element={document}
        onActive={this.onActive}
        onIdle={this.onIdle}
        debounce={250}
        timeout={1000 * 60 * +STATUS_IDLE}
      >
        {this.props.children}
      </IdleTimer>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Websocket as any);
