import React, { PureComponent } from "react";
import { connect } from "react-redux";
import withSizes from "react-sizes";
import ReactTooltip from "react-tooltip";
import IconHelp from "~/assets/help.svg";
import { Branding } from "~/branding";
import ThemedTab from "~/components/common/ThemedTab/ThemedTab";
import ScheduleEdit from "~/components/forms/ScheduleEdit/ScheduleEdit";
import { modalActions } from "~/store/modal";
import Modal from "../AbstractModal/AbstractModal";
import "./ScheduleModal.scss";

namespace ScheduleModal {
  interface DispatchProps {
    closeModal: () => void;
  }
  interface OwnProps {
    isMobile: boolean;
    scheduleId: number;
    activeTabId: number;
  }
  interface StateProps {}
  export interface State {}
  export type Props = DispatchProps & StateProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
};

const mapStateToProps = (state: any) => ({});

@withSizes(({ width }) => ({ isMobile: width < 600 }))
class ScheduleModal extends PureComponent<
  ScheduleModal.Props,
  ScheduleModal.State
> {
  closeEditForm = () => this.props.closeModal();

  render() {
    const ScheduleTab = () => (
      <>
        {
          <ScheduleEdit
            shown={false}
            scheduleId={this.props.scheduleId}
            onBack={this.closeEditForm}
            activeTabId={this.props.activeTabId}
          />
        }
      </>
    );

    const tab = {
      id: 1,
      title: (
        <span>
          Schedules
          <img
            className="help-icon"
            src={IconHelp}
            alt="Help"
            data-tip
            data-for="Schedule"
          />
          <ReactTooltip
            className="tooltipContainer tooltipRight"
            id="Schedule"
            effect="solid"
            place="bottom"
            offset={{ left: this.props.isMobile ? 124 : 0 }}
          >
            <span className="tooltipContent">
              Schedule are commonly used responses that could be prebuilt, so
              the response doesn’t need to be retyped each time.
              <br />
              Schedule examples:
              <ol>
                <li>
                  {`“Thank you for reaching us today. Please visit our website:
                  ${Branding.WEBSITE_URL} to get more information.”`}
                </li>
                <li>
                  “To pay your monthly bill online, please go to
                  www.monthlyutility.com/billpay”
                </li>
              </ol>
            </span>
          </ReactTooltip>
        </span>
      ),
      component: <ScheduleTab />,
    };

    return (
      <Modal className="ScheduleModal" title="Schedule Options">
        <ul className="ScheduleModal__Tabs">
          <ThemedTab key={tab.id} className={"active"} data-tab-id={tab.id}>
            {tab.title}
          </ThemedTab>
        </ul>
        <section>{tab.component}</section>
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleModal as any);
