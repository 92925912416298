import React, { Component } from "react";
import "./Sidebar.scss";
import { connect } from "react-redux";
import { modalActions } from "~/store/modal";
import { LightenDarkenColor, ThemeContext } from "~/utils";
import { Branding } from "~/branding";

namespace Sidebar {
  interface StateProps {
    authenticatedUser: any;
  }
  interface DispatchProps {
    openModal: (modalId: string) => void;
  }
  interface OwnProps {
    className: string;
    close: () => void;
    isOpen: boolean;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapState = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

const mapDispatch = {
  openModal: modalActions.openModal,
};

class Sidebar extends Component<Sidebar.Props> {
  open(modalId: string) {
    this.props.openModal(modalId);
    this.props.close();
  }

  render() {
    let brandColor, darkenColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      console.log(theme, theme === "default");
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      darkenColor = LightenDarkenColor(brandColor, -30);
    }

    return (
      <div className={"Sidebar" + (this.props.isOpen ? " open" : "")}>
        <aside
          style={
            brandColor && darkenColor
              ? { background: brandColor, borderBottomColor: darkenColor }
              : {}
          }
        >
          <header>
            <button type="button" onClick={this.props.close}>
              <i className="icon-close"></i>
            </button>
          </header>
          <ul>
            <li onClick={this.open.bind(this, "CONTACTS_MODAL")}>
              <i className="icon-contacts"></i>
              <span>Contacts</span>
            </li>
            <li onClick={this.open.bind(this, "REPLIES_MODAL")}>
              <i className="icon-reply-options"></i>
              <span>Reply Options</span>
            </li>
            <li onClick={this.open.bind(this, "REPORTS_MODAL")}>
              <i className="icon-reports"></i>
              <span>Reports</span>
            </li>
            <li onClick={this.open.bind(this, "SEARCH_MODAL")}>
              <i className="icon-advanced-search"></i>
              <span>Advanced Search</span>
            </li>
            <li onClick={this.open.bind(this, "SETTINGS_MODAL")}>
              <i className="icon-settings"></i>
              <span>Settings</span>
            </li>
            <li onClick={this.open.bind(this, "HELP_INFO")}>
              <i className="icon-help"></i>
              <span>Help</span>
            </li>
          </ul>
          <footer>
            <a href="/terms">Terms of Use</a>
            <span>&copy; 2019 {`${Branding.APP_NAME}`}</span>
          </footer>
        </aside>
        <section className={this.props.className}>
          {this.props.children}
        </section>
      </div>
    );
  }
}

Sidebar.contextType = ThemeContext;

export default connect(
  mapState,
  mapDispatch
)(Sidebar);
