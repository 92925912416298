import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ReactTooltip from "react-tooltip";
import IconUnsubscribe from "~/assets/unsubscribe.svg";
import { fpn } from "~/utils";
import "./ContactList.scss";
import {
  contactsActions,
  IInternalContact,
  IExternalContact,
} from "~/store/contacts";
import Loader from "../common/Loader/Loader";

interface DispatchProps {
  getContactsList: () => void;
  setActiveContact: (id: string) => void;
}

interface StateProps {
  activeContact: IExternalContact | IInternalContact | null;
  authenticatedUser: any;
  isFetching: boolean;
  items: (IExternalContact | IInternalContact)[];
  statuses: any;
}

interface OwnProps {
  message?: string;
  isEdit: any;
  changeShown: (shown: string) => void;
  search: string;
  shown: boolean;
}

type Props = DispatchProps & OwnProps & StateProps;

const mapDispatchToProps = {
  getContactsList: contactsActions.getContactsList,
  setActiveContact: contactsActions.setContact,
};
const mapStateToProps = (state: any) => ({
  activeContact: state.contacts.activeContact,
  addNewFromNumber: state.modal.modalProps && state.modal.modalProps.activeEdit,
  authenticatedUser: state.users.authenticatedUser,
  isFetching: state.contacts.isFetching,
  items: state.contacts.data,
  statuses: state.statuses.statuses,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  let blacklist = [];
  const defaultPageSize = 50;
  const [pageSize, setPageSize] = useState(defaultPageSize);
  useEffect(() => {
    props.getContactsList();
  }, []);

  useEffect(() => {
    if (props.authenticatedUser) {
      const { activePhone, phones } = props.authenticatedUser;
      if (phones) {
        const active = phones.find((phone: any) => phone.id === activePhone);
        if (active) {
          blacklist = active.blacklisted;
        }
      }
    }
  }, [props.authenticatedUser]);

  function prepareContactLabel(item: any) {
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const name =
      item.display_as && item.display_as.length
        ? `${item.display_as}`
        : `${item.first_name} ${item.last_name}`;
    const isPhoneName = digitsExp.test(
      name
        .trim()
        .split(" ")
        .join("")
    );
    return isPhoneName
      ? fpn(name, props.authenticatedUser.show_censor_contact)
      : name;
  }

  function handleContactClick(item: any) {
    if (!props.isEdit) {
      props.setActiveContact(item.id);
      props.changeShown("profile");
    }
  }

  function filterContacts() {
    let list = props.items.filter(
      item =>
        (item.hasOwnProperty("in_contacts") ||
          item.hasOwnProperty("display_as")) &&
        item.is_deleted !== true
    );
    if (props.search) {
      list = list.filter(item =>
        (item.display_as || `${item.first_name} ${item.last_name}`)
          .toLowerCase()
          .includes(props.search.toLowerCase())
      );
    }
    return list
      .sort(
        (
          a: IExternalContact | IInternalContact,
          b: IExternalContact | IInternalContact
        ): number => {
          const a_name = (a.hasOwnProperty("display_as") && a.display_as.length
            ? a.display_as
            : a.first_name + (a.last_name && ` ${a.last_name}`)
          ).toLowerCase();
          const b_name = (b.hasOwnProperty("display_as") && b.display_as.length
            ? b.display_as
            : b.first_name + (b.last_name && ` ${b.last_name}`)
          ).toLowerCase();
          return a_name === b_name ? 0 : a_name < b_name ? -1 : 1;
        }
      )
      .slice(0, pageSize);
  }

  const filteredItems = filterContacts();
  useEffect(() => {
    if (filteredItems[0] && !props.addNewFromNumber) {
      props.setActiveContact(filteredItems[0].id);
    }
  }, []);

  const handleScroll = (event: any) => {
    if (event) {
      const target = event.target;
      const diff = Math.trunc(target.scrollHeight - target.scrollTop);
      const bottom = diff <= Math.trunc(target.clientHeight);
      if (bottom) {
        if (props.items.length >= pageSize + defaultPageSize) {
          setPageSize(pageSize + defaultPageSize);
        } else if (props.items.length != pageSize) {
          setPageSize(props.items.length);
        }
      }
    }
  };

  return (
    <ul
      className={classNames("ContactList searchable", { shown: props.shown })}
      onScroll={handleScroll}
    >
      {props.isFetching && props.items.length === 0 ? (
        <Loader />
      ) : filteredItems.length ? (
        filteredItems.map((item: any) => (
          <li
            key={item.id}
            className={classNames({
              active: props.activeContact && props.activeContact.id === item.id,
              disabled: props.isEdit,
            })}
            onClick={handleContactClick.bind(null, item)}
          >
            <i
              className={classNames(
                props.statuses[item.id] && props.statuses[item.id].class
                  ? `icon-${props.statuses[item.id].class}`
                  : "",
                {
                  "icon-circle": !item.is_phone && item.username,
                  "icon-phone": item.is_phone,
                  "icon-profile": !item.username,
                }
              )}
            />
            <span>{prepareContactLabel(item)}</span>
            {blacklist.includes(item.phone_number) && (
              <img
                src={IconUnsubscribe}
                alt="Unsubscribed"
                data-tip
                data-for={`contactUnsubscribe${item.id}`}
              />
            )}
            <ReactTooltip
              className="contactTooltip"
              id={`contactUnsubscribe${item.id}`}
              effect="solid"
            >
              <span>Unsubscribed</span>
            </ReactTooltip>
          </li>
        ))
      ) : (
        <li>{props.message || "No data"}</li>
      )}
    </ul>
  );
});
