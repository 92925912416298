import Axios, { AxiosError, AxiosResponse } from "axios";
import { API_URL } from "../config";
import { Dispatch } from "redux";
import { withAuthorizationHeader } from "./index";
import { CONVERSATIONS_UNSET } from "./conversations";
import { STATUS_SET_SUCCESS } from "./statuses";
import { SET_ALL_UNREAD_COUNT } from "./conversations";
import { reduce } from "lodash";

// ------------------------------------
// Constants
// ------------------------------------

export const UPDATE_USER_TOKEN = "UPDATE_USER_TOKEN";

const USERS_CREATE_USER_FAILURE = "USERS_CREATE_USER_FAILURE";
const USERS_CREATE_USER_REQUEST = "USERS_CREATE_USER_REQUEST";
const USERS_CREATE_USER_SUCCESS = "USERS_CREATE_USER_SUCCESS";

const USERS_DELETE_USER_FAILURE = "USERS_DELETE_USER_FAILURE";
const USERS_DELETE_USER_REQUEST = "USERS_DELETE_USER_REQUEST";
const USERS_DELETE_USER_SUCCESS = "USERS_DELETE_USER_SUCCESS";

const USERS_GET_USER_FAILURE = "USERS_GET_USER_FAILURE";
const USERS_GET_USER_REQUEST = "USERS_GET_USER_REQUEST";
const USERS_GET_USER_SUCCESS = "USERS_GET_USER_SUCCESS";

const USERS_LIST_USERS_FAILURE = "USERS_LIST_USERS_FAILURE";
const USERS_LIST_USERS_REQUEST = "USERS_LIST_USERS_REQUEST";
const USERS_LIST_USERS_SUCCESS = "USERS_LIST_USERS_SUCCESS";

const USERS_LOGIN_USER_FAILURE = "USERS_LOGIN_USER_FAILURE";
const USERS_LOGIN_USER_REQUEST = "USERS_LOGIN_USER_REQUEST";
const USERS_LOGIN_USER_SUCCESS = "USERS_LOGIN_USER_SUCCESS";

export const USERS_LOGOUT_USER_REQUEST = "USERS_LOGOUT_USER_REQUEST";

const AUTH_RESET_REQUEST_PASSWORD_FAILURE =
  "AUTH_RESET_REQUEST_PASSWORD_FAILURE";
const AUTH_RESET_REQUEST_PASSWORD_REQUEST =
  "AUTH_RESET_REQUEST_PASSWORD_REQUEST";
const AUTH_RESET_REQUEST_PASSWORD_SUCCESS =
  "AUTH_RESET_REQUEST_PASSWORD_SUCCESS";

const AUTH_RESET_CHECK_PASSWORD_FAILURE = "AUTH_RESET_CHECK_PASSWORD_FAILURE";
const AUTH_RESET_CHECK_PASSWORD_REQUEST = "AUTH_RESET_CHECK_PASSWORD_REQUEST";
const AUTH_RESET_CHECK_PASSWORD_SUCCESS = "AUTH_RESET_CHECK_PASSWORD_SUCCESS";

const USERS_UPDATE_USER_FAILURE = "USERS_UPDATE_USER_FAILURE";
const USERS_UPDATE_USER_REQUEST = "USERS_UPDATE_USER_REQUEST";
export const USERS_UPDATE_USER_SUCCESS = "USERS_UPDATE_USER_SUCCESS";

const USERS_VERIFY_TOKEN_SUCCESS = "USERS_VERIFY_TOKEN_SUCCESS";
const USERS_VERIFY_TOKEN_FAILURE = "USERS_VERIFY_TOKEN_FAILURE";

const USERS_SET_PHONE_SUCCESS = "USERS_SET_PHONE_SUCCESS";
const USERS_SET_PHONE_FAILURE = "USERS_SET_PHONE_FAILURE";
const USERS_SET_PHONE_REQUEST = "USERS_SET_PHONE_REQUEST";

const LOAD_CUSTOMISATION_REQUEST = "LOAD_CUSTOMISATION_REQUEST";
const LOAD_CUSTOMISATION_FAILURE = "LOAD_CUSTOMISATION_FAILURE";
const LOAD_CUSTOMISATION_SUCCESS = "LOAD_CUSTOMISATION_SUCCESS";

// ------------------------------------
// Interfaces
// ------------------------------------

export interface ILoginForm {
  email: string;
  password: string;
  remember_me: boolean;
}

export interface IResetPasswordForm {
  email: string;
}

export interface ICheckTokenForm {
  token: string;
}

export interface IUserPhone {
  company: any;
  created_at: string;
  id: number;
  phone_number: string;
  timezone: string;
  title: string;
}

// ------------------------------------
// Action Creators
// ------------------------------------

const createUserRequest = () => ({ type: USERS_CREATE_USER_REQUEST });
const createUserSuccess = (res: AxiosResponse) => ({
  type: USERS_CREATE_USER_SUCCESS,
  payload: res.data,
});
const createUserFailure = (err: AxiosError) => ({
  type: USERS_CREATE_USER_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteUserRequest = () => ({ type: USERS_DELETE_USER_REQUEST });
const deleteUserSuccess = (userId: number) => ({
  type: USERS_DELETE_USER_SUCCESS,
  payload: userId,
});
const deleteUserFailure = (err: AxiosError) => ({
  type: USERS_DELETE_USER_FAILURE,
  payload: err && err.response && err.response.data,
});

const checkResetTokenRequest = () => ({
  type: AUTH_RESET_CHECK_PASSWORD_REQUEST,
});
const checkResetTokenSuccess = (res: AxiosResponse) => ({
  type: AUTH_RESET_CHECK_PASSWORD_SUCCESS,
  payload: res.data,
});
const checkResetTokenFailure = (err: AxiosError) => ({
  type: AUTH_RESET_CHECK_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});

const resetUserRequest = () => ({ type: AUTH_RESET_REQUEST_PASSWORD_REQUEST });
const resetUserSuccess = (res: AxiosResponse) => ({
  type: AUTH_RESET_REQUEST_PASSWORD_SUCCESS,
  payload: res.data,
});
const resetUserFailure = (err: AxiosError) => ({
  type: AUTH_RESET_REQUEST_PASSWORD_FAILURE,
  payload: err && err.response && err.response.data,
});

const getUserRequest = () => ({ type: USERS_GET_USER_REQUEST });
const getUserSuccess = (res: AxiosResponse) => ({
  type: USERS_GET_USER_SUCCESS,
  payload: res.data,
});
const getUserFailure = (err: AxiosError) => ({
  type: USERS_GET_USER_FAILURE,
  payload: err && err.response && err.response.data,
});

const listUsersRequest = () => ({ type: USERS_LIST_USERS_REQUEST });
const listUsersSuccess = (res: AxiosResponse) => ({
  type: USERS_LIST_USERS_SUCCESS,
  payload: res.data,
});
const listUsersFailure = (err: AxiosError) => ({
  type: USERS_LIST_USERS_FAILURE,
  payload: err && err.response && err.response.data,
});

const loginUserRequest = () => ({ type: USERS_LOGIN_USER_REQUEST });
const loginUserSuccess = (res: AxiosResponse) => ({
  type: USERS_LOGIN_USER_SUCCESS,
  payload: res.data,
});
const loginUserFailure = (err: AxiosError) => ({
  type: USERS_LOGIN_USER_FAILURE,
  payload: err && err.response && err.response.data,
});

const setPhoneRequest = () => ({ type: USERS_SET_PHONE_REQUEST });
const setPhoneSuccess = (res: AxiosResponse) => ({
  type: USERS_SET_PHONE_SUCCESS,
  payload: res.data.data,
});

const loadDomainCustomizationRequest = () => ({
  type: LOAD_CUSTOMISATION_REQUEST,
});
const loadDomainCustomizationSuccess = (res: AxiosResponse) => ({
  type: LOAD_CUSTOMISATION_SUCCESS,
  payload: res.data,
});
const loadDomainCustomizationFailure = () => ({
  type: LOAD_CUSTOMISATION_FAILURE,
});

const updateUserToken = (token: string) => ({
  type: UPDATE_USER_TOKEN,
  payload: token,
});

// const setPhoneFailure = (err: AxiosError) => ({ type: USERS_SET_PHONE_FAILURE, payload: err && err.response && err.response.data })

const logoutUserRequest = () => ({ type: USERS_LOGOUT_USER_REQUEST });

const updateUserRequest = () => ({ type: USERS_UPDATE_USER_REQUEST });
const updateUserSuccess = (res: AxiosResponse) => ({
  type: USERS_UPDATE_USER_SUCCESS,
  payload: res.data,
});
const updateUserFailure = (err: AxiosError) => ({
  type: USERS_UPDATE_USER_FAILURE,
  payload: err && err.response && err.response.data,
});

const verifyTokenSuccess = (res: AxiosResponse) => ({
  type: USERS_VERIFY_TOKEN_SUCCESS,
  payload: res.data,
});
const verifyTokenFailure = (err: AxiosError) => ({
  type: USERS_VERIFY_TOKEN_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------

const createUser = (form: any) => (dispatch: Dispatch) => {
  dispatch(createUserRequest());
  return Axios.post(`${API_URL}/users`, form)
    .then(res => dispatch(createUserSuccess(res)))
    .catch(err => dispatch(createUserFailure(err)));
};

const deleteUser = (userId: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  if (Number.isInteger(userId)) {
    dispatch(deleteUserRequest());
    try {
      const { token } = getState().users.authenticatedUser;
      await Axios.delete(
        `${API_URL}/users/${userId}`,
        withAuthorizationHeader(token)
      );
      dispatch(deleteUserSuccess(userId));
    } catch (err) {
      dispatch(deleteUserFailure(err));
    }
  }
};

const getUser = (userId: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(getUserRequest());
  try {
    const { token } = getState().users.authenticatedUser;
    const res = await Axios.get(
      `${API_URL}/users/${userId}`,
      withAuthorizationHeader(token)
    );
    dispatch(getUserSuccess(res));
  } catch (err) {
    dispatch(getUserFailure(err));
  }
};

const listUsers = () => async (dispatch: Dispatch, getState: () => any) => {
  dispatch(listUsersRequest());
  try {
    const { token } = getState().users.authenticatedUser;
    const res = await Axios.get(
      `${API_URL}/users`,
      withAuthorizationHeader(token)
    );
    dispatch(listUsersSuccess(res));
  } catch (err) {
    dispatch(listUsersFailure(err));
  }
};

const loginUser = (form: any) => (dispatch: Dispatch) => {
  dispatch(loginUserRequest());
  return new Promise((resolve, reject) => {
    Axios.post(`${API_URL}/login`, form)
      .then(res => {
        dispatch(loginUserSuccess(res));
        localStorage.setItem("user", res.data.token);
        resolve(res);
      })
      .catch(err => {
        dispatch(loginUserFailure(err));
        reject(err);
      });
  });
};

const logoutUser = (callback?: Function) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(logoutUserRequest());
  const { instance } = getState().websocket;
  if (instance) instance.destroy();
  localStorage.removeItem("user");
  localStorage.removeItem("bubbleStyles");
  if (callback) callback();
};

/**
 *
 * @function updateUser
 * @param form @{}
 */
const updateUser = (form: object) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(updateUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const { id, token } = getState().users.authenticatedUser;
      const res = await Axios.put(
        `${API_URL}/users/${id}`,
        form,
        withAuthorizationHeader(token)
      );
      dispatch(updateUserSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(updateUserFailure(err));
      reject(err);
    }
  });
};

const verifyToken = (token: string) => (dispatch: Dispatch) => {
  return new Promise(async (resolve, reject) => {
    Axios.get(`${API_URL}/jwt`, withAuthorizationHeader(token))
      .then(res => {
        dispatch(verifyTokenSuccess(res));
        resolve(res);
      })
      .catch(err => {
        dispatch(verifyTokenFailure(err));
        reject(err);
      });
  });
};

const setPhone = (phone_id: number) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { instance } = getState().websocket;
  dispatch(setPhoneRequest());
  const { token } = getState().users.authenticatedUser;
  return new Promise((resolve, reject) => {
    Axios.post(
      `${API_URL}/selectphone`,
      { phone_id },
      withAuthorizationHeader(token)
    )
      .then(res => {
        if (res.data.status === "ok") {
          const data = res.data.data;
          instance.authenticate(data.token);
          dispatch(setPhoneSuccess(res));
          dispatch({ type: CONVERSATIONS_UNSET });
          localStorage.setItem("user", data.token);
          resolve(res);
        } else {
          reject(res);
        }
      })
      .catch(err => {
        // dispatch(setPhoneFailure(err));
        reject(err);
      });
  });
};

/**
 * @function resetPassword
 * @param form @{}
 */
const resetPassword = (form: IResetPasswordForm) => (dispatch: Dispatch) => {
  dispatch(resetUserRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/password/request`, form);
      dispatch(resetUserSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(resetUserFailure(err));
      reject(err);
    }
  });
};

/**
 * @function checkResetPasswordToken
 * @param form @{}
 */
const checkResetPasswordToken = (form: ICheckTokenForm) => (
  dispatch: Dispatch
) => {
  dispatch(checkResetTokenRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const res = await Axios.post(`${API_URL}/password/reset`, form);
      dispatch(checkResetTokenSuccess(res));
      localStorage.setItem("user", res.data.token);
      resolve(res);
    } catch (err) {
      dispatch(checkResetTokenFailure(err));
      reject(err);
    }
  });
};

/**
 * @function loadDomainCustomization
 * @param form @{}
 */
const loadDomainCustomization = () => (dispatch: Dispatch) => {
  dispatch(loadDomainCustomizationRequest());
  return new Promise(async (resolve, reject) => {
    try {
      const origin = window.location.hostname;
      const res = await Axios.post(`${API_URL}/wl`, { origin });
      dispatch(loadDomainCustomizationSuccess(res));
      resolve(res);
    } catch (err) {
      dispatch(loadDomainCustomizationFailure());
      reject(err);
    }
  });
};

const updateToken = (token: string) => (dispatch: Dispatch) => {
  dispatch(updateUserToken(token));
};

export const usersActions = {
  createUser,
  deleteUser,
  getUser,
  listUsers,
  loginUser,
  logoutUser,
  updateUser,
  verifyToken,
  resetPassword,
  checkResetPasswordToken,
  setPhone,
  loadDomainCustomization,
  updateToken,
};

// ------------------------------------
// Initial state
// ------------------------------------
const initialState = {
  isAuthenticated: false,
  isResetPassword: false,
  authenticatedUser: null,
  isDomainCustomizationFetched: false,
  errors: [],
  isFetching: false,
  user: null,
  users: [],
};

// ------------------------------------
// Reducer
// ------------------------------------
export default (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  const authenticatedUser = state.authenticatedUser || {
    company: {
      whitelabeling: null,
    },
    phones: [],
  };
  switch (type) {
    case USERS_CREATE_USER_REQUEST:
    case USERS_DELETE_USER_REQUEST:
    case USERS_GET_USER_REQUEST:
    case USERS_LIST_USERS_REQUEST:
    case USERS_LOGIN_USER_REQUEST:
    case AUTH_RESET_CHECK_PASSWORD_REQUEST:
    case AUTH_RESET_REQUEST_PASSWORD_REQUEST:
    case USERS_UPDATE_USER_REQUEST:
    case USERS_SET_PHONE_REQUEST:
      return { ...state, isFetching: true };

    case USERS_CREATE_USER_FAILURE:
    case USERS_DELETE_USER_FAILURE:
    case USERS_GET_USER_FAILURE:
    case USERS_LIST_USERS_FAILURE:
    case USERS_LOGIN_USER_FAILURE:
    case AUTH_RESET_CHECK_PASSWORD_FAILURE:
    case AUTH_RESET_REQUEST_PASSWORD_FAILURE:
    case USERS_UPDATE_USER_FAILURE:
    case USERS_SET_PHONE_FAILURE:
      return { ...state, isFetching: false, errors: payload };

    case USERS_CREATE_USER_SUCCESS:
    case USERS_GET_USER_SUCCESS:
      return { ...state, isFetching: false, user: payload };

    case USERS_LIST_USERS_SUCCESS:
      return { ...state, isFetching: false, users: payload };

    case USERS_UPDATE_USER_SUCCESS:
      return { ...state, isFetching: false, authenticatedUser: payload.data };

    case USERS_SET_PHONE_SUCCESS:
      return { ...state, isFetching: false, authenticatedUser: payload };

    case USERS_VERIFY_TOKEN_SUCCESS:
    case USERS_LOGIN_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        authenticatedUser: payload,
        isResetPassword: payload.isPasswordExpired,
      };

    case USERS_DELETE_USER_SUCCESS:
      return {
        ...state,
        isFetching: false,
        users: state.users.filter((user: any) => user.id !== payload),
      };

    case AUTH_RESET_CHECK_PASSWORD_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isAuthenticated: true,
        authenticatedUser: {
          ...authenticatedUser,
          ...payload,
          company: {
            ...authenticatedUser.company,
            whitelabeling: {
              ...authenticatedUser.company.whitelabeling,
            },
          },
        },
        isResetPassword: true,
      };

    case USERS_VERIFY_TOKEN_FAILURE:
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case STATUS_SET_SUCCESS:
      return {
        ...state,
        authenticatedUser: {
          ...authenticatedUser,
          custom_status: payload.data.id,
        },
      };

    case LOAD_CUSTOMISATION_SUCCESS:
      return {
        ...state,
        isDomainCustomizationFetched: true,
        authenticatedUser: {
          ...authenticatedUser,
          company: { whitelabeling: payload },
        },
      };

    case LOAD_CUSTOMISATION_FAILURE:
      return {
        ...state,
        isDomainCustomizationFetched: true,
        authenticatedUser: {
          ...authenticatedUser,
          company: false,
        },
      };

    case SET_ALL_UNREAD_COUNT:
      const phones = (authenticatedUser.phones || []).map((item: any) => ({
        ...item,
        unreadCount: reduce(
          payload[item.id] || [],
          (result, item) => {
            result += item.length;
            return result;
          },
          0
        ),
      }));
      return {
        ...state,
        authenticatedUser: {
          ...authenticatedUser,
          phones,
        },
      };
    case UPDATE_USER_TOKEN:
      return {
        ...state,
        authenticatedUser: { ...authenticatedUser, token: payload },
      };

    default:
      return state;
  }
};
