import React from "react";
import { connect } from "react-redux";
import { Branding } from "~/branding";

interface StateProps {
  domainWL: any;
}

interface OwnProps {
  text: any;
}

const keywords = [
  "appName",
  "commandCenterName",
  "partner",
  "support_email",
  "support_phone",
];

const whiteLabelEmailTemplate: any = {
  partner: {
    variableName: Branding.COMPANY_NAME,
    key: "partner_name",
  },
  appName: {
    variableName: Branding.APP_NAME,
    key: "application_name",
  },
  commandCenterName: {
    variableName: Branding.ADMIN_APP_NAME,
    key: "cc_name",
  },
  support_email: {
    variableName: Branding.SUPPORT_EMAIL,
    key: "support_email",
  },
  support_phone: {
    variableName: Branding.SUPPORT_PHONE_NUMBER,
    key: "support_phone",
    formatPhone: true,
  },
};
type Props = StateProps & OwnProps;

const mapStateToProps = (state: any): StateProps => ({
  domainWL:
    (state.users.authenticatedUser &&
      state.users.authenticatedUser.company &&
      state.users.authenticatedUser.company.whitelabeling) ||
    null,
});

export default connect(mapStateToProps)((props: Props) => {
  function textConverter() {
    if (!props.domainWL) {
      return props.text;
    }
    let text = props.text;
    keywords.forEach(keyword => {
      const replaceValue = props.domainWL[whiteLabelEmailTemplate[keyword].key];
      text = text.replace(
        new RegExp(whiteLabelEmailTemplate[keyword].variableName, "g"),
        replaceValue
      );
    });
    return text;
  }

  return <>{textConverter()}</>;
});
