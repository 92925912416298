import React, { Component } from "react";
import { connect } from "react-redux";
import ConversationsList from "../ConversationsList/ConversationsList";
import SearchResultItems from "../ConversationsList/SearchResultItems";
import PhonesDropdown from "../PhonesDropdown/PhonesDropdown";
import SearchInput from "../SearchInput/SearchInput";
import "./ConversationsPanel.scss";
import { Branding } from "~/branding";

interface StateProps {
  isAuthenticated: boolean;
  authenticatedUser: any;
}
interface DispatchProps {}
interface OwnProps {
  activeConversation: string;
  shown: boolean;
  openConversation: (conversationId: number) => void;
  isBlastOpen: boolean;
}
type Props = StateProps & DispatchProps & OwnProps;
interface State {
  search: string;
  clicked: boolean;
  open_search: boolean;
}

const mapStateToProps = (state: any, _ownProps: OwnProps): StateProps => ({
  isAuthenticated: state.users.isAuthenticated,
  authenticatedUser: state.users.authenticatedUser,
});

const mapDispatchToProps: DispatchProps = {};

class ConversationsPanel extends Component<Props, State> {
  state = {
    search: "",
    clicked: false,
    open_search: false,
  };

  componentDidUpdate = (prevProps: Props) => {
    if (
      prevProps.authenticatedUser &&
      this.props.authenticatedUser &&
      prevProps.authenticatedUser.activePhone !==
        this.props.authenticatedUser.activePhone
    ) {
      this.setState({ search: "", clicked: false, open_search: false });
    }
  };

  handleSetSearch = (search: string) => {
    this.setState({
      search: search,
      clicked: false,
      open_search: search.length === 0 ? false : this.state.open_search,
    });
  };

  render() {
    const {
      activeConversation,
      authenticatedUser,
      openConversation,
    } = this.props;
    const { search, clicked, open_search } = this.state;
    return (
      <aside
        className={
          this.props.shown ? "ConversationsPanel shown" : "ConversationsPanel"
        }
      >
        <header>
          <PhonesDropdown />
        </header>
        <footer>
          <SearchInput
            placeholder="Type name or phone..."
            onChange={this.handleSetSearch}
            search={search}
            clicked={clicked}
            open_search={open_search}
            onClicked={() => {
              if (search.trim().length > 0)
                this.setState({ clicked: true, open_search: true });
            }}
          />
        </footer>
        {search.length > 0 && (clicked === true || open_search === true) && (
          <SearchResultItems
            search={search.trim()}
            handleSetSearch={this.handleSetSearch}
            isBlastOpen={this.props.isBlastOpen}
            clicked={clicked}
          />
        )}
        {(search.length === 0 || open_search === false) && (
          <ConversationsList
            activeConversation={activeConversation}
            openConversation={openConversation}
            isBlastOpen={this.props.isBlastOpen}
          />
        )}
        {authenticatedUser &&
          authenticatedUser.company &&
          authenticatedUser.company.whitelabeling &&
          authenticatedUser.company.whitelabeling.show_logo_mv && (
            <img
              className="powered"
              src={Branding.POWERED_BY_LOGO}
              alt={`Powered by ${Branding.COMPANY_NAME}`}
            />
          )}
      </aside>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationsPanel as any);
