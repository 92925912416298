import { AxiosPromise } from "axios";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { BaseSyntheticEvent, ChangeEvent, PureComponent } from "react";
import { connect } from "react-redux";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import ThemedHighlitedTextarea from "~/components/common/ThemedHighlitedTextarea/ThemedHighlitedTextarea";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import {
  HIGHLIGHTS,
  ITemplate,
  templatesActions,
  TEMPLATE_KEYWORDS,
} from "~/store/templates";
import { ThemeContext } from "~/utils";
import "./TemplateEdit.scss";
import OutsideClickHandler from "react-outside-click-handler";
import AttachmentPanel from "../../AttachmentPanel/AttachmentPanel";
import EmojiPanel from "../../EmojiPanel/EmojiPanel";
import TemplateKeywords from "~/components/TemplatePanel/TemplateKeywords";

namespace TemplateEdit {
  export type State = {
    inputValue: string;
    isPanelOpen: boolean;
    templateId: number | null;
    form: ITemplate;
    isMMSPanelOpen: any;
    isLoading: boolean;
  };

  interface StateProps {
    authenticatedUser: any;
    templates: [];
  }

  interface DispatchProps {
    createTemplate: (form: ITemplate) => AxiosPromise;
    deleteTemplate: (id: number) => AxiosPromise;
    updateTemplate: (form: ITemplate) => AxiosPromise;
  }

  interface OwnProps {
    onBack: () => void;
    template?: number;
    shown: boolean;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  createTemplate: templatesActions.createTemplate,
  deleteTemplate: templatesActions.deleteTemplate,
  updateTemplate: templatesActions.updateTemplate,
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  templates: state.templates.data,
});

const initialMMSPanelsState: {
  [x: string]: boolean;
} = {
  Attachment: false,
  Emoji: false,
  Template: false,
};

const initialMediaState = {
  error: "",
  file: null,
  media: null,
};

class TemplateEdit extends PureComponent<
  TemplateEdit.Props,
  TemplateEdit.State
> {
  static propTypes = {
    template: PropTypes.number,
    onBack: PropTypes.func,
    shown: PropTypes.bool.isRequired,
  };

  state = {
    isPanelOpen: false,
    isLoading: false,
    templateId: -1,
    inputValue: "",
    isMMSPanelOpen: { ...initialMMSPanelsState },
    form: {
      title: "",
      template_content: "",
      media_data: initialMediaState,
    },
  };

  componentDidMount() {
    if (this.props.template !== -1) this.loadTemplate(this.props.template);
  }

  componentDidUpdate(prevProps: TemplateEdit.Props) {
    if (
      this.props.template !== -1 &&
      this.props.template !== prevProps.template
    )
      this.loadTemplate(this.props.template);
  }

  loadTemplate = (templateId?: number) => {
    if (this.props.templates !== null) {
      const template: ITemplate = Object.assign(
        {},
        this.props.templates.find((item: ITemplate) => item.id === templateId)
      );
      const media_data = template["media_data"] || initialMediaState;
      const isMMSPanelOpen = { ...initialMMSPanelsState };
      if (template["media_data"]) {
        isMMSPanelOpen.Attachment = true;
      }
      if (template["id"]) {
        this.setState({
          templateId: template["id"],
          isMMSPanelOpen: isMMSPanelOpen,
          form: { ...template, media_data },
        });
      }
    }
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: ITemplate = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    if (this.props.template !== -1) {
      // Update template
      this.props.updateTemplate(this.state.form).then(this.props.onBack);
    } else {
      // Create template
      this.props.createTemplate(this.state.form).then(this.props.onBack);
    }
  };

  createOption = (label: string) => ({ key: label, label, value: label });

  handleInputChange = (inputValue: string) => this.setState({ inputValue });

  togglePanel = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.setState(prevState => ({ isPanelOpen: !prevState.isPanelOpen }));
  };

  onCopy = () => {
    if (this.props.templates !== null) {
      let form: ITemplate | undefined | any = this.props.templates.find(
        (template: ITemplate) => template.id === this.props.template
      );
      if (form) {
        form = Object.assign({}, form);
        delete form.id;
        form.title += " Copy";
        this.props.createTemplate(form).then(this.props.onBack);
      }
    }
  };

  getRanges(input: string) {
    const ranges = HIGHLIGHTS.map(high_light => {
      const ranges = [];
      const inputLower = input;
      const strLower = high_light;
      let index = 0;
      while (((index = inputLower.indexOf(strLower, index)), index !== -1)) {
        ranges.push([index, index + strLower.length]);
        index += strLower.length;
      }
      return ranges;
    });
    return Array.prototype.concat.apply([], ranges);
  }

  toggleMMSPanel = (e: any) => {
    e.stopPropagation();
    this.setState({
      ...this.state,
      isMMSPanelOpen: {
        [e.target.id]: !this.state.isMMSPanelOpen[e.target.id],
      },
    });
  };

  closePanels = () => {
    if (
      this.state.isMMSPanelOpen.Attachment ||
      this.state.isMMSPanelOpen.Emoji ||
      this.state.isMMSPanelOpen.Template
    ) {
      this.setState({
        ...this.state,
        isMMSPanelOpen: { initialMMSPanelsState },
      });
    }
  };

  handleDeleteAttachment = () => {
    this.setState({
      form: { ...this.state.form, media_data: { initialMediaState } },
    });
  };

  handleCloseAttachment = () => {
    this.setState({
      ...this.state,
      isMMSPanelOpen: { initialMMSPanelsState },
    });
  };

  handleSetMedia = ({ file, media }: any) => {
    const media_data = {
      file: {
        name: file.name,
        size: file.size,
      },
      media: {
        media_file_mime: media.media_file_mime,
        media_link: media.media_link,
        media_minio_bucket_name: "lol",
        media_file_name: file.name,
      },
    };
    this.setState({ form: { ...this.state.form, media_data } });
  };

  handleEmoji = (emoji: any) => {
    const messageField: any = document.getElementById("template_content");
    if (messageField) {
      const start = messageField.selectionStart || 0;
      const end = messageField.selectionEnd || 0;
      this.setState(
        prevState => ({
          ...this.state,
          form: {
            ...this.state.form,
            template_content: [
              prevState.form.template_content.slice(0, start as number),
              emoji.native,
              prevState.form.template_content.slice(end as number),
            ].join(""),
          },
        }),
        () => {
          if (messageField !== null) {
            const pos = end - (end - start) + emoji.native.length;
            messageField.focus();
            messageField.setSelectionRange(pos, pos);
          }
        }
      );
    }
  };

  handleTemplate = (template: any) => {
    const messageField: any = document.getElementById("template_content");
    if (messageField) {
      const start = messageField.selectionStart || 0;
      const end = messageField.selectionEnd || 0;
      this.setState(
        prevState => ({
          ...this.state,
          form: {
            ...this.state.form,
            template_content: [
              prevState.form.template_content.slice(0, start as number),
              template,
              prevState.form.template_content.slice(end as number),
            ].join(""),
          },
        }),
        () => {
          if (messageField !== null) {
            const pos = end - (end - start) + template.length;
            messageField.focus();
            messageField.setSelectionRange(pos, pos);
          }
        }
      );
    }
  };

  render() {
    let brandColor: string | undefined = undefined;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }

    const template_options = {
      TEMPLATE_KEYWORDS,
      handleTemplate: this.handleTemplate,
    };

    return (
      <div className={this.props.shown ? "TemplateEdit shown" : "TemplateEdit"}>
        <header>
          <button type="button" onClick={this.props.onBack}>
            <i className="icon-back"></i>
          </button>
          <h1>{this.props.template !== -1 ? "Edit" : "Add"} Template</h1>
        </header>
        <div className="TemplateEdit__Form">
          <div>
            <label className="mandatory" htmlFor="title">
              Title
            </label>
            <ThemedInput
              id="title"
              type="text"
              onChange={this.handleChange}
              placeholder="Title"
              value={this.state.form.title}
            />
          </div>
          <div>
            <label className="mandatory" htmlFor="notes">
              Template
            </label>
            <ThemedHighlitedTextarea
              id="template_content"
              placeholder="Type Template..."
              className={"input highlight-input"}
              onChange={this.handleChange}
              value={this.state.form.template_content}
              getRanges={this.getRanges}
              rows={8}
            ></ThemedHighlitedTextarea>
          </div>
          <section className="MMSPanel">
            <>
              <OutsideClickHandler onOutsideClick={this.closePanels}>
                <div className="options">
                  <button
                    id="Attachment"
                    onClick={this.toggleMMSPanel}
                    style={brandColor ? { color: brandColor } : {}}
                    type="button"
                  >
                    <i className="icon-attach" id="Attachment"></i>
                    {this.state.form.media_data &&
                      this.state.form.media_data.media && (
                        <div className="badge" id="Attachment">
                          1
                        </div>
                      )}
                  </button>
                  <button
                    id="Emoji"
                    onClick={this.toggleMMSPanel}
                    style={brandColor ? { color: brandColor } : {}}
                    type="button"
                  >
                    <i className="icon-emoji" id="Emoji"></i>
                  </button>
                  <button
                    id="Template"
                    onClick={this.toggleMMSPanel}
                    style={brandColor ? { color: brandColor } : {}}
                    type="button"
                  >
                    [ ]
                  </button>
                </div>
                <EmojiPanel
                  selectEmoji={this.handleEmoji}
                  shown={
                    this.state &&
                    this.state.isMMSPanelOpen &&
                    this.state.isMMSPanelOpen.Emoji
                      ? this.state.isMMSPanelOpen.Emoji
                      : false
                  }
                  openDown={true}
                />
                <AttachmentPanel
                  attachment={this.state.form.media_data}
                  closePanel={this.handleCloseAttachment}
                  setAttachment={this.handleSetMedia}
                  deleteAttachment={this.handleDeleteAttachment}
                  shown={this.state.isMMSPanelOpen.Attachment}
                  openDown={true}
                  getLoadingStatus={(isLoading: boolean) => {
                    this.setState({ isLoading });
                  }}
                />
                {this.state.isMMSPanelOpen.Template && (
                  <TemplateKeywords {...template_options} />
                )}
              </OutsideClickHandler>
            </>
          </section>
        </div>

        <div
          onClick={this.togglePanel}
          className={classNames("panel", { open: this.state.isPanelOpen })}
        >
          <div>
            <ThemedButton type="button" onClick={this.onCopy}>
              Copy Template
            </ThemedButton>
            <button
              type="button"
              onClick={() => {
                this.props
                  .deleteTemplate(this.state.templateId)
                  .then(this.props.onBack);
              }}
            >
              Delete Template
            </button>
            <button
              className="close"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              Close
            </button>
          </div>
        </div>
        <footer>
          <ThemedButton
            type="submit"
            onClick={this.handleSubmit}
            disabled={
              !(
                this.state.form.title &&
                this.state.form.template_content &&
                !this.state.isLoading
              )
            }
          >
            {this.props.template !== -1 ? "Update" : "Add"}
          </ThemedButton>
          <button type="button" onClick={this.props.onBack}>
            Cancel
          </button>
          {this.props.template !== -1 && (
            <button
              className="more"
              type="button"
              style={brandColor ? { color: brandColor } : {}}
              onClick={this.togglePanel}
            >
              ···
            </button>
          )}
        </footer>
      </div>
    );
  }
}

TemplateEdit.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateEdit as any);
