import React, { PureComponent, ChangeEvent, BaseSyntheticEvent } from "react";
import { connect } from "react-redux";
import { usersActions } from "~/store/user";
import "./ProfileGeneral.scss";
import { modalActions } from "~/store/modal";
import { AxiosPromise } from "axios";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

namespace ProfileGeneral {
  export interface IForm {
    display_as: string;
    first_name: string;
    last_name: string;
    title: string;
  }

  export type State = {
    form: IForm;
  };

  interface StateProps {
    profile: any;
  }

  interface DispatchProps {
    closeModal: () => void;
    updateUser: (form: IForm) => AxiosPromise;
  }

  interface OwnProps {}

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  updateUser: usersActions.updateUser,
};

const mapStateToProps = (state: any) => ({
  profile: state.users.authenticatedUser,
});

@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
export default class ProfileGeneral extends PureComponent<
  ProfileGeneral.Props,
  ProfileGeneral.State
> {
  state = {
    form: {
      display_as: this.props.profile.display_as || "",
      first_name: this.props.profile.first_name || "",
      last_name: this.props.profile.last_name || "",
      title: this.props.profile.title || "",
    },
  };

  handleChange = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, value } = e.target as HTMLInputElement;
    const form: ProfileGeneral.IForm = { ...this.state.form, [id]: value };
    this.setState({ form });
  };

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    this.props.updateUser(this.state.form).then(this.props.closeModal);
  };

  render() {
    return (
      <React.Fragment>
        <form className="ProfileGeneral">
          <div className="columns">
            <div className="column">
              <label className="mandatory" htmlFor="first_name">
                First Name
              </label>
              <ThemedInput
                className="input"
                id="first_name"
                type="text"
                onChange={this.handleChange}
                placeholder="First Name"
                value={this.state.form.first_name}
              />
            </div>
            <div className="column">
              <label className="mandatory" htmlFor="last_name">
                Last Name
              </label>
              <ThemedInput
                className="input"
                id="last_name"
                type="text"
                onChange={this.handleChange}
                placeholder="Last Name"
                value={this.state.form.last_name}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <label htmlFor="display_as">Display As</label>
              <ThemedInput
                className="input"
                id="display_as"
                type="text"
                onChange={this.handleChange}
                placeholder="Display As"
                value={this.state.form.display_as}
              />
            </div>
            <div className="column">
              <label htmlFor="title">Company Title</label>
              <ThemedInput
                className="input"
                id="title"
                type="text"
                onChange={this.handleChange}
                placeholder="Title"
                value={this.state.form.title}
              />
            </div>
          </div>
        </form>
        <footer>
          <ThemedButton
            className="button is-primary"
            onClick={this.handleSubmit}
          >
            Save
          </ThemedButton>
          <button
            className="button"
            onClick={this.props.closeModal}
            type="button"
          >
            Cancel
          </button>
        </footer>
      </React.Fragment>
    );
  }
}
