import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { ThemeContext } from "~/utils";

interface StateProps {
  authenticatedUser: any;
}
interface DispatchProps {}
interface OwnProps {
  className: string;
  key: any;
  onClick?: any;
  "data-tab-id": any;
  children?: any;
}

export type Props = StateProps & DispatchProps & OwnProps;

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
});

class ThemedTab extends PureComponent<Props> {
  render() {
    let brandColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }

    return (
      <li
        key={this.props.key}
        className={this.props.className}
        style={
          brandColor && this.props.className === "active"
            ? { color: brandColor }
            : {}
        }
        onClick={this.props.onClick}
        data-tab-id={this.props["data-tab-id"]}
      >
        {this.props.children}
      </li>
    );
  }
}

ThemedTab.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedTab);
