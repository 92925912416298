import moment from "moment";
import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "../AbstractModal/AbstractModal";
import "./SearchModal.scss";
import SearchForm from "~/components/forms/SearchForm/SearchForm";
import SearchResults from "~/components/SearchResults/SearchResults";

namespace SearchModal {
  interface StateProps {
    periodFrom: Date;
    periodTo: Date;
    query: string;
  }
  interface DispatchProps {}
  interface OwnProps {}
  export type Props = StateProps & DispatchProps & OwnProps;
  export type State = {
    formScreen: boolean;
  };
}

const mapDispatchToProps = {};

const mapStateToProps = (state: any) => ({
  periodFrom: state.search.form.date_from,
  periodTo: state.search.form.date_to,
  query: state.search.form.query,
});

class SearchModal extends Component<SearchModal.Props, SearchModal.State> {
  state = {
    formScreen: true,
  };

  onBack = () => this.setState({ formScreen: true });

  render() {
    const searchResultsTitle = () => (
      <React.Fragment>
        Search Results for “<b>{this.props.query}</b>”
        <p>
          Period:{" "}
          <span>
            {moment(this.props.periodFrom).format("MM/DD/YYYY")} &mdash;{" "}
            {moment(this.props.periodTo).format("MM/DD/YYYY")}
          </span>
        </p>
      </React.Fragment>
    );
    return (
      <Modal
        title={this.state.formScreen ? "Advanced Search" : searchResultsTitle()}
        showBack={!this.state.formScreen}
        onBack={this.onBack}
        className="SearchModal"
      >
        {this.state.formScreen ? (
          <SearchForm onSearch={() => this.setState({ formScreen: false })} />
        ) : (
          <SearchResults shown={!this.state.formScreen} onBack={this.onBack} />
        )}
      </Modal>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchModal as any);
