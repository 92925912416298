import classNames from "classnames";
import React, { PureComponent, BaseSyntheticEvent } from "react";
import { connect } from "react-redux";
import Apply from "../../../assets/checkmark.svg";
import Cancel from "../../../assets/cancel.svg";
import { IStatusForm } from "~/store/statuses";

import { ThemeContext } from "~/utils";

import "./ThemedStatusInput.scss";

interface StateProps {
  authenticatedUser: any;
  statuses: IStatusForm[];
}

interface DispatchProps {}

interface OwnProps {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  edit: IStatusForm;
  id?: string;
  isEdit: boolean;
  maxLength?: number;
  onApply: (form: IStatusForm, isEdit: boolean) => void;
  onCancel: (e: BaseSyntheticEvent) => void;
  onChange?: any;
  onClick?: any;
  onKeyDown?: any;
  placeholder?: string;
  required?: boolean;
  value?: string;
  autofocus?: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

type State = {
  activeColorIndex: number;
  colors: string[];
  error: boolean;
  focus: boolean;
  form: IStatusForm;
};

const mapStateToProps = (state: any) => ({
  authenticatedUser: state.users.authenticatedUser,
  statuses: state.statuses.data,
});

const initialStateForm = {
  color: "",
  title: "",
};

class ThemedStatusInput extends PureComponent<Props, State> {
  state = {
    activeColorIndex: 0,
    colors: ["blue", "green", "red", "yellow"],
    error: false,
    focus: false,
    form: initialStateForm,
  };

  componentDidMount() {
    if (this.props.isEdit && this.props.edit) {
      let active = this.state.colors.indexOf(this.props.edit.color);
      if (active === -1) active = 0;
      this.setState({
        activeColorIndex: active,
        form: this.props.edit,
      });
    }
  }

  handleChangeColor = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const nextColorIndex =
      this.state.activeColorIndex !== this.state.colors.length - 1
        ? ++this.state.activeColorIndex
        : 0;
    this.setState({
      activeColorIndex: nextColorIndex,
      form: {
        ...this.state.form,
        color: this.state.colors[nextColorIndex],
      },
    });
  };

  handleChangeTitle = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    const { value } = e.target as HTMLInputElement;
    this.setState({
      error: this.props.statuses.map(status => status.title).includes(value)
      form: { ...this.state.form, title: value }
    });
  };

  handleKeyDown = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.keyCode === 13) {
      if (this.state.form.title.length) {
        this.apply(e);
      } else return;
    }
    if (e.keyCode === 27) {
      this.cancel(e);
    }
  };

  handleClick = e => {
    // Do not propagate click, because it cause to close the popup
    e.stopPropagation();
  };

  apply = e => {
    e.stopPropagation();
    if (!this.props.statuses.map(status => status.title).includes(this.state.form.title)) {
      this.props.onApply(this.state.form, !!this.props.isEdit);
      this.setState({
        activeColorIndex: 0,
        error: false,
        form: initialStateForm,
      });
    }
  };

  cancel = e => {
    e.stopPropagation();
    this.props.onCancel(e);
  };

  render() {
    let brandColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
    }

    return (
      <div className="ThemedStatusInput__wrapper">
        <button type="button" onClick={this.handleChangeColor}>
          <i className={`icon-circle icon-${this.state.form.color}`}></i>
        </button>
        <input
          autoComplete={this.props.autoComplete}
          className={classNames("ThemedStatusInput", (this.props.className || "input"), {error: this.state.error})}
          disabled={this.props.disabled}
          id={this.props.id}
          maxLength={this.props.maxLength}
          name="title"
          onChange={this.handleChangeTitle}
          onClick={this.handleClick}
          onKeyDown={this.handleKeyDown}
          placeholder={this.props.placeholder || ""}
          required={this.props.required}
          style={
            brandColor && this.state.focus ? { borderColor: brandColor } : {}
          }
          type="text"
          value={this.state.form.title}
          autoFocus={this.props.autofocus || false}
        />
        {this.state.error && <sub className="error">This status is already exist.</sub>}
        {this.props.isEdit && (
          <div className="editButtons">
            {!this.state.error && <button type="button" className="apply" onClick={this.apply}>
              <img src={Apply} alt="Apply" />
            </button>}
            <button type="button" className="cancel" onClick={this.cancel}>
              <img src={Cancel} alt="Cancel" />
            </button>
          </div>
        )}
      </div>
    );
  }
}

ThemedStatusInput.contextType = ThemeContext;

export default connect(mapStateToProps)(ThemedStatusInput);
