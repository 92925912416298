import Axios, { AxiosResponse, AxiosError } from "axios";
import { Dispatch } from "redux";
import { API_URL } from "~/config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

interface IListExternalContact {
  id: number;
  title: string;
  phone_number: string;
  company?: string;
  job_title?: string;
  email?: string;
  address_line_1?: string;
  address_line_2?: string;
  city?: string;
  state?: string;
  zipcode: string;
  custom_field_1?: string;
  custom_field_2?: string;
  custom_field_3?: string;
}

interface IListInternalContact {
  created_at: string;
  id: number;
  is_deleted: boolean;
  phone_number: string;
  timezone: string;
  title: string;
}

export interface IList {
  compliance: string;
  external: IListExternalContact[];
  id: string;
  internal: IListInternalContact;
  is_deleted: boolean;
  name: string;
  notes: string;
  receive_compliance: any[];
  stopped: any[];
}

// ------------------------------------
// Constants
// ------------------------------------
const CLEAR_ACTIVE_LIST = "CLEAR_ACTIVE_LIST";

const LISTS_CREATE_REQUEST = "LISTS_CREATE_REQUEST";
const LISTS_CREATE_SUCCESS = "LISTS_CREATE_SUCCESS";
const LISTS_CREATE_FAILURE = "LISTS_CREATE_FAILURE";

const LISTS_DELETE_REQUEST = "LISTS_DELETE_REQUEST";
const LISTS_DELETE_SUCCESS = "LISTS_DELETE_SUCCESS";
const LISTS_DELETE_FAILURE = "LISTS_DELETE_FAILURE";

const LISTS_GET_REQUEST = "LISTS_GET_REQUEST";
const LISTS_GET_SUCCESS = "LISTS_GET_SUCCESS";
const LISTS_GET_FAILURE = "LISTS_GET_FAILURE";

const LISTS_UPDATE_REQUEST = "LISTS_UPDATE_REQUEST";
const LISTS_UPDATE_SUCCESS = "LISTS_UPDATE_SUCCESS";
const LISTS_UPDATE_FAILURE = "LISTS_UPDATE_FAILURE";

const SET_ACTIVE_LIST = "SET_ACTIVE_LIST";

const BLAST_DETAILS_BY_ID_GET_REQUEST = "BLAST_DETAILS_BY_ID_GET_REQUEST";
const BLAST_DETAILS_BY_ID_GET_SUCCESS = "BLAST_DETAILS_BY_ID_GET_SUCCESS";
const BLAST_DETAILS_BY_ID_GET_FAILURE = "BLAST_DETAILS_BY_ID_GET_FAILURE";

// ------------------------------------
// Action Creators
// ------------------------------------
const clearActiveList = () => ({ type: CLEAR_ACTIVE_LIST });
const createListRequest = () => ({ type: LISTS_CREATE_REQUEST });
const createListSuccess = (res: AxiosResponse) => ({
  type: LISTS_CREATE_SUCCESS,
  payload: res.data,
});
const createListFailure = (err: AxiosError) => ({
  type: LISTS_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteListRequest = () => ({ type: LISTS_DELETE_REQUEST });
const deleteListSuccess = (listId: number) => ({
  type: LISTS_DELETE_SUCCESS,
  payload: listId,
});
const deleteListFailure = (err: AxiosError) => ({
  type: LISTS_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const getListRequest = () => ({ type: LISTS_GET_REQUEST });
const getListSuccess = (res: AxiosResponse) => ({
  type: LISTS_GET_SUCCESS,
  payload: res.data,
});
const getListFailure = (err: AxiosError) => ({
  type: LISTS_GET_FAILURE,
  payload: err && err.response && err.response.data,
});

const setActiveList = (id: string) => ({
  type: SET_ACTIVE_LIST,
  payload: id,
});

const getBlastDetailsByIdRequest = () => ({
  type: BLAST_DETAILS_BY_ID_GET_REQUEST,
});
const getBlastDetailsByIdSuccess = (res: AxiosResponse) => ({
  type: BLAST_DETAILS_BY_ID_GET_SUCCESS,
  payload: res.data,
});
const getBlastDetailsByIdFailure = (err: AxiosError) => ({
  type: BLAST_DETAILS_BY_ID_GET_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateListRequest = () => ({ type: LISTS_UPDATE_REQUEST });
const updateListSuccess = (res: IList) => ({
  type: LISTS_UPDATE_SUCCESS,
  payload: res,
});
const updateListFailure = (err: AxiosError) => ({
  type: LISTS_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------
const createList = (form: IList) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(createListRequest());
  try {
    const { id, token } = getState().users.authenticatedUser;
    const res = await Axios.post(
      `${API_URL}/users/${id}/blast`,
      form,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok" && res.data.status_code === 200) {
      dispatch(createListSuccess(res.data));
    }
  } catch (err) {
    dispatch(createListFailure(err));
  }
};

const deleteList = (listId: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token } = getState().users.authenticatedUser;
  if (id && token && listId) {
    try {
      dispatch(deleteListRequest());
      await Axios.delete(
        `${API_URL}/users/${id}/blast/${listId}`,
        withAuthorizationHeader(token)
      );
      dispatch(deleteListSuccess(listId));
    } catch (err) {
      dispatch(deleteListFailure(err));
    }
  }
};

const getList = () => (dispatch: Dispatch, getState: () => any) => {
  const { authenticatedUser } = getState().users;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser) {
      const { id, token } = authenticatedUser;
      if (id && token) {
        try {
          dispatch(getListRequest());
          const res = await Axios.get(
            `${API_URL}/users/${id}/blast/list-blast-details`,
            withAuthorizationHeader(token)
          );
          dispatch(getListSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(getListFailure(err));
          reject(err);
        }
      }
    }
    reject("Bad user.");
  });
};

const setList = (listId: string) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { authenticatedUser } = getState().users;
  return new Promise(async (resolve, reject) => {
    if (authenticatedUser) {
      const { id, token } = authenticatedUser;
      if (id && token) {
        try {
          dispatch(getBlastDetailsByIdRequest());
          const res = await Axios.get(
            `${API_URL}/users/${id}/blast/blast-details/${listId}`,
            withAuthorizationHeader(token)
          );
          dispatch(getBlastDetailsByIdSuccess(res));
          resolve(res);
        } catch (err) {
          dispatch(getBlastDetailsByIdFailure(err));
          reject(err);
        }
      }
    }
    reject("Bad user.");
  });
};

const updateList = (listId: number, form: IList) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token } = getState().users.authenticatedUser;
  if (id && token && listId && form) {
    try {
      dispatch(updateListRequest());
      const res = await Axios.put(
        `${API_URL}/users/${id}/blast/${listId}`,
        form,
        withAuthorizationHeader(token)
      );
      if (res.data.status === "ok") {
        form.id = listId;
        dispatch(updateListSuccess(form));
      } else throw res;
    } catch (err) {
      dispatch(updateListFailure(err));
    }
  }
};

export const listsActions = {
  clearActiveList,
  createList,
  deleteList,
  getList,
  setList,
  updateList,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeList: null,
  data: [],
  isFetching: false,
  isFetchingBlastDetails: false,
};

export default (
  state = initialState,
  { type, payload }: { type: string; payload?: any }
) => {
  switch (type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case LISTS_CREATE_REQUEST:
    case LISTS_DELETE_REQUEST:
    case LISTS_GET_REQUEST:
    case LISTS_UPDATE_REQUEST:
      return { ...state, isFetching: true, isFetchingBlastDetails: false };

    case BLAST_DETAILS_BY_ID_GET_REQUEST:
      return { ...state, isFetching: false, isFetchingBlastDetails: true };

    case LISTS_CREATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingBlastDetails: false,
        data: [payload, ...state.data],
      };

    case LISTS_DELETE_SUCCESS:
      return {
        ...state,
        activeList: null,
        isFetching: false,
        isFetchingBlastDetails: false,
        data: state.data.filter((item: any) => item.id !== payload),
      };

    case LISTS_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingBlastDetails: false,
        data: payload.data,
      };

    case BLAST_DETAILS_BY_ID_GET_SUCCESS:
      return {
        ...state,
        isFetching: false,
        isFetchingBlastDetails: false,
        activeList: payload.data,
      };

    case SET_ACTIVE_LIST:
      return {
        ...state,
        activeList: state.data.find((item: any) => item.id === payload) || null,
      };

    case LISTS_UPDATE_SUCCESS:
      return {
        ...state,
        activeList: payload,
        isFetching: false,
        isFetchingBlastDetails: false,
        data: state.data.map((item: any) =>
          item.id === payload.id ? payload : item
        ),
      };

    case CLEAR_ACTIVE_LIST:
      return {
        ...state,
        activeList: null,
      };

    case LISTS_CREATE_FAILURE:
    case LISTS_DELETE_FAILURE:
    case LISTS_GET_FAILURE:
    case LISTS_UPDATE_FAILURE:
    case BLAST_DETAILS_BY_ID_GET_FAILURE:
      return { ...state, isFetching: false, isFetchingBlastDetails: false };
    default:
      return state;
  }
};
