import { AxiosResponse } from "axios";
import { History } from "history";
import React, { Component, BaseSyntheticEvent, ChangeEvent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { usersActions } from "~/store/user";
import { Link } from "react-router-dom";
import ThemedCheckbox from "~/components/common/ThemedCheckbox/ThemedCheckbox";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";

namespace LoginForm {
  export interface State {
    form: {
      email: string;
      password: string;
      remember_me: boolean;
    };
  }

  interface OwnProps {
    history: History;
  }

  interface StateProps {
    loginError: string;
  }

  interface DispatchProps {
    loginUser: (form: {}) => Promise<AxiosResponse>;
  }

  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapDispatchToProps = {
  loginUser: usersActions.loginUser,
};

const mapStateToProps = (state: any) => ({
  loginError: state.users.errors,
});

@(connect(
  mapStateToProps,
  mapDispatchToProps
) as any)
@(withRouter as any)
export default class LoginForm extends Component<
  LoginForm.Props,
  LoginForm.State
> {
  state = {
    form: {
      email: "",
      password: "",
      remember_me: false,
    },
    showPassword: false,
  };

  togglePassword = () =>
    this.setState(prevState => ({ showPassword: !prevState.showPassword }));
  showPassword = () => this.setState({ showPassword: true });
  hidePassword = () => this.setState({ showPassword: false });

  handleSubmit = (e: BaseSyntheticEvent) => {
    e.preventDefault();
    // Validate form fields here

    // Send form
    this.props
      .loginUser(this.state.form)
      .then(res => res && res.status === 200 && this.props.history.push("/"));
  };

  handleFieldChange = (e: ChangeEvent) => {
    e.stopPropagation();
    let newFormState = Object.assign({}, this.state.form);
    newFormState[e.target["name"]] = e.target["value"];
    this.setState({ form: newFormState });
  };

  handleCheckbox = (e: ChangeEvent) => {
    e.stopPropagation();
    const { id, checked } = e.target as HTMLInputElement;
    const form = { ...this.state.form, [id]: checked };
    this.setState({ form });
  };

  render() {
    return (
      <form className="form" onSubmit={this.handleSubmit}>
        <h1>Sign In</h1>
        <ThemedInput
          className="input"
          name="email"
          onChange={this.handleFieldChange}
          placeholder="Email"
          type="email"
          value={this.state.form.email}
        />
        <div className="field">
          <ThemedInput
            className="input"
            name="password"
            onChange={this.handleFieldChange}
            placeholder="Password"
            type={this.state.showPassword ? "text" : "password"}
            value={this.state.form.password}
          />
          <button
            type="button"
            onMouseDown={this.showPassword}
            onMouseUp={this.togglePassword}
            onBlur={this.hidePassword}
          >
            <i className="far fa-eye"></i>
          </button>
        </div>
        {this.props.loginError && (
          <span className="error-msg">{this.props.loginError}</span>
        )}
        <div>
          <ThemedCheckbox
            id="remember_me"
            text="Keep me logged in"
            checked={this.state.form.remember_me}
            onChange={this.handleCheckbox}
          />
        </div>
        <ThemedButton className="submit" type="submit">
          Sign In
        </ThemedButton>
        <Link to="/reset">Forgot Password?</Link>
      </form>
    );
  }
}
