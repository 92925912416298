import classNames from "classnames";
import csv from "csv";
import _ from "lodash";
import React, { FormEvent, useState, useEffect } from "react";
import DragAndDrop from "~/components/common/DragAndDrop/DragAndDrop";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import { contactsActions, IExternalContact } from "~/store/contacts";
import { connect } from "react-redux";

interface DispatchProps {
  importContacts: (contacts: IExternalContact[]) => Promise<unknown>;
  getContactsList: () => void;
}

interface StateProps {
  errors: [];
}

interface OwnProps {
  onBack: () => void;
  shown: boolean;
}

type Props = DispatchProps & StateProps & OwnProps;

const mapDispatchToProps = {
  importContacts: contactsActions.importContacts,
  getContactsList: contactsActions.getContactsList,
};

const mapStateToProps = (state: any) => ({
  errors: state.contacts.errors,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const [csvData, setCSVData] = useState([]);
  const [duplicate, setDuplicate] = useState(0);
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {}, []);

  // First column 10-11 digits
  function validateCSVLine(line: string[]) {
    return /^\d{10,11}$/.test(line[0]);
  }

  function convertCSVLine(line: string[]) {
    return {
      phone_number: line[0].length === 10 ? `1${line[0]}` : line[0],
      first_name: line[1] ? line[1] : line[0],
      last_name: line[2] ? line[2] : "",
      company: line[3] ? line[3] : "",
      job_title: line[4] ? line[4] : "",
      email: line[5] ? line[5] : "",
      address_line_1: line[6] ? line[6] : "",
      address_line_2: line[7] ? line[7] : "",
      city: line[8] ? line[8] : "",
      state: line[9] ? line[9] : "",
      zipcode: line[10] ? line[10] : "",
      custom_field_1: line[11] ? line[11] : "",
      custom_field_2: line[12] ? line[12] : "",
      custom_field_3: line[13] ? line[13] : "",
    };
  }

  function handleDrop(e: FileList) {
    const reader = new FileReader();
    reader.onload = () => {
      csv.parse(
        reader.result,
        {
          // columns: true
        },
        (_err: Error, csv: any) => {
          if (_err) {
            console.log("ERROR UPLOADING", _err);
            setCSVData([]);
            setUploadError(`Please use CSV file to import contacts. ${_err}`);
            setDuplicate(0);
            return;
          }

          const csvData =
            csv && csv.length > 0
              ? csv.filter(validateCSVLine).map(convertCSVLine)
              : [];

          if (csvData.length) {
            setCSVData(csvData);
            setUploadError("");
            setDuplicate(0);
          } else {
            setUploadError(
              "The file contains errors. Please provide valid phone numbers"
            );
            setDuplicate(0);
          }
        }
      );
    };
    reader.readAsBinaryString(e[0]);
  }

  function handleSubmitCSV(e: FormEvent) {
    e.preventDefault();
    props.importContacts(csvData).then(res => {
      const duplicate = _.get(res, "data.data.duplicateCount", 0);
      if (duplicate > 0) {
        setDuplicate(duplicate);
      } else {
        props.getContactsList();
        props.onBack();
      }
    });
  }

  return (
    <form
      className={classNames("ContactEdit", { shown: props.shown })}
      onSubmit={handleSubmitCSV}
    >
      {duplicate > 0 && (
        <div className="errors text-center">
          {`${duplicate} contact${duplicate > 1 ? "s" : ""} already exists`}
        </div>
      )}
      <DragAndDrop
        handleDrop={handleDrop}
        csvData={csvData}
        error={uploadError}
      />
      <span className="csv-upload-hint">
        Format of CSV: one recipient per row with number, first name, last name,
        notes. Download a sample template:
        <a href="https://messageviewcdn-production.brightlink.com/lol/sample.csv">
          sample.csv
        </a>
      </span>
      <div className="ContactEdit__ImportButtons">
        <ThemedButton type="submit" disabled={csvData.length === 0}>
          Import
        </ThemedButton>
        <button type="button" onClick={props.onBack}>
          Cancel
        </button>
      </div>
    </form>
  );
});
