import { createBrowserHistory } from "history";
import { Dispatch, AnyAction } from "redux";
import { Location } from "history";

// ------------------------------------
// Constants
// ------------------------------------
export const LOCATION_CHANGE = "LOCATION_CHANGE";

// ------------------------------------
// Actions
// ------------------------------------
export function locationChange(location = "/") {
  return {
    type: LOCATION_CHANGE,
    payload: location,
  };
}

// ------------------------------------
// Specialized Action Creator
// ------------------------------------
export const updateLocation = ({ dispatch }: { dispatch: Dispatch }) => {
  return (nextLocation: any) => dispatch(locationChange(nextLocation));
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState: Location<any> = createBrowserHistory().location;
export default (state = initialState, action: AnyAction) => {
  return action.type === LOCATION_CHANGE ? action.payload : state;
};
