import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

export interface ITemplate {
  id?: number;
  title: string;
  template_content: string;
  media_data?: any | undefined;
}

// ------------------------------------
// Constants
// ------------------------------------
const TEMPLATES_CREATE_REQUEST = "TEMPLATES_CREATE_REQUEST";
const TEMPLATES_CREATE_SUCCESS = "TEMPLATES_CREATE_SUCCESS";
const TEMPLATES_CREATE_FAILURE = "TEMPLATES_CREATE_FAILURE";

const TEMPLATES_DELETE_REQUEST = "TEMPLATES_DELETE_REQUEST";
const TEMPLATES_DELETE_SUCCESS = "TEMPLATES_DELETE_SUCCESS";
const TEMPLATES_DELETE_FAILURE = "TEMPLATES_DELETE_FAILURE";

const TEMPLATES_LIST_REQUEST = "TEMPLATES_LIST_REQUEST";
const TEMPLATES_LIST_SUCCESS = "TEMPLATES_LIST_SUCCESS";
const TEMPLATES_LIST_FAILURE = "TEMPLATES_LIST_FAILURE";

const TEMPLATES_UPDATE_REQUEST = "TEMPLATES_UPDATE_REQUEST";
const TEMPLATES_UPDATE_SUCCESS = "TEMPLATES_UPDATE_SUCCESS";
const TEMPLATES_UPDATE_FAILURE = "TEMPLATES_UPDATE_FAILURE";

export const TEMPLATE_KEYWORDS: any = {
  "Contact Details": [
    ["[First Name]", "[Last Name]"],
    ["[Company]", "[Job Title]", "[Email]"],
    ["[Address Line 1]", "[Address Line 2]"],
    ["[City]", "[State]", "[Zip Code]"],
    ["[Phone Number]", "[Custom Field 1]"],
    ["[Custom Field 2]", "[Custom Field 3]"],
  ],

  "My Details": [
    ["[My First Name]", "[My Last Name]"],
    ["[My Phone Number]"],
    ["[My Company]"],
    ["[My Job Title]", "[My Email]"],
  ],
  Dates: [[`[Today's Date]`, `[Tomorrow's Date]`]],
};

export const HIGHLIGHT_PROFILE_DATA = [
  ...TEMPLATE_KEYWORDS["My Details"].flat(),
];
export const HIGHLIGHT_CONTACT_DATA = [
  ...TEMPLATE_KEYWORDS["Contact Details"].flat(),
];

export const HIGHLIGHTS = [
  ...HIGHLIGHT_PROFILE_DATA,
  ...HIGHLIGHT_CONTACT_DATA,
  `[Today's Date]`,
  `[Tomorrow's Date]`,
];

// ------------------------------------

// Action Creators
// ------------------------------------
const createTemplateRequest = () => ({
  type: TEMPLATES_CREATE_REQUEST,
});
const createTemplateSuccess = (res: ITemplate) => ({
  type: TEMPLATES_CREATE_SUCCESS,
  payload: res,
});
const createTemplateFailure = (err: AxiosError) => ({
  type: TEMPLATES_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteTemplateRequest = () => ({
  type: TEMPLATES_DELETE_REQUEST,
});
const deleteTemplateSuccess = (res: number) => ({
  type: TEMPLATES_DELETE_SUCCESS,
  payload: res,
});
const deleteTemplateFailure = (err: AxiosError) => ({
  type: TEMPLATES_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listTemplatesRequest = () => ({
  type: TEMPLATES_LIST_REQUEST,
});
const listTemplatesSuccess = (res: AxiosResponse) => ({
  type: TEMPLATES_LIST_SUCCESS,
  payload: res.data,
});
const listTemplatesFailure = (err: AxiosError) => ({
  type: TEMPLATES_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateTemplateRequest = () => ({
  type: TEMPLATES_UPDATE_REQUEST,
});
const updateTemplateSuccess = (res: ITemplate) => ({
  type: TEMPLATES_UPDATE_SUCCESS,
  payload: res,
});
const updateTemplateFailure = (err: AxiosError) => ({
  type: TEMPLATES_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});

// ------------------------------------
// Actions
// ------------------------------------
const createTemplate = (form: ITemplate) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  const restForm = Object.assign({}, form);
  if (!restForm.media_data || !restForm.media_data.media) {
    delete restForm.media_data;
  }
  try {
    dispatch(createTemplateRequest());
    const res = await Axios.post(
      `${API_URL}/phone_numbers/${activePhone}/template`,
      restForm,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(createTemplateSuccess({ ...res.data.data }));
    }
  } catch (err) {
    dispatch(createTemplateFailure(err));
  }
};

const deleteTemplate = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(deleteTemplateRequest());
    const res = await Axios.delete(
      `${API_URL}/phone_numbers/${activePhone}/template/${id}`,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(deleteTemplateSuccess(id));
    }
  } catch (err) {
    dispatch(deleteTemplateFailure(err));
  }
};

const listTemplates = () => async (dispatch: Dispatch, getState: () => any) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(listTemplatesRequest());
    const res = await Axios.get(
      `${API_URL}/phone_numbers/${activePhone}/templates`,
      withAuthorizationHeader(token)
    );
    dispatch(listTemplatesSuccess(res));
  } catch (err) {
    dispatch(listTemplatesFailure(err));
  }
};

const updateTemplate = (form: ITemplate) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token, activePhone } = getState().users.authenticatedUser;

  if (id && token && form) {
    const restForm = Object.assign({}, form);
    if (!restForm.media_data || !restForm.media_data.media) {
      console.log("================ Delete Key =================");
      delete restForm.media_data;
    }
    console.log("================ restForm " + restForm);
    try {
      dispatch(updateTemplateRequest());
      await Axios.put(
        `${API_URL}/phone_numbers/${activePhone}/template/${restForm.id}`,
        restForm,
        withAuthorizationHeader(token)
      );
      dispatch(updateTemplateSuccess(restForm));
    } catch (err) {
      dispatch(updateTemplateFailure(err));
    }
  }
};

export const templatesActions = {
  createTemplate,
  deleteTemplate,
  listTemplates,
  updateTemplate,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeReply: -1,
  isFetching: false,
  data: [],
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case TEMPLATES_CREATE_REQUEST:
    case TEMPLATES_DELETE_REQUEST:
    case TEMPLATES_LIST_REQUEST:
    case TEMPLATES_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case TEMPLATES_CREATE_SUCCESS:
      const newReplies = [...state.data];
      !newReplies.find((item: ITemplate) => item.id === action.payload.id) &&
        newReplies.push(action.payload as never);
      return {
        ...state,
        isFetching: false,
        activeReply: action.payload.id,
        data: newReplies,
      };

    case TEMPLATES_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeReply: -1,
        data: state.data.filter(
          (reply: ITemplate) => reply.id !== action.payload
        ),
      };

    case TEMPLATES_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.map((item: ITemplate) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case TEMPLATES_LIST_SUCCESS:
      return { ...state, isFetching: false, data: action.payload.data };

    case TEMPLATES_CREATE_FAILURE:
    case TEMPLATES_DELETE_FAILURE:
    case TEMPLATES_LIST_FAILURE:
    case TEMPLATES_UPDATE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
