import React, { Component } from "react";
import * as _ from "lodash";
import classNames from "classnames";
import "./IntroSlider.scss";
import ThemedButton from "~/components/common/ThemedButton/ThemedButton";
import { Branding } from "~/branding";
import WhitelableTextConverter from "~/components/WhitelableTextConverter/WhitelableTextConverter";

type IntroSliderProps = {
  enterApp: Function;
};

type IntroItem = {
  title: any;
  text: any;
};

type IntroSliderState = {
  currentItem: number;
};

export default class IntroSlider extends Component<
  IntroSliderProps,
  IntroSliderState
> {
  state = {
    currentItem: 0,
  };
  render() {
    const textItems: IntroItem[] = [
      {
        title: (
          <WhitelableTextConverter text={`What is ${Branding.APP_NAME}?`} />
        ),
        text: (
          <WhitelableTextConverter
            text={`${Branding.APP_NAME} is a comprehensive cloud messaging solution that lets you send and receive SMS and MMS messages from your business number, including traditional landlines`}
          />
        ),
      },
      {
        title: "Key Features",
        text:
          "TEXT-ENABLE CURRENT NUMBERS: Use your existing phone numbers to contact customers via SMS and MMS messages",
      },
      {
        title: "Key Features",
        text: "Send messages from any device to any device",
      },
      {
        title: "Key Features",
        text: "Create auto-replies",
      },
    ];
    const { currentItem } = this.state;
    const textItem = textItems[currentItem] || textItems[0];
    return (
      <div className="IntroSlider">
        <div className="IntroSlider__TextContainer">
          <div className="IntroSlider__TextItem">
            <header>{textItem.title}</header>
            <article>{textItem.text}</article>
          </div>
        </div>
        <ul className="IntroSlider__Navigator">
          {_.map(textItems, (_item: IntroItem, index: number) => (
            <li
              key={index}
              onClick={() => this.setState({ currentItem: index })}
              className={classNames({ active: index === currentItem })}
            ></li>
          ))}
        </ul>
        <footer>
          <div className="bottomMargin">
            <ThemedButton
              type="button"
              className="button is-primary"
              onClick={() => {
                if (textItems.length === currentItem + 1) {
                  this.props.enterApp();
                } else {
                  this.setState((prevState: IntroSliderState) => ({
                    currentItem:
                      prevState.currentItem + 1 < textItems.length
                        ? prevState.currentItem + 1
                        : prevState.currentItem,
                  }));
                }
              }}
            >
              Next
            </ThemedButton>
          </div>
          <div>
            <button
              type="button"
              className="skip"
              onClick={() => this.props.enterApp()}
            >
              Skip
            </button>
          </div>
        </footer>
      </div>
    );
  }
}
