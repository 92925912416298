import React from "react";
import "./ErrorMessage.scss";

interface ErrorMessageProps {
  errorMessage: string;
  showMessage: boolean;
  className?: string;
}

export default ({
  errorMessage: message,
  showMessage,
  className,
}: ErrorMessageProps) => {
  return (
    <>{showMessage && <div className={className || "error"}>{message}</div>}</>
  );
};
