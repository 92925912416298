import Axios, { AxiosError } from "axios";
import { get } from "lodash";
import React from "react";

export function isLocalStorageAvailable(): boolean {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

// export const fpn = (str: string) => {
//   if (str.length < 10) {
//     return str;
//   }
//   const input = str.replace(/\D/g, "");
//   const country = input.substring(0, 1);
//   const zip = input.substring(1, 4);
//   const middle = input.substring(4, 7);
//   const last = input.substring(7, 11);
//   return `${country} (${zip}) ${middle} ${last}`;
// };

export const fpn = (str: string, showCensor: boolean = false) => {
  if (str.length < 10) {
    return str;
  }
  const input = str.replace(/\D/g, "");
  const country = input.substring(0, 1);
  const zip = input.substring(1, 4);
  const middle = input.substring(4, 7);
  const last = input.substring(7, 11);
  // return showCensor ? `${country} (${zip}) - ${input.substring(4, 5)}XX - ${last}` : `${country} (${zip}) ${middle} ${last}`;
  return showCensor
    ? `${country} (${zip}) ${input.substring(4, 5)}XX - ${last}`
    : `${country} (${zip}) ${middle} ${last}`;
};

export const formatNumberandContacts = (
  str: string,
  showCensor: boolean = false
) => {
  const sanitizedInput = str.replace(/^\s+|\s+$/g, "");
  const isPhoneNumber = /^\d+$/.test(sanitizedInput);
  if (!isPhoneNumber) return str;

  if (str.length < 10) {
    return str;
  }
  const input = str.replace(/\D/g, "");
  const country = input.substring(0, 1);
  const zip = input.substring(1, 4);
  const middle = input.substring(4, 7);
  const last = input.substring(7, 11);
  // return showCensor ? `${country} (${zip}) - ${input.substring(4, 5)}XX - ${last}` : `${country} (${zip}) ${middle} ${last}`;
  return showCensor
    ? `${country} (${zip}) ${input.substring(4, 5)}XX - ${last}`
    : `${country} (${zip}) ${middle} ${last}`;
};

export const fpnNew = (str: string, showCensor: boolean = false) => {
  if (str.length < 10) {
    return str;
  }

  const input = str.replace(/\D/g, "");
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);
  return showCensor
    ? `(${zip}) ${input.substring(4, 5)}XX - ${last}`
    : `(${zip}) ${middle} ${last}`;
};

export const fpn1 = (str: string, showCensor: boolean = false) => {
  if (str.length < 10) {
    return str;
  }
  const input = str.replace(/\D/g, "");
  const country = input.substring(0, 1);
  const zip = input.substring(1, 4);
  const middle = input.substring(4, 7);
  const last = input.substring(7, 11);
  return showCensor
    ? `${country} (${zip}) ${input.substring(4, 5)}XX - ${last}`
    : `${country} (${zip}) ${middle} ${last}`;
};

export function formatFileSize(size: number) {
  if (size / 1024 / 1024 >= 1) {
    return `${(size / 1024 / 1024).toFixed(2)} MB`;
  } else if (size / 1024 >= 1) {
    return `${(size / 1024).toFixed(2)} KB`;
  }
  return `${size} B`;
}

/**
 * Remove following special characters from phone number string:
 * - round brackets
 * - spaces
 * - hypens
 * - plus sign
 * @param phone_number {string} Phone number
 */
export function normalizePhoneNumber(phone_number: string) {
  let phone = phone_number.replace(/[\-\+\(\) ]/g, "");
  return phone;
}

/**
 * Check is phone number valid
 * @param phone_number {string} Phone number
 */
export function isValidPhoneNumber(phone_number: string) {
  const phone = normalizePhoneNumber(phone_number);

  // Replace in future for check NPA code validity
  // return !isNaN(+phone_number)
  //   && (phone_number.length === 10 && +phone_number[0] > 1 && +phone_number[1] !== 9)
  //   || (phone_number.length === 11 && phone_number[0] === '1' && +phone_number[1] > 1 && +phone_number[2] !== 9);

  return (
    /^\d+$/.test(phone) &&
    (phone.length === 10 || (phone.length === 11 && phone[0] === "1"))
  );
}

export const escapeRegExp = (str: string) =>
  str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

export function LightenDarkenColor(col: string, amt: number) {
  var usePound = false;
  if (col[0] == "#") {
    col = col.slice(1);
    usePound = true;
  }

  var num = parseInt(col, 16);

  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;

  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;

  var g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;

  return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
}

export const formatAutoStatus = (statusId: number) => {
  let color: string;
  let title: string;
  switch (true) {
    case statusId === 1:
      color = "green";
      title = "Available";
      break;
    case statusId === 2:
      color = "gray";
      title = "Idle";
      break;
    // case statusId === 3:
    //   color = 'red';
    //   title = 'Offline';
    //   break;
    default:
      color = "red";
      title = "Offline";
  }
  return {
    color,
    title,
  };
};

export const ThemeContext = React.createContext({
  theme: "default",
  notification_visual: true,
  notification_audible: true,
});

export const getFirstActiveContact = items => {
  const filteredItems = items.filter(
    item =>
      (item.hasOwnProperty("in_contacts") &&
        item.in_contacts &&
        item.is_deleted !== true) ||
      item.hasOwnProperty("display_as")
  );
  return get(filteredItems, "[0].id", "");
};

export const fetchAndOutputAttach = async (
  e: React.BaseSyntheticEvent,
  media_file_name: string,
  media_link: string
) => {
  e.preventDefault();
  try {
    await Axios.request({
      url: media_link,
      method: "GET",
      responseType: "blob",
    })
      .then(({ data }) => {
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = media_file_name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((error: AxiosError) => console.error(error));
  } catch (err) {
    throw err;
  }
};
