import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import { modalActions } from "~/store/modal";
import "./AbstractModal.scss";

interface DispatchProps {
  closeModal: () => void;
}

interface OwnProps {
  children?: any;
  className?: string;
  onBack?: () => void;
  showBack?: boolean;
  title: string | JSX.Element;
  confrimModal: boolean;
}

type Props = DispatchProps & OwnProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
};

export default connect(
  null,
  mapDispatchToProps
)((props: Props) => {
  function handleClose(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (e.currentTarget === e.target || e.target.className === "icon-close") {
      e.stopPropagation();
      props.closeModal();
    }
  }

  function handleOuterClose() {
    return false;
  }

  return (
    <div className={classNames("modal", props.className)}>
      <div
        className={classNames("inner", { set_height: !props.confrimModal })}
        onClick={handleOuterClose}
      >
        <header>
          {props.showBack && (
            <button
              type="button"
              className="back-button"
              onClick={props.onBack}
            >
              <i className="icon-back"></i>
            </button>
          )}
          <button className="close-button" onClick={handleClose} type="button">
            <i className="icon-close"></i>
          </button>
          {props.title && <h1>{props.title}</h1>}
        </header>
        {props.children}
      </div>
    </div>
  );
});
