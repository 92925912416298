import React, { useState } from "react";
import { connect } from "react-redux";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ContactList from "~/components/ContactList/ContactList";
import ContactProfile from "~/components/ContactProfile/ContactProfile";
import ContactEdit from "~/components/forms/ContactEdit/ContactEdit";
import { contactsActions } from "~/store/contacts";
import ThemedButton from "../common/ThemedButton/ThemedButton";
import ContactImport from "../forms/ContactImport/ContactImport";

interface DispatchProps {
  clearActiveContact: () => void;
}

interface StateProps {
  activeEdit: boolean;
}

type Props = StateProps & DispatchProps;

const mapDispatchToProps = {
  clearActiveContact: contactsActions.clearActiveContact,
};

const mapStateToProps = (state: any) => ({
  activeEdit: state.modal.modalProps.activeEdit || false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const [isCSV, setCSV] = useState(false);
  const [isEdit, setEdit] = useState(props.activeEdit);
  const [shown, setShown] = useState("contacts");
  const [searchFilter, setSearchFilter] = useState("");

  function onBack() {
    setShown("contacts");
  }

  function onEdit() {
    setEdit(true);
    setShown("profile");
  }

  function handleChangeSearch(e) {
    e.stopPropagation();
    setSearchFilter(e.target.value);
  }

  function onAdd() {
    props.clearActiveContact();
    setEdit(true);
    setShown("profile");
  }

  function onImport() {
    props.clearActiveContact();
    setCSV(true);
    setEdit(true);
    setShown("profile");
  }

  function closeEdit() {
    setCSV(false);
    setEdit(false);
    setShown("contacts");
  }

  return (
    <>
      <section>
        <div>
          <ThemedInput
            className="ContactsModal__SearchInput"
            disabled={isEdit}
            onChange={handleChangeSearch}
            placeholder="Search contact"
            type="text"
            value={searchFilter}
          />
          <ContactList
            changeShown={setShown}
            isEdit={isEdit}
            search={searchFilter}
            shown={shown === "contacts"}
          />
        </div>
        {isEdit ? (
          isCSV ? (
            <ContactImport onBack={closeEdit} shown={shown === "profile"} />
          ) : (
            <ContactEdit onBack={closeEdit} shown={shown === "profile"} />
          )
        ) : (
          <ContactProfile
            changeShown={setShown}
            onBack={onBack}
            onEdit={onEdit}
            shown={shown === "profile"}
          />
        )}
      </section>
      {!isEdit && (
        <footer>
          <ThemedButton onClick={onImport}>
            <i className="icon-import"></i>
          </ThemedButton>
          <ThemedButton onClick={onAdd}>
            <i className="icon-add"></i>
          </ThemedButton>
        </footer>
      )}
    </>
  );
});
