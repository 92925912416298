import React from "react";
import { fetchAndOutputAttach } from "~/utils";
import classNames from "classnames";
import "./AttachmentView.scss";

const allowedImages = ["image/jpeg", "image/pjpeg", "image/png"];
const allowedPdf = ["application/pdf", "application/x-pdf"];
const allowedMIMETypes = [
  ...allowedImages,
  ...allowedPdf,
  "audio/wav",
  "audio/wave",
  "audio/x-wav",
  "video/3gpp",
  "video/mp4",
];

export default ({
  media_data: {
    media: { media_link, media_file_mime },
    file: { name: media_file_name },
  },
}: any) => {
  const fileExt = media_link && media_link.slice(-4);
  return (
    <div className="bubble">
      {media_link && media_file_mime && fileExt && (
        <div className="media">
          <a
            href={media_link}
            target="_blank"
            download={media_file_name}
            onClick={e => {
              fetchAndOutputAttach(e, media_file_name, media_link);
            }}
          >
            {allowedImages.includes(media_file_mime) ? (
              <img src={media_link} />
            ) : (
              <div className="flex">
                <div
                  className={classNames("filePreview", {
                    generic: !allowedMIMETypes.includes(media_file_mime),
                  })}
                >
                  {allowedMIMETypes.includes(media_file_mime) ? (
                    fileExt[0] === "." ? (
                      fileExt.slice(1)
                    ) : (
                      fileExt
                    )
                  ) : (
                    <i className="icon-attach"></i>
                  )}
                </div>
                <div className="imageDetails">
                  <div>{media_file_name}</div>
                </div>
              </div>
            )}
          </a>
        </div>
      )}
    </div>
  );
};
