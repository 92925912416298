import classNames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React, { BaseSyntheticEvent, PureComponent } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Swipeable, EventData } from "react-swipeable";
import { conversationActions, IConversation } from "~/store/conversations";
import { modalActions } from "~/store/modal";
import { fpn } from "~/utils";
import { contactsActions } from "~/store/contacts";
import "./ConversationsList.scss";
import Loader from "../common/Loader/Loader";

interface OwnProps {
  activeConversation: any;
  openConversation: (conversationId: string) => void;
  isBlastOpen: boolean;
}

interface DispatchProps {
  getConversationsList: () => void;
  hideConversation: (conversationId: string, type: string) => void;
  openModal: (modalId: string, modalProps: object) => void;
  setModalState: (payload: any) => void;
  clearUnreadMessages: (conversationId: string) => void;
  getContactsList: () => void;
  setNewContact: (phone_number: string) => void;

  getConversationsListLazy: () => void;
}

interface StateProps {
  isAuthenticated: boolean;
  authenticatedUser: any;
  companyId: number;
  conversations: [];
  statuses: any;
  contacts: [];
  isConversationsFetching: boolean;
  isFetchingBlast: boolean;

  hasMoreConversations: boolean;
  isFetchConversationLazy: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

interface Refs {
  conversationListRef: HTMLUListElement | null;
  conversationsRefs: [HTMLLIElement | null];
}

interface State {
  doNotScroll: boolean;
  swipedItemIndex: string;
}

const mapStateToProps = (state: any, _ownProps: OwnProps): StateProps => ({
  isAuthenticated: state.users.isAuthenticated,
  authenticatedUser: state.users.authenticatedUser,
  companyId: state.users.authenticatedUser.company.id,
  conversations: state.conversations.data,
  statuses: state.statuses.statuses,
  contacts: state.contacts.data,
  isConversationsFetching: state.conversations.isFetching,
  isFetchingBlast: state.conversations.isFetchingBlast,

  hasMoreConversations: state.conversations.hasMoreConversations,
  isFetchConversationLazy: state.conversations.isFetchConversationLazy,
});

const mapDispatchToProps: DispatchProps = {
  getConversationsList: conversationActions.getConversationsList,
  hideConversation: conversationActions.hideConversation,
  openModal: modalActions.openModal,
  setModalState: modalActions.setModalState,
  clearUnreadMessages: conversationActions.clearUnreadMessages,
  getContactsList: contactsActions.getContactsList,
  setNewContact: contactsActions.setNewContact,

  getConversationsListLazy: conversationActions.getConversationsListLazy,
};

class ConversationsList extends PureComponent<Props, State, Refs> {
  private conversationListRef: HTMLUListElement | null = null;
  private conversationsRefs: Array<HTMLLIElement | null> = [];
  private conversationsCountOld: number = 0;

  static propTypes = {
    activeConversation: PropTypes.object,
    openConversation: PropTypes.func,
  };

  state = {
    doNotScroll: false,
    swipedItemIndex: "",
  };

  constructor(props: Props) {
    super(props);

    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s",
        s: "just now",
        ss: "just now",
        m: "1 min ago",
        mm: "%d min ago",
        h: "1h ago",
        hh: "%dh ago",
        d: "1d ago",
        dd: "%dd ago",
        M: "1m ago",
        MM: "%dm ago",
        y: "1y ago",
        yy: "%dy ago",
      },
    });
  }

  componentDidMount() {
    if (this.props.isAuthenticated && this.props.conversations.length === 0) {
      // this.props.getContactsList();
      this.props.getConversationsList();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.props.isAuthenticated &&
      this.props.isAuthenticated !== prevProps.isAuthenticated
    )
      this.props.getConversationsList();

    if (
      (this.props.activeConversation &&
        prevProps.activeConversation &&
        this.props.activeConversation !== null &&
        this.props.activeConversation.id !== prevProps.activeConversation.id) ||
      this.conversationsCountOld !== this.conversationsRefs.length
    )
      this.scrollToActiveConversation();

    this.conversationsCountOld = this.conversationsRefs.length;
  }

  handleScroll = (event: any) => {
    if (event && this.props.hasMoreConversations) {
      const target = event.target;
      console.log(
        "scrollHeight => ",
        target.scrollHeight,
        " scrollTop =>",
        target.scrollTop,
        " clientHeight =>",
        target.clientHeight
      );
      const diff = Math.trunc(target.scrollHeight - target.scrollTop);
      console.log("diiff => ", diff);
      const bottom = diff <= Math.trunc(target.clientHeight);
      console.log("bottom => ", bottom);
      if (bottom && !this.props.isFetchConversationLazy) {
        this.props.getConversationsListLazy();
      }
    }
  };

  handleSwipe = (e: EventData) => {
    if (e.dir === "Left" && e.deltaX > 100) {
      const el = e.event.target as HTMLDivElement;
      if (el.dataset && el.dataset.index) {
        this.setState({ swipedItemIndex: el.dataset.index });
      }
    }
  };

  handleAddContact = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.props.setNewContact(e.currentTarget.dataset.phone_number);
    this.props.openModal("CONTACTS_MODAL", { activeEdit: true });
  };

  handleAddBlast = (e: BaseSyntheticEvent) => {
    e.stopPropagation();
    this.props.openModal("CONTACTS_MODAL", {
      activeEdit: true,
      openList: true,
    });
  };

  scrollToActiveConversation = () => {
    // Conversations list should not be scrolled
    // if the user change conversation by himself
    if (!this.state.doNotScroll) {
      if (this.conversationListRef && this.props.activeConversation) {
        const activeConversation = this.conversationsRefs.find(
          li =>
            li &&
            li.dataset &&
            li.dataset.index === this.props.activeConversation.id
        );
        if (activeConversation) {
          // Scroll to active conversation
          this.conversationListRef.scrollTo(
            0,
            activeConversation.offsetTop -
              this.conversationListRef.clientHeight / 2 +
              activeConversation.clientHeight / 2
          );
        }
      }
    }
    // Reset not scrolling flag
    this.setState({ doNotScroll: false });
  };

  openConversation = (conversationId: string) => {
    const selectedConversation: IConversation = (this.props.conversations.find(
      (conversation: IConversation) => conversation.id === conversationId
    ) as never) as IConversation;
    if (
      selectedConversation &&
      this.props.activeConversation !== null &&
      selectedConversation.id !== this.props.activeConversation.id
    ) {
      if (selectedConversation.unreadMessages > 0) {
        this.props.clearUnreadMessages(conversationId);
      }
      this.setState({ doNotScroll: true }, () =>
        this.props.openConversation(conversationId)
      );
    }
  };

  prepareLabel = (conversation: IConversation) => {
    if (conversation.type === "blast") {
      return conversation.name;
    }
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      conversation.name
        .trim()
        .split(" ")
        .join("")
    );

    let name = conversation.name;

    if (isPhoneName) {
      name = fpn(
        conversation.name,
        this.props.authenticatedUser.show_censor_contact
      );
    }

    return conversation.external.length === 1 &&
      conversation.external[0].in_contacts === false
      ? fpn(
          conversation.external[0].phone_number,
          this.props.authenticatedUser.show_censor_contact
        )
      : name;
  };

  handleHideConversation = (
    conversation: IConversation,
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    this.props.hideConversation(conversation.id, conversation.type);
  };

  renderConversationIcon = (conversation: IConversation) => {
    if (conversation.type === "blast") return "icon-broadcast";
    // 1-to-1 chat with external
    if (
      conversation.external.length === 1 &&
      conversation.internal.length === 1
    )
      return "icon-profile";
    // group chat with internals
    if (conversation.external.length === 0 && conversation.internal.length > 2)
      return "icon-group";
    // group chat with externals
    if (conversation.external.length > 1 && conversation.internal.length === 1)
      return "icon-group";
    // 1-to-1 chat with internal
    if (
      conversation.external.length === 0 &&
      conversation.internal.length === 2
    ) {
      const { activePhone } = this.props.authenticatedUser;
      const companyId = this.props.companyId;
      if (activePhone) {
        const interlocutor = conversation.internal.find(
          phone => phone.id !== activePhone
        );
        if (interlocutor && interlocutor.id) {
          if (interlocutor.company && interlocutor.company.id !== companyId) {
            return "icon-profile";
          }
          if (interlocutor.shared) {
            return "icon-phone";
          } else {
            return this.props.statuses[interlocutor.id]
              ? `icon-circle icon-${this.props.statuses[interlocutor.id].class}`
              : "icon-circle";
          }
        }
      }
      return "icon-circle";
    }
    return "icon-profile";
  };

  renderTime = (conversation: IConversation) => {
    return conversation.last_message ? (
      <Moment fromNow>{conversation.last_message}</Moment>
    ) : null;
  };

  render() {
    const { activeConversation, conversations } = this.props;
    const companyId = this.props.companyId;
    return (
      <React.Fragment>
        {this.props.isConversationsFetching && <Loader />}
        {conversations.length ? (
          <div className="con-panel">
            <ul
              className={classNames("ConversationsList", {
                open: this.props.isBlastOpen,
              })}
              ref={ref => (this.conversationListRef = ref)}
              onScroll={this.handleScroll}
            >
              {conversations
                .filter(
                  (conversation: IConversation) => conversation.type !== "blast"
                )
                .map((conversation: IConversation, key) => (
                  <Swipeable key={key} onSwiped={this.handleSwipe}>
                    <li
                      ref={ref => (this.conversationsRefs[key] = ref)}
                      data-index={conversation.id}
                      onClick={() => this.openConversation(conversation.id)}
                      className={classNames({
                        active:
                          activeConversation &&
                          activeConversation.id === conversation.id,
                        swiped: this.state.swipedItemIndex === conversation.id,
                        unread: conversation.unreadMessages > 0,
                        hidden:
                          conversation.internal.length == 2 &&
                          conversation.messages.length === 0 &&
                          (!conversation.hasOwnProperty("last_message") ||
                            conversation.last_message === false) &&
                          conversation.internal[0].company.id !=
                            conversation.internal[1].company.id &&
                          activeConversation &&
                          activeConversation.id !== conversation.id,
                      })}
                    >
                      <span>
                        {this.renderTime(conversation)}
                        {conversation.unreadMessages > 0 && (
                          <div className="ConversationsList__Badge">
                            <span>{conversation.unreadMessages}</span>
                          </div>
                        )}
                      </span>
                      <i
                        className={this.renderConversationIcon(conversation)}
                      ></i>
                      {this.prepareLabel(conversation)}
                      <div className="ConversationList__actions">
                        {conversation.external.length === 1 &&
                          conversation.external[0].in_contacts === false && (
                            <button
                              type="button"
                              className="is-primary"
                              onClick={this.handleAddContact}
                              data-id={conversation.external[0].id}
                              data-phone_number={
                                conversation.external[0].phone_number
                              }
                            >
                              <i className="icon-plus"></i>
                            </button>
                          )}

                        {conversation.internal.length === 2 &&
                          Array.from(
                            new Set(
                              conversation.internal.map(conv => conv.company.id)
                            )
                          ).length == 2 &&
                          !this.props.contacts.find(
                            (item: any) =>
                              conversation.internal.filter(
                                internal => internal.company.id !== companyId
                              )[0].phone_number === item.phone_number &&
                              item.phone_number !== "" &&
                              item.in_contacts === true
                          ) && (
                            <button
                              type="button"
                              className="is-primary"
                              onClick={this.handleAddContact}
                              data-id={null}
                              data-phone_number={conversation.internal
                                .filter(
                                  internal => internal.company.id !== companyId
                                )[0]
                                .phone_number.slice(1, 11)}
                            >
                              <i className="icon-plus"></i>
                            </button>
                          )}
                        <button
                          type="button"
                          onClick={this.handleHideConversation.bind(
                            this,
                            conversation
                          )}
                        >
                          <i className="icon-hide"></i>
                        </button>
                      </div>
                    </li>
                  </Swipeable>
                ))}
              {this.props.isFetchConversationLazy && <Loader />}
            </ul>

            <div
              className={classNames("cus-collapse", {
                open: this.props.isBlastOpen,
              })}
            >
              <span>List</span>
              <button
                type="button"
                className="is-primary"
                onClick={this.handleAddBlast}
              >
                <i className="icon-plus"></i>
              </button>
            </div>
            <ul
              className="ConversationsList"
              ref={ref => (this.conversationListRef = ref)}
            >
              {this.props.isFetchingBlast && <Loader />}
              {conversations
                .filter(
                  (conversation: IConversation) => conversation.type === "blast"
                )
                .map((conversation: IConversation, key) => (
                  <Swipeable key={key} onSwiped={this.handleSwipe}>
                    <li
                      ref={ref => (this.conversationsRefs[key] = ref)}
                      data-index={conversation.id}
                      onClick={() => this.openConversation(conversation.id)}
                      className={classNames({
                        active:
                          activeConversation &&
                          activeConversation.id === conversation.id,
                        swiped: this.state.swipedItemIndex === conversation.id,
                        unread: conversation.unreadMessages > 0,
                        hidden:
                          conversation.internal.length == 2 &&
                          conversation.messages.length === 0 &&
                          (!conversation.hasOwnProperty("last_message") ||
                            conversation.last_message === false) &&
                          conversation.internal[0].company.id !=
                            conversation.internal[1].company.id &&
                          activeConversation &&
                          activeConversation.id !== conversation.id,
                      })}
                    >
                      <span>
                        {this.renderTime(conversation)}
                        {conversation.unreadMessages > 0 && (
                          <div className="ConversationsList__Badge">
                            <span>{conversation.unreadMessages}</span>
                          </div>
                        )}
                      </span>
                      <i
                        className={this.renderConversationIcon(conversation)}
                      ></i>
                      {this.prepareLabel(conversation)}
                      <div className="ConversationList__actions">
                        {conversation.type != "blast" &&
                          conversation.external.length === 1 &&
                          conversation.external[0].in_contacts === false && (
                            <button
                              type="button"
                              className="is-primary"
                              onClick={this.handleAddContact}
                              data-id={conversation.external[0].id}
                              data-phone_number={
                                conversation.external[0].phone_number
                              }
                            >
                              <i className="icon-plus"></i>
                            </button>
                          )}

                        {conversation.internal.length === 2 &&
                          Array.from(
                            new Set(
                              conversation.internal.map(conv => conv.company.id)
                            )
                          ).length == 2 &&
                          !this.props.contacts.find(
                            (item: any) =>
                              conversation.internal.filter(
                                internal => internal.company.id !== companyId
                              )[0].phone_number === item.phone_number &&
                              item.phone_number !== "" &&
                              item.in_contacts === true
                          ) && (
                            <button
                              type="button"
                              className="is-primary"
                              onClick={this.handleAddContact}
                              data-id={null}
                              data-phone_number={conversation.internal
                                .filter(
                                  internal => internal.company.id !== companyId
                                )[0]
                                .phone_number.slice(1, 11)}
                            >
                              <i className="icon-plus"></i>
                            </button>
                          )}
                        <button
                          type="button"
                          onClick={this.handleHideConversation.bind(
                            this,
                            conversation
                          )}
                        >
                          <i className="icon-hide"></i>
                        </button>
                      </div>
                    </li>
                  </Swipeable>
                ))}
            </ul>
          </div>
        ) : (
          <div className="ConversationList">
            Create or select a contact
            <br />
            to start a conversation.
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConversationsList as any);
