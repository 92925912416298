import classnames from "classnames";
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./PrebuiltRepliesPanel.scss";
import {
  prebuiltRepliesActions,
  IPrebuiltReply,
} from "~/store/replies_prebuilt";
import { IConversation } from "~/store/conversations";
import styled from "styled-components";
import { LightenDarkenColor, escapeRegExp, ThemeContext } from "~/utils";

namespace PrebuiltRepliesPanel {
  interface StateProps {
    activeConversation: IConversation;
    authenticatedUser: any;
    prebuiltReplies: IPrebuiltReply[];
  }
  interface DispatchProps {
    listReplies: () => void;
  }
  interface OwnProps {
    closePanel: () => void;
    selectReply: (text: string) => void;
    shown: boolean;
  }
  export type Props = StateProps & DispatchProps & OwnProps;
}

const mapStateToProps = (state: any) => ({
  activeConversation: state.conversations.activeConversation,
  authenticatedUser: state.users.authenticatedUser,
  prebuiltReplies: state.prebuiltReplies.data,
});

const mapDispatchToProps = {
  listReplies: prebuiltRepliesActions.listPrebuiltReplies,
};

class PrebuiltRepliesPanel extends PureComponent<PrebuiltRepliesPanel.Props> {
  static propTypes = {
    closePanel: PropTypes.func.isRequired,
    selectReply: PropTypes.func.isRequired,
    shown: PropTypes.bool.isRequired,
  };

  public componentDidMount() {
    this.props.listReplies();
  }

  public render() {
    const {
      activeConversation,
      authenticatedUser,
      prebuiltReplies,
      shown,
    } = this.props;

    const filteredReplies = [];

    if (shown && activeConversation && authenticatedUser) {
      const incomingMessages = activeConversation.messages
        ? activeConversation.messages.filter(
            msg =>
              !(
                (msg.sender.internal &&
                  msg.sender_id === authenticatedUser.id) ||
                msg.message_type === "autoreply"
              )
          )
        : [];
      if (incomingMessages.length && prebuiltReplies !== null) {
        const lastMessage = incomingMessages[incomingMessages.length - 1];
        for (let i = 0, rl = prebuiltReplies.length; i < rl; i++) {
          const reply = prebuiltReplies[i];
          for (let j = 0, kl = reply.keywords.length; j < kl; j++) {
            if (
              new RegExp(
                "(?:^|[^\\w\\n])" +
                  escapeRegExp(reply.keywords[j]) +
                  "(?=$|[\\W])",
                "gi"
              ).test(lastMessage.text_content)
            ) {
              const modifiedPrebuiltReply = {
                ...prebuiltReplies[i],
                keyword: reply.keywords[j],
              };
              filteredReplies.push(modifiedPrebuiltReply);
              break;
            }
          }
        }
      }
    }

    let brandColor: string, lightColor;
    const { authenticatedUser: user } = this.props;
    if (
      this.context.theme ||
      (user && user.company && user.company.whitelabeling)
    ) {
      const theme = this.context.theme;
      brandColor =
        theme === "default"
          ? user.company.whitelabeling.primary_css_color
          : theme;
      lightColor = LightenDarkenColor(brandColor, 30);
    }

    let ThemedKeyword = styled.li`
      &:hover > div > div > b {
        background: ${lightColor || "#00bbe5"} !important;
      }
    `;

    return (
      <aside className={classnames("PrebuiltRepliesPanel", { shown })}>
        <ul>
          {filteredReplies.length ? (
            filteredReplies.map((reply, key) => (
              <ThemedKeyword
                key={key}
                onClick={() => this.props.selectReply(reply.text_content)}
              >
                <div className="flex-container">
                  <div>
                    <b>{reply.keyword}</b>
                  </div>
                  <div
                    style={{ borderRight: `10px solid ${brandColor}` }}
                    className="arrow-left"
                  ></div>
                  <div
                    style={{ border: `1px solid ${brandColor}` }}
                    className="border-temp"
                  >
                    {reply.text_content}
                  </div>
                </div>
              </ThemedKeyword>
            ))
          ) : (
            <li className="disabled">No suggested replies match.</li>
          )}
        </ul>
        <i className="icon-close" onClick={this.props.closePanel} />
      </aside>
    );
  }
}

PrebuiltRepliesPanel.contextType = ThemeContext;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PrebuiltRepliesPanel as any);
