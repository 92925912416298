export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const __DEV__ = process.env.NODE_ENV === "development";
export const __TEST__ = process.env.NODE_ENV === "test";
export const __PROD__ = process.env.NODE_ENV === "production";
export const API_URL = __DEV__
  ? `${process.env.APIURL_DEV || "//localhost"}` +
    (process.env.APIPORT === "-1" ? "" : `:${process.env.APIPORT || 3000}`)
  : `https://${process.env.APIURL_PROD}`;
export const wsOptions = {
  authTokenName: "user",
  hostname: __DEV__
    ? process.env.APIURL_DEV || "localhost"
    : process.env.APIURL_PROD,
  port: +(process.env.WS_PORT || 8000),
  secure: process.env.WS_SECURE === "true",
};

export const CONVERSATION_FETCH_LIMIT = 20;
