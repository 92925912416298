import { AxiosPromise } from "axios";
import classnames from "classnames";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { conversationActions } from "~/store/conversations";
import { formatFileSize } from "../../utils";
import DragAndDrop from "../common/DragAndDrop/DragAndDrop";
import Loader from "../common/Loader/Loader";
import "./AttachmentPanel.scss";

interface DispatchProps {
  addAttachment: (file: File) => AxiosPromise;
}

interface OwnProps {
  attachment: any;
  closePanel: () => void;
  deleteAttachment: () => void;
  setAttachment: (data: any) => void;
  shown: boolean;
  openDown?: boolean;
  getLoadingStatus?: (isLoading: boolean) => void;
}

type Props = DispatchProps & OwnProps;

const MAX_ATTACHMENT_SIZE = 300 * 1024;
const MAX_ATTACHMENT_BIG_SIZES = 5 * 1024 * 1024;
const MAX_ATTACHMENT_IN_MB = MAX_ATTACHMENT_BIG_SIZES / 1024 / 1024;
const MAX_ATTACHMENT_IN_KB = MAX_ATTACHMENT_SIZE / 1024;

const allowedImages = ["image/jpeg", "image/pjpeg", "image/png"];
const allowedPdf = ["application/pdf", "application/x-pdf"];
const allowedMultimedia = [
  "audio/wav",
  "audio/wave",
  "audio/x-wav",
  "video/3gpp",
  "video/mp4",
];
const allowedBigMIMETypes = [...allowedImages, ...allowedPdf];
const allowedMIMETypes = [
  ...allowedImages,
  ...allowedPdf,
  ...allowedMultimedia,
];

const mapsDispatchToProps: DispatchProps = {
  addAttachment: conversationActions.addAttachment,
};

export default connect(
  null,
  mapsDispatchToProps
)((props: Props) => {
  const ddArea = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const [uploadError, setUploadError] = useState("");

  async function handleChangeFile(images: FileList) {
    if (images && images.length === 1) {
      if (!allowedMIMETypes.includes(images[0].type)) {
        return setUploadError(
          "Unsupported file format. Select a JPEG, PNG, WAV, 3GP, MP4 or PDF (up to 5 pages) file."
        );
      }
      if (allowedBigMIMETypes.includes(images[0].type)) {
        if (images[0].size > MAX_ATTACHMENT_BIG_SIZES) {
          return setUploadError(
            `The file you are trying to upload exceeds maximum file size allowed (${MAX_ATTACHMENT_IN_MB}MB)`
          );
        }
      } else if (images[0].size > MAX_ATTACHMENT_SIZE) {
        return setUploadError(
          `The file you are trying to upload exceeds maximum file size allowed (${MAX_ATTACHMENT_IN_KB}kB)`
        );
      }
      setLoading(true);
      if (props.getLoadingStatus) {
        props.getLoadingStatus(true);
      }
      props.addAttachment(images[0]).then((res: any) => {
        if (res.status === 201) {
          props.setAttachment({
            file: images[0],
            media: res.data,
          });
          setUploadError("");
          setLoading(false);
          props.closePanel();
          if (props.getLoadingStatus) {
            props.getLoadingStatus(false);
          }
        } else {
          setUploadError(
            (res &&
              res.response &&
              res.response.data &&
              res.response.data.error_message) ||
              "Uploading error"
          );
          setLoading(false);
          if (props.getLoadingStatus) {
            props.getLoadingStatus(false);
          }
        }
      });
    }
  }

  function handleDeleteImage() {
    ddArea && ddArea.current.clearFile();
    props.deleteAttachment();
  }

  function renderUploadPreview() {
    if (
      props.attachment &&
      props.attachment.media &&
      (props.attachment.file || props.attachment.file_Name)
    ) {
      const { media_link } = props.attachment.media;
      // Notice that 3-letters extensions has leading dot because we slice for 4 letters
      const imageFileExt = [
        "jpeg",
        ".jpg",
        ".jpe",
        "jfif",
        ".jfi",
        ".jif",
        ".png",
      ];
      const fileExt = media_link.slice(-4);
      return (
        <div className="flex">
          {imageFileExt.includes(fileExt) ? (
            <img className="filePreview" src={media_link} />
          ) : (
            <div className="filePreview">
              {fileExt[0] === "." ? fileExt.slice(1) : fileExt}
            </div>
          )}
          <div className="imageDetails">
            <div>
              <div>
                {props.attachment.file
                  ? props.attachment.file.name
                  : props.attachment.file_Name}
              </div>
              <div>
                {props.attachment.file
                  ? formatFileSize(props.attachment.file.size)
                  : props.attachment.file_Size}
              </div>
            </div>
            <button
              className="removeFile"
              type="button"
              onClick={handleDeleteImage}
            >
              <i className="icon-delete"></i>
            </button>
          </div>
        </div>
      );
    }
    return null;
  }

  return (
    <aside
      className={classnames(
        "AttachmentPanel",
        { openDown: props.openDown },
        { openTop: !props.openDown },
        { shown: props.shown }
      )}
    >
      <DragAndDrop
        csvData={[]}
        dataType={allowedMIMETypes.join(",")}
        handleDrop={handleChangeFile}
        hint={`Maximum file size for JPEG, PNG, or PDF (up to 5 pages): ${MAX_ATTACHMENT_IN_MB}MB<br />Maximum file size for WAV, 3GP, or MP4: ${MAX_ATTACHMENT_IN_KB}kB`}
        ref={ddArea}
      >
        {renderUploadPreview()}
      </DragAndDrop>
      {!!uploadError && <span className="error-msg">{uploadError}</span>}
      {isLoading && (
        <div className="loaderContainer">
          <Loader />
        </div>
      )}
      <i className="icon-close" onClick={props.closePanel} />
    </aside>
  );
});
