import classNames from "classnames";
import React from "react";
import { connect } from "react-redux";
import NoAvatar from "~/assets/avatar.svg";
import {
  IExternalContact,
  IInternalContact,
  contactsActions,
} from "~/store/contacts";
import { fpn } from "~/utils";
import Loader from "../common/Loader/Loader";
import "./ContactProfile.scss";
import { modalActions } from "~/store/modal";
import { conversationActions } from "~/store/conversations";

interface StateProps {
  activeContact: IExternalContact | IInternalContact | any;
  contacts: [];
  isLoading: boolean;
  statuses: any[];
  authenticatedUser: any;
}

interface DispatchProps {
  closeModal: () => void;
  createConversation: (members: string[], listeners: string[]) => void;
  deleteContact: (contactId: number) => void;
}

interface OwnProps {
  changeShown: (shown: string) => void;
  onBack: () => void;
  onEdit: () => void;
  shown: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

const mapDispatchToProps = {
  closeModal: modalActions.closeModal,
  createConversation: conversationActions.createConversation,
  deleteContact: contactsActions.deleteContact,
};

const mapStateToProps = (state: any) => ({
  activeContact: state.contacts.activeContact,
  contacts: state.contacts.data,
  statuses: state.statuses.statuses,
  authenticatedUser: state.users.authenticatedUser,
});

const headers = {
  // phone_number: "PHONE NUMBER",
  // first_name: "FIRST NAME",
  // last_name: "LAST NAME",
  company: "COMPANY",
  job_title: "JOB TITLE",
  email: "EMAIL",
  address_line_1: "ADDRESS LINE 1",
  address_line_2: "ADDRESS LINE 2",
  city: "CITY",
  state: "STATE",
  zipcode: "ZIPCODE",
  custom_field_1: "CUSTOME FIELD 1",
  custom_field_2: "CUSTOME FIELD 2",
  custom_field_3: "CUSTOME FIELD 3",
  notes: "NOTES",
};

let data: any[] = [];

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  let phone, status;
  if (props.activeContact) {
    data = [];
    console.log("[props.activeContact] ", props.activeContact);
    Object.entries(headers)
      .filter(([key]) => props.activeContact[key])
      .forEach(([key, value], index) => {
        if (index % 2 == 0) {
          data.push({ [value]: props.activeContact[key] });
        } else {
          const insert_index = Math.floor(index / 2);
          data[insert_index] = {
            ...data[insert_index],
            [value]: props.activeContact[key],
          };
        }
      });
    if (props.activeContact.default_phone_id) {
      phone = props.activeContact.phones.find(
        (ph: any) => ph.id === props.activeContact.default_phone_id
      );
    } else {
      phone = props.activeContact;
    }
    status = props.statuses[props.activeContact.id];
  }

  async function handleDeleteContact() {
    if (props.activeContact) {
      await props.deleteContact(props.activeContact.id);
      props.changeShown("contacts");
    }
  }

  function handleStartConversation() {
    if (props.activeContact) {
      const members = [];
      if (props.activeContact.is_phone === true) {
        members.push(props.activeContact.id);
      } else if (props.activeContact.default_phone_id) {
        members.push(props.activeContact.default_phone_id);
      }

      const listeners =
        props.activeContact.display_as === undefined
          ? [props.activeContact.id]
          : [];

      // If start conversation with Phone (contact === Phone)
      if (members.length + listeners.length === 0) {
        members.push(props.activeContact.id);
      }
      props.createConversation(members, listeners);
      props.closeModal();
    }
  }

  function contactName(name: string) {
    const digitsExp = new RegExp("^[0-9]+$", "g");
    const isPhoneName = digitsExp.test(
      name
        .trim()
        .split(" ")
        .join("")
    );
    if (isPhoneName) {
      name = fpn(name, props.authenticatedUser.show_censor_contact);
    }
    return name;
  }

  return (
    <div className={classNames("ContactProfile", { shown: props.shown })}>
      {props.activeContact && (
        <>
          <button type="button" onClick={props.onBack}>
            <i className="icon-back"></i>
          </button>
          <header>
            <img src={NoAvatar} alt="User avatar" />
            <div>
              <h1>
                {contactName(
                  `${props.activeContact.first_name} ${props.activeContact.last_name}`
                )}
              </h1>
              <h2>
                {phone && phone.phone_number && phone.phone_number.length > 0
                  ? fpn(
                      phone.phone_number,
                      props.authenticatedUser.show_censor_contact
                    )
                  : ""}
              </h2>
            </div>
          </header>
          {/* {props.activeContact && Object.entries(headers).filter(([key]) => props.activeContact[key]).map(([key, value], index) => {
            return (<div style={{ margin: 0 }}>
              <h3 style={{ marginTop: index == 0 ? 40 : 10 }}>{value}</h3>
              <p>{props.activeContact[key]}</p>
            </div>)
          })} */}
          {props.activeContact &&
            data.map((data_entry, index) => {
              return (
                <div className="rows">
                  {Object.entries(data_entry).map(
                    ([key, value]: any, index1) => {
                      return (
                        <div
                          style={{ margin: 0 }}
                          className={index1 == 0 ? "left_col" : "right_col"}
                        >
                          <h3 style={{ marginTop: index == 0 ? 40 : 10 }}>
                            {key}
                          </h3>
                          <p>{value}</p>
                        </div>
                      );
                    }
                  )}
                </div>
              );
            })}
          {status && (
            <div>
              <h3>STATUS</h3>
              <p>
                <span className={`icon-circle icon-${status.class}`} />{" "}
                {status.title}
              </p>
            </div>
          )}
          {props.isLoading ? (
            <Loader />
          ) : (
            <footer>
              <button
                className="text-btn"
                type="button"
                onClick={handleStartConversation}
              >
                <i className="icon-chat"></i>
              </button>
              {!props.activeContact.hasOwnProperty("display_as") && (
                <>
                  <button
                    className="edit-btn"
                    type="button"
                    onClick={props.onEdit}
                  >
                    <i className="icon-edit"></i>
                  </button>
                  <button
                    className="delete-btn"
                    type="button"
                    onClick={handleDeleteContact}
                  >
                    <i className="icon-delete"></i>
                  </button>
                </>
              )}
            </footer>
          )}
        </>
      )}
    </div>
  );
});
