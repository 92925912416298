import moment from "moment";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, AnyAction } from "redux";
import { API_URL } from "../config";
import { withAuthorizationHeader } from "./index";
import { USERS_LOGOUT_USER_REQUEST } from "./user";

export interface IRecurringScheduleMessage {
  id?: number;
  isActive: boolean;
  dayName: string;
  timeFrom?: string;
  isUpdate?: boolean;
}

export interface ISchedule {
  id?: number;
  schedule_at?: moment.Moment | string | undefined;
  message_content: string;
  recurring_schedule_message: IRecurringScheduleMessage[];
  recurring_schedule_timeFrom: moment.Moment | string | undefined;
  media_data?: any | undefined;
  // is_active: boolean;
  // recurring_replies: IRecurringScheduleMessage[];
  // media_link: string | null;
  // file_Name: string | null;
  // file_Size: string | null;
  // media_file_mime: string;
  type: number;
  saving: boolean;
}

// ------------------------------------
// Constants
// ------------------------------------
const SCHEDULE_CREATE_REQUEST = "SCHEDULE_CREATE_REQUEST";
const SCHEDULE_CREATE_SUCCESS = "SCHEDULE_CREATE_SUCCESS";
const SCHEDULE_CREATE_FAILURE = "SCHEDULE_CREATE_FAILURE";

const SCHEDULE_DELETE_REQUEST = "SCHEDULE_DELETE_REQUEST";
const SCHEDULE_DELETE_SUCCESS = "SCHEDULE_DELETE_SUCCESS";
const SCHEDULE_DELETE_FAILURE = "SCHEDULE_DELETE_FAILURE";

const SCHEDULE_LIST_REQUEST = "SCHEDULE_LIST_REQUEST";
const SCHEDULE_LIST_SUCCESS = "SCHEDULE_LIST_SUCCESS";
const SCHEDULE_LIST_FAILURE = "SCHEDULE_LIST_FAILURE";

const SCHEDULE_UPDATE_REQUEST = "SCHEDULE_UPDATE_REQUEST";
const SCHEDULE_UPDATE_SUCCESS = "SCHEDULE_UPDATE_SUCCESS";
const SCHEDULE_UPDATE_FAILURE = "SCHEDULE_UPDATE_FAILURE";

const SCHEDULE_CONVERSATION_REMOVE = "SCHEDULE_CONVERSATION_REMOVE";

// ------------------------------------
// Action Creators
// ------------------------------------
const createScheduleRequest = () => ({
  type: SCHEDULE_CREATE_REQUEST,
});
const createScheduleSuccess = (res: ISchedule) => ({
  type: SCHEDULE_CREATE_SUCCESS,
  payload: res,
});
const createScheduleFailure = (err: AxiosError) => ({
  type: SCHEDULE_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const deleteScheduleRequest = () => ({
  type: SCHEDULE_DELETE_REQUEST,
});
const deleteScheduleSuccess = (res: number) => ({
  type: SCHEDULE_DELETE_SUCCESS,
  payload: res,
});
const deleteScheduleFailure = (err: AxiosError) => ({
  type: SCHEDULE_DELETE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listSchedulesRequest = () => ({
  type: SCHEDULE_LIST_REQUEST,
});
const listSchedulesSuccess = (
  res: AxiosResponse,
  activeConversationId: number
) => ({
  type: SCHEDULE_LIST_SUCCESS,
  payload: { data: res.data, activeConversationId },
});
const listSchedulesFailure = (err: AxiosError) => ({
  type: SCHEDULE_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateScheduleRequest = () => ({
  type: SCHEDULE_UPDATE_REQUEST,
});
const updateScheduleSuccess = (res: ISchedule) => ({
  type: SCHEDULE_UPDATE_SUCCESS,
  payload: res,
});
const updateScheduleFailure = (err: AxiosError) => ({
  type: SCHEDULE_UPDATE_FAILURE,
  payload: err && err.response && err.response.data,
});
const removeConversationScheduleSuccess = (res: number) => ({
  type: SCHEDULE_CONVERSATION_REMOVE,
  payload: res,
});

// ------------------------------------
// Actions
// ------------------------------------
const createSchedule = (form: ISchedule) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  const { type, id } = getState().conversations.activeConversation;
  try {
    if (!form.media_data || !form.media_data.media) {
      console.log("================ Delete Key =================");
      delete form.media_data;
    }
    dispatch(createScheduleRequest());
    const res = await Axios.post(
      `${API_URL}/schedule_message`,
      { ...form, conversation_type: type, conversation_id: id },
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(createScheduleSuccess(res.data.data));
    }
  } catch (err) {
    dispatch(createScheduleFailure(err));
  }
};

const deleteSchedule = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  try {
    dispatch(deleteScheduleRequest());
    const res = await Axios.delete(
      `${API_URL}/schedule_message/${id}`,
      withAuthorizationHeader(token)
    );
    if (res.data.status === "ok") {
      dispatch(deleteScheduleSuccess(id));
    }
  } catch (err) {
    dispatch(deleteScheduleFailure(err));
  }
};

const listSchedules = () => async (dispatch: Dispatch, getState: () => any) => {
  const { token, activePhone } = getState().users.authenticatedUser;
  const {
    id: activeConversationId,
  } = getState().conversations.activeConversation;
  console.log("Active Conversation id ", activeConversationId);
  try {
    dispatch(listSchedulesRequest());
    const res = await Axios.get(
      `${API_URL}/schedule_message/${activeConversationId}`,
      withAuthorizationHeader(token)
    );
    console.log("res ", res);
    dispatch(listSchedulesSuccess(res, activeConversationId));
  } catch (err) {
    dispatch(listSchedulesFailure(err));
  }
};

const updateSchedule = (form: ISchedule) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { id, token, activePhone } = getState().users.authenticatedUser;

  if (id && token && form) {
    const restForm = Object.assign({}, form);
    console.log("================== restForm ", restForm);
    if (!restForm.media_data || !restForm.media_data.media) {
      console.log("================ Delete Key =================");
      delete restForm.media_data;
    }
    try {
      dispatch(updateScheduleRequest());
      const res = await Axios.put(
        `${API_URL}/schedule_message/${restForm.id}`,
        restForm,
        withAuthorizationHeader(token)
      );
      dispatch(updateScheduleSuccess(res.data.data));
    } catch (err) {
      dispatch(updateScheduleFailure(err));
    }
  }
};

const removeSchedule = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  dispatch(removeConversationScheduleSuccess(id));
};

export const schedulesActions = {
  createSchedule,
  deleteSchedule,
  listSchedules,
  updateSchedule,
  removeSchedule,
};

// ------------------------------------
// Helper function(s)
// ------------------------------------
export const tzScheduleShift = (
  shift: string,
  msg: IRecurringScheduleMessage,
  key: number,
  e: Array<IRecurringScheduleMessage>
) => {
  const r_s_message = Object.assign({}, msg);
  const with_time = e.find(
    (item: IRecurringScheduleMessage) =>
      item["isActive"] && item["timeFrom"] !== undefined
  );
  if (with_time && e.length >= 2 && ["load", "save"].includes(shift)) {
    // Check if DST rollover occurs during conversion
    let message_index = key;
    const test_date = moment.utc(with_time["timeFrom"], "HH:mm:ss");
    let dates = [
      test_date.utc().format("YYYY-MM-DD"),
      test_date.local().format("YYYY-MM-DD"),
    ];
    if (shift == "save") {
      dates.reverse();
    }
    if (dates[0] < dates[1]) {
      message_index = key < e.length - 1 ? key + 1 : 0;
    } else if (dates[0] > dates[1]) {
      message_index = key > 0 ? key - 1 : e.length - 1;
    }
    r_s_message["dayName"] = e[message_index]["dayName"];
  }
  return r_s_message;
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeSchedule: -1,
  isFetching: false,
  data: [],
  activeConversationId: undefined,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };
    case SCHEDULE_CREATE_REQUEST:
    case SCHEDULE_DELETE_REQUEST:
    case SCHEDULE_LIST_REQUEST:
    case SCHEDULE_UPDATE_REQUEST:
      return { ...state, isFetching: true };

    case SCHEDULE_CREATE_SUCCESS:
      const newReplies = [...state.data];
      !newReplies.find((item: ISchedule) => item.id === action.payload.id) &&
        newReplies.push(action.payload as never);
      return {
        ...state,
        isFetching: false,
        activeSchedule: action.payload.id,
        data: newReplies,
      };

    case SCHEDULE_DELETE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        activeSchedule: -1,
        data: state.data.filter(
          (schedule: ISchedule) => schedule.id !== action.payload
        ),
      };

    case SCHEDULE_CONVERSATION_REMOVE:
      return {
        ...state,
        isFetching: false,
        activeSchedule: -1,
        data: state.data.filter(
          (schedule: ISchedule) =>
            schedule.type === 1 || schedule.id !== action.payload
        ),
      };

    case SCHEDULE_UPDATE_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.map((item: ISchedule) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };

    case SCHEDULE_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload.data,
        activeConversationId: action.payload.activeConversationId,
      };

    case SCHEDULE_CREATE_FAILURE:
    case SCHEDULE_DELETE_FAILURE:
    case SCHEDULE_LIST_FAILURE:
    case SCHEDULE_UPDATE_FAILURE:
      return { ...state, isFetching: false };

    default:
      return state;
  }
};
