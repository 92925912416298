import classNames from "classnames";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import { ChromePicker } from "react-color";
import Select from "react-select";
import Popover from "react-tiny-popover";
import "./ChatSettings.scss";

namespace ChatSettings {
  interface StyleSettings {
    color?: string;
    fontSize?: number;
    font?: { value: string; label: string };
  }

  export interface Props {
    data: StyleSettings;
    onChangeStyles: (options: {
      payload?: StyleSettings;
      shouldSetInitial?: boolean;
    }) => void;
    containerClassName: string;
  }

  export interface State {
    isPopoverOpen: boolean;
    isColorPickerOpen: boolean;
  }
}

const DEFAULT_COLORS = [
  "#00BBE5",
  "#5700E5",
  "#E50089",
  "#E57C00",
  "#00C013",
  "custom",
];

export default class ChatSettings extends React.PureComponent<
  ChatSettings.Props,
  ChatSettings.State
> {
  state = {
    isPopoverOpen: false,
    isColorPickerOpen: false,
  };

  handleUpdateColor = (item: string) => {
    this.props.onChangeStyles({ payload: { color: item } });
    this.setState({ isColorPickerOpen: false });
  };

  handleToggleSettings = () => {
    this.setState({ isColorPickerOpen: false }, () =>
      this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
    );
  };

  close = () =>
    this.setState({ isColorPickerOpen: false }, () =>
      this.setState({ isPopoverOpen: false })
    );

  handleChangeCustomColor = (color: { hex: string }) =>
    this.props.onChangeStyles({ payload: { color: color.hex } });

  renderFontSelector = () => {
    const FONT_OPTIONS = [
      { value: "Arial, Helvetica, sans-serif", label: "Arial" },
      { value: '"Arial Black", Gadget, sans-serif', label: "Arial Black" },
      { value: '"Comic Sans MS", cursive, sans-serif', label: "Comic Sans MS" },
      { value: '"Courier New", Courier, monospace', label: "Courier New" },
      { value: "Georgia, serif", label: "Georgia" },
      { value: "Impact, Charcoal, sans-serif", label: "Impact" },
      {
        value: '"Lucida Sans Unicode", "Lucida Grande", sans-serif',
        label: "Lucida Sans Unicode",
      },
      { value: '"Lucida Console", Monaco, monospace', label: "Lucida Console" },
      {
        value: '"Palatino Linotype", "Book Antiqua", Palatino, serif',
        label: "Palatino Linotype",
      },
      { value: "Tahoma, Geneva, sans-serif", label: "Tahoma" },
      { value: '"Times New Roman", Times, serif', label: "Times New Roman" },
      { value: '"Trebuchet MS", Helvetica, sans-serif', label: "Trebuchet MS" },
      { value: "Verdana, Geneva, sans-serif", label: "Verdana" },
    ];

    return (
      <Select
        className="select"
        isSearchable={false}
        value={this.props.data.font}
        onChange={font => this.props.onChangeStyles({ payload: { font } })}
        options={FONT_OPTIONS}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "#d4d4d4 !important",
            primary25: "#e5e5e5 !important",
          },
        })}
      />
    );
  };

  renderColorPicker = (item: string) => {
    const {
      data: { color },
    } = this.props;
    const { isColorPickerOpen } = this.state;

    const isPresetColor = color && !DEFAULT_COLORS.includes(color);

    return (
      <Popover
        key={item}
        isOpen={isColorPickerOpen}
        position="bottom"
        align="end"
        padding={16}
        content={
          <div style={{ margin: 10 }}>
            <ChromePicker
              color={color}
              disableAlpha
              onChangeComplete={this.handleChangeCustomColor}
            />
          </div>
        }
      >
        <div
          className="ChatSettings__ColorPickerButton"
          style={{ border: `2px solid ${isPresetColor ? color : "#C4C4C4"}` }}
          onClick={() =>
            this.setState({ isColorPickerOpen: !isColorPickerOpen })
          }
        >
          <i
            className="icon-add"
            style={{ color: isPresetColor ? color : "#C4C4C4" }}
          ></i>
        </div>
      </Popover>
    );
  };

  handleResetStyles = () =>
    this.props.onChangeStyles({ shouldSetInitial: true });

  renderColorSelector = () => {
    const {
      data: { color },
    } = this.props;

    return (
      <div className="ChatSettings__ColorsRow">
        {DEFAULT_COLORS.map(item =>
          item === "custom" ? (
            this.renderColorPicker(item)
          ) : (
            <div
              className="ChatSettings__ColorItemOuter"
              style={{
                border:
                  color === item ? "2px solid #00BBE5" : "2px solid white",
              }}
              key={item}
              onClick={this.handleUpdateColor.bind(this, item)}
            >
              <div
                className="ChatSettings__ColorItemInner"
                style={{ backgroundColor: item }}
              />
            </div>
          )
        )}
      </div>
    );
  };

  render() {
    const {
      data: { fontSize },
      onChangeStyles,
    } = this.props;
    const { isPopoverOpen } = this.state;

    const sliderStyles = {
      handleStyle: {
        width: 22,
        height: 22,
        backgroundColor: "#A1A1A1",
        border: 0,
        boxShadow: "unset",
      },
      railStyle: { backgroundColor: "#E8E8E8", height: 4, top: 8 },
      trackStyle: { backgroundColor: "transparent" },
    };

    return (
      <Popover
        isOpen={isPopoverOpen}
        position="bottom"
        onClickOutside={this.close}
        containerClassName={this.props.containerClassName}
        content={
          <div className="ChatSettings">
            <div className="ChatSettings__Label">Font Family</div>
            {this.renderFontSelector()}

            <div className="ChatSettings__Label">Font Size</div>
            <div style={{ height: 52, marginTop: 6 }}>
              <Slider
                value={fontSize}
                min={14}
                max={21}
                onChange={fontSize => onChangeStyles({ payload: { fontSize } })}
                {...sliderStyles}
              />
            </div>

            <div className="ChatSettings__Label">Bubble Color</div>
            {this.renderColorSelector()}

            <div
              className="ChatSettings__Label ChatSettings__LabelDanger"
              onClick={this.handleResetStyles}
            >
              Restore to defaults
            </div>
          </div>
        }
      >
        <div
          style={{ padding: 8, cursor: "pointer" }}
          onClick={this.handleToggleSettings}
        >
          <i
            className={classNames({
              "icon-settings": !isPopoverOpen,
              "icon-close": isPopoverOpen,
            })}
          ></i>
        </div>
      </Popover>
    );
  }
}
