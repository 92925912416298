import React, { useState, useEffect } from "react";
import ThemedInput from "~/components/common/ThemedInput/ThemedInput";
import ListList from "~/components/ListList/ListList";
import ListProfile from "~/components/ListProfile/ListProfile";
import ListEdit from "~/components/forms/ListEdit/ListEdit";
import ThemedButton from "../common/ThemedButton/ThemedButton";
import { listsActions } from "~/store/lists";
import { connect } from "react-redux";

interface DispatchProps {
  clearActiveList: () => void;
}

interface StateProps {
  activeEdit: boolean;
}

type Props = StateProps & DispatchProps;

const mapDispatchToProps = {
  clearActiveList: listsActions.clearActiveList,
};

const mapStateToProps = (state: any) => ({
  activeEdit: state.modal.modalProps.activeEdit || false,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)((props: Props) => {
  const [isEdit, setEdit] = useState(false);
  const [shown, setShown] = useState("contacts");
  const [searchFilter, setSearchFilter] = useState("");
  useEffect(() => {
    if (props.activeEdit) {
      onAdd();
    }
  }, [props.activeEdit]);

  function onBack() {
    setShown("contacts");
  }

  function onEdit() {
    setEdit(true);
  }

  function handleChangeSearch(e) {
    e.stopPropagation();
    setSearchFilter(e.target.value);
  }

  function onAdd() {
    props.clearActiveList();
    setEdit(true);
    setShown("profile");
  }

  function closeEdit() {
    setEdit(false);
    setShown("contacts");
  }

  return (
    <>
      <section>
        <div>
          <ThemedInput
            className="ContactsModal__SearchInput"
            disabled={isEdit}
            onChange={handleChangeSearch}
            placeholder="Search list"
            type="text"
            value={searchFilter}
          />
          <ListList
            changeShown={setShown}
            isEdit={isEdit}
            search={searchFilter}
            shown={shown === "contacts"}
          />
        </div>
        {isEdit ? (
          <ListEdit onBack={closeEdit} shown={shown === "profile"} />
        ) : (
          <ListProfile
            onBack={onBack}
            onEdit={onEdit}
            setShown={setShown}
            shown={shown === "profile"}
          />
        )}
      </section>
      {!isEdit && (
        <footer>
          <ThemedButton onClick={onAdd}>
            <i className="icon-add"></i>
          </ThemedButton>
        </footer>
      )}
    </>
  );
});
