import Axios, { AxiosError, AxiosResponse } from "axios";
import { Dispatch, AnyAction } from "redux";
import * as _ from "lodash";
import uuid from "uuid";

import { API_URL, CONVERSATION_FETCH_LIMIT } from "../config";
import { withAuthorizationHeader } from "./index";
import { IExternalContact, IInternalContact, DELETE_CONTACT } from "./contacts";
import { USERS_UPDATE_USER_SUCCESS, USERS_LOGOUT_USER_REQUEST } from "./user";
import { MODAL_HIDE } from "../store/modal";

export interface IMessage {
  id: number;
  conversation_id: string;
  is_delivered: boolean;
  media_file_mime?: string;
  media_file_name?: string;
  media_link?: string;
  media_minio_bucket_name?: string;
  message_type?: string;
  sender: {
    display_as: string;
    first_name: string;
    id: number;
    internal: boolean;
    last_name: string;
    username?: string;
    is_Not_Same?: boolean;
  };
  sender_id: number;
  sent_at: string;
  text_content: string;
}

export interface IConversation {
  id: string;
  external: IExternalContact[];
  internal: IInternalContact[];
  is_deleted: boolean;
  messages: IMessage[];
  name: string;
  type: string;
  last_message: string;
  unreadMessages: number;
  company: object;
}

// ------------------------------------
// Constants
// ------------------------------------
const CONVERSATIONS_CREATE_REQUEST = "CONVERSATIONS_CREATE_REQUEST";
const CONVERSATIONS_CREATE_SUCCESS = "CONVERSATIONS_CREATE_SUCCESS";
const CONVERSATIONS_CREATE_FAILURE = "CONVERSATIONS_CREATE_FAILURE";

const CONVERSATIONS_GET_REQUEST = "CONVERSATIONS_GET_REQUEST";
const CONVERSATIONS_GET_SUCCESS = "CONVERSATIONS_GET_SUCCESS";
const CONVERSATIONS_GET_FAILURE = "CONVERSATIONS_GET_FAILURE";

const CONVERSATIONS_HIDE_REQUEST = "CONVERSATIONS_HIDE_REQUEST";
const CONVERSATIONS_HIDE_SUCCESS = "CONVERSATIONS_HIDE_SUCCESS";
const CONVERSATIONS_HIDE_FAILURE = "CONVERSATIONS_HIDE_FAILURE";

const CONVERSATIONS_LIST_REQUEST = "CONVERSATIONS_LIST_REQUEST";
const CONVERSATIONS_LIST_SUCCESS = "CONVERSATIONS_LIST_SUCCESS";
const CONVERSATIONS_LIST_FAILURE = "CONVERSATIONS_LIST_FAILURE";

const CONVERSATIONS_RECEIVE_CONVERSATION = "CONVERSATIONS_RECEIVE_CONVERSATION";
const CONVERSATIONS_MARK_CONVERSATION = "CONVERSATIONS_MARK_CONVERSATION";
const CONVERSATIONS_RECEIVE_MESSAGE = "CONVERSATIONS_RECEIVE_MESSAGE";
const CONVERSATIONS_RESET_SCROLL = "CONVERSATIONS_RESET_SCROLL";

const CONVERSATIONS_SET_ACTIVE = "CONVERSATIONS_SET_ACTIVE";
const CONVERSATIONS_SET_ACTIVE_AND_SCROLL =
  "CONVERSATIONS_SET_ACTIVE_AND_SCROLL";

const CONVERSATIONS_SEND_REQUEST = "CONVERSATIONS_SEND_REQUEST";
const CONVERSATIONS_SEND_SUCCESS = "CONVERSATIONS_SEND_SUCCESS";
const CONVERSATIONS_SEND_FAILURE = "CONVERSATIONS_SEND_FAILURE";

const CONVERSATION_UPDATE_NAME = "CONVERSATION_UPDATE_NAME";

const SET_UNREAD_COUNT = "SET_UNREAD_COUNT";
export const SET_ALL_UNREAD_COUNT = "SET_ALL_UNREAD_COUNT";

export const UPDATE_CONTACT = "UPDATE_CONTACT";

export const CONVERSATIONS_UNSET = "CONVERSATIONS_UNSET";
const SET_DIFF_UNREAD = "SET_DIFF_UNREAD";
const CLEAR_DIFF_UNREAD = "CLEAR_DIFF_UNREAD";

const CONVERSATIONS_REMOVE_MESSAGE = "CONVERSATIONS_REMOVE_MESSAGE";
export const CONVERSATIONS_REMOVE_MESSAGE_FAILURE =
  "CONVERSATIONS_REMOVE_MESSAGE_FAILURE";

const CONVERSATIONS_BALST_LIST_REQUEST = "CONVERSATIONS_BALST_LIST_REQUEST";
const CONVERSATIONS_BLAST_LIST_SUCCESS = "CONVERSATIONS_BLAST_LIST_SUCCESS";
const CONVERSATIONS_BLAST_LIST_FAILURE = "CONVERSATIONS_BLAST_LIST_FAILURE";

const LOAD_MESSAGE_GET_SUCCESS = "LOAD_MESSAGE_GET_SUCCESS";
const LOAD_MESSAGE_GET_REQUEST = "LOAD_MESSAGE_GET_REQUEST";

// ------------------------------------
// Conversation and Conversation Search Pagination'
// ------------------------------------

const CONVERSATIONS_LIST_REQUEST_LAZY = "CONVERSATIONS_LIST_REQUEST_LAZY";
const CONVERSATIONS_LIST_SUCCESS_LAZY = "CONVERSATIONS_LIST_SUCCESS_LAZY";
const CONVERSATIONS_LIST_FAILURE_LAZY = "CONVERSATIONS_LIST_FAILURE_LAZY";

const CONVERSATIONS_LIST_EXCLUDE_SUCCESS = "CONVERSATIONS_LIST_EXCLUDE_SUCCESS";

const SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY =
  "SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY";
const SEARCH_CONVERSATIONS_LIST_SUCCESS_LAZY =
  "SEARCH_CONVERSATIONS_LIST_SUCCESS_LAZY";
const SEARCH_CONVERSATIONS_LIST_FAILURE_LAZY =
  "SEARCH_CONVERSATIONS_LIST_FAILURE_LAZY";

const SCROLL_SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY =
  "SCROLL_SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY";

const CONVERSATIONS_ADVANCE_SEARCH_GET_SUCCESS =
  "CONVERSATIONS_ADVANCE_SEARCH_GET_SUCCESS";
// ------------------------------------
// Action Creators
// ------------------------------------
const createConversationRequest = () => ({
  type: CONVERSATIONS_CREATE_REQUEST,
});
const createConversationSuccess = (res: any) => ({
  type: CONVERSATIONS_CREATE_SUCCESS,
  payload: res,
});
const createConversationFailure = (err: any) => ({
  type: CONVERSATIONS_CREATE_FAILURE,
  payload: err && err.response && err.response.data,
});

const getConversationRequest = () => ({ type: CONVERSATIONS_GET_REQUEST });
const getConversationSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_GET_SUCCESS,
  payload: res.data,
});
const getConversationAdvanceSearchSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_ADVANCE_SEARCH_GET_SUCCESS,
  payload: res.data,
});
const getConversationFailure = (err: AxiosError) => ({
  type: CONVERSATIONS_GET_FAILURE,
  payload: err && err.response && err.response.data,
});

const hideConversationRequest = () => ({ type: CONVERSATIONS_HIDE_REQUEST });
const hideConversationSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_HIDE_SUCCESS,
  payload: res.data,
});
const hideConversationFailure = (err: AxiosError) => ({
  type: CONVERSATIONS_HIDE_FAILURE,
  payload: err && err.response && err.response.data,
});

const listConversationsRequest = () => ({ type: CONVERSATIONS_LIST_REQUEST });
const listConversationsSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_LIST_SUCCESS,
  payload: res.data,
});
const listConversationsFailure = (err: AxiosError) => ({
  type: CONVERSATIONS_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const receiveConversationMessage = (message: IMessage) => ({
  type: CONVERSATIONS_RECEIVE_MESSAGE,
  payload: message,
});

const receiveNewConversation = (conversation: IConversation) => ({
  type: CONVERSATIONS_RECEIVE_CONVERSATION,
  payload: conversation,
});

const markDeletedConversation = (conversation: IConversation) => ({
  type: CONVERSATIONS_MARK_CONVERSATION,
  payload: conversation,
});

const updateConversationName = (res: AxiosResponse) => ({
  type: CONVERSATION_UPDATE_NAME,
  payload: res.data,
});

const resetConversationScroll = () => ({ type: CONVERSATIONS_RESET_SCROLL });

const setActiveConversation = (conversationId: string) => ({
  type: CONVERSATIONS_SET_ACTIVE,
  payload: conversationId,
});
const setActiveConversationAndScroll = (
  conversationId: string,
  messageId: number
) => ({
  type: CONVERSATIONS_SET_ACTIVE_AND_SCROLL,
  payload: { cid: conversationId, mid: messageId },
});

const sendMessageRequest = () => ({ type: CONVERSATIONS_SEND_REQUEST });
const sendMessageSuccess = (res: any) => ({
  type: CONVERSATIONS_SEND_SUCCESS,
  payload: res,
});
const sendMessageFailure = (err: any) => ({
  type: CONVERSATIONS_SEND_FAILURE,
  payload: err && err.response && err.response.data,
});

const updateContactStore = (contact: IExternalContact) => ({
  type: UPDATE_CONTACT,
  payload: contact,
});

const setUnreadMessagesCount = (payload: any) => ({
  type: SET_UNREAD_COUNT,
  payload,
});
const setAllUnreadMessagesCount = (payload: any) => ({
  type: SET_ALL_UNREAD_COUNT,
  payload,
});

const setDiffOfUnread = (sender: string) => ({
  type: SET_DIFF_UNREAD,
  payload: { sender },
});

const removeConversationMessage = (message: IMessage) => ({
  type: CONVERSATIONS_REMOVE_MESSAGE,
  payload: message,
});

const removeConversationMessageFailure = (err: any) => ({
  type: CONVERSATIONS_REMOVE_MESSAGE_FAILURE,
  payload: err ? err : [],
});

const listBlastConversationsRequest = () => ({
  type: CONVERSATIONS_BALST_LIST_REQUEST,
});

const listBlastConversationsSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_BLAST_LIST_SUCCESS,
  payload: res.data,
});

const listBlastConversationsFailure = (err: AxiosError) => ({
  type: CONVERSATIONS_BLAST_LIST_FAILURE,
  payload: err && err.response && err.response.data,
});

const getLoadMessageRequest = () => ({ type: LOAD_MESSAGE_GET_REQUEST });

const getLoadMessageSuccess = (res: AxiosResponse) => ({
  type: LOAD_MESSAGE_GET_SUCCESS,
  payload: res.data,
});

/** Added For Conversation And Search Pagination */
const listConversationsRequestlazy = () => ({
  type: CONVERSATIONS_LIST_REQUEST_LAZY,
});
const listConversationsSuccesslazy = (res: AxiosResponse) => ({
  type: CONVERSATIONS_LIST_SUCCESS_LAZY,
  payload: res.data,
});
const listConversationsFailurelazy = (err: AxiosError) => ({
  type: CONVERSATIONS_LIST_FAILURE_LAZY,
  payload: err && err.response && err.response.data,
});

const listConversationsExcludeSuccess = (res: AxiosResponse) => ({
  type: CONVERSATIONS_LIST_EXCLUDE_SUCCESS,
  payload: res.data,
});

const listSearchConversationsRequestlazy = (name: string) => ({
  type: SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY,
  payload: name,
});
const listSearchConversationsSuccesslazy = (res: AxiosResponse) => ({
  type: SEARCH_CONVERSATIONS_LIST_SUCCESS_LAZY,
  payload: res.data,
});
const listSearchConversationsFailurelazy = (err: AxiosError) => ({
  type: SEARCH_CONVERSATIONS_LIST_FAILURE_LAZY,
  payload: err && err.response && err.response.data,
});

const listScrollSearchConversationsRequestlazy = () => ({
  type: SCROLL_SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY,
});

// ------------------------------------
// Actions
// ------------------------------------

const createBlast = (id: number) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { instance } = getState().websocket;
  const payload = { id };
  // dispatch(createConversationRequest());
  instance &&
    instance.emit("blast_add", payload, (err: Error, res: any) => {
      if (err) {
        dispatch(createConversationFailure(err));
      } else {
        dispatch(receiveNewConversation(res));
        dispatch(setActiveConversation(res.data.id));
      }
    });
};

const createConversation = (internal = [], external = []) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { instance } = getState().websocket;
  const { activePhone } = getState().users.authenticatedUser;
  const payload = {
    internal: [...internal, ...[activePhone]],
    external,
  };
  dispatch(createConversationRequest());
  instance &&
    instance.emit("conversation_add", payload, (err: Error, res: any) =>
      err
        ? dispatch(createConversationFailure(err))
        : dispatch(createConversationSuccess(res))
    );
};

const makeRequestCreator = () => {
  let call: any;
  return (url: string, options: object) => {
    if (call) {
      call.cancel("Only one request allowed at a time.");
    }
    call = Axios.CancelToken.source();
    return Axios.get(url, {
      ...options,
      cancelToken: call.token,
    });
  };
};
const get = makeRequestCreator();

const getConversation = (
  conversationId: string,
  type: string,
  cb: Function
) => async (dispatch: Dispatch, getState: () => any) => {
  const { id, token } = getState().users.authenticatedUser;

  if (id && token) {
    try {
      dispatch(getConversationRequest());
      const fetch_message_url =
        type === "blast"
          ? `${API_URL}/users/${id}/${type}/${conversationId}`
          : `${API_URL}/users/${id}/${type}/${conversationId}/message/0`;
      const res = await get(fetch_message_url, withAuthorizationHeader(token));
      dispatch(getConversationSuccess(res));
      cb();
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(`Cancelling previous request: ${err.message}`);
      } else {
        dispatch(getConversationFailure(err));
      }
    }
  }
};

const getConversationAdvanceSearch = (
  conversationId: string,
  type: string,
  messageId: number
) => async (dispatch: Dispatch, getState: () => any) => {
  const { id, token } = getState().users.authenticatedUser;

  if (id && token) {
    try {
      dispatch(getConversationRequest());
      const fetch_message_url =
        type === "blast"
          ? `${API_URL}/users/${id}/${type}/${conversationId}`
          : `${API_URL}/users/${id}/${type}/${conversationId}/message/0/${messageId}`;
      const res = await get(fetch_message_url, withAuthorizationHeader(token));
      const res_data = { ...res };
      res_data["data"]["scrollToMessage"] = messageId;
      dispatch(getConversationAdvanceSearchSuccess(res_data));
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(`Cancelling previous request: ${err.message}`);
      } else {
        dispatch(getConversationFailure(err));
      }
    }
  }
};

const getCSVDataForDownloadMessage = (
  conversationId: string,
  type: string
) => async (dispatch: Dispatch, getState: () => any) => {
  return new Promise(async (resolve, reject) => {
    const { id, token } = getState().users.authenticatedUser;
    if (id && token) {
      try {
        const res = await get(
          `${API_URL}/csv_data/users/${id}/${type}/${conversationId}`,
          withAuthorizationHeader(token)
        );
        resolve(res.data);
      } catch (err) {
        reject(err && err.response && err.response.data);
      }
    } else {
      reject({ msg: "No Token" });
    }
  });
};

const getConversationsList_old = () => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(listConversationsRequest());
      const res = await Axios.get(
        `${API_URL}/message/conversations`,
        withAuthorizationHeader(token)
      );
      dispatch(listConversationsSuccess(res));
      resolve(res);
      try {
        dispatch(listBlastConversationsRequest());
        const res = await Axios.get(
          `${API_URL}/blast/conversations`,
          withAuthorizationHeader(token)
        );
        dispatch(listBlastConversationsSuccess(res));
      } catch (err) {
        dispatch(listBlastConversationsFailure(err));
      }
    } catch (err) {
      dispatch(listConversationsFailure(err));
      reject(err);
    }
  });
};

const getConversationsList = () => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(listConversationsRequest());
      const excres = await Axios.get(
        `${API_URL}/message/conversations/exclude`,
        withAuthorizationHeader(token)
      );
      dispatch(listConversationsExcludeSuccess(excres));
      const res = await Axios.post(
        `${API_URL}/message/conversations/lazy`,
        { excludeConversationId: [...excres.data] },
        withAuthorizationHeader(token)
      );
      dispatch(listConversationsSuccess(res));
      resolve(res);
      try {
        dispatch(listBlastConversationsRequest());
        const res = await Axios.get(
          `${API_URL}/blast/conversations`,
          withAuthorizationHeader(token)
        );
        dispatch(listBlastConversationsSuccess(res));
      } catch (err) {
        dispatch(listBlastConversationsFailure(err));
      }
    } catch (err) {
      dispatch(listConversationsFailure(err));
      reject(err);
    }
  });
};

const getConversationsListLazy = () => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  let excludeConversationId = [...getState().conversations.excludeId];
  getState().conversations.data.forEach((dt: any) => {
    if (dt.type === "conversation") {
      excludeConversationId.push(dt.id);
    }
  });
  try {
    dispatch(listConversationsRequestlazy());
    const res = await Axios.post(
      `${API_URL}/message/conversations/lazy`,
      { excludeConversationId },
      withAuthorizationHeader(token)
    );
    dispatch(listConversationsSuccesslazy(res));
  } catch (err) {
    dispatch(listConversationsFailurelazy(err));
  }
};

const getSearchConversationsListLazy = (
  name: string,
  scroll: boolean = false
) => async (dispatch: Dispatch, getState: () => any) => {
  const { token } = getState().users.authenticatedUser;
  let excludeConversationId = [...getState().conversations.excludeId];
  let excludePhoneId = getState().users.authenticatedUser.phones.map(
    (phone: any) => phone.id
  );
  if (scroll) {
    getState().conversations.searchConversations.forEach((dt: any) => {
      if (dt.type === "conversation") {
        excludeConversationId.push(dt.id);
      }
    });
  }
  try {
    if (scroll) {
      dispatch(listScrollSearchConversationsRequestlazy());
    } else {
      dispatch(listSearchConversationsRequestlazy(name));
    }
    const res = await Axios.post(
      `${API_URL}/message/conversations/lazy/${name}`,
      { excludeConversationId, excludePhoneId },
      withAuthorizationHeader(token)
    );
    dispatch(listSearchConversationsSuccesslazy(res));
  } catch (err) {
    dispatch(listSearchConversationsFailurelazy(err));
  }
};

const receiveConversation = (conversation: IConversation) => (
  dispatch: Dispatch
) => {
  dispatch(receiveNewConversation(conversation));
};

const markConversationDeleted = (conversation: IConversation) => (
  dispatch: Dispatch
) => {
  dispatch(markDeletedConversation(conversation));
};

const receiveMessage = (message: IMessage) => (
  dispatch: Dispatch,
  getState: any
) => {
  dispatch(receiveConversationMessage(message));
  const { activeConversation } = getState().conversations;
  message.conversation_id === activeConversation.id &&
    dispatch(clearUnreadMessages(activeConversation.id));
};

const resetScroll = () => (dispatch: Dispatch) => {
  dispatch(resetConversationScroll());
};

const hideConversation = (conversationId: string, type: string) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  try {
    dispatch(hideConversationRequest());
    const res = await Axios.post(
      `${API_URL}/conversation/visibility`,
      { conversation_id: conversationId, visible: false, type },
      withAuthorizationHeader(token)
    );
    dispatch(hideConversationSuccess(res));
  } catch (err) {
    dispatch(hideConversationFailure(err));
  }
};

const openConversation = (conversationId: string) => (dispatch: Dispatch) => {
  dispatch(setActiveConversation(conversationId));
};

const openConversationAndScroll = (
  conversationId: string,
  messageId: number
) => (dispatch: Dispatch, getState: () => any) => {
  getConversationAdvanceSearch(conversationId, "conversation", messageId)(
    dispatch,
    getState
  ).then(() => {
    dispatch(setActiveConversationAndScroll(conversationId, messageId));
    dispatch(clearUnreadMessages(conversationId));
  });
};

const setUnreadMessages = (payload: any) => (
  dispatch: Dispatch,
  getState: () => any
) => {
  const data = { ...payload };
  const { activeConversation } = getState().conversations;
  console.log("activeConversation ", activeConversation);
  if (activeConversation && activeConversation !== -1) {
    for (const phoneId in data) {
      data[phoneId][activeConversation.id] &&
        delete data[phoneId][activeConversation.id];
    }
  }

  const unreadConversations =
    getState().conversations.unreadConversations || {};

  if (unreadConversations) {
    const getFlatListOfUnreads = list =>
      Object.values(list).reduce(
        (result, value) => ({ ...result, ...value }),
        {}
      );
    const newConv = getFlatListOfUnreads(data);
    const conv = getFlatListOfUnreads(unreadConversations);

    // const updatedConversationId = Object.keys(newConv).find(
    //   item => !conv[item] || newConv[item].length > conv[item].length
    // );
    let updatedConversationId = Object.keys(newConv).find(
      item => !conv[item] || newConv[item].length > conv[item].length
    );
    // if (!updatedConversationId && activeConversation && document.hidden) {
    //   updatedConversationId = activeConversation.id;
    // }

    if (updatedConversationId) {
      const updatedConversation = getState().conversations.data.find(
        item => item.id === updatedConversationId
      );
      if (updatedConversation) {
        const sender =
          updatedConversation.external && updatedConversation.external.length
            ? `${updatedConversation.external[0].first_name} ${updatedConversation.external[0].last_name}`
            : (updatedConversation.internal.find(item => item.id) || {})
                .title || "Internal User";
        dispatch(setDiffOfUnread(sender));
      }
    }
  }

  const unreadMessages =
    payload[getState().users.authenticatedUser.activePhone] || {};
  dispatch(setUnreadMessagesCount(unreadMessages));
  dispatch(setAllUnreadMessagesCount(data));
};

const clearUnreadMessages = (conversationId: string) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { instance } = getState().websocket;
  const { activePhone } = getState().users.authenticatedUser;
  instance &&
    instance.emit(
      "clear_unread",
      { conversationId },
      (_err: Error, _res: any) => {
        dispatch(setUnreadMessagesCount({ [conversationId]: [] }));
        dispatch(
          setAllUnreadMessagesCount({ [activePhone]: { [conversationId]: [] } })
        );
      }
    );
};

const clearDiffUnread = () => async (dispatch: Dispatch) =>
  dispatch({ type: CLEAR_DIFF_UNREAD });

const sendMessage = (
  conversationId: string,
  message: string,
  type: string,
  media: object | null = null
) => (dispatch: Dispatch, getState: () => any) => {
  const { instance } = getState().websocket;
  let payload = {
    conversation: conversationId,
    text_content: message,
    type,
  };

  if (media) {
    payload = { ...payload, ...media };
  }

  dispatch(sendMessageRequest());
  instance &&
    instance.emit("post_add", payload, (err: Error, res: any) =>
      err
        ? dispatch(sendMessageFailure(err))
        : dispatch(sendMessageSuccess(res))
    );
};

const addAttachment = (file: File) => async (
  dispatch: Dispatch,
  getState: () => any
) => {
  const { token } = getState().users.authenticatedUser;
  const BUCKET_NAME = "lol";
  const fileName = `${uuid.v4()}.${file.type.split("/")[1].split("+")[0]}`;

  try {
    const formData = new FormData();

    formData.append("photo", file);

    return Axios.put(
      `${API_URL}/assets/upload_file/${BUCKET_NAME}/${fileName}`,
      formData,
      withAuthorizationHeader(token)
    )
      .then(res => ({
        ...res,
        data: {
          media_file_name: file.name,
          media_file_mime: file.type,
          media_minio_bucket_name: BUCKET_NAME,
          media_link: res.data.data.split("?")[0],
        },
      }))
      .catch(err => err);
  } catch (err) {
    return err;
  }
};

const removeMessage = (message: any) => (dispatch: Dispatch) => {
  console.log("================= Delete Message ======= ", message);
  dispatch(removeConversationMessage(message));
};

const deleteMessage = (
  conversationId: string,
  messageId: string,
  type: string
) => (dispatch: Dispatch, getState: () => any) => {
  const { instance } = getState().websocket;
  let payload = {
    conversation_id: conversationId,
    message_id: messageId,
    type,
  };
  console.log("Delete Message Payload ", payload);
  if (instance) {
    instance.emit("remove_message", payload, (err: Error, res: any) => {
      if (err) {
        dispatch(removeConversationMessageFailure([err]));
      } else {
        dispatch({ type: MODAL_HIDE });
      }
    });
  } else {
    dispatch(
      removeConversationMessageFailure(["Error while Connecting Server."])
    );
  }
};

const loadMessage = (
  conversationId: string,
  type: string,
  cb: Function
) => async (dispatch: Dispatch, getState: () => any) => {
  const { id, token } = getState().users.authenticatedUser;
  const messageCount: 0 = getState().conversations.activeConversation.messages
    .length;
  if (id && token) {
    try {
      dispatch(getLoadMessageRequest());
      const res = await get(
        `${API_URL}/users/${id}/${type}/${conversationId}/message/${messageCount}`,
        withAuthorizationHeader(token)
      );
      dispatch(getLoadMessageSuccess(res));
      cb();
    } catch (err) {
      if (Axios.isCancel(err)) {
        console.error(`Cancelling previous request: ${err.message}`);
      } else {
        dispatch(getConversationFailure(err));
      }
    }
  }
};

const sortConversations = (a: any, b: any): number => {
  const aDate = a.last_message ? new Date(a.last_message).getTime() : 0;
  const bDate = b.last_message ? new Date(b.last_message).getTime() : 0;
  return aDate < bDate ? -1 : aDate > bDate ? 1 : 0;
};

export const conversationActions = {
  createBlast,
  createConversation,
  getConversation,
  getConversationsList,
  hideConversation,
  openConversation,
  openConversationAndScroll,
  receiveConversation,
  markConversationDeleted,
  receiveMessage,
  resetScroll,
  sendMessage,
  updateContactStore,
  setUnreadMessages,
  clearUnreadMessages,
  addAttachment,
  clearDiffUnread,
  updateConversationName,
  getCSVDataForDownloadMessage,
  removeMessage,
  deleteMessage,
  loadMessage,

  getConversationsListLazy,
  getSearchConversationsListLazy,
};

// ------------------------------------
// Reducer
// ------------------------------------
const initialState = {
  activeConversation: null,
  scrollToMessage: -1,
  isFetching: false,
  isFetchingConversation: false,
  data: [],
  unreadMessages: {},
  unreadConversations: null,
  diffUnread: null,
  hasMoreMessage: false,

  isFetchConversationLazy: false,
  hasMoreConversations: true,
  excludeId: [],
  searchConversations: [],
  isFetchingSearchConversations: false,
  hasSearchMoreConversations: true,
};

export default (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case USERS_LOGOUT_USER_REQUEST:
      return { ...initialState };

    case CONVERSATIONS_CREATE_REQUEST:
      return { ...state, isFetching: true };

    case CONVERSATIONS_LIST_REQUEST:
      return {
        ...state,
        data: [],
        isFetching: true,
        hasMoreConversations: true,
      };

    case CONVERSATIONS_GET_REQUEST:
      return { ...state, isFetchingConversation: true };

    case CONVERSATIONS_BALST_LIST_REQUEST:
      return { ...state, isFetchingBlast: true };

    case CONVERSATIONS_GET_SUCCESS:
      return {
        ...state,
        isFetchingConversation: false,
        activeConversation: action.payload,
        hasMoreMessage: action.payload.hasMoreMessage,
        scrollToMessage: -1,
        data: state.data.find(
          (conversation: IConversation) => conversation.id === action.payload.id
        )
          ? state.data.map((conversation: IConversation) =>
              conversation.id === action.payload.id
                ? action.payload
                : conversation
            )
          : [action.payload, ...state.data],
      };

    case CONVERSATIONS_ADVANCE_SEARCH_GET_SUCCESS:
      return {
        ...state,
        isFetchingConversation: false,
        activeConversation: action.payload,
        hasMoreMessage: action.payload.hasMoreMessage,
        scrollToMessage: action.payload.scrollToMessage,
        data: state.data.find(
          (conversation: IConversation) => conversation.id === action.payload.id
        )
          ? state.data.map((conversation: IConversation) =>
              conversation.id === action.payload.id
                ? action.payload
                : conversation
            )
          : [action.payload, ...state.data],
      };

    case CONVERSATIONS_MARK_CONVERSATION:
      let conversations = Object.assign([], state.data);
      return {
        ...state,
        data: state.data.map((conversation: IConversation) => {
          if (conversation.id === action.payload.id) {
            conversation.is_deleted = true;
          }
          return conversation;
        }),
      };

    case CONVERSATIONS_RECEIVE_CONVERSATION:
      const conversation = action.payload.id
        ? action.payload
        : action.payload.data;
      conversations = Object.assign([], state.data);
      if (
        !_.includes(_.map(conversations, (c: any) => c.id), conversation.id)
      ) {
        conversations.unshift(conversation as never);
      }
      conversations.sort(sortConversations).reverse();
      return { ...state, data: conversations };

    case CONVERSATIONS_RECEIVE_MESSAGE:
      let newConversations = state.data
        .map((item: IConversation) => {
          if (item.id === action.payload.conversation_id) {
            item.messages = [...item.messages, action.payload];
            item.last_message = new Date().toISOString();
          }
          return item;
        })
        .sort(sortConversations)
        .reverse();
      return {
        ...state,
        activeConversation:
          state.activeConversation &&
          state.activeConversation.id === action.payload.conversation_id
            ? {
                ...state.activeConversation,
                last_message: new Date().toISOString(),
                messages: [
                  ...state.activeConversation.messages,
                  action.payload,
                ],
              }
            : { ...state.activeConversation },
        scrollToMessage: -1,
        data: Object.assign([], newConversations),
      };

    case CONVERSATIONS_SET_ACTIVE: {
      const con = state.data.find(
        (item: IConversation) => item.id === action.payload
      );
      let activeConversation = con;
      if (!activeConversation) {
        activeConversation = state.searchConversations.find(
          (item: IConversation) => item.id === action.payload
        );
      }
      return {
        ...state,
        data: con
          ? state.data
          : [...state.data, activeConversation]
              .sort(sortConversations)
              .reverse(),
        activeConversation: activeConversation,
        scrollToMessage: -1,
      };
    }

    case CONVERSATIONS_SET_ACTIVE_AND_SCROLL:
      return {
        ...state,
        activeConversation: state.data.find(
          (item: IConversation) => item.id === action.payload.cid
        ),
        scrollToMessage: action.payload.mid,
      };

    case CONVERSATIONS_RESET_SCROLL:
      return {
        ...state,
        scrollToMessage: -1,
      };

    case CONVERSATIONS_CREATE_SUCCESS: {
      const excludeId = state.excludeId.filter(
        (id: any) => id !== action.payload.data.id
      );
      return {
        ...state,
        isFetching: false,
        excludeId: excludeId,
        activeConversation: action.payload.data,
      };
    }

    case CONVERSATIONS_LIST_SUCCESS:
      const activeConversation = action.payload[0]
        ? action.payload[0]
        : state.activeConversation
        ? state.activeConversation
        : null;
      const processed = action.payload.map((item: IConversation) => ({
        ...item,
        unreadMessages: state.unreadMessages[item.id]
          ? state.unreadMessages[item.id].length
          : 0,
      }));
      return {
        ...state,
        isFetching: false,
        activeConversation,
        data: processed,
        hasMoreConversations: action.payload.length == CONVERSATION_FETCH_LIMIT,
      };

    case CONVERSATIONS_BLAST_LIST_SUCCESS:
      const processed = action.payload.map((item: IConversation) => ({
        ...item,
        unreadMessages: state.unreadMessages[item.id]
          ? state.unreadMessages[item.id].length
          : 0,
      }));
      return {
        ...state,
        isFetchingBlast: false,
        data: [...state.data, ...processed],
      };

    case CONVERSATIONS_CREATE_FAILURE:
      return { ...state, isFetching: false };

    case CONVERSATIONS_GET_FAILURE:
      return { ...state, isFetchingConversation: false };

    case CONVERSATIONS_HIDE_SUCCESS:
      return {
        ...state,
        activeConversation: state.data.length > 0 ? state.data[0] : undefined,
        excludeId: [...state.excludeId, action.payload],
        data: state.data.filter((item: IConversation) => {
          return item.id !== action.payload;
        }),
      };
    case CONVERSATIONS_UNSET:
      return { ...state, activeConversation: null };

    case DELETE_CONTACT:
    case UPDATE_CONTACT:
      return {
        ...state,
        data: state.data.map((item: IConversation) => {
          if (
            item.external &&
            item.external.length === 1 &&
            item.external[0].id === action.payload.id
          ) {
            item = {
              ...item,
              name: `${action.payload.first_name} ${action.payload.last_name}`,
              external: [action.payload],
              messages: item.messages.map((message: IMessage) => {
                return message.sender_id === action.payload.id
                  ? {
                      ...message,
                      sender: {
                        ...message.sender,
                        first_name: action.payload.first_name,
                        last_name: action.payload.last_name,
                      },
                    }
                  : message;
              }),
            };
          }
          return item;
        }),
      };

    case USERS_LOGOUT_USER_REQUEST:
      return { ...state, activeConversation: null };

    case USERS_UPDATE_USER_SUCCESS:
      if (state.activeConversation && state.activeConversation.messages) {
        state.activeConversation.messages.forEach((message: IMessage) => {
          if (
            message.sender.internal &&
            message.sender_id === action.payload.id
          ) {
            message.sender = {
              ...message.sender,
              display_as: action.payload.display_as,
              first_name: action.payload.first_name,
              last_name: action.payload.last_name,
            };
          }
        });
        return { ...state };
      }
      return state;
    case SET_UNREAD_COUNT:
      const data = state.data.map((conversation: IConversation) => ({
        ...conversation,
        unreadMessages: action.payload.hasOwnProperty(conversation.id)
          ? action.payload[conversation.id].length
          : conversation.hasOwnProperty("unreadMessages")
          ? conversation.unreadMessages
          : 0,
      }));
      return { ...state, data };
    case SET_ALL_UNREAD_COUNT:
      return {
        ...state,
        unreadMessages: _.reduce(
          action.payload,
          (result, item) => {
            return { ...result, ...item };
          },
          {}
        ),
        unreadConversations: action.payload,
      };

    case SET_DIFF_UNREAD:
      return { ...state, diffUnread: { ...action.payload } };

    case CLEAR_DIFF_UNREAD:
      return { ...state, diffUnread: null };

    case CONVERSATION_UPDATE_NAME:
      if (
        state.activeConversation &&
        state.activeConversation.type === "blast"
      ) {
        return { ...state };
      }
      return {
        ...state,
        activeConversation: state.activeConversation
          ? [
              ...state.activeConversation.internal,
              ...state.activeConversation.external,
            ]
              .map(n => n.phone_number)
              .includes(action.payload.phone_number)
            ? {
                ...state.activeConversation,
                name: `${action.payload.first_name} ${action.payload.last_name}`,
                isUpdated: !state.activeConversation.isUpdated,
                updated_external: action.payload,
              }
            : { ...state.activeConversation }
          : null,
        data: state.data.map((conversation: IConversation) => {
          if (conversation.type === "blast") {
            return conversation;
          }
          const all = [...conversation.internal, ...conversation.external].map(
            n => n.phone_number
          );
          if (all.includes(action.payload.phone_number)) {
            conversation.name = `${action.payload.first_name} ${action.payload.last_name}`;
            return conversation;
          }
          return conversation;
        }),
      };

    case CONVERSATIONS_REMOVE_MESSAGE:
      const updateConversations = state.data
        .map((item: IConversation) => {
          if (item.id === action.payload.conversation_id) {
            item.messages = item.messages.filter(
              msg => msg.id !== action.payload.message_id
            );
            item.last_message = item.messages[item.messages.length - 1]
              ? item.messages[item.messages.length - 1].sent_at
              : "";
          }
          return item;
        })
        .sort(sortConversations)
        .reverse();
      return {
        ...state,
        activeConversation:
          state.activeConversation &&
          state.activeConversation.id === action.payload.conversation_id
            ? {
                ...state.activeConversation,
                messages: state.activeConversation.messages.filter(
                  msg => msg.id !== action.payload.message_id
                ),
              }
            : { ...state.activeConversation },
        data: Object.assign([], updateConversations),
      };

    case LOAD_MESSAGE_GET_REQUEST:
      return {
        ...state,
        isFetchingConversation: true,
        scrollToMessage: state.activeConversation.messages[0].id,
      };

    case LOAD_MESSAGE_GET_SUCCESS:
      const messages = [
        ...action.payload.messages,
        ...state.activeConversation.messages,
      ];
      const update_data = state.data.map((item: IConversation) => {
        if (item.id === state.activeConversation.id) {
          item.messages = [...messages];
        }
        return item;
      });
      return {
        ...state,
        isFetchingConversation: false,
        hasMoreMessage: action.payload.hasMoreMessage,
        scrollToMessage: state.activeConversation.messages[0].id,
        activeConversation: {
          ...state.activeConversation,
          messages,
        },
        data: Object.assign([], update_data),
      };

    case CONVERSATIONS_LIST_REQUEST_LAZY:
      return { ...state, isFetchConversationLazy: true };

    case SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY: {
      let str = action.payload.toLowerCase();
      return {
        ...state,
        isFetchingSearchConversations: true,
        hasSearchMoreConversations: true,
        searchConversations: [
          ...state.data.filter(
            (conversation: any) =>
              conversation.type === "blast" &&
              conversation.name.toLocaleLowerCase().indexOf(str) !== -1
          ),
        ],
      };
    }
    case SCROLL_SEARCH_CONVERSATIONS_LIST_REQUEST_LAZY:
      return { ...state, isFetchingSearchConversations: true };

    case SEARCH_CONVERSATIONS_LIST_SUCCESS_LAZY: {
      return {
        ...state,
        isFetchingSearchConversations: false,
        hasSearchMoreConversations:
          action.payload.length == CONVERSATION_FETCH_LIMIT,
        searchConversations: [...state.searchConversations, ...action.payload]
          .sort(sortConversations)
          .reverse(),
      };
    }

    case CONVERSATIONS_LIST_SUCCESS_LAZY:
      const processed = action.payload.map((item: IConversation) => ({
        ...item,
        unreadMessages: state.unreadMessages[item.id]
          ? state.unreadMessages[item.id].length
          : 0,
      }));
      return {
        ...state,
        data: [...state.data, ...processed].sort(sortConversations).reverse(),
        hasMoreConversations: action.payload.length == CONVERSATION_FETCH_LIMIT,
        isFetchConversationLazy: false,
      };

    case CONVERSATIONS_LIST_EXCLUDE_SUCCESS:
      return {
        ...state,
        excludeId: action.payload,
      };

    default:
      return state;
  }
};
